import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    ////console.log(items)
    //console.log(searchText)

    function sansAccent(str) {
      var accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ];
      var noaccent = [
        "A",
        "a",
        "E",
        "e",
        "I",
        "i",
        "O",
        "o",
        "U",
        "u",
        "N",
        "n",
        "C",
        "c",
      ];

      for (var i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i]);
      }

      return str;
    };

    if (searchText === "") {
      //console.log("text null")
      return items;
    } else {
      if (!items) return [];
      if (!searchText) return items;

      searchText = sansAccent(searchText.toLowerCase());
      return items.filter((it) => {
        //console.log(it)
        return (
          sansAccent(it.nom.toLowerCase()).includes(searchText) ||
          sansAccent(it.code_article.toLowerCase()).includes(searchText)
        );
      });
    }


  }
}
