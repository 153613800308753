<div class="modal-header">
  <h3 class="modal-title">Intervention du {{BdcService.dateHeure(rapport.date)}}</h3>

  <div class="card-options mr-0">
    <button class="btn btn-sm btn-default" (click)="delete()"><i class="fe fe-trash"></i> Supprimer</button>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
</div>
<div class="modal-body">

  <div class="clearfix">
    <div class="float-left">
      <p class="text-muted">Technicien : {{rapport.techniciens_mef}}</p>
    </div>

  </div>



  <div class="row">
    <div class="col-12">
      <table class="table card-table">
        <thead>
          <tr>
            <th class="w-1">Code</th>
            <th>Dénomination</th>
            <th>Qté</th>
            <th>Prix HT</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let article of rapport.articles">
            <td class="text-muted">{{article.code_article}}</td>
            <td>{{article.nom}}</td>
            <td class="text-muted">{{article.nb}}</td>
            <td class="text-muted">{{article.prix_ht}}€</td>
          </tr>

          <tr>
            <td class="text-muted"></td>
            <td class="text-muted"></td>
            <td class="float-right">Total</td>
            <td><strong>{{totalArticles(rapport).ht.toFixed(2)}}€</strong></td>
          </tr>
        </tbody>

      </table>
    </div>
    <div class="col-4">




    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p *ngIf="rapport.commentaire !== 'undefined'">{{rapport.commentaire}}</p>
    </div>
    <div class="col-12">

      <div class="row" *ngIf="rapport.photos.length > 0">
        <div class="col-12">
          <h5>Photos</h5>
        </div>
      </div>

      <div class="row" *ngIf="rapport.photos.length > 0">

        <div class="col-6">
          <b>Photos avant</b>
          <br>
          <span *ngFor="let photo of rapport.photos">
            <img *ngIf="photo.type === 'avant'" class="photo-inter" [src]="env.rapports_folder + photo.photo" />
          </span>

        </div>
        <div class="col-6">
          <b>Photos après</b>
          <br>
          <span *ngFor="let photo of rapport.photos">
            <img *ngIf="photo.type === 'apres'" class="photo-inter" [src]="env.rapports_folder + photo.photo" />
          </span>

        </div>
      </div>
    </div>

    <br>

    <div class="col-12">
      <h5 *ngIf="rapport.signature != ''">Signature du locataire</h5>
      <img *ngIf="rapport.signature != ''" [src]="env.signatures_folder + rapport.signature">
    </div>
  </div>










</div>
