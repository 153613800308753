<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/bailleurs">Bailleurs</a></li>
          <li class="breadcrumb-item">{{client.nom}}</li>
        </ol>
      </nav>

      <div class="page-header">
          <h1 class="page-title" *ngIf="!edit">
            {{client.nom}}
          </h1>
          <button class="btn btn-sm btn-default" (click)="editNom()" style="margin-left: 10px;"  *ngIf="!edit">
            <span class="fe fe-edit"></span>
          </button>

          <div class="input-group mb-3 col-6" *ngIf="edit">
            <input type="text" class="form-control" placeholder="{{client.nom}}"
                  [(ngModel)]="client.nom" aria-describedby="button-addon2">
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button" id="button-addon2" (click)="saveNom()">Enregistrer</button>
            </div>
          </div>

        <div class="card-options" style="margin-right: 10px;">

          <div class="form-group">

            <label class="custom-switch">
              <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
                [(ngModel)]="client.actif" (change)="changeState()">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description" *ngIf="client.actif">Actif</span>
              <span class="custom-switch-description" *ngIf="!client.actif">Inactif</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-cards">
        <div class="col-6">

          <div class="card">
            <div class="card-status bg-blue"></div>
            <div class="card-header">
              <h3 class="card-title">Marchés</h3>
              <div class="card-options">
                <button class="btn btn-sm btn-default" (click)="newMarche()">
                  <span class="fe fe-plus"></span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>Actifs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let marche of marchesActifs" (click)="goMarche(marche.id)">
                    <td>
                      <span>{{marche.nom}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-body">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>Inactifs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let marche of marchesInactifs" (click)="goMarche(marche.id)">
                    <td>
                      <span>{{marche.nom}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="col-6">



          <div class="card">
            <div class="card-status bg-blue"></div>
            <div class="card-header">
              <h3 class="card-title">Chargés de secteur</h3>
              <div class="card-options">
                <button class="btn btn-sm btn-default" (click)="newCharge()">
                  <span class="fe fe-plus"></span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>Actifs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let charge of chargesActifs" (click)="goCharge(charge.id)">
                    <td>
                      <span>{{charge.nom}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-body">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>Inactifs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let charge of chargesInactifs" (click)="goCharge(charge.id)">
                    <td>
                      <span>{{charge.nom}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
