import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-custom-article-modal',
  templateUrl: './custom-article-modal.component.html',
  styleUrls: ['./custom-article-modal.component.scss']
})
export class CustomArticleModalComponent implements OnInit {
  @Input() idBdc;

  nom = '';
  prix = 0;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
  }

  addBailleur() {
    if (this.nom !== '') {
      this.BdcService.newArticleCustom('HB', this.nom, this.prix).then((id_article: any) => {
        this.activeModal.close({
          article: {
            id: id_article,
            nom: this.nom, 
            code_article: 'HB',
            nb: 1,
            prix_ht: this.prix
          }
        });
      });
    }
  }

  cancel() {
    this.activeModal.close();
  }

}

