import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import frLocale from '@fullcalendar/core/locales/fr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { ModConfPlanifIndivComponent } from './modals/mod-conf-planif-indiv/mod-conf-planif-indiv.component';
import { ModSelectDateComponent } from './modals/mod-select-date/mod-select-date.component';
import { ModDeleteEventComponent } from './modals/mod-delete-event/mod-delete-event.component';
import { ModReplanifEventComponent } from './modals/mod-replanif-event/mod-replanif-event.component';
import { ModSelectTechComponent } from './modals/mod-select-tech/mod-select-tech.component';

import {
  BdcService
} from '../../../services/service-bdc.service';
import { TestBed } from '@angular/core/testing';

declare var moment: any;
declare var mapboxgl: any;
declare var $: any;

@Component({
  selector: 'app-widget-planif-indiv',
  templateUrl: './widget-planif-indiv.component.html',
  styleUrls: ['./widget-planif-indiv.component.scss']
})
export class WidgetPlanifIndivComponent implements OnInit {
  @Input() bdc;

  @Output() planifIntervention = new EventEmitter<any[]>();

  agence;

  planifBcd = [];
  planifJour = []; // Les planifications de la periode selectionnee
  techniciens = {
    totalTechs: [],
    actifs: []
  };

  // Calendrier
  calendar_indiv;
  identifierIndex = 0;

  // Map
  map = null;
  tabMarkers = [];
  geojsonSelected = {
    type: "geojson",
    data: {
      "type": "FeatureCollection",
      features: []
    }
  };

  constructor(private router: Router, private BdcService: BdcService, private modalService: NgbModal, private route: ActivatedRoute, private ChangeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  ngOnDestroy() {
    this.calendar_indiv.destroy();
    this.identifierIndex = 0;
    this.planifJour = [];

    $(document).off('click');
  }

  init() {
    const that = this;

    this.identifierIndex = 0;

    if (this.bdc.date !== "") {
      this.BdcService.getAgence(this.BdcService.getAgenceId()).then((agence: any) => {
        this.agence = agence;

        this.getResources().then((techs: any) => {
          that.techniciens = techs;
          this.planifBcd = [];

          for (let planif of this.bdc.planification) {

            this.planifBcd.push({
              id: planif.id,
              id_bdc: planif.id_bdc,
              bdc: this.bdc,
              title: "Planif. n° " + (this.identifierIndex + 1),
              start: new Date(planif.start),
              end: new Date(planif.end),
              start_hr: moment(planif.start).format('DD/MM/YYYY HH[h]mm'),
              end_hr: moment(planif.end).format('DD/MM/YYYY HH[h]mm'),
              resourceId: planif.id_technicien,
              nom: that.getTech(planif.id_technicien).title,
              couleur: "hsl( " + that.getTech(planif.id_technicien).couleur + ", 90%, 30% )",
              editable: true,
              overlap: true
            });

            this.identifierIndex++;
          }

          this.initTab();
          this.initMap();
          this.initCalendar();
          this.dayChanged(); // Get events du jour

        });
      });
    }
  }

  getTech(id) {
    console.log(id)
    console.log(this.techniciens.totalTechs)

    for (const tech of this.techniciens.totalTechs) {
      if (parseInt(tech.id) == id) {
        console.log(tech)
        return tech;
      }
    }
    return null;
  }

  initTab() {
    const that = this;

    // Initialisation de Data Tables
    setTimeout(function () {
      if ($.fn.dataTable.isDataTable('#tabPlanifs')) {
        $('#tabPlanifs').DataTable();
      }
      else {
        $('#tabPlanifs').DataTable({
          stateSave: true,
          lengthChange: false,
          searching: false,
          info: false,
          language: {
            "sProcessing": "Traitement en cours...",
            "sSearch": "Rechercher&nbsp;:",
            "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
            "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
              "sFirst": "Premier",
              "sPrevious": "Pr&eacute;c&eacute;dent",
              "sNext": "Suivant",
              "sLast": "Dernier"
            },
            "oAria": {
              "sSortAscending": ": activer pour trier la colonne par ordre croissant",
              "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            },
            "select": {
              "rows": {
                _: "%d lignes selectionnées",
                0: "Aucune ligne selectionnée",
                1: "1 ligne selectionnée"
              }
            }
          }
        });
      }
    }, 250);
  }

  initCalendar() {
    const that = this;

    // Get calendar
    let calendarEl: HTMLElement = document.getElementById('calendar_indiv')!;

    if (!document.getElementById('calendar_indiv').hasChildNodes()) {

      this.calendar_indiv = new Calendar(calendarEl, {
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        plugins: [interactionPlugin, dayGridPlugin, listPlugin, timeGridPlugin, resourceTimelinePlugin],
        locale: frLocale,
        editable: true, // enable draggable events
        selectable: true,
        droppable: true,
        height: 'auto',
        scrollTime: '00:00', // undo default 6am scrollTime

        // Boutons
        customButtons: {
          chooseDate: {
            text: 'Choisir la date',
            click: function () {
              const modalRef = that.modalService.open(ModSelectDateComponent);
              modalRef.result.then((result) => {
                //console.log(result)
                that.calendar_indiv.gotoDate(result);
                that.dayChanged();
              }, (reason) => {
                //console.log(reason);
              });
            }
          }
        },
        header: {
          left: 'today,chooseDate prev,next',
          center: 'title',
          right: 'resourceTimelineDay,timeGridWeek,dayGridMonth'
        },
        buttonIcons: {
          prev: 'left-single-arrow',
          next: 'right-single-arrow'
        },
        defaultView: 'resourceTimelineDay',
        resourceLabelText: 'Techniciens',

        eventSources: [
          {
            id: 'savedEvents',
            events: (fetchInfo, successCallback, failureCallback) => {
              successCallback(that.planifJour);
            },
            textColor: 'white'
          },
          {
            id: 'aPlanifier',
            events: (fetchInfo, successCallback, failureCallback) => {
              successCallback(that.planifBcd); ///planifBdc
            },
            textColor: 'white'
          }
        ],

        eventRender: function (renderInfo) {
          renderInfo.el.style.backgroundColor = renderInfo.event.extendedProps.couleur;
        },
        resourceRender: function (renderInfo) {
          renderInfo.el.style.background = "linear-gradient(to right, hsl( " + renderInfo.resource.extendedProps.couleur + ", 90%, 30% ), white)";
          renderInfo.el.style.borderRadius = "10px";
          renderInfo.el.style.color = 'white';
        },

        eventDrop: function (eventResizeInfo) {
          that.saveEventChange(eventResizeInfo)
        },
        eventResize: function (eventResizeInfo) {
          that.saveEventChange(eventResizeInfo)
        },

        // Sélection d'une date dans le calendrier
        select: (info) => {
          if (info.view.viewSpec.type == 'timeGridWeek') {
            const modalRef = that.modalService.open(ModSelectTechComponent);
            modalRef.componentInstance.techs = that.calendar_indiv.getResources();

            modalRef.result.then((techId) => {
              console.log(techId)

              that.planifBcd.push({
                id: that.identifierIndex,
                id_bdc: that.bdc.id,
                title: "Planif. n° " + (that.identifierIndex + 1),
                resourceId: techId,
                nom: that.getTech(techId).title,
                couleur: "hsl( " + that.calendar_indiv.getResourceById(techId).extendedProps.couleur + ", 90%, 30% )",
                start: info.start,
                end: info.end,
                start_hr: moment(info.start).format('DD/MM/YYYY HH[h]mm'),
                end_hr: moment(info.end).format('DD/MM/YYYY HH[h]mm'),
                editable: true,
                overlap: true
              });

              that.identifierIndex++;
              that.calendar_indiv.refetchEvents();
            }, (reason) => {
            });

          }
          else {
            that.planifBcd.push({
              id: that.identifierIndex,
              id_bdc: that.bdc.id,
              title: "Planif. n° " + (that.identifierIndex + 1),
              resourceId: info.resource.id,
              nom: that.getTech(info.resource.id).title,
              couleur: "hsl( " + that.calendar_indiv.getResourceById(info.resource.id).extendedProps.couleur + ", 90%, 30% )",
              start: info.start,
              end: info.end,
              start_hr: moment(info.start).format('DD/MM/YYYY HH[h]mm'),
              end_hr: moment(info.end).format('DD/MM/YYYY HH[h]mm'),
              editable: true,
              overlap: true
            });

            that.identifierIndex++;
            that.calendar_indiv.refetchEvents();
          }
        },

        eventClick: function (eventClickInfo) {

          // Si l'événement cliqué concerne le BDC
          if (eventClickInfo.event.source.id === 'aPlanifier') {
            const modalRef = that.modalService.open(ModDeleteEventComponent);
            modalRef.componentInstance.idBdc = eventClickInfo.event.extendedProps.id_bdc;;
            modalRef.componentInstance.event = eventClickInfo.event;
            modalRef.result.then((result) => {
              that.deleteNewEvent(result.event)
            }, (reason) => { });
          } else if (eventClickInfo.event.source.id === 'savedEvents') { // Si ça concerne un autre BDC
            const modalRef = that.modalService.open(ModReplanifEventComponent);
            modalRef.componentInstance.idBdc = eventClickInfo.event.extendedProps.id_bdc;
            modalRef.componentInstance.event = eventClickInfo.event;
            modalRef.result.then((result) => {

              if (result.action === 'replanifier') {
                that.BdcService.bdcToPlanificationState(result.event._def.extendedProps.id_bdc).then((res) => {
                  that.router.navigate(['bons-de-commande/planification/' + result.event._def.extendedProps.id_bdc]);
                  that.initCalendar();
                });
              }
              else if (result.action === 'consulter') {
                switch (result.event._def.extendedProps.bdc.status) {
                  case "": {
                    that.router.navigate(['bons-de-commande/validation/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "A_VALIDER": {
                    that.router.navigate(['bons-de-commande/validation/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "A_PLANIFIER": {
                    that.router.navigate(['bons-de-commande/planification/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "A_EXECUTER": {
                    that.router.navigate(['bons-de-commande/intervention/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "A_FACTURER": {
                    that.router.navigate(['bons-de-commande/facturation/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "FACTURE": {
                    that.router.navigate(['bons-de-commande/post-facturation/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "ARCHIVE": {
                    that.router.navigate(['bons-de-commande/historique/' + result.event._def.extendedProps.bdc.id]);
                    break;
                  }
                  case "CORBEILLE": {
                    //statements;
                    break;
                  }
                  default: {
                    //statements;
                    break;
                  }
                }

              }
            }, (reason) => {
            });
          }
        }
      });

      this.calendar_indiv.render();

      for (const tech of that.techniciens.actifs) { // Chaque technicien est ajouté en tant que ressource
        this.calendar_indiv.addResource(tech);
      }

      // Si changement de jour, chargement des events
      $(document).on('click', '.fc-today-button', function () {
        that.dayChanged();
      });
      $(document).on('click', '.fc-prev-button', function () {
        that.dayChanged();
      });
      $(document).on('click', '.fc-next-button', function () {
        that.dayChanged();
      });
      $(document).on('click', '.fc-timeGridWeek-button', function () {
        that.dayChanged();
      });
      $(document).on('click', '.fc-resourceTimelineDay-button', function () {
        that.dayChanged();
      });
      $(document).on('click', '.fc-dayGridMonth-button', function () {
        that.dayChanged();
      });
    }
    else {
      this.calendar_indiv.destroy();
    }
  }


  initMap() {
    const that = this;

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hpcHNvbmR1bGVlIiwiYSI6ImQzM2UzYmQxZTFjNjczZWMyY2VlMzQ5NmM2MzEzYWRmIn0.0iPy8Qyw2FjGSxawGZxW8A';
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location 11
      center: [this.agence.longitude, this.agence.latitude], // starting position [lng, lat]
      zoom: this.agence.zoom // starting zoom
    });

    this.map.on('load', function () {

      that.map.loadImage("assets/map-icons/maps-and-flags" + ".png", (error, image) => {
        if (error) throw error;
        if (!that.map.hasImage('maps-and-flags')) {
          that.map.addImage('maps-and-flags', image);
          loaded();
        }
        else {
          loaded();
        }
      });

      let loaded = function () {
        that.getGeoloc(that.bdc, that.bdc.adresse_locataire + ' ' + that.bdc.code_postal_locataire + ' ' + that.bdc.ville_locataire).then((resp: any) => {

          that.map.addSource("selected", that.geojsonSelected);

          that.map.addLayer({
            id: "selected-bdc",
            type: "symbol",
            source: "selected",
            "layout": {
              "icon-image": "{icon}",
              "icon-ignore-placement": true,
              "icon-allow-overlap": true,
              "text-allow-overlap": true
            }
          });

          // Infos bdc card
          that.BdcService.getBdc(that.bdc.id).then(async (bdc: any) => {

            if (typeof resp.geometry.coordinates === 'undefined') {

              const url = encodeURI(bdc.adresse_locataire + ' ' + bdc.code_postal_locataire + ' ' + bdc.ville_locataire);

              await that.BdcService.geoCoding(url).then((resp2: any) => {
                resp.geometry.coordinates = resp2.features[0].geometry.coordinates;
              });
            }

            // Marker on map
            that.geojsonSelected.data.features = [{
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": resp.geometry.coordinates
              },
              "properties": {
                "icon": "maps-and-flags",
                "title": that.bdc.id
              }
            }];

            that.map.getSource('selected').setData(that.geojsonSelected.data);
          });
        });
      }
    });
  }


  dayChanged() {
    const that = this;

    let day = this.calendar_indiv.getDate();
    let start;
    let end;

    console.log(this.calendar_indiv)

    if (this.calendar_indiv.view.type === 'timeGridWeek') { // On prend tous les jours de la semaine
      start = moment(day).subtract(1, 'day').startOf('week').add(1 + i, 'day').format('YYYY-MM-DD')
      end = moment(day).subtract(1, 'day').endOf('week').add(1 + i, 'day').format('YYYY-MM-DD')
    }
    else if (this.calendar_indiv.view.type === 'dayGridMonth') { // On prend tous les jours de la semaine
      start = moment(day).startOf('month').format('YYYY-MM-DD')
      end = moment(day).endOf('month').format('YYYY-MM-DD')
    }
    else {
      start = moment(day).format('YYYY-MM-DD')
      end = moment(day).format('YYYY-MM-DD')
    }

    this.planifJour = [];
    this.calendar_indiv.refetchEvents();

    // Suppression de tous les marqueurs du jour sur la carte
    if (this.tabMarkers !== null) {
      for (var i = this.tabMarkers.length - 1; i >= 0; i--) {
        this.tabMarkers[i].remove();
      }
    }

    (<any>this.BdcService.getPlanifJour(start + ' 00:00:00', end + ' 23:59:59')).then((planifs) => {
      //console.log(planifs)

      // Pour chaque BDC
      for (const planif of planifs) {

        console.log(this.calendar_indiv.getResourceById(planif.id_technicien))

        if(this.calendar_indiv.getResourceById(planif.id_technicien) != null) {

          // Obtention de la position de chaque BDC
          this.getGeoloc(planif, planif.bdc.adresse_locataire + ' ' + planif.bdc.code_postal_locataire + ' ' + planif.bdc.ville_locataire).then((resp: any) => {

            // Ajout d'un marqueur de la couleur du technicien
            var el = document.createElement('div');
            el.className = 'marker';
            el.style.width = 20 + 'px';
            el.style.height = 20 + 'px';
            el.style.opacity = '0.6';

            el.style.color = "hsl( " + this.calendar_indiv.getResourceById(resp.planif.id_technicien).extendedProps.couleur + ", 100%, 50% )";
            el.style['background-color'] = "hsl( " + this.calendar_indiv.getResourceById(resp.planif.id_technicien).extendedProps.couleur + ", 100%, 50% )";
            el.style['border-radius'] = '50%'

            // add marker to map
            const marker = new mapboxgl.Marker(el)
              .setLngLat(resp.geometry.coordinates)
              .addTo(this.map);
            this.tabMarkers.push(marker);
          }, (err) => { });

          // La planif correspond à ce BDC
          if (planif.id_bdc === this.bdc.id) {
          }
          else {
            this.planifJour.push({
              id: planif.id,
              id_bdc: planif.id_bdc,
              bdc: planif.bdc,
              title: planif.bdc.no_bdc + ' - ' + planif.bdc.ville_locataire,
              start: new Date(planif.start),
              end: new Date(planif.end),
              resourceId: planif.id_technicien,
              couleur: "hsl( " + that.calendar_indiv.getResourceById(planif.id_technicien).extendedProps.couleur + ", 60%, 20% )",
              editable: false,
              resourceEditable: false,
              overlap: true
            });
          }
        }

        this.calendar_indiv.refetchEvents();
      }
    });
  }

  getGeoloc(planif, adresse) {
    return new Promise((resolve, reject) => {
      const url = encodeURI(adresse);

      this.BdcService.geoCoding(url).then((resp: any) => {

        if (resp.features.length > 0) {
          const reponse = {
            planif: planif,
            geometry: resp.features[0].geometry
          };
          resolve(reponse)
        }
        else {
          reject();
        }
      });
    });
  }

  getPlanifsOfBdc() {
    return this.planifBcd;
  }

  // Cette fonction ajoute les techniciens au calendrier comme ressources
  // Elle est appelée dans la fonction d'initialisation et pour la map
  getResources() { // Obtention de la liste des techniciens et attribution d'une couleur
    return new Promise((resolve, reject) => {
      (<any>this.BdcService.getTechniciens()).then((techniciens) => {
        let totalTech = [];
        let techActifs = [];

        for (const i in techniciens) { // Chaque technicien est ajouté en tant que ressource
          const technicien = techniciens[i];
          totalTech.push({
            id: technicien.id,
            title: technicien.prenom + ' ' + technicien.nom,
            couleur: this.makeColor(i, techniciens.length)
          });
        }

        for (let j = 0; j < techniciens.length; j++) {
          const tech = techniciens[j];

          if (tech.actif == 1) {
            techActifs.push(tech);
          }
        }

        console.log(techActifs)

        let techs = [];

        for (const i in techActifs) { // Chaque technicien est ajouté en tant que ressource
          const technicien = techActifs[i];
          techs.push({
            id: technicien.id,
            title: technicien.prenom + ' ' + technicien.nom,
            couleur: this.makeColor(i, techActifs.length)
          });
        }

        console.log(techs)

        resolve({
          totalTechs: totalTech,
          actifs: techs
        });
      });
    })
  }

  saveEventChange(event) {
    for (var i = 0; i < this.planifBcd.length; i++) {

      if (this.planifBcd[i].id.toString() === event.event.id.toString()) {
        this.planifBcd[i].start = event.event.start;
        this.planifBcd[i].end = event.event.end;
        this.planifBcd[i].resourceId = event.event._def.resourceIds[0];
        this.planifBcd[i].couleur = "hsl( " + this.calendar_indiv.getResourceById(event.event._def.resourceIds[0]).extendedProps.couleur + ", 90%, 30% )";
        this.planifBcd[i].start_hr = moment(event.event.start).format('DD/MM/YYYY HH[h]mm');
        this.planifBcd[i].end_hr = moment(event.event.end).format('DD/MM/YYYY HH[h]mm');
        this.planifBcd[i].nom = this.getTech(event.event._def.resourceIds[0]).title;
      }
    }

    this.calendar_indiv.refetchEvents();
  }

  clicOnEvent(date) {
    this.calendar_indiv.gotoDate(date);
    this.dayChanged();
  }

  makeColor(colorNum, colors) {
    if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
    return colorNum * (360 / colors) % 360;
  }

  deleteNewEvent(event) {
    for (var i = 0; i < this.planifBcd.length; i++) {
      if (this.planifBcd[i].id.toString() === event.id.toString()) {
        this.planifBcd.splice(i, 1);
      }
    }

    this.calendar_indiv.refetchEvents();
  }

  savePlanif() {
    const modalRef = this.modalService.open(ModConfPlanifIndivComponent);
    modalRef.componentInstance.events = this.planifBcd;
    modalRef.result.then((result) => {


      this.BdcService.planifierBdc(this.bdc.id, this.planifBcd).then((res) => {
        this.router.navigate(['bons-de-commande/intervention/' + this.bdc.id]);
      }).catch((err) => {
        if (err === 'no_event') {
          alert("Aucune intervention n'est planifiée pour ce bon de commande")
        }
      });
    }, (reason) => {
    });
  }

}
