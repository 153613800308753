<div class="card">
  <div class="card-status bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Rapport</h3>
    <div class="card-options" *ngIf="!readonly">
      <button class="btn btn-sm btn-default" (click)="save()">
        <span class="fe fe-save"></span> Enregistrer
      </button>
    </div>
  </div>

  <div class="card-body">
    <div class="form-group" *ngIf="!readonly">
      <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"
      [(ngModel)]="rapport"></textarea>
    </div>

    <div *ngIf="readonly">
      <p *ngIf="rapport != ''">{{rapport}}</p>
      <p *ngIf="rapport == ''">Aucun rapport pour cette commande</p>
    </div>
  </div>

</div>
