import { Component, OnInit, Input, OnChanges } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { BdcService } from "../../../../services/service-bdc.service";

import { ResumeAddArticleModalComponent } from "./resume-add-article-modal/resume-add-article-modal.component";

declare var $: any;
import * as moment from "moment";

@Component({
  selector: "app-resume-add-intervention-modal",
  templateUrl: "./resume-add-intervention-modal.component.html",
  styleUrls: ["./resume-add-intervention-modal.component.scss"],
})
export class ResumeAddInterventionModalComponent implements OnInit {
  @Input() idBdc;
  bdc;
  id_marche = 0;
  id_bailleur = 0;

  date;

  articles = [];
  articlesCommande = [];
  objet_intervention = '';


  techniciens = [];
  selectedTech = "0";

  listesArticles = [];

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private BdcService: BdcService
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    const that = this;

    this.date = moment().format("YYYY-MM-DD[T]HH:mm");

    this.BdcService.getBdc(this.idBdc).then((bdc: any) => {
      this.bdc = bdc;

      this.articlesCommande = bdc.commande.articles;

      this.id_bailleur = bdc.id_bailleur;
      this.id_marche = bdc.id_marche;

      for (let article of this.articlesCommande) {
        article.selected = false;
        article.nb_inter = article.nb - article.realises;

        if (article.nb_inter < 0) {
          article.nb_inter = 0;
        }
      }

      this.BdcService.getListesArticles().then((listesArticles: any) => {
        console.log(listesArticles);

        const bailleur = listesArticles.find((bailleur) => {
          return bailleur.id == that.bdc.id_bailleur;
        });

        console.log(bailleur);

        const marche = bailleur.marches.find((marche) => {
          return marche.id == that.bdc.id_marche;
        });

        console.log(marche);

        this.BdcService.getArticlesFromList(
          marche.id_liste_articles_active
        ).then((articles: any) => {
          that.listesArticles = articles.articles;

          that.listesArticles.map((item) => {
            item.nom = item.code_article + " - " + item.nom;

            return item;
          });

          console.log(that.listesArticles);

          (<any>$("#select-article")).selectize({
            placeholder: "Recherche d'articles...",
          });
          that.BdcService.maj_select("#select-article", that.listesArticles);
          $("#select-article")[0].selectize.setValue("");

          $("#select-article").change(function () {
            console.log(parseInt($(this).val() as string));

            let article = that.listesArticles.find((item) => {
              return item.id == parseInt($(this).val() as string);
            });

            article.nb = 1;
            that.articles.push(article);

            $("#select-article")[0].selectize.setValue("");
          });
        });
      });
    });

    this.BdcService.getTechniciens().then((techniciens: any) => {
      let totalTech = [];

      for (let j = 0; j < techniciens.length; j++) {
        const tech = techniciens[j];

        if (tech.actif == 1) {
          totalTech.push(tech);
        }
      }

      console.log(totalTech);

      techniciens.map((item) => {
        item.nom = item.fullname;
      });

      this.techniciens = totalTech;

      $("#input-tech").selectize({
        delimiter: ",",
      });

      this.BdcService.maj_select("#input-tech", this.techniciens); // Insertion de la liste des clients dans le menu
      $("#input-tech")[0].selectize.setValue("");

      $("#input-tech").change(function () {
        //console.log($(this).val());
        that.selectedTech = $(this).val();
        //console.log(that.selectedTech)
      });
    });
  }

  interventionConforme() {
    for (let article of this.articlesCommande) {
      article.nb_inter = article.nb - article.realises;

      if (article.nb_inter < 0) {
        article.nb_inter = 0;
      }

      if (article.nb > article.realises) {
        article.selected = true;
      } else article.selected = false;
    }

    this.articles = [];
  }

  showArticles() {
    return this.articles !== [];
  }

  totalArticles() {
    const prix = {
      ht: 0,
    };

    for (const article of this.articles) {
      prix.ht += parseFloat(article.prix_ht) * article.nb;
    }

    for (const article of this.articlesCommande) {
      if (article.selected) {
        prix.ht += parseFloat(article.prix_ht) * article.nb_inter;
      }
    }

    return prix;
  }

  articlesModal() {
    const modalRef = this.modalService.open(ResumeAddArticleModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.bailleur = this.id_bailleur;
    modalRef.componentInstance.marche_init = this.id_marche;

    modalRef.result.then(
      (result) => {
        result.article.nb = 1;
        this.articles.push(result.article);
      },
      (reason) => {}
    );
  }

  supprimerArticle(id) {
    for (let i = 0; i < this.articles.length; i++) {
      if (this.articles[i].id === id) {
        this.articles.splice(i, 1);
      }
    }
  }

  // Formulaire
  createForm() {
    this.form = this.fb.group({
      fichier: null,
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get("fichier").setValue(file);
    }
  }

  private prepareSave(rapport): any {
    let post_articles: any = [];
    for (var i = 0; i < rapport.articles.length; i++) {
      post_articles.push({
        id: rapport.articles[i].id,
        nb: rapport.articles[i].nb,
      });
    }

    post_articles = JSON.stringify(post_articles);

    //console.log(rapport.techniciens)

    let input = new FormData();

    input.append("id_bdc", rapport.id_bdc);
    input.append("commentaire", rapport.commentaire);
    input.append("date", this.date);
    input.append("liste_articles", post_articles);
    input.append("techniciens", JSON.stringify(rapport.techniciens));

    return input;
  }

  save() {
    console.log("save");

    let articlesRapport = [];

    for (let article of this.articlesCommande) {
      //console.log(article)
      if (article.selected && article.nb_inter > 0) {
        //console.log("selected")
        article.nb = article.nb_inter;
        articlesRapport.push(article);
      }
    }
    //console.log(articlesRapport)
    articlesRapport = articlesRapport.concat(this.articles);
    //console.log(articlesRapport)

    var techniciens_inter = this.selectedTech.split(",");

    let bloquerEnregistrement = false;

    if (articlesRapport.length == 0) {
      if (
        !confirm("Aucun article n'a été enregistré. Enregistrer quand même ?")
      ) {
        bloquerEnregistrement = true;
      }
    } else if (techniciens_inter.length == 0 || this.selectedTech == '0') {
      if (
        !confirm(
          "Aucun technicien n'a été sélectionné. Enregistrer quand même ?"
        )
      ) {
        bloquerEnregistrement = true;
      }
    }

    console.log(techniciens_inter);
    console.log(articlesRapport);

    if (!bloquerEnregistrement) {
      const rapport = {
        id_bdc: this.idBdc,
        articles: articlesRapport,
        techniciens: techniciens_inter,
        commentaire: this.objet_intervention,
      };
      //console.log(rapport)

      const formModel = this.prepareSave(rapport);

      this.BdcService.addRapport(formModel).then(() => {
        this.activeModal.close();
      });
    } else {
      //this.activeModal.close();
    }
  }

  cancel() {
    this.activeModal.close();
  }
}
