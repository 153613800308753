import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { BdcService } from '../../../services/service-bdc.service';

@Component({
  selector: 'app-liste-devis-a-realiser',
  templateUrl: './liste-devis-a-realiser.component.html',
  styleUrls: ['./liste-devis-a-realiser.component.scss']
})
export class ListeDevisARealiserComponent implements OnInit {

  listeBdc = [];

  constructor(private BdcService: BdcService, private router: Router, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.BdcService.getListBdcDevisARealiser().then((listeACommander: any) => {
      this.listeBdc = listeACommander;
    });


  }

}
