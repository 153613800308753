<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Choisir la date</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
  (click)="cancel()">
  </button>
</div>

<div class="modal-body">
  <div class="form-group">
    <input class="form-control" type="date" [(ngModel)]="date">
  </div>
</div>


<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal"
  (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="ok()">Choisir la date</button>
</div>
