import { Component, OnInit, Input, OnChanges } from '@angular/core';

import {
  BdcService
} from '../../../services/service-bdc.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;
import 'bootstrap-notify';

@Component({
  selector: 'app-options-toggles',
  templateUrl: './options-toggles.component.html',
  styleUrls: ['./options-toggles.component.scss']
})
export class OptionsTogglesComponent implements OnInit, OnChanges {
  @Input() bdc;

  form: FormGroup;

  constructor(private BdcService: BdcService, private fb: FormBuilder) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
  }

  // Options
  createForm() {
    this.form = this.fb.group({
      fichier: null
    });
  }

  private prepareSave(): any {
    let input = new FormData();

    input.append('id', this.bdc.id);
    input.append('accord_hors_delais', ((this.bdc.accord_hors_delais === false) ? 0 as any : 1 as any));
    input.append('piece_a_commander', ((this.bdc.piece_a_commander === false) ? 0 as any : 1 as any));
    input.append('piece_a_commander_commentaire', this.bdc.piece_a_commander_commentaire);
    input.append('devis_commentaire', this.bdc.devis_commentaire);
    input.append('devis', ((this.bdc.devis === false) ? 0 as any : 1 as any));
    input.append('anomalie_commentaire', this.bdc.anomalie_commentaire);
    input.append('anomalie', ((this.bdc.anomalie === false) ? 0 as any : 1 as any));
    input.append('rapport', ((this.bdc.rapport === false) ? 0 as any : 1 as any));

    return input;
  }


  saveOptions() {
    const formModel = this.prepareSave();
    this.BdcService.majOptionsBdc(formModel).then(resp => {
      $(document).ready(function(){
        $.notify({
          message: 'Enregistré'
        },{
          type: 'success',
          z_index: 2031,
        });
      });
    });
  }


}
