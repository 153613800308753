<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="page-header">
        <h1 class="page-title">
          Statistiques des articles
        </h1>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="card">

              <div class="card-body">
                <div class="row">

                  <div class="col-3">
                    <div class="form-group">
                      <label class="form-label">Bailleur</label>
                      <select class="form-control custom-select selectized" id="select-bailleur" tabindex="-1"
                        style="display: none;">
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label class="form-label">Marché</label>
                      <select class="form-control custom-select selectized" id="select-marche" tabindex="-1"
                        style="display: none;">
                      </select>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label class="form-label">Liste d'articles</label>
                      <select class="form-control custom-select selectized" id="select-liste" tabindex="-1"
                        style="display: none;">
                      </select>
                    </div>
                  </div>

                  <div class="col-2">
                    <div class="form-group">
                      <label class="form-label">Début</label>
                      <input class="form-control" type="date" [(ngModel)]="date_debut" (change)="getArticles()">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label class="form-label">Fin</label>
                      <input class="form-control" type="date" [(ngModel)]="date_fin" (change)="getArticles()">
                    </div>
                  </div>

                </div>
              </div>

              <div class="card-body">
                <table id="table-articles" class="table table-hover card-table">
                  <thead>
                    <tr>
                      <th colspan="3">Infos Article</th>
                      <th colspan="2">Statistiques</th>
                    </tr>
                    <tr>
                      <th>Code</th>
                      <th>Nom</th>
                      <th>Prix HT</th>
                      <th>Total Commandes</th>
                      <th>Total Interventions</th>
                    </tr>

                  </thead>
                  <!--<tbody>
                    <tr *ngFor="let item of articles">
                      <td>
                        {{item.code_article}}
                      </td>
                      <td>
                        {{item.nom}}
                      </td>
                      <td>
                        {{item.prix_ht}}
                      </td>
                      <td>
                        {{item.total_commandes}}
                      </td>
                    </tr>
                  </tbody>-->
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
