<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Planification</h5>


  <div class="card-options mr-0">
      <button class="btn btn-sm btn-default" (click)="delete()"><i class="fe fe-trash"></i> Supprimer</button>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>

  </div>
</div>

<div class="modal-body">
  <div class="row">

    <div class="col-3">
      <div class="form-group">
        <label class="form-label">Date</label>

        <input type="date" class="form-control" [(ngModel)]="date">
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="form-label">Heure</label>

        <select class="custom-select" [(ngModel)]="demiJournee">
          <option value="1">Matin</option>
          <option value="2">Après-midi</option>
        </select>

      </div>
    </div>


    <div class="col-3">
      <div class="form-group">
        <label class="form-label">Technicien</label>
        <select class="form-control custom-select selectized" id="select-tech" tabindex="-1" style="display: none;">
        </select>
      </div>
    </div>

    <div class="col-2 offset-1">
      <div class="form-group">
        <label class="form-label">&nbsp;</label>
        <button type="button" class="btn btn-primary" (click)="replanif()">Enregistrer</button>
      </div>
    </div>

  </div>
</div>

<div class="modal-body">
  <app-bon-de-commande [bdc]="event.bdc"></app-bon-de-commande>
</div>
