import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'urgence'
})
export class UrgenceFilterPipe implements PipeTransform {

    transform(items: any[], urgence): any[] {
        //console.log(items)
        //console.log(urgence)

        //return items;

        if (urgence === 0) {
            //console.log('urgence === 0')
            return items;
        } else {
            //console.log("pre-filter")
            if (!items) {
                //console.log("[-------]")
                return [];
            } 
            if (!urgence) {
                //console.log('!!!!!!!!!!')
                return items;
            }

            if (!(urgence.low || urgence.medium || urgence.high)) {
                return items;
            }

            //console.log("filter")

            return items.filter(it => {
                //console.log(it.urgence)

                if ((it.urgence.toString() === '1') && urgence.low) {
                    return true;
                }
                if ((it.urgence.toString() === '2') && urgence.medium) {
                    return true;
                }
                if ((it.urgence.toString() === '3') && urgence.high) {
                    return true;
                }

                return false;
            });
        }
    }
}
