import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-mod-select-tech',
  templateUrl: './mod-select-tech.component.html',
  styleUrls: ['./mod-select-tech.component.scss']
})
export class ModSelectTechComponent implements OnInit {
  @Input() techs;

  listTechs = [];
  selectedTech;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
    const that = this;

    console.log(this.techs)

    for(const i in this.techs) {
      this.techs[i].nom = this.techs[i].title;
    }

    (<any>$('#select-tech')).selectize({});
    this.BdcService.maj_select("#select-tech", this.techs);
    that.selectedTech = this.techs[0].id;

    $('#select-tech').change(function () {
      console.log(parseInt($(this).val() as string))
      that.selectedTech = parseInt($(this).val() as string);
    });

    console.log(this.techs)
  }

  ok() {
    console.log(this.selectedTech)
    this.activeModal.close(this.selectedTech);
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
