<div class="row">
  <div class="col-3">
    <div class="form-group">
      <label class="custom-switch">
        <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
          [(ngModel)]="bdc.accord_hors_delais" (change)="saveOptions()">
        <span class="custom-switch-indicator"></span>
        <span class="custom-switch-description">Accord hors délai</span>
      </label>
    </div>
  </div>
  <div class="col-3">
    <div class="form-group">
      <label class="custom-switch">
        <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
          [(ngModel)]="bdc.piece_a_commander" (change)="saveOptions()">
        <span class="custom-switch-indicator"></span>
        <span class="custom-switch-description">Pièces à commander</span>
      </label>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <label class="custom-switch">
        <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" [(ngModel)]="bdc.devis"
          (change)="saveOptions()">
        <span class="custom-switch-indicator"></span>
        <span class="custom-switch-description">Devis</span>
      </label>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <label class="custom-switch">
        <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" [(ngModel)]="bdc.anomalie"
          (change)="saveOptions()">
        <span class="custom-switch-indicator"></span>
        <span class="custom-switch-description">Anomalie</span>
      </label>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <label class="custom-switch">
        <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" [(ngModel)]="bdc.rapport"
          (change)="saveOptions()">
        <span class="custom-switch-indicator"></span>
        <span class="custom-switch-description">Rapport</span>
      </label>
    </div>
  </div>

</div>

<div class="row" *ngIf="bdc.anomalie">
  <div class="col-12">
    <div class="alert alert-danger" role="alert">

      <div class="row">
        <div class="col-8">
          <h4 class="alert-heading">Anomalie</h4>
        </div>
        <div class="col-4">
          <div class="row">
            <button class="btn btn-sm btn-default ml-auto" style="margin-right: 13px;" (click)="saveOptions()">
              <span class="fe fe-save"></span> Enregistrer
            </button>
          </div>
        </div>
      </div>

      <hr style="margin-top: 0.75rem; margin-bottom: 1rem;">

      <div class="form-group">
        <textarea class="form-control" id="anomalie_commentaire" name="anomalie_commentaire" rows="3" [(ngModel)]="bdc.anomalie_commentaire"></textarea>
      </div>

    </div>
  </div>
</div>

<div class="row" *ngIf="bdc.piece_a_commander || bdc.piece_a_commander_commentaire !== ''">
  <div class="col-12">
    <div class="alert alert-primary" role="alert">

      <div class="row">
        <div class="col-8">
          <h4 class="alert-heading">Pièces à commander</h4>
        </div>
        <div class="col-4">
          <div class="row">
            <button class="btn btn-sm btn-default ml-auto" style="margin-right: 13px;" (click)="saveOptions()">
              <span class="fe fe-save"></span> Enregistrer
            </button>
          </div>
        </div>
      </div>

      <hr style="margin-top: 0.75rem; margin-bottom: 1rem;">

      <div class="form-group">
        <textarea class="form-control" id="piece_a_commander_commentaire" name="piece_a_commander_commentaire" rows="3" [(ngModel)]="bdc.piece_a_commander_commentaire"></textarea>
      </div>

    </div>
  </div>
</div>

<div class="row" *ngIf="bdc.devis">
  <div class="col-12">
    <div class="alert alert-primary" role="alert">

      <div class="row">
        <div class="col-8">
          <h4 class="alert-heading">Devis à réaliser</h4>
        </div>
        <div class="col-4">
          <div class="row">
            <button class="btn btn-sm btn-default ml-auto" style="margin-right: 13px;" (click)="saveOptions()">
              <span class="fe fe-save"></span> Enregistrer
            </button>
          </div>
        </div>
      </div>

      <hr style="margin-top: 0.75rem; margin-bottom: 1rem;">

      <div class="form-group">
        <textarea class="form-control" id="devis_commentaire" name="devis_commentaire" rows="3" [(ngModel)]="bdc.devis_commentaire"></textarea>
      </div>

    </div>
  </div>
</div>
