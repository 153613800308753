import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';

import { BdcService } from '../../services/service-bdc.service';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-chiffre-affaires',
  templateUrl: './chiffre-affaires.component.html',
  styleUrls: ['./chiffre-affaires.component.scss']
})
export class ChiffreAffairesComponent implements OnInit {

  listeClients = [];
  bailleurSelected = 0;
  marcheSelected = 0;
  granulariteSelected;

  tabTechs = [];
  selectedTech = [];

  date_debut;
  date_fin;

  caData = [];
  caChart;

  chartColors = {
    "blue": "rgb(54, 162, 235)",
    "red": "rgb(255, 99, 132)",
    "green": "rgb(75, 192, 192)",
    "orange": "rgb(255, 159, 64)",
    "purple": "rgb(153, 102, 255)",
    "yellow": "rgb(255, 205, 86)",
    "grey": "rgb(201, 203, 207)"
  }

  constructor(private BdcService: BdcService) { }

  ngOnInit() {

    this.initForm();

    this.initGraph();
  }

  initForm() {
    const that = this;

    this.BdcService.getListesArticles().then((listeClients: any) => { // Permet d'obtenir les clients et marchés

      this.listeClients = [];

      for (let i = 0; i < listeClients.length; i++) {
        if(listeClients[i].actif == 1) {
          this.listeClients.push(listeClients[i]);
        }
      }

      console.log(this.listeClients);

      // Selection du bailleur
      (<any>$('#select-bailleur')).selectize({});
      this.listeClients.push({
        id: 0,
        nom: 'Tout'
      });
      this.listeClients.sort((a, b) => {
        if (a.id < b.id) return -1;
        else if (b.id < a.id) return 1;
        return 0;
      });
      this.BdcService.maj_select("#select-bailleur", this.listeClients);
      this.BdcService.set_selectize('#select-bailleur', 0);
      this.bailleurSelected = 0;

      $('#select-bailleur').change(function () {
        that.bailleurSelected = parseInt($(this).val() as string);

        //console.info("Bailleur ", that.bailleurSelected);

        if (that.bailleurSelected === 0) {
          that.BdcService.maj_select("#select-marche", [{
            id: 0,
            nom: 'Tout'
          }]);
          that.marcheSelected = 0;
        }
        else {
          let totalMarches = that.listeClients.find(elt =>
            elt.id == that.bailleurSelected
          ).marches;

          let listeSelectClients = [];

          for (let i = 0; i < totalMarches.length; i++) {
            if(totalMarches[i].actif == 1) {
              listeSelectClients.push(totalMarches[i]);
            }
          }

          listeSelectClients.push({
            id: 0,
            nom: 'Tout'
          });
          listeSelectClients.sort((a, b) => {
            if (a.id < b.id) return -1;
            else if (b.id < a.id) return 1;
            return 0;
          });

          //console.log(listeSelectClients)

          that.BdcService.maj_select("#select-marche", listeSelectClients);
        }

        //console.log('formchange')
        //that.formChange();
      });

      // Selection du marche
      (<any>$('#select-marche')).selectize({});
      this.BdcService.maj_select("#select-marche", [{
        id: 0,
        nom: 'Tout'
      }]);
      this.marcheSelected = 0;

      $('#select-marche').change(function () {
        if (!isNaN(parseInt($(this).val() as string))) {
          that.marcheSelected = parseInt($(this).val() as string);

          //console.info("Marché ", that.marcheSelected);

          that.formChange();
        }
      });

    })

    /*
    ** INIT DATES
    */

    this.date_fin = moment().format("YYYY-MM-DD");
    this.date_debut = moment().subtract(3, 'months').format("YYYY-MM-DD");

    // Selection de la granularité
    (<any>$('#select-granularite')).selectize({});
    const listeGranularite = [{
      id: 0,
      nom: 'Jour'
    },
    {
      id: 1,
      nom: 'Semaine'
    },
    {
      id: 2,
      nom: 'Mois'
    },
    {
      id: 3,
      nom: 'Année'
    }];
    this.BdcService.maj_select("#select-granularite", listeGranularite);
    this.BdcService.set_selectize('#select-granularite', 1);
    this.granulariteSelected = 1;

    $('#select-granularite').change(function () {
      that.granulariteSelected = parseInt($(this).val() as string);

      //console.info("Granularite ", that.granulariteSelected);

      switch (that.granulariteSelected) {
        case 0:
          that.caChart.options.scales.xAxes[0].time.unit = 'day';
          break;
        case 1:
          that.caChart.options.scales.xAxes[0].time.unit = 'week';
          break;
        case 2:
          that.caChart.options.scales.xAxes[0].time.unit = 'month';
          break;
        case 3:
          that.caChart.options.scales.xAxes[0].time.unit = 'year';
          break;

        default:
          that.caChart.options.scales.xAxes[0].time.unit = 'week';
          break;
      }

      that.formChange();
    });

    /*
    ** INIT TECH
    */

    this.BdcService.getTechniciens().then((techs: any) => {
      console.log(techs)

      techs.map((item) => {
        item.nom = item.fullname;
      })

      for (let i = 0; i < techs.length; i++) {
        if(techs[i].actif == 1) {
          this.tabTechs.push(techs[i]);
        }
      }

      $('#select-tech').selectize({
        delimiter: ','
      });

      this.BdcService.maj_select("#select-tech", this.tabTechs);
      $('#select-tech')[0].selectize.setValue('');

      $('#select-tech').change(function () {
        that.selectedTech = ($(this).val() as string).split(',');

        that.selectedTech = that.selectedTech.map((item) => {
          //console.log(item)

          const tech = that.tabTechs.find((jtem) => {
            return jtem.id == item
          });
          //console.log(tech)

          return tech;
        });

        if(that.selectedTech[0] == undefined) that.selectedTech = [];

        //console.log(techs)
        //console.log(that.selectedTech)

        that.formChange();

      });

    })

  }


  formChange() {
    //console.log(this.date_debut);
    //console.log(this.date_fin);

    //console.log(this.selectedTech)
    //console.log(this.selectedTech.length)

    if ((this.selectedTech.length === 0) || (this.selectedTech.length === 1 && this.selectedTech[0] === "")) {
      this.majGraph();
    }
    else {
      this.majGraphTech();
    }
  }

  initGraph() {
    this.BdcService.getChiffreAffaires(this.date_debut + ' 00:00:00', this.date_fin + ' 23:59:59', this.granulariteSelected, this.bailleurSelected, this.marcheSelected, 0).then((resp: any) => {
      for (let i = 0; i < resp.data.length; i++) {
        this.caData.push({
          t: moment(resp.data[i].date, "DD-MMM-YYYY"),
          y: resp.data[i].ca
        });
      }

      //console.log(this.caData);

      var ctx = document.getElementById('myChart');
      this.caChart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [{
            label: "Chiffre d'Affaires",
            data: this.caData,
            fill: false,
            borderColor: [
              'rgb(54, 162, 235)'
            ]
          }]
        },
        options: {
          scales: {
            xAxes: [{
              type: 'time',
              scaleLabel: {
                labelString: 'Date',
                display: true
              },

              time: {
                unit: 'week',
                displayFormats: {
                  week: 'DD/MM/YYYY'
                }
              }
            }],
            yAxes: [{
              scaleLabel: {
                labelString: '€',
                display: true
              }
            }]
          }
        }
      });
    });
  }

  majGraph() {

    this.BdcService.getChiffreAffaires(this.date_debut + ' 00:00:00', this.date_fin + ' 23:59:59', this.granulariteSelected, this.bailleurSelected, this.marcheSelected, 0).then((resp: any) => {
      this.caData = [];

      for (let i = 0; i < resp.data.length; i++) {
        this.caData.push({
          t: moment(resp.data[i].date, "DD-MMM-YYYY"),
          y: resp.data[i].ca
        });
      }

      //console.log(this.caData);

      this.caChart.data.datasets = [{
        label: "Chiffre d'Affaires",
        data: this.caData,
        fill: false,
        borderColor: [
          'rgb(54, 162, 235)'
        ]
      }];
      this.caChart.update();
    });
  }

  majGraphTech() {
    //console.log(this.selectedTech)

    this.caChart.data.datasets = [];

    for (let i = 0; i < this.selectedTech.length; i++) {
      //console.log(i)
      this.BdcService.getChiffreAffaires(this.date_debut + ' 00:00:00', this.date_fin + ' 23:59:59', this.granulariteSelected, this.bailleurSelected, this.marcheSelected, this.selectedTech[i].id).then((resp: any) => {
        //console.log(resp);

        this.caData = [];

        for (let i = 0; i < resp.data.length; i++) {
          this.caData.push({
            t: moment(resp.data[i].date, "DD-MMM-YYYY"),
            y: resp.data[i].ca
          });
        }

        //console.log(this.caData);

        var colorNames = Object.keys(this.chartColors);
        var colorName = colorNames[this.caChart.data.datasets.length % colorNames.length];
        var newColor = this.chartColors[colorName];

        let techName = this.tabTechs.find((item) => {
          //console.log(item)
          return item.id == resp.id_tech;
        }).fullname;



        this.caChart.data.datasets.push({
          label: techName,
          data: this.caData,
          fill: false,
          borderColor: [
            newColor
          ]
        });

        this.caChart.update();
      });
    }

    //console.log(this.caChart)



  }

}
