<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouveau chargé de secteur</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="form-label">Nom</label>
    <input type="text" class="form-control" name="nom-client" [(ngModel)]="nom" placeholder="Nom">
    <br>
    <label class="form-label">Téléphone</label>
    <input type="text" class="form-control" name="tel-client" [(ngModel)]="tel" placeholder="Téléphone">
    <br>
    <label class="form-label">Email</label>
    <input type="email" class="form-control" name="tel-client" [(ngModel)]="mail" placeholder="Email">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="addCharge()">Sauvegarder</button>
</div>
