import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  BdcService
} from '../../services/service-bdc.service';

import { ModalNewChargeOfMarcheComponent } from '../../clients/marche/modal-new-charge-of-marche/modal-new-charge-of-marche.component';
import { ModalNewArticleComponent } from '../../clients/marche/modal-new-article/modal-new-article.component';
import { ModalNewListeComponent } from '../../clients/marche/modal-new-liste/modal-new-liste.component';

declare var $: any;

@Component({
  selector: 'app-marche',
  templateUrl: './marche.component.html',
  styleUrls: ['./marche.component.scss']
})
export class MarcheComponent implements OnInit {

  articles = [];
  marche = {} as any;
  charges = [];

  liste_active = true;

  edit = false;

  idMarche = this.route.snapshot.params['idMarche'];

  constructor(private BdcService: BdcService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
    // //console.log(this.idMarche)
  }

  init() {
    this.BdcService.getChargesOfMarche(this.idMarche).then((charges: any) => {
      this.charges = charges.charges_secteur;
      //console.log(this.charges);
    });

    /*this.BdcService.getArticles("marche", this.idMarche).then((articles) => {
      this.articles = (articles === 0) ? [] as any : articles as any;
      //console.log(this.articles)
    });*/

    this.BdcService.getListesArticlesMarche(this.idMarche).then((listes: any) => {
      //console.log(listes.marche.id_liste_articles_active)
      //console.log(listes)

      const that = this;

      // Initialisation avec la liste active
      if(listes.marche.id_liste_articles_active != 0){
        that.BdcService.getArticlesOfListe(listes.marche.id_liste_articles_active).then((art: any) => {
          //console.log(art)
          that.articles = art;
        });
      }

      ( < any > $('#select-liste-articles')).selectize({
        render: {
          option: function (item, escape) {
            //console.log(item)

            let bell = "<span>";

            if(item.value == listes.marche.id_liste_articles_active) {
              bell = '<span class="fa fa-check" style:"float: right; color: green"></span>'
            }

            return "<div>" + bell + ' ' + escape(item.text) + "</div>";
          }
        }
      });
      this.BdcService.maj_select("#select-liste-articles", listes.listes);
      this.BdcService.set_selectize('#select-liste-articles', listes.marche.id_liste_articles_active);

      $('#select-liste-articles').change(function () {
        var id_liste = parseInt($(this).val() as string);
        //console.log(id_liste)

        that.BdcService.getArticlesOfListe(id_liste).then((art: any) => {
          //console.log(art)

          that.articles = art;
        })
      });



    });

    this.BdcService.getMarche(this.idMarche).then((marche: any) => {
      marche.actif = marche.actif == 1 ? true : false;

      console.log(marche)

      this.marche = marche;
    });
  }

  goCharge(id) {
    this.router.navigate(['bailleurs/charge-secteur/' + id]);
  }

  newCharge() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ModalNewChargeOfMarcheComponent, { backdrop: 'static' });
    modalRef.componentInstance.idMarche = this.idMarche;

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  newArticle() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ModalNewListeComponent, { size: 'lg' });
    modalRef.componentInstance.idMarche = this.idMarche;

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  changeState() {
    this.BdcService.changeMarcheState(this.marche.id, this.marche.actif);
  }

  supprimerCharge(id_charge) {
    this.BdcService.deleteChargeSecteurOfMarche(this.marche.id, id_charge);
  }

  editNom() {
    this.edit = true;
  }
  saveNom() {
    this.BdcService.changeNomMarche(this.marche.id, this.marche.nom);

    this.edit = false;
  }

}
