<div class="modal-header">
  <h3 class="modal-title">Photos</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <b>Photos avant</b>
      <br>
      <span *ngFor="let photo of rapport.photos">

        <!--<img *ngIf="photo.type === 'avant'" class="photo-inter"
          [src]="'http://lab.etiennevilledieu.fr/quentin/photos/rapports/' + photo.photo" />-->
        <img *ngIf="photo.type === 'avant'" class="photo-inter"
          [src]="env.rapports_folder + photo.photo" />
      </span>

    </div>
    <div class="col-6">
      <b>Photos après</b>
      <br>
      <span *ngFor="let photo of rapport.photos">

        <!--<img *ngIf="photo.type === 'apres'" class="photo-inter"
          [src]="'http://lab.etiennevilledieu.fr/quentin/photos/rapports/' + photo.photo" />-->
        <img *ngIf="photo.type === 'apres'" class="photo-inter"
          [src]="env.rapports_folder + photo.photo" />
      </span>


    </div>
  </div>
</div>
<div class="modal-footer">
  
</div>
