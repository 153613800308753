<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="row row-cards row-deck">
        <div class="col-12">

          <div class="d-flex" style="margin-bottom:12px">
            <app-widget-planif></app-widget-planif>


          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">

                <app-liste-bdc [listeBdc]="listeBdcAPlanifier" [tableId]="'listeAPlanifier'" [datatables]="true"
                  [colonnes]="'is_identifiant id no_bdc client date urgence objet_intervention prix'"
                  [select]="'validation'" (majRequest)="majListe()">
                </app-liste-bdc>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
