import { Component, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { BdcService } from "../../services/service-bdc.service";
import {
  NotificationsService
} from '../../services/notifications.service';

import { ModalBonInTableurComponent } from "../../bonsDeCommande/tableur-bdc/modal-bon-in-tableur/modal-bon-in-tableur.component";

declare var $: any;
declare var moment: any;
import 'bootstrap-notify';

@Component({
  selector: 'app-rapport-listes-bons',
  templateUrl: './rapport-listes-bons.component.html',
  styleUrls: ['./rapport-listes-bons.component.scss']
})
export class RapportListesBonsComponent implements OnInit {

  typeListe = this.route.snapshot.params.typeListe;

  listeBdc = []; // Liste des bons injectés dans le tableur

  timerMaj; // Timer gérant la fréquence de mise à jour des données

  colonnes =
    "is_identifiant id no_bdc client date urgence objet_intervention prix";
  defaultVisibleColumns = [1,2,8,9,11,0,3,12,22,23,24,25,17];
  tableId = "tableur"; // Id du tableur datatables dans le DOM

  table; // L'objet datatables
  datatableOptions; // Options de construction du datatables

  fontSize = 0.9;

  columnTab;

  /* Sélection des lignes */
  select = undefined; // Action à effectuer lors de la sélection
  selectedRows = []; // Lignes sélectionnées
  delete_selection_listener;

  selectedStatus = 'ALL';

  isDataLoaded = false; // Flag servant à la gestion de l'enregistrement de la pagination du tableau

  constructor(
    private BdcService: BdcService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    public NotificationsService: NotificationsService,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    console.log(this.typeListe);

    if(this.typeListe == "difficulte-planif") {
      this.selectedStatus = "DIFFICULTE_PLANIF";
    }
    else if(this.typeListe == "rapport-retard") {
      this.selectedStatus = "RAPPORT_RETARD";
    }

    if (localStorage.getItem("page_" + this.tableId) === null) {
      localStorage.setItem("page_" + this.tableId, "0");
    }


    if (localStorage.getItem("selected_columns_" + this.tableId) === null) {
      localStorage.setItem("selected_columns_" + this.tableId, JSON.stringify(this.defaultVisibleColumns));
    }
    else this.defaultVisibleColumns = JSON.parse(localStorage.getItem("selected_columns_" + this.tableId));

    if (localStorage.getItem("tab_font_size") === null) {
      localStorage.setItem("tab_font_size", JSON.stringify(0.9));
    }
    else this.fontSize = JSON.parse(localStorage.getItem("tab_font_size"));

    this.initTable();

    /*this.timerMaj = setInterval(() => {
      this.majListe();
    }, 30000);*/
  }

  ngOnDestroy() {
    //clearInterval(this.timerMaj);
    $(document).off("click");
  }


  getListBdc() {
    if(this.selectedStatus == 'ALL') {
      return new Promise((resolve, reject) => {
        this.BdcService.getAllBdcActifs().then((listeBdc: any[]) => {
          resolve(listeBdc);
        }).catch(err => {
          reject(err);
        })
      });
    }
    else if(this.selectedStatus == 'DIFFICULTE_PLANIF') {
      return new Promise((resolve, reject) => {
        this.BdcService.getListDifficultePlanif().then((listeBdc: any[]) => {
          resolve(listeBdc);
        }).catch(err => {
          reject(err);
        })
      });
    }
    else if(this.selectedStatus == 'RAPPORT_RETARD') {
      return new Promise((resolve, reject) => {
        this.BdcService.getListRapportsRetard().then((listeBdc: any[]) => {
          resolve(listeBdc);
        }).catch(err => {
          reject(err);
        })
      });
    }
  }


  majListe() {
    this.getListBdc().then((listeBdc: any[]) => {
      console.info("Maj liste", listeBdc)

      this.listeBdc = listeBdc;
      this.changeDetectorRef.detectChanges();

      /*
      **  SYNTHESE DES INTER ET PLANIFS
      */

      for (let i = 0; i < listeBdc.length; i++) {
        let bdc = listeBdc[i];

        bdc.synthese = {
          intervention: {},
          retours: []
        }

        const interToSynthese = function(inter) {

          let tech = inter.techniciens.length > 0 ? inter.techniciens[0].fullname : '';

          return {
            type: "inter",
            date: inter.date,
            technicien: tech
          }
        }

        const planifToSynthese = function(planif) {
          return {
            type: "planif",
            date: planif.start,
            technicien: planif.technicien
          }
        }

        if(bdc.interventions.liste.length !== 0) {
          // Sort interventions by date
          bdc.interventions.liste.sort((a,b) => moment(a.date).diff(b.date));

          // 1st is intervention
          bdc.synthese.intervention = interToSynthese(bdc.interventions.liste[0]);

          // last is last intervention
          if(bdc.interventions.liste.length > 0) {
            bdc.synthese.last_intervention = interToSynthese(bdc.interventions.liste[bdc.interventions.liste.length -1]);
          }

          // others are retours
          if(bdc.interventions.liste.length > 1) {
            let buffer = bdc.interventions.liste.slice(1);
            bdc.synthese.retours = buffer.map(element => interToSynthese(element));
          }

          // Future planifs are retours
          if(bdc.planification.length > 1) {

            bdc.planification.sort((a,b) => moment(a.start).diff(b.start));

            let futurePlanifs = bdc.planification.filter((element) => {
              if(moment(element.start).isBetween(moment(), "2100-01-01 00:00:00", undefined, '(]')) {
                return true;
              }
              else return false;
            })

            let buffer = bdc.synthese.retours.concat(futurePlanifs)
            bdc.synthese.retours = buffer.map(element => planifToSynthese(element));
          }

        }
        else {
          if(bdc.planification.length > 0) {

            bdc.planification.sort((a,b) => moment(a.start).diff(b.start));

            // 1st is intervention
            bdc.synthese.intervention = planifToSynthese(bdc.planification[0]);

            // others are retours
            if(bdc.planification.length > 1) {
              let buffer = bdc.planification.slice(1);
              bdc.synthese.retours = buffer.map(element => planifToSynthese(element));
            }
          }
          else {

          }
        }
      }

      /*
      **  FIN SYNTHESE DES INTER ET PLANIFS
      */

      this.newData();
    });
  }

  initTable() {
    const that = this;

    this.isDataLoaded = false;

    /*
     ** CONFIGURATION DES COLONNES
     */
    let orderColumn = 1; // Id de la colonne à trier à l'initialisation

    /*if (typeof this.select !== "undefined") {
      orderColumn = 1;

      columns.push();
    }*/
    that.columnTab = [
      { // Status 15
        title: "Status",
        name: "status",
        data: "status",
        width: "10px",
        className: "text-center",
        columnFilter: true,
        render: function (data, type, row, meta) {
          if (data === "" || data === "A_VALIDER") {
            return '<i class="text-muted fe fe-check-square"></i>';
          } else if (data === "A_PLANIFIER") {
            return '<i class="text-muted fe fe-calendar"></i>';
          } else if (data === "A_EXECUTER") {
            return '<i class="text-muted fe fe-truck"></i>';
          } else if (data === "A_FACTURER" || data === "FACTURE") {
            return '<i class="text-muted fe fe-dollar-sign"></i>';
          } else if (data === "ARCHIVE") {
            return '<i class="text-muted fe fe-book"></i>';
          } else {
            return '<i class="text-muted fe fe-trash"></i>';
          }
        },
      },
      { // N° Quitus 1
        title: "N° Quitus",
        name: "no_quitus",
        data: "id",
        width: "10px",
        className: "text-center",
        render: function (data, type, row, meta) {
          return '<span class="text-muted">' + data + "</span>";
        },
      },
      { // Bailleur + Marché 2
        title: "Marché", // Nom colonne
        name: "bailleur-marche", // Nom selectize
        data: "bailleur", // Nom champ de données
        width: "10px",

        render: function (data, type, row, meta) {
          return row.bailleur + '<div class="small text-muted">' + row.marche + "</div>";
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'bailleur');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "bailleur", 'Bailleur');
        }
      },
      { // N° BDC
        title: "N° BDC",
        name: "no_bdc",
        data: "no_bdc",
        width: "10px",
        className: "text-center",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'no_bdc');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "no_bdc", 'N° Bon de commande');
        }
      },
      { // Bailleur
        title: "Bailleur",
        name: "bailleur",
        data: "bailleur",
        width: "10px",
        className: "text-center",

        render: function (data, type, row, meta) {
          return row.bailleur;
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'bailleur');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "bailleur", 'Bailleur');
        }
      },
      { // Marché 4
        title: "Marche",
        name: "marche",
        data: "marche",
        width: "10px",
        render: function (data, type, row, meta) {
          return row.marche;
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'marche');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "marche", 'Marché');
        }
      },
      { // Chargé de secteur
        title: "Chargé de secteur",
        name: "client",
        data: "client",
        width: "10px",
        render: function (data, type, row, meta) {

          let text = row.client

          if(row.tel_charge !== null) {
            text += '<div class="small text-muted">' + row.tel_charge + "</div>";
          }

          return text;
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'client');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "client", 'Chargé de secteur');
        }
      },
      { // Urgence
        title: "Urgence",
        name: "urgence",
        data: "urgence",
        width: "10px",
        className: "text-center",
        render: function (data, type, row, meta) {
          if (data == 3) {
            return '<span class="icon"><span class="fa fa-bell evimportant"></span></span><span class="icon"><span class="fa fa-bell evimportant"></span></span><span class="icon"><span class="fa fa-bell evimportant"></span></span>';
          } else if (data == 2) {
            return '<span class="icon"><span class="fa fa-bell evwarning"></span></span><span class="icon"><span class="fa fa-bell evwarning"></span></span>';
          } else {
            return '<span class="icon"><span class="fa fa-bell"></span></span>';
          }
        },
        columnFilter: function (data, idColonne, dataIndex) {
          let row = that.table.row(dataIndex).data();

          let datafilter = that.columnTab[idColonne].datafilter;

          if (datafilter.length > 0) {
            if ((row.urgence == 3) && (datafilter.includes("A traiter immédiatement"))) {
              return true;
            }
            else if ((row.urgence == 2) && (datafilter.includes("A traiter dans la semaine"))) {
              return true;
            }
            else if ((row.urgence == 1) && (datafilter.includes("Pas d'urgence"))) {
              return true;
            }
            else return false;
          } else return true;
        },
        dropdownBuilder: function (idColonne) {
          let listeDeChoix = ["Pas d'urgence", "A traiter dans la semaine", "A traiter immédiatement"];
          that.dropdownCheckboxes(listeDeChoix, idColonne);
        }
      },
      { // Urgence + Date 6
        title: "Urgence & Date limite",
        name: "urgence-date-limite",
        data: "urgence",
        width: "10px",
        render: function (data, type, row, meta) {
          let content = '<span style="display:inline-block;width:60px;">';

          if (row.urgence == 3) {
            content += '<span class="icon"><span class="fa fa-bell evimportant"></span></span><span class="icon"><span class="fa fa-bell evimportant"></span></span><span class="icon"><span class="fa fa-bell evimportant"></span></span>';
          } else if (row.urgence == 2) {
            content += '<span class="icon"><span class="fa fa-bell evwarning"></span></span><span class="icon"><span class="fa fa-bell evwarning"></span></span>';
          } else {
            content += '<span class="icon"><span class="fa fa-bell"></span></span>';
          }

          content += '</span>' + that.BdcService.dateCompacte(row.date_limite) + '' +
            '<div class="small text-muted">Envoyé le ' +
            that.BdcService.dateCompacte(row.date_reception) +
            "</div>";

          return content;
        },
        columnFilter: function (data, idColonne, dataIndex) {
          let row = that.table.row(dataIndex).data();

          let datafilter = that.columnTab[idColonne].datafilter;

          if (datafilter.length > 0) {
            if ((row.urgence == 3) && (datafilter.includes("A traiter immédiatement"))) {
              return true;
            }
            else if ((row.urgence == 2) && (datafilter.includes("A traiter dans la semaine"))) {
              return true;
            }
            else if ((row.urgence == 1) && (datafilter.includes("Pas d'urgence"))) {
              return true;
            }
            else return false;
          } else return true;
        },
        dropdownBuilder: function (idColonne) {
          let listeDeChoix = ["Pas d'urgence", "A traiter dans la semaine", "A traiter immédiatement"];
          that.dropdownCheckboxes(listeDeChoix, idColonne);
        }
      },
      { // Date d'appel
        title: "Date d'appel",
        name: "date_appel",
        data: "date_reception",
        width: "10px",
        className: "text-center",
        render: function (data, type, row, meta) {
          return (
            that.BdcService.dateCompacte(row.date_reception)
          );
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterDate(idColonne, dataIndex, 'date_reception');
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownDate (idColonne);
        }
      },
      { // Date limite
        title: "Date limite",
        name: "date_limite",
        data: "date_limite",
        width: "10px",
        className: "text-center",
        render: function (data, type, row, meta) {
          return (
            "<div>" +
            that.formatDate(row.date_limite) +
            "</div>"
          );
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterDate(idColonne, dataIndex, 'date_limite');
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownDate (idColonne);
        }
      },
      { // Date
        title: "Date",
        name: "date",
        data: "date_limite",
        width: "10px",
        columnFilter: true,
        render: function (data, type, row, meta) {
          return (
            "<div>" +
            that.BdcService.dateCompacte(row.date_limite) +
            "</div>" +
            '<div class="small text-muted">Date de création : ' +
            that.BdcService.dateCompacte(row.date_reception) +
            "</div>"
          );
        },
      },
      { // Prix commande 8
        title: "Prix com.",
        name: "prix_commande",
        data: "commande.prix_ht",
        width: "10px",
        className: "text-center",
        render: function (data, type, row, meta) {
          return data.toFixed(2);
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterRange(idColonne, dataIndex, "commande.prix_ht");
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownRange(idColonne);
        }
      },
      { // Location
        title: "Location",
        name: "location",
        data: "urgence",
        width: "10px",
        render: function (data, type, row, meta) {
          let content = row.nom_locataire;

          if (row.tel_fixe_locataire != '' || row.tel_portable_locataire != '') {
            content += '<div class="small text-muted">';

            if (row.tel_fixe_locataire != '') {
              content += row.tel_fixe_locataire;
            }

            if (row.tel_fixe_locataire != '' && row.tel_portable_locataire != '') {
              content += " / ";
            }

            if (row.tel_portable_locataire != '') {
              content += row.tel_portable_locataire;
            }

            content += "</div>";
          }


          content += '<div class="small text-muted">' +
            row.adresse_locataire + ' ' +
            "</div>";
          content += '<div class="small text-muted">' +
            row.code_postal_locataire + ' ' + row.ville_locataire +
            "</div>";

          return content;
        }
      },
      { // Code postal 10
        title: "Code postal",
        name: "code_postal",
        data: "code_postal_locataire",
        width: "10px",
        className: "text-center",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'code_postal_locataire');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "code_postal_locataire", 'Code postal');
        }
      },
      { // Ville 10
        title: "Ville",
        name: "ville",
        data: "ville_locataire",
        width: "10px",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'ville_locataire');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "ville_locataire", 'Ville');
        }
      },
      { // Objet de l'intervention 11
        title: "Objet de l'intervention",
        name: "objet_intervention",
        data: "objet_intervention",
        width: "10px",
        render: function (data, type, row, meta) {

          let text = "";

          if(row.objet_intervention_1 !== null) {
            text += '<div class="small text-muted">' +
            row.objet_intervention_1 +
            "</div>";
          }

          if(row.objet_intervention_2 !== null) {
            text += '<div class="small text-muted">' +
            row.objet_intervention_2 +
            "</div>";
          }

          return text;
        },
      },
      { // Avancement
        title: "Avancement",
        name: "avancement",
        data: "commande.progression",
        render: function (data, type, row, meta) {
          return (
            '<div class="clearfix">' +
            '<div class="float-left">' +
            '<small class="text-muted">' +
            row.commande.progression + ' % ' +
            "</small>" +
            "</div>" +
            '<div class="float-right">' +
            '<small class="text-muted">' +
            "</small>" +
            "</div>" +
            "</div>" +
            '<div class="progress progress-xs">' +
            '<div class="progress-bar bg-green" role="progressbar" ' +
            'style="width: ' +
            row.commande.progression +
            '%"></div>' +
            "</div>"
          );
        },
      },
      { // Objet de l'intervention 11
        title: "Pièce à commander",
        name: "piece_a_commander",
        data: "piece_a_commander_commentaire",
        width: "10px",
        render: function (data, type, row, meta) {
          return (
            '<div class="small">' +
            data +
            "</div>"
          );
        },
      },
      { // N° Facture 13
        title: "N° Facture",
        name: "no_facture",
        data: "no_facture",
        width: "10px",
        className: "text-center",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'no_facture');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "no_facture", 'N° Facture');
        }
      },
      { // N° Devis 13
        title: "N° Devis",
        name: "no_devis",
        data: "no_devis",
        width: "10px",
        className: "text-center",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'no_devis');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "no_devis", 'N° Devis');
        }
      },
      { // N° Affaire 13
        title: "N° Affaire",
        name: "no_affaire",
        data: "no_affaire",
        width: "10px",
        className: "text-center",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'no_affaire');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "no_affaire", 'N° Affaire');
        }
      },
      { // N° Assurance 13
        title: "N° Assurance",
        name: "no_assurance",
        data: "no_assurance",
        width: "10px",
        className: "text-center",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'no_assurance');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "no_assurance", 'N° Assurance');
        }
      },
      { // Téléphone
        title: "Téléphone",
        name: "telephone",
        data: "tel_fixe_locataire",
        width: "10px",
        render: function (data, type, row, meta) {
          return (
            '<div class="small text-muted">' +
            row.tel_fixe_locataire +
            "</div>" +
            '<div class="small text-muted">' +
            row.tel_portable_locataire +
            "</div>"
          );
        },
      },
      { // Date d'intervention
        title: "Première inter.",
        name: "date_intervention",
        data: "synthese.intervention.date",
        width: "10px",
        render: function (data, type, row, meta) {
          if(data !== undefined) {

            let demiJournee = that.isAm(data) ? 'Matin' : 'Après-midi';

            return that.formatDate(data) + '<div class="small text-muted">' + demiJournee + "</div>";
          }
          else return '';
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterDateInter(idColonne, dataIndex, 'synthese.intervention.date');
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownDateInter (idColonne);
        }
      },
      { // Dernière intervention
        title: "Derniere inter.",
        name: "last_intervention",
        data: "synthese.last_intervention.date",
        width: "10px",
        render: function (data, type, row, meta) {
          if(data !== undefined) {

            let demiJournee = that.isAm(data) ? 'Matin' : 'Après-midi';

            return that.formatDate(data) + '<div class="small text-muted">' + demiJournee + "</div>";
          }
          else return '';
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterDateInter(idColonne, dataIndex, 'synthese.last_intervention.date');
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownDateInter (idColonne);
        }
      },
      { // Technicien intervention 17
        title: "Technicien",
        name: "tech_inter",
        data: "synthese.intervention.technicien",
        width: "10px",
        render: function (data, type, row, meta) {
          if(data !== undefined) {
            return data;
          }
          else return '';
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'synthese.intervention.technicien');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "synthese.intervention.technicien", 'Technicien');
        }
      },
      { // Prix intervention
        title: "Prix réal.",
        name: "prix_intervention",
        data: "interventions.prix_ht",
        width: "10px",
        className: "text-center",
        render: function (data, type, row, meta) {
          if(data !== undefined && data !== 0) {
            return '<span class="">' + data.toFixed(2) + "</span>";
          }
          else return '';
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterRange(idColonne, dataIndex, "interventions.prix_ht");
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownRange(idColonne);
        }
      },
      { // Retours 19
        title: "Retours",
        name: "retours",
        data: "synthese.retours",
        width: "10px",
        render: function (data, type, row, meta) {

          let html = '';

          for (let i = 0; i < data.length; i++) {
            html += '<div class="small text-muted">' + that.formatDate(data[i].date) + "</div>";
          }

          return html;

          /*if(data !== undefined && data !== 0) {
            return '<span class="">' + data + "</span>";
          }
          else return '';*/
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterRange(idColonne, dataIndex, "synthese.retours.length");
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownRange(idColonne);
        }
      }
    ];

    // Ajout de la checkbox
    /*that.columnTab.unshift({
        title: "",
        render: function (data, type, row, meta) {
          return (
            '<input type="checkbox" id="select-' +
            row.id +
            '" class="editor-active tab-select">'
          );
        },
      });*/

    // Ajout des champs indispensables à chaque colonne
    that.columnTab = that.columnTab.map((item, index) => {
      item.id = index;
      item.datafilter = [];

      //Colonnes à masquer à l'initialisation
      if (!that.defaultVisibleColumns.includes(index)) {
        item.visible = false;
      }

      return item;
    });

    /*
     ** SELECTIZE DES COLONNES A AFFICHER
     */
    let listSelectizeColumns = that.columnTab.map((item, index) => {
      return {
        id: item.id,
        column_name: item.name,
        nom: item.title,
      };
    });

    $("#selected-columns").selectize({ delimiter: ",", plugins: ["remove_button"] });
    this.BdcService.maj_select("#selected-columns", listSelectizeColumns); // Insertion de la liste des colonnes dans le menu
    $("#selected-columns")[0].selectize.setValue(that.defaultVisibleColumns);

    $("#selected-columns").change(function () {
      let tListCol = listSelectizeColumns.slice();
      let selection = $(this).val().split(",").map(item => parseInt(item));

      // Enregistrement des colonnes affichées
      localStorage.setItem("selected_columns_" + that.tableId, JSON.stringify(selection));

      let columnsToCreate = $(selection).not(that.defaultVisibleColumns).get();
      let columnsToDelete = $(that.defaultVisibleColumns).not(selection).get();
      that.defaultVisibleColumns = selection;

      // Création des colonnes
      for (let i = 0; i < columnsToCreate.length; i++) {
        const element = columnsToCreate[i];

        // Recherche de la colonne qui porte cet identifiant
        let col = tListCol.find(item => item.id == element).column_name; // Récupération de son nom datatable

        var column = that.table.column(col + ":name");

        column.visible(true);

        that.configFiltreColonne(element); // Création du menu et du filtre de la colonne
      }

      // Suppression des colonnes
      for (let i = 0; i < columnsToDelete.length; i++) {
        let col = tListCol.find(item => item.id == columnsToDelete[i]);

        $('#notif-filter-' + col.id).addClass('col-no-filter'); // Masquage du marqueur de filtre si il est présent

        var column = that.table.column(col.column_name + ":name");
        column.visible(false); // Masquage de la colonne

        that.columnTab.find(item => item.id == col.id).datafilter = []; // Réinitialisation des données du filtre
      }

      // that.updateFiltres();
    });

    /*
     ** CHAMP DE RECHERCHE GLOBAL
     */
    $("#recherche-tab").on("keyup", function () {
      that.table.search(this.value).draw();
    });

    /*
     ** CONFIGURATION DE LA DATATABLE
     */
    this.datatableOptions = {
      data: that.listeBdc,
      columns: that.columnTab,
      colReorder: true,
      order: [[orderColumn, "desc"]], // Tri du tableau via une colonne
      stateSave: false,
      "pageLength": 50,
      initComplete: function (settings, json) {
      },
      drawCallback: function (settings) { // Callback : A la fin d'un rendu
        var api = this.api();

        // Si on est sur la page dont le no est enregistré, on peut commencer à autoriser des modifs de no de page futures
        if (api.page.info().page == parseInt(localStorage.getItem("page_" + that.tableId))) {
          that.isDataLoaded = true;
        }

        if (that.isDataLoaded) {
          localStorage.setItem("page_" + that.tableId, api.page.info().page);
        }
      },
      language: {
        sProcessing: "Traitement en cours...",
        sSearch: "Rechercher&nbsp;:",
        sLengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        sInfo:
          "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        sInfoEmpty:
          "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
        sInfoFiltered:
          "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        sInfoPostFix: "",
        sLoadingRecords: "Chargement en cours...",
        sZeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        sEmptyTable: "Aucune donn&eacute;e disponible dans le tableau",
        oPaginate: {
          sFirst: "Premier",
          sPrevious: "Pr&eacute;c&eacute;dent",
          sNext: "Suivant",
          sLast: "Dernier",
        },
        oAria: {
          sSortAscending: ": activer pour trier la colonne par ordre croissant",
          sSortDescending:
            ": activer pour trier la colonne par ordre d&eacute;croissant",
        },
        select: {
          rows: {
            _: "%d lignes selectionnées",
            0: "Aucune ligne selectionnée",
            1: "1 ligne selectionnée",
          },
        },
      },
    };

    /*
     ** INITIALISATION DU TABLEAU
     ** Lorsque le DOM est prêt
     */
    $(document).ready(function () {
      $.fn.dataTable.moment( 'DD/MM/YYYY' );

      // Initialisation de la datatable
      that.table = $("#" + that.tableId).DataTable(that.datatableOptions);

      that.majListe(); // On va chercher les données une fois que le tableau est initialisé

      // Lors du clic sur le bouton d'action des lignes sélectionnées
      $("#" + that.tableId).on("click", "tbody tr td", function () {
        const bdcClicked = that.table.row($(this).parent()).data();

        if ($($(this).children()[0]).is(".tab-select")) {
          // Si le clic est sur la checkbox

          if ($($(this).children()[0]).is(":checked")) {
            that.selectRow(that.table.row($(this).parent()).data().id);
          } else {
            that.deselectRow(that.table.row($(this).parent()).data().id);
          }
        } else {
          //that.clicBdc(that.table.row($(this).parent()).data());

          that.ngZone.run(() => {
            const modalRef = that.modalService.open(ModalBonInTableurComponent, {
              size: "llg",
            });
            modalRef.componentInstance.bdc = bdcClicked;
            modalRef.result.then(
              (result) => {
                console.log("result")
                that.majListe();
              },
              (reason) => {
                console.log("reason")
                that.majListe();
              }
            );
          })


        }
      });
    });
  }

  // Mise à jour des données à intégrer au tableau
  newData() {
    const that = this;

    if (this.table !== undefined) { // Si le tableau est initialisé

      this.table.clear(); // Suppression de toutes les données du tableau
      this.table.rows.add(this.listeBdc); // Chargement de la liste des bons

      this.table.draw(false); // Rendu des lignes

      // Affichage de la page enregistrée uniquement si celle-ci existe toujours
      if (parseInt(localStorage.getItem("page_" + this.tableId)) < this.table.page.info().pages) {
        this.table.page(parseInt(localStorage.getItem("page_" + this.tableId))).draw(false);
      } else {
        this.table.page(0).draw(false);
      }

      /*
       ** INITIALISATION DES FILTRES DES COLONNES VISIBLES
       */
      $.fn.dataTable.ext.search = [];



      for (let i = 0; i < that.defaultVisibleColumns.length; i++) {
        const element = that.defaultVisibleColumns[i];

        // Config du filtre de la colonne
        that.configFiltreColonne(element);
      }

      that.initStatusFilter(that.selectedStatus); // Filtre de status

      that.table.draw();
    }
  }



  /*
   ** DROPDOWN FILTRE
   */
  configFiltreColonne(id) {
    const that = this;

    let colonne = that.columnTab.find(item => item.id == id);
    if (colonne.columnFilter != undefined) {

      let visibleRows = that.table.rows({ order: 'index', search: 'applied' })

      let dataCol = visibleRows.data().map((item) => {
        return item[colonne.data];
      });

      // Création du filtre (si il y a une fonction particuliere)
      if (colonne.columnFilter != true) {

        colonne.dropdownBuilder(colonne.id, dataCol);

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          // Don't filter on anything other than "myTable"
          if (settings.nTable.id !== that.tableId) {
            return true;
          }

          return colonne.columnFilter(data, colonne.id, dataIndex);
        });
      }

      // Blocage de la propagation du clic vers les parents
      $(".filter-box").click(function (event) {
        event.stopPropagation();
      });

      // Récupération du header de la colonne
      let header_col = that.table.column(colonne.id).header();
      let dropdownId = "tableur-col-" + colonne.id;

      // Au survol du header
      $(header_col).hover(
        function () {
          $("#" + dropdownId).css("display", "block"); // Affichage de la dropdown
        },
        function () {
          // Si on sort, masquage du dropdown lors d'un clic à l'extérieur
          $("#" + dropdownId).css("display", "none");
        }
      );
    }
  }

  /*
  ** TYPES DE DROPDOWNS FILTRES
  */

  dropdownSelectize(listeDeChoix, idColonne, label) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let idSelectize = 'filter-dropdown-' + idColonne;

    let dropdownHtml = '<div class="form-group"><small class="form-text text-muted">' + label + '</small>' +
      '<input id="' + idSelectize + '" type="text" class="form-control" placeholder="Recherche"></div>';

    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();

    let dropdownId = "tableur-col-" + idColonne;

    //$("#" + dropdownId).remove();

    // Création du dropdown
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" +
      dropdownId +
      "' class='filter-box'>" +
      dropdownHtml +
      "</div>"
    );

    let listSelectize = listeDeChoix.map((item, index) => {

      if(item == '') {
        return {
          id: index,
          nom: '(vide)'
        };
      }
      else {
        return {
          id: index,
          nom: item
        };
      }
    });

    $('#' + idSelectize).selectize({
      delimiter: ",",
      plugins: ["remove_button"],
    });

    that.BdcService.maj_select('#' + idSelectize, listSelectize);


    $('#' + idSelectize)[0].selectize.setValue();

    $('#' + idSelectize).change(function () {
      let tList = listSelectize.slice();

      datafilter = [];

      if ($(this).val() != '') {
        // Pour chaque élément sélectionné
        let selection = $(this).val().split(",");

        for (let i = 0; i < selection.length; i++) {
          let itemSelection = listSelectize.find(item => item.id == selection[i])

          datafilter.push(itemSelection.nom);
        }

      }

      if (datafilter.length == 0) {
        $('#notif-filter-' + idColonne).addClass('col-no-filter')
      }
      else {
        $('#notif-filter-' + idColonne).removeClass('col-no-filter')
      }

      that.columnTab[idColonne].datafilter = datafilter;

      that.table.draw();
    });

  }

  dropdownCheckboxes(listeDeChoix, idColonne) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let checkboxesHtml = "";

    // Pour chaque item, création d'une checkbox
    for (let i = 0; i < listeDeChoix.length; i++) {
      checkboxesHtml +=
        '<label for="' +
        idColonne +
        "-" +
        i +
        '" ' +
        'style="display: block"' +
        '><input type="checkbox" id="' +
        idColonne +
        "-" +
        i +
        '"/>' +
        listeDeChoix[i] +
        "</label>";
    }

    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();

    let dropdownId = "tableur-col-" + idColonne;

    $("#" + dropdownId).remove();

    // Création du dropdown
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" +
      dropdownId +
      "' class='filter-box'>" +
      checkboxesHtml +
      "</div>"
    );

    // Lors d'une modif sur une checkbox
    $("#" + dropdownId + " input[type='checkbox']").on("change", (e) => {
      //let tabFilter = [];

      datafilter = [];

      // On regarde l'état de chaque checkbox
      $("#tableur-col-" + idColonne + " input[type='checkbox']").each(
        (index, checkB) => {
          if ($(checkB).is(":checked")) {
            // Si la checkbox est validée

            let item = $(
              "label[for='" + $(checkB).attr("id") + "']"
            ).text();

            datafilter.push(item);

            /*
            // Précision de regex si le champ est vide (sinon ça sélectionne tout)
            let regexItem =
              item == "" ? "^$" : $.fn.dataTable.util.escapeRegex(item);

            tabFilter.push(
              // On l'ajoute au tableau de regex
              "(" + regexItem + ")"
            );
            */
          }
        }
      );

      if (datafilter.length == 0) {
        $('#notif-filter-' + idColonne).addClass('col-no-filter')
      }
      else {
        $('#notif-filter-' + idColonne).removeClass('col-no-filter')
      }

      that.columnTab[idColonne].datafilter = datafilter;

      that.table.draw();
    });







  }

  dropdownRange(idColonne) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let Html = '<div class="row">';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Minimum</small>' +
      '<input id="' + idColonne + '-min" type="number" class="form-control" placeholder="Min."></div></div>';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Maximum</small>' +
      '<input id="' + idColonne + '-max" type="number" class="form-control" placeholder="Max."></div></div>';

    Html += '</div>';


    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();
    let dropdownId = "tableur-col-" + idColonne;

    // Création du dropdown
    $("#" + dropdownId).remove();
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" + dropdownId + "' class='filter-box'>" +
      Html +
      "</div>"
    );

    $('#' + idColonne + '-min, #' + idColonne + '-max').keyup(function () {
      that.table.draw();
    });


    /*
    // Lors d'une modif sur une checkbox
    $("#" + dropdownId + " input[type='checkbox']").on("change", (e) => {
      //let tabFilter = [];

      datafilter = [];



      that.columnTab[idColonne].datafilter = datafilter;



      that.table.draw();
    });


*/




  }

  dropdownDate(idColonne) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let Html = '<div class="row">';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Minimum</small>' +
      '<input id="' + idColonne + '-min" type="date" class="form-control" placeholder="Min."></div></div>';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Maximum</small>' +
      '<input id="' + idColonne + '-max" type="date" class="form-control" placeholder="Max."></div></div>';

    Html += '</div>';
    Html += '<div class="row"><div class="col-12"><button type="button" class="btn btn-secondary btn-sm" id="' + idColonne + '-reinit">Réinitialiser</button></div></div>'


    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();
    let dropdownId = "tableur-col-" + idColonne;

    // Création du dropdown
    $("#" + dropdownId).remove();
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" + dropdownId + "' class='filter-box'>" +
      Html +
      "</div>"
    );

    $('#' + idColonne + '-min, #' + idColonne + '-max').change(function () {
      that.table.draw();
    });

    $('#' + idColonne + '-reinit').click(function () {
      $('#' + idColonne + '-min').val('');
      $('#' + idColonne + '-max').val('');
      that.table.draw();
    });

  }

  dropdownDateInter(idColonne) {
    const that = this;

    that.columnTab[idColonne].datafilter = false; // Filter blank

    let Html = '<div class="row">';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Minimum</small>' +
      '<input id="' + idColonne + '-min" type="date" class="form-control" placeholder="Min."></div></div>';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Maximum</small>' +
      '<input id="' + idColonne + '-max" type="date" class="form-control" placeholder="Max."></div></div>';

    Html += '</div>';
    Html += '<div class="row"><div class="col-12"><button type="button" class="btn btn-secondary btn-sm" id="' + idColonne + '-reinit">Réinitialiser</button>&nbsp;' +
    '<button type="button" class="btn btn-secondary btn-sm" id="' + idColonne + '-blank">Aucune intervention</button>' + '</div></div>'


    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();
    let dropdownId = "tableur-col-" + idColonne;

    // Création du dropdown
    $("#" + dropdownId).remove();
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" + dropdownId + "' class='filter-box'>" +
      Html +
      "</div>"
    );

    $('#' + idColonne + '-min, #' + idColonne + '-max').change(function () {
      that.columnTab[idColonne].datafilter = false;
      that.table.draw();
    });

    $('#' + idColonne + '-reinit').click(function () {
      $('#' + idColonne + '-min').val('');
      $('#' + idColonne + '-max').val('');
      that.columnTab[idColonne].datafilter = false;
      that.table.draw();
    });

    $('#' + idColonne + '-blank').click(function () {
      $('#' + idColonne + '-min').val('');
      $('#' + idColonne + '-max').val('');
      that.columnTab[idColonne].datafilter = true; // Filter blank
      that.table.draw();
    });

  }




  // Filtres

  filterSelectize(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    let datafilter = that.columnTab[idColonne].datafilter;

    if (datafilter.length > 0) {
      if (datafilter.includes(that.GetPropertyValue(row, dataTarget))) {
        return true;
      }
      else {
        if (datafilter.includes('(vide)') && that.GetPropertyValue(row, dataTarget) == '') return true;
        return false;
      }
    } else return true;
  }

  filterRange(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    if ($('#' + idColonne + '-min').val() == '' && $('#' + idColonne + '-max').val() == '') {
      $('#notif-filter-' + idColonne).addClass('col-no-filter')
    }
    else {
      $('#notif-filter-' + idColonne).removeClass('col-no-filter')
    }

    var min = parseInt($('#' + idColonne + '-min').val(), 10);
    var max = parseInt($('#' + idColonne + '-max').val(), 10);
    var prix = parseFloat(that.GetPropertyValue(row, dataTarget)) || 0; // use data for the age column

    if ((isNaN(min) && isNaN(max)) ||
      (isNaN(min) && prix <= max) ||
      (min <= prix && isNaN(max)) ||
      (min <= prix && prix <= max)) {
      return true;
    }
    return false;
  }

  filterDate(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    if ($('#' + idColonne + '-min').val() == '' && $('#' + idColonne + '-max').val() == '') {
      $('#notif-filter-' + idColonne).addClass('col-no-filter')
    }
    else {
      $('#notif-filter-' + idColonne).removeClass('col-no-filter')
    }

    var min = $('#' + idColonne + '-min').val();
    var max = $('#' + idColonne + '-max').val();
    var date = moment(that.GetPropertyValue(row, dataTarget)).format("YYYY-MM-DD")

    // Si un des deux paramètres n'est pas complet, on désactive le filtre
    if((moment(min).format("DD/MM/YYYY") == "Invalid date") ||
    (moment(max).format("DD/MM/YYYY") == "Invalid date")) {
      return true;
    }
    // Si la date est invalide, on n'affiche pas
    else if((moment(date).format("DD/MM/YYYY") == "Invalid date") || (date === undefined)) {
      return false;
    }
    else if (moment(date).isBetween(min, max, undefined, '[]')) {
      return true;
    }
    return false;
  }

  filterDateInter(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    if ($('#' + idColonne + '-min').val() == '' && $('#' + idColonne + '-max').val() == '') {
      $('#notif-filter-' + idColonne).addClass('col-no-filter')
    }
    else {
      $('#notif-filter-' + idColonne).removeClass('col-no-filter')
    }

    let date_raw = that.GetPropertyValue(row, dataTarget)

    var min = $('#' + idColonne + '-min').val();
    var max = $('#' + idColonne + '-max').val();
    var date = moment(date_raw).format("YYYY-MM-DD")

    if(that.columnTab[idColonne].datafilter){ // Si on cherche les champs vides
      if(date_raw === undefined) {
        return true;
      }
      else return false;
    }
    else {
      // Si un des deux paramètres n'est pas complet, on désactive le filtre
      if((moment(min).format("DD/MM/YYYY") == "Invalid date") ||
      (moment(max).format("DD/MM/YYYY") == "Invalid date")) {
        return true;
      }
      // Si la date est invalide, on n'affiche pas
      else if((moment(date).format("DD/MM/YYYY") == "Invalid date") || (date_raw === undefined)) {
        return false;
      }
      else if (moment(date).isBetween(min, max, undefined, '[]')) {
        return true;
      }
    }
    return false;
  }

  initStatusFilter(status) {
    this.selectedStatus = status;

    if(status == 'ALL') {
      $('#label-status-all').addClass("active").siblings().removeClass("active");
    }
    else if(status == 'DIFFICULTE_PLANIF') {
      $('#label-status-a-planifier').addClass("active").siblings().removeClass("active");
    }
    else if(status == 'RAPPORT_RETARD') {
      $('#label-rapport-retard').addClass("active").siblings().removeClass("active");
    }

    // this.majListe();
  }

  switchStatusFilter(status) {
    this.selectedStatus = status;

    if(status == 'ALL') {
      $('#label-status-all').addClass("active").siblings().removeClass("active");
    }
    else if(status == 'DIFFICULTE_PLANIF') {
      $('#label-status-a-planifier').addClass("active").siblings().removeClass("active");
    }
    else if(status == 'RAPPORT_RETARD') {
      $('#label-rapport-retard').addClass("active").siblings().removeClass("active");
    }

    this.majListe();
  }




  // Pre-builders

  pre_dropdownSelectize (idColonne, dataTarget, nomChamp) {
    const that = this;

    let visibleRows = that.table.rows({ order: 'index', search: 'applied' })

    let dataCol = [];

    for (let i = 0; i < visibleRows.data().length; i++) {
      let item = visibleRows.data()[i];

      let data = that.GetPropertyValue(item, dataTarget)

      if (data == null) dataCol.push('');
      else dataCol.push(data);
    }

    // Recherche des occurrences uniques
    let listeDeChoix = dataCol.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    listeDeChoix.sort((a, b) => {
      if (a === '') return 1;
      else if (b === '') return -1;
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    that.dropdownSelectize(listeDeChoix, idColonne, nomChamp);
  }

  pre_dropdownCheckboxes (idColonne) {
    const that = this;

    let visibleRows = that.table.rows({ order: 'index', search: 'applied' })

          let dataCol = visibleRows.data().map((item) => {
            if (item[that.columnTab[idColonne].data] == null) return '';
            else return item[that.columnTab[idColonne].data];
          });

          // Recherche des occurrences uniques
          let listeDeChoix = dataCol.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });

          listeDeChoix.sort((a, b) => {
            if (a === '') return 1;
            else if (b === '') return -1;
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });

          /*
           ** CONSTRUCTION DE LA LISTE
           */
          that.dropdownCheckboxes(listeDeChoix, idColonne);
  }


  /*
  ** EXPORT
  */

 exportFactu() {
   console.log("EXPORT")

   // A_FACTURER
   // FACTURE

   let listExport = this.listeBdc.filter(bon => {
     if((bon.status == "A_FACTURER") || (bon.status == "A_FACTURER")) {
       return true;
     }
     else return false;
   })

   console.log(listExport)
   this.export(listExport);
 }

 export(list) {
  const rows = [
    ["Id quitus", "No de commande", "Bailleur", "Marche", "Charge de secteur", "Date de creation", "Objet d'intervention", "Prix HT"]
  ];

  for (let i = 0; i < list.length; i++) {
    const bon = list[i];

    rows.push([
      bon.id,
      bon.no_bdc,
      bon.bailleur,
      bon.marche,
      bon.client,
      moment(bon.date).format("DD/MM/YYYY"),
      bon.objet_intervention,
      bon.interventions.prix_ht.toFixed(2)
    ])
  }

  this.exportToCsv("export_facturation.csv", rows);
}



exportSelected(list) {
  this.export(list);
}

exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ';';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}




  /*
   ** ACTION SUR LES LIGNES
   */

  // Sélection d'une ligne
  selectRow(id) {
    const that = this;

    if (this.selectedRows.indexOf(id) === -1) {
      this.selectedRows.push(id);

      if (this.selectedRows.length === 1) {
        that.actionSelection();
      }
    }
  }

  // Déselection d'une ligne
  deselectRow(id) {
    if (this.selectedRows.indexOf(id) !== -1) {
      this.selectedRows.splice(this.selectedRows.indexOf(id), 1);

      if (this.selectedRows.length === 0) {
        $("#action-selection").remove();
        this.delete_selection_listener.off();
      }
    }
  }

  // Action sur les lignes sélectionnées
  actionSelection() {
    const that = this;

    let textButton = "";
    let actionFct = function (id) { };

    // Création du boutton
    let createButton = function () {
      $(".dataTables_length").after(
        '<label><button id="action-selection" class="btn btn-primary btn-sm" style="margin: 1rem 1rem">' +
        textButton +
        "</button></label>"
      );

      // Action lors du clic sur le bouton
      that.delete_selection_listener = $("#action-selection").on(
        "click",
        function () {
          // On effectue l'action sur chacun des items sélectionnés
          for (let i = 0; i < that.selectedRows.length; i++) {
            actionFct(that.selectedRows[i]);
          }

          that.selectedRows = [];
          $("#action-selection").remove();
          that.delete_selection_listener.off();
        }
      );
    };

    if (that.select === "corbeille") {
      textButton = "Supprimer";

      actionFct = function (id_bdc) {
        that.BdcService.supprimerBdc(id_bdc).then(() => { });
      };

      createButton();
    } else if (that.select === "validation") {
      textButton = "Passer en validation";

      actionFct = function (id_bdc) {
        that.BdcService.bdcToValidationState(id_bdc).then(() => { });
      };

      createButton();
    } else if (that.select === "planification") {
      textButton = "Passer en planification";

      actionFct = function (id_bdc) {
        that.BdcService.bdcToPlanificationState(id_bdc).then(() => { });
      };

      createButton();
    } else if (that.select === "export_factu") {
      $(".dataTables_length").after(
        '<label><button id="action-selection" class="btn btn-primary btn-sm" style="margin: 1rem 1rem">Exporter la sélection</button></label>'
      );

      that.delete_selection_listener = $("#action-selection").on(
        "click",
        function () {
          let tabSelectedBdc = [];

          for (let i = 0; i < that.selectedRows.length; i++) {
            tabSelectedBdc.push(
              that.listeBdc.find((element) => {
                return element.id === that.selectedRows[i];
              })
            );
          }
        }
      );
    }

    this.newData();
  }

  /*
  ** Clic sur une ligne
  */
  clicBdc(bdc) {

    switch (bdc.status) {
      case "": {
        this.router.navigate(["bons-de-commande/validation/" + bdc.id]);
        break;
      }
      case "A_VALIDER": {
        this.router.navigate(["bons-de-commande/validation/" + bdc.id]);
        break;
      }
      case "A_PLANIFIER": {
        this.router.navigate(["bons-de-commande/planification/" + bdc.id]);
        break;
      }
      case "A_EXECUTER": {
        this.router.navigate(["bons-de-commande/intervention/" + bdc.id]);
        break;
      }
      case "A_FACTURER": {
        this.router.navigate(["bons-de-commande/facturation/" + bdc.id]);
        break;
      }
      case "FACTURE": {
        this.router.navigate(["bons-de-commande/post-facturation/" + bdc.id]);
        break;
      }
      case "ARCHIVE": {
        this.router.navigate(["bons-de-commande/historique/" + bdc.id]);
        break;
      }
      case "CORBEILLE": {
        //statements;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }


  fontSizeUp() {
    this.fontSize += 0.1;
    document.documentElement.style.setProperty('--tab-font-size', this.fontSize + 'em')
    localStorage.setItem("tab_font_size", JSON.stringify(this.fontSize));
  }

  fontSizeDown() {
    this.fontSize -= 0.1;
    document.documentElement.style.setProperty('--tab-font-size', this.fontSize + 'em')
    localStorage.setItem("tab_font_size", JSON.stringify(this.fontSize));
  }



  GetPropertyValue(obj1, dataToRetrieve) {
    return dataToRetrieve
      .split('.') // split string based on `.`
      .reduce(function(o, k) {
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
      }, obj1) // set initial value as object
  }

  public isAm (date) {
    if(moment(date).isBetween(moment(date).format("YYYY-MM-DD") + " 00:00:00", moment(date).format("YYYY-MM-DD") + " 11:59:00")) {
      return true;
    }
    else return false;
  }

  public formatDate(date) {
    if(moment(date).format("DD/MM/YYYY") == "Invalid date") return "";
    else return moment(date).format("DD/MM/YYYY");
  }
}
