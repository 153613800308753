import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { Calendar } from "@fullcalendar/core";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import frLocale from "@fullcalendar/core/locales/fr";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";

import { ModalConfirmationPlanificationComponent } from "./modal-confirmation-planification/modal-confirmation-planification.component";
import { SelectionDateModalComponent } from "./selection-date-modal/selection-date-modal.component";
import { DeleteEventModalComponent } from "./delete-event-modal/delete-event-modal.component";
import { ReplanifEventModalComponent } from "./replanif-event-modal/replanif-event-modal.component";
import { SelectionTechModalComponent } from "./selection-tech-modal/selection-tech-modal.component";

import { BdcService } from "../../../services/service-bdc.service";
import { TestBed } from "@angular/core/testing";

declare var moment: any;
declare var mapboxgl: any;
declare var $: any;

@Component({
  selector: "app-widget-planif",
  templateUrl: "./widget-planif.component.html",
  styleUrls: ["./widget-planif.component.scss"],
})
export class WidgetPlanifComponent implements OnInit {
  //@Input() idBdc;
  @Output() planifIntervention = new EventEmitter<any[]>();

  agence;

  // Calendrier
  calendar;
  draggableEvent = {
    id: 0,
    id_bdc: 0,
    title: "",
    duration: "02:00",
    sourceId: 1,
  };
  identifierIndex = 0;

  tabEvents = [];
  newEvents = [];
  eventsAPlanifier = [];

  map = null;
  tabMarkers = [];
  planifJour = [];
  geojsonSelected = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  };

  selectedBdc: any;
  isSelectedBdc = false;

  listeBdcAPlanifier = [];

  couleursRessources = [
    "#F23D5E",
    "#0477BF",
    "#049DD9",
    "#F2C335",
    "#F2856D",
    "#08E800",
    "#E8CF78",
    "#FF2500",
    "#740FE8",
    "#59E7FF",
  ];
  idCouleur = 0;

  public cardIsCollapsed = false;

  constructor(
    private router: Router,
    private BdcService: BdcService,
    private modalService: NgbModal
  ) {
    this.BdcService.getListBdcAPlanifier().then((listeBdcAPlanifier: any[]) => {
      this.listeBdcAPlanifier = listeBdcAPlanifier;
    });
  }

  // Cette fonction ajoute les techniciens au calendrier comme ressources
  // Elle est appelée dans la fonction d'initialisation et pour la map
  getResources() {
    // Obtention de la liste des techniciens et attribution d'une couleur
    (<any>this.BdcService.getTechniciens()).then((techniciens) => {
      let totalTech = [];

      for (let j = 0; j < techniciens.length; j++) {
        const tech = techniciens[j];

        if (tech.actif == 1) {
          totalTech.push(tech);
        }
      }

      for (const i in totalTech) {
        // Chaque technicien est ajouté en tant que ressource
        const technicien = totalTech[i];

        this.calendar.addResource({
          id: technicien.id,
          title: technicien.prenom + " " + technicien.nom,
          couleur: this.makeColor(i, totalTech.length),
        });
      }

      console.log(totalTech)
    });
  }


  init() {
    const that = this;

    // (re)init vars
    this.tabEvents = [];
    this.newEvents = [];
    this.identifierIndex = 0;

    // Get calendar
    let calendarEl: HTMLElement = document.getElementById("calendar")!;

    this.calendar = new Calendar(calendarEl, {
      schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        listPlugin,
        timeGridPlugin,
        resourceTimelinePlugin,
      ],
      locale: frLocale,
      editable: true, // enable draggable events
      selectable: true,
      droppable: true,
      height: "auto",
      scrollTime: "00:00", // undo default 6am scrollTime

      // Boutons
      customButtons: {
        chooseDate: {
          text: "Choisir la date",
          click: function () {
            const modalRef = that.modalService.open(
              SelectionDateModalComponent
            );
            modalRef.result.then(
              (result) => {
                //console.log(result)
                that.calendar.gotoDate(result);
                that.dayChanged();
              },
              (reason) => {
                //console.log(reason);
              }
            );
          },
        },
      },
      header: {
        left: "today,chooseDate prev,next",
        center: "title",
        right: "resourceTimelineDay,timeGridWeek,dayGridMonth",
      },
      buttonIcons: {
        prev: "left-single-arrow",
        next: "right-single-arrow",
      },
      defaultView: "resourceTimelineDay",
      resourceLabelText: "Techniciens",

      eventSources: [
        {
          id: "savedEvents",
          events: (fetchInfo, successCallback, failureCallback) => {
            successCallback(that.planifJour);
          },
          textColor: "white",
        },
        {
          id: "aPlanifier",
          events: (fetchInfo, successCallback, failureCallback) => {
            successCallback(that.eventsAPlanifier);
          },
          textColor: "white",
        },
      ],

      eventRender: function (renderInfo) {
        renderInfo.el.style.backgroundColor =
          renderInfo.event.extendedProps.couleur;
      },
      resourceRender: function (renderInfo) {
        renderInfo.el.style.background =
          "linear-gradient(to right, hsl( " +
          renderInfo.resource.extendedProps.couleur +
          ", 90%, 30% ), white)";
        renderInfo.el.style.borderRadius = "10px";
        renderInfo.el.style.color = "white";
      },

      eventDrop: function (eventResizeInfo) {
        that.saveEventChange(eventResizeInfo);
      },
      eventResize: function (eventResizeInfo) {
        that.saveEventChange(eventResizeInfo);
      },
      eventReceive: function (dropInfo) {
        if (dropInfo.view.viewSpec.type == "timeGridWeek") {
          const modalRef = that.modalService.open(SelectionTechModalComponent);
          modalRef.componentInstance.techs = that.calendar.getResources();

          modalRef.result.then(
            (techId) => {
              that.eventsAPlanifier.push({
                id: that.identifierIndex,
                id_bdc: dropInfo.event._def.extendedProps.id_bdc,
                title: dropInfo.event.title,
                resourceId: techId,
                couleur:
                  "hsl( " +
                  that.calendar.getResourceById(techId).extendedProps.couleur +
                  ", 90%, 30% )",
                start: dropInfo.event.start,
                end: dropInfo.event.end,
                editable: true,
                overlap: true,
              });

              that.identifierIndex++;

              that.calendar.refetchEvents();

              // On retire des événements parasites qui viennent doubler
              let allevents = that.calendar.getEvents();
              for (let evt of allevents) {
                if (typeof evt.source.internalEventSource === "undefined") {
                  evt.remove();
                }
              }
            },
            (reason) => {}
          );
        } else {
          that.eventsAPlanifier.push({
            id: that.identifierIndex,
            id_bdc: dropInfo.event._def.extendedProps.id_bdc,
            title: dropInfo.event.title,
            resourceId: dropInfo.event._def.resourceIds[0],
            couleur:
              "hsl( " +
              that.calendar.getResourceById(dropInfo.event._def.resourceIds[0])
                .extendedProps.couleur +
              ", 90%, 30% )",
            start: dropInfo.event.start,
            end: dropInfo.event.end,
            editable: true,
            overlap: true,
          });

          that.calendar.refetchEvents();

          // On retire des événements parasites qui viennent doubler
          let allevents = that.calendar.getEvents();
          for (let evt of allevents) {
            if (typeof evt.source.internalEventSource === "undefined") {
              evt.remove();
            }
          }

          that.identifierIndex++;
        }

        //that.eventsAPlanifier.push(dropInfo.event);
        //console.log(dropInfo.event)

        /*that.eventsAPlanifier.push({
          id: that.identifierIndex,
          id_bdc: dropInfo.event._def.extendedProps.id_bdc,
          title: dropInfo.event.title,
          resourceId: dropInfo.event._def.resourceIds[0],
          couleur: "hsl( " + that.calendar.getResourceById(dropInfo.event._def.resourceIds[0]).extendedProps.couleur + ", 90%, 30% )",
          start: dropInfo.event.start,
          end: dropInfo.event.end,
          editable: true,
          overlap: true
        });

        that.calendar.refetchEvents();

        // On retire des événements parasites qui viennent doubler
        let allevents = that.calendar.getEvents()
        for (let evt of allevents) {
          if (typeof evt.source.internalEventSource === 'undefined') {
            evt.remove();
          }
        }

        that.identifierIndex++;*/
        //console.log(that.identifierIndex)
      },
      // Sélection d'une date dans le calendrier
      select: (info) => {
        //console.log(info)

        if (that.draggableEvent.id_bdc !== 0) {
          // Si un bdc est sélectionné

          if (info.view.viewSpec.type == "timeGridWeek") {
            const modalRef = that.modalService.open(
              SelectionTechModalComponent
            );
            modalRef.componentInstance.techs = that.calendar.getResources();

            modalRef.result.then(
              (techId) => {
                //console.log(techId)

                that.eventsAPlanifier.push({
                  id: that.identifierIndex,
                  id_bdc: that.draggableEvent.id_bdc,
                  title: that.draggableEvent.title,
                  resourceId: techId,
                  couleur:
                    "hsl( " +
                    that.calendar.getResourceById(techId).extendedProps
                      .couleur +
                    ", 90%, 30% )",
                  start: info.start,
                  end: info.end,
                  editable: true,
                  overlap: true,
                });

                that.identifierIndex++;

                that.calendar.refetchEvents();
              },
              (reason) => {}
            );
          } else {
            that.eventsAPlanifier.push({
              id: that.identifierIndex,
              id_bdc: that.draggableEvent.id_bdc,
              title: that.draggableEvent.title,
              resourceId: info.resource.id,
              couleur:
                "hsl( " +
                that.calendar.getResourceById(info.resource.id).extendedProps
                  .couleur +
                ", 90%, 30% )",
              start: info.start,
              end: info.end,
              editable: true,
              overlap: true,
            });

            that.identifierIndex++;

            that.calendar.refetchEvents();
          }
        }
      },

      eventClick: function (eventClickInfo) {
        //console.log(eventClickInfo)

        // Si l'événement cliqué concerne le BDC
        if (eventClickInfo.event.source.id === "aPlanifier") {
          const modalRef = that.modalService.open(DeleteEventModalComponent);
          modalRef.componentInstance.idBdc =
            eventClickInfo.event.extendedProps.id_bdc;
          modalRef.componentInstance.event = eventClickInfo.event;
          modalRef.result.then(
            (result) => {
              that.deleteNewEvent(result.event);
            },
            (reason) => {}
          );
        } else if (eventClickInfo.event.source.id === "savedEvents") {
          // Si ça concerne un autre BDC
          const modalRef = that.modalService.open(ReplanifEventModalComponent);
          modalRef.componentInstance.idBdc =
            eventClickInfo.event.extendedProps.id_bdc;
          modalRef.componentInstance.event = eventClickInfo.event;
          modalRef.result.then(
            (result) => {
              // Renvoi du bdc en planification
              //console.log(result)

              if (result.action === "replanifier") {
                that.BdcService.bdcToPlanificationState(
                  result.event._def.extendedProps.id_bdc
                ).then((res) => {
                  that.router.navigate([
                    "bons-de-commande/planification/" +
                      result.event._def.extendedProps.id_bdc,
                  ]);

                  that.init();
                  //window.location.reload();
                });
              } else if (result.action === "consulter") {
                switch (result.event._def.extendedProps.bdc.status) {
                  case "": {
                    that.router.navigate([
                      "bons-de-commande/validation/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "A_VALIDER": {
                    that.router.navigate([
                      "bons-de-commande/validation/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "A_PLANIFIER": {
                    that.router.navigate([
                      "bons-de-commande/planification/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "A_EXECUTER": {
                    that.router.navigate([
                      "bons-de-commande/intervention/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "A_FACTURER": {
                    that.router.navigate([
                      "bons-de-commande/facturation/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "FACTURE": {
                    that.router.navigate([
                      "bons-de-commande/post-facturation/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "ARCHIVE": {
                    that.router.navigate([
                      "bons-de-commande/historique/" +
                        result.event._def.extendedProps.bdc.id,
                    ]);
                    break;
                  }
                  case "CORBEILLE": {
                    //statements;
                    break;
                  }
                  default: {
                    //statements;
                    break;
                  }
                }
              }
            },
            (reason) => {}
          );
        }
      },
    });

    this.getResources(); // Get tech

    this.calendar.render();

    this.dayChanged(); // Get events du jour

    // Si changement de jour, chargement des events
    $(document).on('click', '.fc-today-button', function () {
      that.dayChanged();
    });
    $(document).on('click', '.fc-prev-button', function () {
      that.dayChanged();
    });
    $(document).on('click', '.fc-next-button', function () {
      that.dayChanged();
    });
    $(document).on('click', '.fc-timeGridWeek-button', function () {
      that.dayChanged();
    });
    $(document).on('click', '.fc-resourceTimelineDay-button', function () {
      that.dayChanged();
    });
    $(document).on('click', '.fc-dayGridMonth-button', function () {
      that.dayChanged();
    });

    // config de l'event draggable
    let draggableEl = document.getElementById("draggableEvent");
    let drag = new Draggable(draggableEl, {
      eventData: function (eventEl) {
        return that.draggableEvent;
      },
    });
  }

  initMap() {
    const that = this;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiY2hpcHNvbmR1bGVlIiwiYSI6ImQzM2UzYmQxZTFjNjczZWMyY2VlMzQ5NmM2MzEzYWRmIn0.0iPy8Qyw2FjGSxawGZxW8A";
    this.map = new mapboxgl.Map({
      container: "map", // container id
      style: "mapbox://styles/mapbox/streets-v11", // stylesheet location 11
      center: [this.agence.longitude, this.agence.latitude], // starting position [lng, lat]
      zoom: this.agence.zoom, // starting zoom
    });

    this.map.on("load", function () {
      that.map.loadImage(
        "assets/map-icons/maps-and-flags" + ".png",
        (error, image) => {
          if (error) throw error;
          if (!that.map.hasImage("maps-and-flags")) {
            that.map.addImage("maps-and-flags", image);

            that.map.loadImage(
              "assets/map-icons/wrench" + ".png",
              (error, image) => {
                if (error) throw error;
                if (!that.map.hasImage("wrench")) {
                  that.map.addImage("wrench", image);

                  // Ajout des marqueurs BDC à planifier
                  that.BdcService.getListBdcAPlanifier().then((aValider) => {
                    //console.log(aValider);

                    let geojsonBdc = {
                      type: "geojson",
                      data: {
                        type: "FeatureCollection",
                        features: [],
                      },
                      cluster: true,
                      clusterMaxZoom: 14, // Max zoom to cluster points on
                      clusterRadius: 10, // Radius of each cluster when clustering points (defaults to 50)
                    };

                    that.map.addSource("points", geojsonBdc);

                    that.map.addLayer({
                      id: "clusters",
                      type: "circle",
                      source: "points",
                      filter: ["has", "point_count"],
                      paint: {
                        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 100
                        //   * Yellow, 30px circles when point count is between 100 and 750
                        //   * Pink, 40px circles when point count is greater than or equal to 750
                        "circle-color": [
                          "step",
                          ["get", "point_count"],
                          "#909090",
                          100,
                          "#909090",
                          750,
                          "#909090",
                        ],
                        "circle-radius": [
                          "step",
                          ["get", "point_count"],
                          20,
                          100,
                          30,
                          750,
                          40,
                        ],
                      },
                    });

                    that.map.addLayer({
                      id: "cluster-count",
                      type: "symbol",
                      source: "points",
                      filter: ["has", "point_count"],
                      layout: {
                        "text-field": "{point_count_abbreviated}",
                        "text-font": [
                          "DIN Offc Pro Medium",
                          "Arial Unicode MS Bold",
                        ],
                        "text-size": 12,
                        "icon-ignore-placement": true,
                        "icon-allow-overlap": true,
                        "text-allow-overlap": true,
                      },
                      paint: {
                        "text-color": "white",
                      },
                    });

                    that.map.addLayer({
                      id: "unclustered-point",
                      type: "symbol",
                      source: "points",
                      filter: ["!", ["has", "point_count"]],
                      layout: {
                        "icon-image": "{icon}",
                        "text-field": "{title}",
                        "text-font": [
                          "Open Sans Semibold",
                          "Arial Unicode MS Bold",
                        ],
                        "text-offset": [0, 0.6],
                        "text-anchor": "top",
                        "icon-ignore-placement": true,
                        "icon-allow-overlap": true,
                        "text-allow-overlap": true,
                      },
                    });

                    that.map.addSource("selected", that.geojsonSelected);

                    that.map.addLayer({
                      id: "selected-bdc",
                      type: "symbol",
                      source: "selected",
                      layout: {
                        "icon-image": "{icon}",
                        "text-field": "{title}",
                        "text-font": [
                          "Open Sans Semibold",
                          "Arial Unicode MS Bold",
                        ],
                        "text-offset": [0, 0.6],
                        "text-anchor": "top",
                        "icon-ignore-placement": true,
                        "icon-allow-overlap": true,
                        "text-allow-overlap": true,
                      },
                    });

                    // Clic sur une inter à planifier
                    that.map.on("click", "unclustered-point", function (e) {
                      var coordinates = e.features[0].geometry.coordinates.slice();
                      var description = e.features[0].properties.description;
                      var idBdc = e.features[0].properties.title;

                      that.bdcSelected(idBdc, coordinates);
                    });

                    // Change the cursor to a pointer when the mouse is over the places layer.
                    that.map.on("mouseenter", "unclustered-point", function () {
                      that.map.getCanvas().style.cursor = "pointer";
                    });

                    // Change it back to a pointer when it leaves.
                    that.map.on("mouseleave", "unclustered-point", function () {
                      that.map.getCanvas().style.cursor = "";
                    });

                    for (let bdc of aValider as any) {
                      that
                        .getGeoloc(
                          bdc,
                          bdc.adresse_locataire +
                            " " +
                            bdc.code_postal_locataire +
                            " " +
                            bdc.ville_locataire
                        )
                        .then(
                          (resp: any) => {
                            geojsonBdc.data.features.push({
                              type: "Feature",
                              geometry: {
                                type: "Point",
                                coordinates: resp.geometry.coordinates,
                              },
                              properties: {
                                description: "test",
                                title: resp.planif.id,
                                icon: "wrench",
                              },
                            });

                            that.map
                              .getSource("points")
                              .setData(geojsonBdc.data);
                          },
                          (err) => {}
                        );
                    }
                  });
                }
              }
            );
          }
        }
      );
    });
  }

  ngOnInit() {
    this.BdcService.getAgence(this.BdcService.getAgenceId()).then(
      (agence: any) => {
        //console.log(agence)

        this.agence = agence;

        this.init();
        this.initMap();
      }
    );
  }

  ngOnDestroy() {
    console.log("destroy")
    this.calendar.destroy();
    this.calendar = null;

    this.tabEvents = [];
    this.newEvents = [];
    this.eventsAPlanifier = [];
    this.identifierIndex = 0;
    this.planifJour = [];

    $(document).off('click');
  }

  dayChanged() {
    const that = this;

    console.log("day changed")

    let day = this.calendar.getDate();

    let start;
    let end;

    console.log(this.calendar.view)

    if (this.calendar.view.type === "timeGridWeek") {
      // On prend tous les jours de la semaine
      start = moment(day)
        .subtract(1, "day")
        .startOf("week")
        .add(1 + i, "day")
        .format("YYYY-MM-DD");
      end = moment(day)
        .subtract(1, "day")
        .endOf("week")
        .add(1 + i, "day")
        .format("YYYY-MM-DD");
    } else if (this.calendar.view.type === "dayGridMonth") {
      // On prend tous les jours de la semaine
      start = moment(day).startOf("month").format("YYYY-MM-DD");
      end = moment(day).endOf("month").format("YYYY-MM-DD");
    } else {
      start = moment(day).format("YYYY-MM-DD");
      end = moment(day).format("YYYY-MM-DD");
    }

    this.planifJour = [];
    this.calendar.refetchEvents();

    // Suppression de tous les marqueurs du jour sur la carte
    if (this.tabMarkers !== null) {
      for (var i = this.tabMarkers.length - 1; i >= 0; i--) {
        this.tabMarkers[i].remove();
      }
    }

    (<any>this.BdcService.getPlanifJour(start + ' 00:00:00', end + ' 23:59:59')).then((planifs) => {
      console.log(planifs)

      console.log("wait")

      // Pour chaque BDC
      for (const planif of planifs) {

        if(this.calendar.getResourceById(planif.id_technicien) != null) {

          // Tableau des événements planifiés ce jour sur le calendrier
          this.planifJour.push({
            id: planif.id,
            id_bdc: planif.id_bdc,
            bdc: planif.bdc,
            title: planif.bdc.no_bdc + " - " + planif.bdc.ville_locataire,
            start: new Date(planif.start),
            end: new Date(planif.end),
            resourceId: planif.id_technicien,
            couleur:
              "hsl( " +
              that.calendar.getResourceById(planif.id_technicien).extendedProps
                .couleur +
              ", 60%, 20% )",
            editable: false,
            resourceEditable: false,
            overlap: true,
          });

          console.log("refetch");

          this.calendar.refetchEvents();
        }
      }

      console.log('Ok !')

      // Pour chaque BDC
      for (const planif of planifs) {

        if(this.calendar.getResourceById(planif.id_technicien) != null) {

          // Obtention de la position de chaque BDC
          this.getGeoloc(
            planif,
            planif.bdc.adresse_locataire +
              " " +
              planif.bdc.code_postal_locataire +
              " " +
              planif.bdc.ville_locataire
          ).then(
            (resp: any) => {
              // Ajout d'un marqueur de la couleur du technicien
              var el = document.createElement("div");
              el.className = "marker";
              el.style.width = 20 + "px";
              el.style.height = 20 + "px";
              el.style.opacity = "0.6";

              el.style.color =
                "hsl( " +
                this.calendar.getResourceById(resp.planif.id_technicien)
                  .extendedProps.couleur +
                ", 100%, 50% )";
              el.style["background-color"] =
                "hsl( " +
                this.calendar.getResourceById(resp.planif.id_technicien)
                  .extendedProps.couleur +
                ", 100%, 50% )";
              el.style["border-radius"] = "50%";

              // add marker to map
              const marker = new mapboxgl.Marker(el)
                .setLngLat(resp.geometry.coordinates)
                .addTo(this.map);
              this.tabMarkers.push(marker);
            },
            (err) => {}
          );


        }
      }
    });
  }

  getGeoloc(planif, adresse) {
    return new Promise((resolve, reject) => {
      const url = encodeURI(adresse);

      this.BdcService.geoCoding(url).then((resp: any) => {
        if (resp.features.length > 0) {
          const reponse = {
            planif: planif,
            geometry: resp.features[0].geometry,
          };
          resolve(reponse);
        } else {
          reject();
        }
      });
    });
  }

  bdcSelected(idBdc, coordinates) {
    //console.log(idBdc)
    //console.log(coordinates)

    // Infos bdc card
    this.BdcService.getBdc(idBdc).then(async (bdc: any) => {
      this.selectedBdc = bdc;

      this.isSelectedBdc = true;

      this.draggableEvent = {
        id: this.identifierIndex,
        id_bdc: idBdc,
        title: idBdc + " - " + bdc.adresse_locataire,
        duration: "02:00",
        sourceId: 1,
      };

      if (typeof coordinates === "undefined") {
        const url = encodeURI(
          bdc.adresse_locataire +
            " " +
            bdc.code_postal_locataire +
            " " +
            bdc.ville_locataire
        );

        await this.BdcService.geoCoding(url).then((resp: any) => {
          coordinates = resp.features[0].geometry.coordinates;
        });
      }

      // Marker on map
      this.geojsonSelected.data.features = [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coordinates,
          },
          properties: {
            icon: "maps-and-flags",
            title: idBdc,
          },
        },
      ];

      this.map.getSource("selected").setData(this.geojsonSelected.data);
    });
  }

  saveEventChange(event) {
    for (var i = 0; i < this.eventsAPlanifier.length; i++) {
      if (
        this.eventsAPlanifier[i].id.toString() === event.event.id.toString()
      ) {
        this.eventsAPlanifier[i].start = event.event.start;
        this.eventsAPlanifier[i].end = event.event.end;
        this.eventsAPlanifier[i].resourceId = event.event._def.resourceIds[0];
        this.eventsAPlanifier[i].couleur =
          "hsl( " +
          this.calendar.getResourceById(event.event._def.resourceIds[0])
            .extendedProps.couleur +
          ", 90%, 30% )";
      }
    }

    this.calendar.refetchEvents();

    //this.changes();
  }

  getColor(idResource) {
    ////console.log(idResource.id_technicien);

    const resources = this.calendar.getResources();
    ////console.log(resources)

    for (let resource of resources) {
      ////console.log(resource._resource);
      ////console.log(idResource.id_technicien);

      if (resource._resource.extendedProps.id_tech === idResource) {
        ////console.log(resource._resource.extendedProps.couleur)
        return resource._resource.extendedProps.couleur;
      }
    }
  }

  makeColor(colorNum, colors) {
    if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
    return (colorNum * (360 / colors)) % 360;
  }

  clickMapMarker(event) {
    //console.log(event)
  }

  selectBdcOnMap(idBdc, coordinates) {
    this.geojsonSelected.data.features = [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
        properties: {
          icon: "maps-and-flags",
          title: idBdc,
        },
      },
    ];

    this.map.getSource("selected").setData(this.geojsonSelected.data);
  }

  deleteNewEvent(event) {
    for (var i = 0; i < this.eventsAPlanifier.length; i++) {
      if (this.eventsAPlanifier[i].id.toString() === event.id.toString()) {
        this.eventsAPlanifier.splice(i, 1);
      }
    }

    this.calendar.refetchEvents();
  }

  savePlanif() {
    const modalRef = this.modalService.open(
      ModalConfirmationPlanificationComponent
    );
    modalRef.componentInstance.events = this.eventsAPlanifier;
    modalRef.result.then(
      (result) => {
        for (const event of this.eventsAPlanifier) {
          this.BdcService.planifierBdc(event.id_bdc, [event]).then((res) => {
            window.location.reload();
          });
        }
      },
      (reason) => {}
    );
  }

  goToBdc(idBdc) {
    this.router.navigate(["bons-de-commande/planification/" + idBdc]);
  }
}
