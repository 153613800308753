import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { BdcService } from '../../services/service-bdc.service';

@Component({
  selector: 'app-liste-historique',
  templateUrl: './liste-historique.component.html',
  styleUrls: ['./liste-historique.component.scss']
})
export class ListeHistoriqueComponent implements OnInit {

  listeBdcAValider = [];

  constructor(private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
    this.BdcService.getListBdcArchives().then((listeBdcAValider: any[]) => {
      this.listeBdcAValider = listeBdcAValider;
    });
  }

}
