import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

import {
  BdcService
} from '../../../services/service-bdc.service';

import { ModalPhotosComponent } from './modal-photos/modal-photos.component';
import { ResumeAddInterventionModalComponent } from './resume-add-intervention-modal/resume-add-intervention-modal.component';

import * as moment from 'moment';

@Component({
  selector: 'app-resume-interventions',
  templateUrl: './resume-interventions.component.html',
  styleUrls: ['./resume-interventions.component.scss']
})
export class ResumeInterventionsComponent implements OnInit, OnChanges {

  @Input() idBdc;
  @Input() readonly;
  @Input() photos;

  @Output() prixFactuEvent = new EventEmitter<any>();
  @Output() bdcEvent = new EventEmitter<any[]>();

  env = environment;

  rapports = [];
  isRapports = false;

  prixFactu = 0;
  prixTotal = 0;

  constructor(private modalService: NgbModal, public BdcService: BdcService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    const that = this;

    if (this.idBdc !== '') {

      if (typeof this.photos === 'undefined') {
        this.photos = true;
      }

      that.BdcService.getBdc(this.idBdc).then((bdc: any) => {
        this.bdcEvent.emit(bdc);
      });

      that.BdcService.getRapportsOfBdc(this.idBdc).then((rapports: any) => {
        this.rapports = rapports.sort((a,b) => moment(a.date).diff(b.date));

        this.isRapportsFct();

        setTimeout(function () {
          that.prixFactu = 0;

          for (let rapport of rapports) {
            that.prixFactu = that.prixFactu + that.totalArticles(rapport).ht;

            rapport.deleteRequested = false;

            ////console.log(rapport.techniciens)

            if (rapport.techniciens.length !== 0) {
              rapport.techniciens_mef = "avec ";

              ////console.log(rapport.techniciens)

              let rapp = rapport;

              for (let i = 0; i < rapport.techniciens.length; i++) {
                const tech = rapport.techniciens[i];

                rapp.techniciens_mef += tech.prenom + ' ' + tech.nom

                if(i < (rapport.techniciens.length - 1)) {
                  rapp.techniciens_mef += ', ';
                }
              }
            }
          }

          that.prixFactuEvent.emit(that.prixFactu.toFixed(2));
        }, 500);
      }, (err) => {
        console.log(err)
      });
    }
  }

  isRapportsFct() {
    if (this.rapports.length == 0) {
      this.isRapports = false;
    } else {
      this.isRapports = true;
    }
  }

  totalArticles(rapport) {
    const prix = {
      ht: 0
    };

    //console.log(rapport)

    if (rapport.articles !== undefined) {
      for (const article of rapport.articles) {
        prix.ht += parseFloat(article.prix_ht) * article.nb;
      }

      return prix;
    }
    else return { ht: 0 };
  }

  newInterventionModal() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ResumeAddInterventionModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idBdc = this.idBdc;

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  photosModal(rapport) {
    console.log(rapport)

    const modalRef = this.modalService.open(ModalPhotosComponent, { size: 'lg' });
    modalRef.componentInstance.rapport = rapport;

    modalRef.result.then((result) => {
      //this.init()
    }, (reason) => {
      //console.log(reason);
    });
  }

  edit(rapport) {
    console.log("EDIT")
    console.log(rapport)
  }

  delete(rapport) {
    rapport.deleteRequested = true;
  }

  confirm_delete(rapport) {
    this.BdcService.deleteRapport(rapport.id).then((res) => {
      this.init();
    });
  }

}
