import { Component, OnInit } from '@angular/core';

import { BdcService } from '../../services/service-bdc.service';
import {
  NotificationsService
} from '../../services/notifications.service';

@Component({
  selector: 'app-navbar-bdc',
  templateUrl: './navbar-bdc.component.html',
  styleUrls: ['./navbar-bdc.component.scss']
})
export class NavbarBDCComponent implements OnInit {
  nbValider = 0;
  nbPlanifier = 0;
  nbInterventions = 0;
  nbFacturation = 0;

  constructor(private BdcService: BdcService, public NotificationsService: NotificationsService) { }

  ngOnInit() {
    /*this.BdcService.getListBdcAValider().then((valider: any) => {
      this.nbValider = valider.length;
    });

    this.BdcService.getListBdcAPlanifier().then((planifier: any) => {
      this.nbPlanifier = planifier.length;
    });

    this.BdcService.getListInterventions().then((interventions: any) => {
      this.nbInterventions = interventions.length;
    });

    this.BdcService.getListFacturation().then((facturation: any) => {
      this.nbFacturation = facturation.length;
    });*/
  }

}
