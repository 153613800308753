<div class="card">
  <div class="card-status bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Commentaires</h3>
    <div class="card-options">
      <button class="btn btn-sm btn-default" (click)="addModal()">
        <span class="fe fe-plus"></span>
      </button>
    </div>
  </div>

  <div *ngIf="commentaires.length > 0">
    <div class="card-body" *ngFor="let commentaire of commentaires">
      <div class="clearfix">
        <div class="float-left">
          <p class="text-muted">Commenaire du {{BdcService.dateHeure(commentaire.date)}}</p>
        </div>
        <div class="float-right">
          <p class="text-muted dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"></p>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <button class="dropdown-item btn-primary" type="button" (click)="edit(commentaire)"><i
                class="fe fe-edit"></i> Modifier</button>
            <button class="dropdown-item btn-danger" type="button" (click)="delete(commentaire)"><i
                class="fe fe-trash-2"></i> Supprimer</button>
          </div>
        </div>
      </div>
      <div *ngIf="!commentaire.edit">
        {{commentaire.commentaire}}
      </div>
      <div class="row justify-content-between" *ngIf="commentaire.edit">
        <div class="col-9">
          <textarea class="form-control" name="edit_commentaire" [(ngModel)]="commentaire.commentaire" rows="3"
            placeholder="commentaire.commentaire"></textarea>
        </div>
        <div class="col align-self-end">
          <button type="button" class="btn btn-primary" (click)="save_com(commentaire)">Enregistrer</button>
        </div>
      </div>
      <div *ngIf="commentaire.delete">
        <button type="button" class="btn btn-danger" (click)="delete_com(commentaire)">Supprimer</button>
      </div>
    </div>
  </div>

  <div class="card-body" *ngIf="commentaires.length <= 0">
    <div class="clearfix">

      <div class="float-left">
        <p class="text-muted">Aucun commentaire</p>
      </div>
    </div>
  </div>
</div>
