import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { BdcService } from '../../../../services/service-bdc.service';

import { AvisPassageComponent } from '../../../liste-interventions/intervention/avis-passage/avis-passage.component';
import { ModalAppelsComponent } from '../../modal-appels/modal-appels.component';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-calendrier-modal-event',
  templateUrl: './calendrier-modal-event.component.html',
  styleUrls: ['./calendrier-modal-event.component.scss']
})
export class CalendrierModalEventComponent implements OnInit {
  @Input() event;
  @Input() selfBdc;

  bdc;

  tabTechs = [];

  date;
  heure;
  matin;
  selectedTech;

  demiJournee = 1;

  constructor(
    public activeModal: NgbActiveModal,
    private BdcService: BdcService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    const that = this;

    this.bdc = this.event.bdc;

    console.log(this.bdc)

    this.date = moment(this.event.start).format("YYYY-MM-DD");
    this.heure = moment(this.event.start).format("hh:mm:ss");
    this.matin = this.isAm(this.event.start);

    this.demiJournee = this.matin ? 1 : 2;

    this.selectedTech = this.event.id_technicien;

    /*
    ** INIT TECH
    */

    // Récupération de tous les techniciens
    this.BdcService.getTechniciens().then((techs: any) => {

      techs.map((item) => {
        item.nom = item.fullname;
      })

      for (let i = 0; i < techs.length; i++) {
        if (techs[i].actif == 1) {
          this.tabTechs.push(techs[i]);
        }
      }

      $('#select-tech').selectize({
        delimiter: ','
      });

      this.BdcService.maj_select("#select-tech", this.tabTechs);
      $('#select-tech')[0].selectize.setValue(this.selectedTech);

      $('#select-tech').change(function () {
        that.selectedTech = ($(this).val() as string).split(',')[0];
      });

    })
  }

  isAm = function (date) {
    if(moment(date).isBetween(moment(date).format("YYYY-MM-DD") + " 00:00:00", moment(date).format("YYYY-MM-DD") + " 11:59:00")) {
      return true;
    }
    else return false;
  }

  // Appels
  appelsModal() {
    const modalRef = this.modalService.open(ModalAppelsComponent, { size: 'lg' });
      modalRef.componentInstance.idBdc = this.bdc.id;
      modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  // Avis de passage
  avisPassageModal() {
    console.log(this.bdc.id)

   const modalRef = this.modalService.open(AvisPassageComponent);
    modalRef.componentInstance.idBdc = this.bdc.id;
    modalRef.result.then((result) => {
      //this.getBdc();
    }, (reason) => { });
  }

  eventFromInterventions(event) {
    this.bdc = event;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  replanif() {
    let start = this.date;
    let end = this.date;

    if(this.demiJournee == 1) {
      start = this.date + " 08:00:00"
      end = this.date + " 08:30:00"
    }
    else {
      start = this.date + " 13:00:00"
      end = this.date + " 13:30:00"
    }

    this.BdcService.updatePlanification(this.event.id, start, end, this.selectedTech).then((result) => {
      this.activeModal.close("update");
    })
  }

  delete() {
    if(confirm("Voulez-vous vraiment supprimer cette planification ?")) {
      this.BdcService.deletePlanification(this.event.id).then((result) => {
        this.activeModal.close("delete");
      })
    }
  }

}
