<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <nav aria-label="breadcrumb" *ngIf="marche.bailleur != undefined">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/bailleurs">Bailleurs</a></li>
          <li class="breadcrumb-item"><a routerLink="/bailleurs/marches/{{marche.bailleur.id}}">{{marche.bailleur.nom}}</a></li>
          <li class="breadcrumb-item">{{marche.nom}}</li>
        </ol>
      </nav>

      <div class="page-header">

        <h1 class="page-title" *ngIf="!edit">
          {{marche.nom}}
        </h1>
        <button class="btn btn-sm btn-default" (click)="editNom()" style="margin-left: 10px;"  *ngIf="!edit">
          <span class="fe fe-edit"></span>
        </button>

        <div class="input-group mb-3 col-6" *ngIf="edit">
          <input type="text" class="form-control" placeholder="{{marche.nom}}"
                [(ngModel)]="marche.nom" aria-describedby="button-addon2">
          <div class="input-group-append">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="saveNom()">Enregistrer</button>
          </div>
        </div>

        <div class="card-options">
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
                [(ngModel)]="marche.actif" (change)="changeState()">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description" *ngIf="marche.actif">Actif</span>
              <span class="custom-switch-description" *ngIf="!marche.actif">Inactif</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-cards">
        <div class="col-4">

          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Chargés de secteur</h3>
              <div class="card-options">
                <button class="btn btn-sm btn-default" (click)="newCharge()">
                  <span class="fe fe-plus"></span>
                </button>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let charge of charges">
                    <td (click)="goCharge(charge.id)">
                      <span>{{charge.nom}}</span>
                    </td>
                    <td (click)="supprimerCharge(charge.id)">
                      <i class="fe fe-trash trash-link"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="col-8">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Articles</h3>

              <div class="card-options">

              </div>
            </div>

            <div class="card-body" style="padding-bottom: 0px;">
              <div class="row">
                <div class="col-4">
                  <select name="liste_articles" id="select-liste-articles" class="form-control custom-select selectized"
                    tabindex="-1" style="display: none;">
                  </select>
                </div>
                <div class="col-5">
                </div>
                <div class="col-3">
                  <button class="btn btn-block btn-primary" (click)="newArticle()">
                    Mise à jour
                  </button>
                </div>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Nom</th>
                    <th>Prix HT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let article of articles">
                    <td>
                      <span>{{article.code_article}}</span>
                    </td>

                    <td>
                      <span>{{article.nom}}</span>
                    </td>

                    <td>
                      <span>{{article.prix_ht}}€</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
