import { Component, OnInit, Input, OnChanges } from '@angular/core'; 
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-multiple-no-affaire',
  templateUrl: './modal-multiple-no-affaire.component.html',
  styleUrls: ['./modal-multiple-no-affaire.component.scss']
})
export class ModalMultipleNoAffaireComponent implements OnInit {
  @Input() idBdc;
  @Input() status;

  constructor(public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit() {
    console.log(this.idBdc)
  }

  getBdc() {
    this.activeModal.close();
    switch (this.status) {
      case "": {
        this.router.navigate(['bons-de-commande/validation/' + this.idBdc]);
        break;
      }
      case "A_VALIDER": {
        this.router.navigate(['bons-de-commande/validation/' + this.idBdc]);
        break;
      }
      case "A_PLANIFIER": {
        this.router.navigate(['bons-de-commande/planification/' + this.idBdc]);
        break;
      }
      case "A_EXECUTER": {
        this.router.navigate(['bons-de-commande/intervention/' + this.idBdc]);
        break;
      }
      case "A_FACTURER": {
        this.router.navigate(['bons-de-commande/facturation/' + this.idBdc]);
        break;
      }
      case "FACTURE": {
        this.router.navigate(['bons-de-commande/post-facturation/' + this.idBdc]);
        break;
      }
      case "ARCHIVE": {
        this.router.navigate(['bons-de-commande/historique/' + this.idBdc]);
        break;
      }
      case "CORBEILLE": {
        //statements; 
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  cancel() {
    this.activeModal.close();
  }

}
