import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-modal-new-article',
  templateUrl: './modal-new-article.component.html',
  styleUrls: ['./modal-new-article.component.scss']
})
export class ModalNewArticleComponent implements OnInit {
  @Input() idMarche;

  nom = '';
  code = '';
  prixHt = '';

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
  }

  addArticle() {
    if (this.code !== '' && this.nom !== '' && this.prixHt !== '') {
      this.BdcService.newArticle(this.idMarche, this.code, this.nom, this.prixHt).then((article: any) => {
        //console.log(article)
        this.activeModal.close();
      });
    }
  }

  cancel() {
    this.activeModal.close();
  }

}