<div id="liste-globale" class="home">
  <div class="my-3 my-md-5">
    <div class="container-fluid">

      <div class="card p-2">
        <div class="row">
          <div class="col-12 d-flex">


            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-secondary" id="label-status-all">
                <input type="radio" name="status-all" id="status-all" (click)="switchStatusFilter('ALL')">
                <i class="fe fe-check-square"></i> Tous
              </label>
              <label class="btn btn-secondary" id="label-status-a-planifier">
                <input type="radio" name="status-a-planifier" id="status-a-planifier" (click)="switchStatusFilter('A_PLANIFIER')">
                <i class="fe fe-calendar"></i> A planifier <span
                  class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_planifier}}</span>
              </label>
              <label class="btn btn-secondary" id="label-status-a-executer">
                <input type="radio" name="status-a-executer" id="status-a-executer" (click)="switchStatusFilter('A_EXECUTER')">
                <i class="fe fe-truck"></i> En intervention <span
                  class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_executer}}</span>
              </label>
              <label class="btn btn-secondary" id="label-status-facturation">
                <input type="radio" name="status-facturation" id="status-facturation" (click)="switchStatusFilter('FACTURATION')">
                <i class="fe fe-dollar-sign"></i> A facturer <span
                  class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_facturer}}</span>
              </label>
            </div>

            <!--<button class="btn btn-secondary ml-5" (click)="exportFactu()">
               Exporter
            </button>-->

            <div class=" ml-auto">

              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-secondary" id="label-anomalie-commander">
                  <input type="radio" name="anomalie-commander" id="anomalie-commander" routerLink="/liste-globale/bons-en-attente/piece-a-commander">
                  <i class="fe fe-alert-octagon"></i> Pièce à commander <span *ngIf="NotificationsService.getCompteurs().piece_a_commander"
                  class="badge badge-pill badge-danger">{{NotificationsService.getCompteurs().piece_a_commander}}</span>
                </label>
                <label class="btn btn-secondary" id="label-anomalie-devis">
                  <input type="radio" name="anomalie-devis" id="anomalie-devis" routerLink="/liste-globale/bons-en-attente/devis">
                  <i class="fe fe-calendar"></i> Devis à réaliser <span *ngIf="NotificationsService.getCompteurs().devis"
                    class="badge badge-pill badge-danger">{{NotificationsService.getCompteurs().devis}}</span>
                </label>
                <label class="btn btn-secondary" id="label-anomalie-autre">
                  <input type="radio" name="anomalie-autre" id="anomalie-autre" routerLink="/liste-globale/bons-en-attente/anomalie">
                  <i class="fe fe-truck"></i> Anomalie <span *ngIf="NotificationsService.getCompteurs().anomalie"
                    class="badge badge-pill badge-danger">{{NotificationsService.getCompteurs().anomalie}}</span>
                </label>
              </div>

              <span ngbDropdown class="nav-item">
                <a ngbDropdownToggle id="dpdwnMoreBdc" class="nav-link"><i class="fe fe-more-vertical"></i></a>

                <div ngbDropdownMenu aria-labelledby="dpdwnMoreBdc"
                  class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">

                  <a ngbDropdownItem routerLink="/liste-globale/historique">
                    <i class="fe fe-book"></i> Historique
                  </a>

                  <a ngbDropdownItem routerLink="/liste-globale/corbeille">
                    <i class="fe fe-trash"></i> Corbeille
                  </a>

                </div>
              </span>

            </div>

          </div>
        </div>

      </div>

      <div class="row row-cards row-deck">
        <div class="col-12">

          <div class="card p-3">

            <div class="row">
              <div class="col-8">
                <div class="form-group">
                  <small class="form-text text-muted">Colonnes</small>
                  <input id="selected-columns" type="text" placeholder="Colonnes">
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <small class="form-text text-muted">Recherche</small>
                  <input id="recherche-tab" type="text" class="form-control" placeholder="Recherche">
                </div>
              </div>

              <div class="col-1">
                <div class="form-group">
                  <small class="form-text text-muted">Taille</small>

                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" (click)="fontSizeUp()"><i
                        class="fe fe-chevron-up"></i></button>
                    <button type="button" class="btn btn-secondary" (click)="fontSizeDown()"><i
                        class="fe fe-chevron-down"></i></button>
                  </div>
                </div>
              </div>

            </div>




            <div id="{{tableId}}-wrapper" class="table-responsive">
              <table id="{{tableId}}" class="table table-hover table-vcenter text-nowrap card-table">


              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
