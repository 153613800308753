import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { BdcService } from '../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-liste-validation',
  templateUrl: './liste-validation.component.html',
  styleUrls: ['./liste-validation.component.scss']
})
export class ListeValidationComponent implements OnInit {

  listeBdcAValider = [];

  Arr = Array;

  show_filtres = false;

  filter = {
    num_manquant: false,
    client: false,
    urgence: {
      selected: false,
      low: false,
      medium: false,
      high: false
    }
  };

  timerMaj;

  constructor(private BdcService: BdcService, private router: Router, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.majListe();

    this.timerMaj = setInterval(() => {
      this.majListe();
    }, 30000);
  }


  ngOnDestroy() {
    clearInterval(this.timerMaj);
  }

  majListe() {
    console.log("maj")
    this.BdcService.getListBdcAValider().then((listeBdcAValider: any[]) => {
      console.log(listeBdcAValider)
      this.listeBdcAValider = listeBdcAValider;
      this.changeDetectorRef.detectChanges();
    });
  }

  changeLow() {
    this.filter.urgence.low = !this.filter.urgence.low;
    this.listeBdcAValider = [... this.listeBdcAValider];
  }

  changeMedium() {
    this.filter.urgence.medium = !this.filter.urgence.medium;
    this.listeBdcAValider = [... this.listeBdcAValider];
  }

  changeHigh() {
    this.filter.urgence.high = !this.filter.urgence.high;
    this.listeBdcAValider = [... this.listeBdcAValider];
  }
}
