import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-modal-new-charge-of-marche',
  templateUrl: './modal-new-charge-of-marche.component.html',
  styleUrls: ['./modal-new-charge-of-marche.component.scss']
})
export class ModalNewChargeOfMarcheComponent implements OnInit {
  @Input() idMarche;

  charges_secteur = [];
  charge;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService, private router: Router) {
   }

  ngOnInit() {
    const that = this;

    ////console.log(this.idMarche)

    this.BdcService.getMarche(this.idMarche).then((marche: any) => {
      ////console.log(marche)

      this.BdcService.getChargesOfBailleur(marche.id_bailleur).then((charges: any) => {
        console.log(charges);

        this.charges_secteur = [];

        for (let i = 0; i < charges.length; i++) {
          const element = charges[i];

          if(charges[i].actif == 1) {
            this.charges_secteur.push(charges[i]);
          }
        }

        this.charge = this.charges_secteur.length > 0 ? this.charges_secteur[0].id : 0;

        ( < any > $('#select-charge')).selectize({});
        this.BdcService.maj_select("#select-charge", this.charges_secteur);

        $('#select-charge').change(function () {
          that.charge = parseInt($(this).val() as string)
        });

      });
    });


  }

  addCharge() {
      this.BdcService.newChargeOfMarche(this.idMarche, this.charge).then((charge: any) => {
        //console.log(charge)
        this.activeModal.close();
      });

  }

  cancel() {
    this.activeModal.close();
  }

}
