import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthentificationService } from '../../services/authentification.service';
import { BdcService } from "../../services/service-bdc.service";

declare var $: any;
import 'bootstrap-notify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email = "";
  mdp = "";

  showAlertForgotPw = false;

  constructor(private authService: AuthentificationService, private router: Router, private BdcService: BdcService) { }

  ngOnInit() {
  }

  login() {

    if (this.email == '') {
      $('#email-login').addClass('is-invalid');
    }
    if (this.mdp == '') {
      $('#pwd-login').addClass('is-invalid');
    }

    this.authService.login(this.email, this.mdp).then((response) => {
      this.router.navigate(['liste-globale']);
    }, (err) => {
      console.log("Echec de connexion")
      console.log(err)

      $(document).ready(function(){
        $.notify({
          message: "L'identifiant ou le mot de passe sont invalides"
        },{
          type: 'danger',
          z_index: 2031,
        });
      });
    });

  }

  forgotPwd() {
    console.log("Mot de passe oublié");

    if (this.email == '') {
      $('#email-login').addClass('is-invalid');
    }

    this.BdcService.forgot_pw(this.email).then((resp: any) => {

      if(resp.result == "ok") {
        this.showAlertForgotPw = true;
      }
    });
  }

}
