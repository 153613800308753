import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";

import { BdcService } from "../../../services/service-bdc.service";

import { CalendrierModalEventComponent } from './calendrier-modal-event/calendrier-modal-event.component';
import { CalendrierModalNewEventComponent } from './calendrier-modal-new-event/calendrier-modal-new-event.component';
import { element } from 'protractor';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-week-calendar',
  templateUrl: './week-calendar.component.html',
  styleUrls: ['./week-calendar.component.scss']
})
export class WeekCalendarComponent implements OnInit {

  @Input() bdc;

  selectedWeek;
  eventsWeek;

  planifsToCome = [];

  showWeekEnd = true;
  showAdresse = true;

  constructor(
    private BdcService: BdcService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    //moment.locale('fr-ca')
    moment.locale('fr')

    let day = moment();
    this.selectedWeek = moment(day).format("YYYY-[W]ww");

    this.changeWeek();
    this.refreshList();

    console.log(this.bdc)

    if (localStorage.getItem("calendar_week_end") === null) {
      localStorage.setItem("calendar_week_end", JSON.stringify(this.showWeekEnd));
    }
    else this.showWeekEnd = JSON.parse(localStorage.getItem("calendar_week_end"));

    if (localStorage.getItem("calendar_adresse") === null) {
      localStorage.setItem("calendar_adresse", JSON.stringify(this.showAdresse));
    }
    else this.showAdresse = JSON.parse(localStorage.getItem("calendar_adresse"));

    if(this.bdc === undefined) {
      console.log("Calendrier global")
    }
    else {
      console.log("Calendrier Bon de commande")
    }
  }

  changeWeek() {
/*
    let weekDay = moment(this.selectedWeek)
    .subtract(1, "day")
    .startOf("week")
    .add(1, "day");;
  */
    let weekDay = moment(this.selectedWeek)
    .startOf("week");

    this.eventsWeek = {
      lundi: {
        moment: weekDay.format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      },
      mardi: {
        moment: weekDay.add(1, "day").format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      },
      mercredi: {
        moment: weekDay.add(1, "day").format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      },
      jeudi: {
        moment: weekDay.add(1, "day").format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      },
      vendredi: {
        moment: weekDay.add(1, "day").format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      },
      samedi: {
        moment: weekDay.add(1, "day").format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      },
      dimanche: {
        moment: weekDay.add(1, "day").format("YYYY-MM-DD"),
        am: [],
        pm: [],
        ca_tech: [],
        ca_day: 0,
        tri_tech: {
          am: [],
          pm: []
        }
      }
    };

    /*
    let start = moment(this.selectedWeek)
      .subtract(1, "day")
      .startOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");

    let end = moment(this.selectedWeek)
      .subtract(1, "day")
      .endOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");
      */

     let start = moment(this.selectedWeek)
     .startOf("week")
     .format("YYYY-MM-DD");

   let end = moment(this.selectedWeek)
     .endOf("week")
     .format("YYYY-MM-DD");

    this.BdcService.getPlanifJour(start + ' 00:00:00', end + ' 23:59:59').then((planifs: any) => {

      for (let i = 0; i < planifs.length; i++) {
        const planif = planifs[i];

        const add_ca = function (planif, day) { // Ajout d'une planif au CA du jour du tech

          // Recherche du technicien dans le tab de ca_tech du jour
          let tech = day.ca_tech.find(element => {
            if(element.id_tech === planif.id_technicien) { // Si on a le tech

              // Ajout du CA
              element.ca += planif.bdc.commande.prix_ht;

              return true;
            }
          })

          // Si on n'a pas trouvé le tech, on le crée
          if(tech === undefined) {
            day.ca_tech.push({
              id_tech: planif.technicien.id,
              nom_tech: planif.technicien.fullname,
              ca: planif.bdc.commande.prix_ht
            })
          }
        }

        // Si la planif est le lundi
        if(moment(planif.start).isSame(this.eventsWeek.lundi.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.lundi.am.push(planif) // Si c'est le matin
          else this.eventsWeek.lundi.pm.push(planif) // Si c'est l'aprem

          add_ca(planif, this.eventsWeek.lundi) // Chiffre d'affaires du technicien
          this.eventsWeek.lundi.ca_day += planif.bdc.commande.prix_ht; // Chiffre d'affaires du jour
        }

        // Mardi
        else if(moment(planif.start).isSame(this.eventsWeek.mardi.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.mardi.am.push(planif)
          else this.eventsWeek.mardi.pm.push(planif)

          add_ca(planif, this.eventsWeek.mardi)
          this.eventsWeek.mardi.ca_day += planif.bdc.commande.prix_ht;
        }
        else if(moment(planif.start).isSame(this.eventsWeek.mercredi.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.mercredi.am.push(planif)
          else this.eventsWeek.mercredi.pm.push(planif)

          add_ca(planif, this.eventsWeek.mercredi)
          this.eventsWeek.mercredi.ca_day += planif.bdc.commande.prix_ht;
        }
        else if(moment(planif.start).isSame(this.eventsWeek.jeudi.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.jeudi.am.push(planif)
          else this.eventsWeek.jeudi.pm.push(planif)

          add_ca(planif, this.eventsWeek.jeudi)
          this.eventsWeek.jeudi.ca_day += planif.bdc.commande.prix_ht;
        }
        else if(moment(planif.start).isSame(this.eventsWeek.vendredi.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.vendredi.am.push(planif)
          else this.eventsWeek.vendredi.pm.push(planif)

          add_ca(planif, this.eventsWeek.vendredi)
          this.eventsWeek.vendredi.ca_day += planif.bdc.commande.prix_ht;
        }
        else if(moment(planif.start).isSame(this.eventsWeek.samedi.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.samedi.am.push(planif)
          else this.eventsWeek.samedi.pm.push(planif)

          add_ca(planif, this.eventsWeek.samedi)
          this.eventsWeek.samedi.ca_day += planif.bdc.commande.prix_ht;
        }
        else if(moment(planif.start).isSame(this.eventsWeek.dimanche.moment, 'day')) {
          if(this.isAm(planif.start)) this.eventsWeek.dimanche.am.push(planif)
          else this.eventsWeek.dimanche.pm.push(planif)

          add_ca(planif, this.eventsWeek.dimanche)
          this.eventsWeek.dimanche.ca_day += planif.bdc.commande.prix_ht;
        }
      }






      const add_planif_to_tech = function (planif, day) { // Ajout d'une planif au CA du jour du tech

          // Recherche du technicien dans le tab de ca_tech du jour
          let tech = day.find(element => {
            if(element.id_tech === planif.id_technicien) { // Si on a le tech

              // Ajout du CA
              element.planifs.push(planif);

              return true;
            }
          })

          // Si on n'a pas trouvé le tech, on le crée
          if(tech === undefined) {
            day.push({
              id_tech: planif.technicien.id,
              nom_tech: planif.technicien.fullname,
              planifs: [planif]
            })
          }
        }


      const tri_day = function (jour) {
        jour.tri_tech = {
          am: [],
          pm: []
        };

        for (let i = 0; i < jour.am.length; i++) { // matin
          add_planif_to_tech(jour.am[i], jour.tri_tech.am);
        }

        for (let i = 0; i < jour.pm.length; i++) { // aprem
          add_planif_to_tech( jour.pm[i], jour.tri_tech.pm);
        }
      }


      // Tri des techs pour chaque jour

      tri_day(this.eventsWeek.lundi)
      tri_day(this.eventsWeek.mardi)
      tri_day(this.eventsWeek.mercredi)
      tri_day(this.eventsWeek.jeudi)
      tri_day(this.eventsWeek.vendredi)
      tri_day(this.eventsWeek.samedi)
      tri_day(this.eventsWeek.dimanche)

      console.log(this.eventsWeek)

    });
  }

  refreshList() {
    // Planifs entre aujourd'hui et l'an 2100
    this.BdcService.getPlanifJour(moment().format("YYYY-MM-DD") + ' 00:00:00', '2100-01-01 23:59:59').then((planifs: any) => {
      this.planifsToCome = planifs;
    });
  }

  public isAm (date) {
    if(moment(date).isBetween(moment(date).format("YYYY-MM-DD") + " 00:00:00", moment(date).format("YYYY-MM-DD") + " 11:59:00")) {
      return true;
    }
    else return false;
  }

  public formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  previous () {
    this.selectedWeek = moment(this.selectedWeek).subtract(1, 'week').format("YYYY-[W]ww");
    this.changeWeek();
  }

  next () {
    console.log(moment(this.selectedWeek).add(1, 'week').format("YYYY-[W]ww"))
    this.selectedWeek = moment(this.selectedWeek).add(1, 'week').format("YYYY-[W]ww");
    this.changeWeek();
  }

  maj(result) {
    this.changeWeek();
        this.refreshList();

        if(result == "update") {
          $(document).ready(function(){
            $.notify({
              message: 'Evenement mis à jour.'
            },{
              type: 'success'
            });
          });
        }
        else if(result == "delete") {
          $(document).ready(function(){
            $.notify({
              message: 'Evenement supprimé.'
            },{
              type: 'success'
            });
          });
        }
  }

  clickEvent(event) {
    const modalRef = this.modalService.open(CalendrierModalEventComponent, { size: 'llg', backdrop: 'static' });
    modalRef.componentInstance.event = event;
    modalRef.componentInstance.selfBdc = this.bdc;
    modalRef.result.then((result) => {
        this.changeWeek();
        this.refreshList();

        if(result == "update") {
          $(document).ready(function(){
            $.notify({
              message: 'Evenement mis à jour.'
            },{
              type: 'success'
            });
          });
        }
        else if(result == "delete") {
          $(document).ready(function(){
            $.notify({
              message: 'Evenement supprimé.'
            },{
              type: 'success'
            });
          });
        }

    }, (reason) => {});
  }

  clickNew(date, matin) {
    const modalRef = this.modalService.open(CalendrierModalNewEventComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.dateClick = date;
    modalRef.componentInstance.matin = matin;
    modalRef.componentInstance.bdc = this.bdc;
    modalRef.result.then((result) => {
        this.changeWeek();
        this.refreshList();

        $(document).ready(function(){
          $.notify({
            message: 'Planification enregistrée.'
          },{
            type: 'success'
          });
        });
    }, (reason) => {});
  }

  displayDay(date) {
    return moment(date).format("D MMMM");
  }

  weekEnd() {
    localStorage.setItem("calendar_week_end", JSON.stringify(this.showWeekEnd));
  }

  changeShowAdresse() {
    localStorage.setItem("calendar_adresse", JSON.stringify(this.showAdresse));
  }

}

