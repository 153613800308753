import { Component, OnInit, Input, OnChanges } from '@angular/core'; 
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var PDFObject: any;

@Component({
  selector: 'app-modal-piece-jointe',
  templateUrl: './modal-piece-jointe.component.html',
  styleUrls: ['./modal-piece-jointe.component.scss']
})
export class ModalPieceJointeComponent implements OnInit, OnChanges {
  @Input() bdc;

  piecesJointes = {
    old: [],
    new: []
  }

  constructor ( public activeModal: NgbActiveModal, private BdcService: BdcService ) { }

  ngOnInit() {
    //this.piecesJointes.old = this.bdc.pieces_jointes.slice();
  }

  ngOnChanges() {
    //this.piecesJointes.old = this.bdc.pieces_jointes.slice();
  }

  majPiecesJointes(pj) {
    this.piecesJointes = pj;
  }


  private prepareSavePJ(idBdc, nom, description, fichier): any {
    let input = new FormData();

    input.append('id_bdc', idBdc);
    input.append('nom', nom);
    input.append('description', description);
    
    input.append('fichier', fichier);

    return input;
  }

  valider() {

    // Update old
    for(let i = 0; i < this.piecesJointes.old.length; i++) {
      this.BdcService.updatePJ(this.piecesJointes.old[i].id, this.piecesJointes.old[i].nom, this.piecesJointes.old[i].description);
    }

    // Delete
    // Pour chaque pj récupérée dans get_bdc (toutes celles en bdd)
    for(let i = 0; i < this.bdc.pieces_jointes.length; i++) {

      // Si la pj n'est pas trouvée, c'est qu'elle est à supprimer
      if(this.piecesJointes.old.find(element => element.id == this.bdc.pieces_jointes[i].id) == undefined) {
        this.BdcService.deletePJ(this.bdc.pieces_jointes[i].id);
      }
    }

    // New PJ
    for(let i = 0; i < this.piecesJointes.new.length; i++) {
      const formModelPJ = this.prepareSavePJ(this.bdc.id, this.piecesJointes.new[i].nom, this.piecesJointes.new[i].description, this.piecesJointes.new[i].fichier);
      this.BdcService.postPJ(formModelPJ);
    }

    this.activeModal.close();

  }

}
