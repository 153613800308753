import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../services/service-bdc.service';
import {AppelModalComponent} from '../../../liste-planification/planification/appel-modal/appel-modal.component';

@Component({
  selector: 'app-liste-appels-modal',
  templateUrl: './liste-appels-modal.component.html',
  styleUrls: ['./liste-appels-modal.component.scss']
})
export class ListeAppelsModalComponent implements OnInit {
  @Input() idBdc;

  bdc;
  isArticles;
  appels;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private BdcService: BdcService) { }


  ngOnInit() {
    this.getBdc();
  }

  getBdc() {
    this.BdcService.getBdc(this.idBdc).then((bdc) => {
      this.bdc = bdc;
      ////console.log(this.bdc)
      this.bdc.appels = [];

      this.BdcService.getAppels(this.idBdc).then((appels) => {
        this.bdc.appels = appels === 0 ? [] : appels;
        this.isArticlesFct();
        //console.log(this.bdc.appels)
      });
    });
  }

  isArticlesFct() {
    //console.log(this.bdc.appels.length)
    if (this.bdc.appels.length === 0) {
      this.isArticles = false;
    } else {
      this.isArticles = true;
    }
  }

  appelsModal() {
    const modalRef = this.modalService.open(AppelModalComponent);
    modalRef.componentInstance.idBdc = this.idBdc;
    modalRef.result.then((result) => {
      //console.log('getbdc')
      this.getBdc();
    }, (reason) => {
      //console.log(reason);
    });
  }

  cancel() {
    this.activeModal.close();
  }

}
