<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouvel article</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="form-label">Code</label>
    <input type="text" class="form-control" name="code" [(ngModel)]="code" placeholder="Code">
    <label class="form-label">Nom</label>
    <input type="text" class="form-control" name="nom" [(ngModel)]="nom" placeholder="Nom">
    <label class="form-label">Prix HT</label>
    <input type="text" class="form-control" name="prixHt" [(ngModel)]="prixHt" placeholder="Prix HT">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="addArticle()">Sauvegarder</button>
</div>
