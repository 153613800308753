import { Component, OnInit, Input, OnChanges } from '@angular/core'; 
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-selection-date-modal',
  templateUrl: './selection-date-modal.component.html',
  styleUrls: ['./selection-date-modal.component.scss']
})
export class SelectionDateModalComponent implements OnInit {
  date;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  ok() {
    this.activeModal.close(this.date);
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
