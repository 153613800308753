<div class="modal-header">
  <h5 class="modal-title" id="addArticleModalLabel">Attention</h5>
</div>
<div class="modal-body">

    Ce numéro de bon de commande existe déjà
  
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="getBdc()">Accéder au bon de commande</button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">Retour</button>
</div>
