<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="card">
        <div class="card-header no-print">
          <h3 class="card-title">Bon n°{{bdc.id}}</h3>
          <div class="ml-auto">
            <button type="button" class="btn btn-secondary float-right" (click)="quitus()">Imprimer</button>
          </div>
        </div>
        <div class="card-body">


          <app-infos-bon [bdc]="bdc" [readonly]="true"></app-infos-bon>


          <app-options-toggles [bdc]="bdc"></app-options-toggles>



          <div class="row">
            <div class="col-6">


              <app-intervention-card [bdc]="bdc"></app-intervention-card>


            </div>

            <div class="col-6">
              <div class="card">
                <div class="card-status bg-blue"></div>
                <div class="card-header">
                  <h3 class="card-title">Planification</h3>

                </div>
                <div class="card-body">
                  <div *ngFor="let tech of techniciens">
                    <div class="row">
                      <div class="col-6">
                        <b>{{tech.nom}}</b>
                        <br>
                      </div>
                      <div class="col-6">
                        <div *ngFor="let event of tech.planif">
                          Le <b>{{BdcService.dateCompacte(event.start)}} </b> de <b>
                            {{BdcService.heureCompacte(event.start)}}</b> à
                          <b> {{BdcService.heureCompacte(event.end)}}</b>
                        </div>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-resume-interventions [idBdc]="bdc.id" [readonly]="true" [photos]="false"></app-resume-interventions>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-redac-rapport [idBdc]="bdc.id" [readonly]="true" [(rapport)]="bdc.texte_rapport"></app-redac-rapport>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-status bg-blue"></div>
                <div class="card-header">
                  <h3 class="card-title">Facturation</h3>
                </div>
                <div class="card-body">
                  <p>
                    <b>N° de facture : </b>{{bdc.no_facture}}<br>
                    <b>N° de facture fournisseur : </b>{{bdc.no_facture_fournisseur}}
                  </p>
                  <p>
                    <b>Prix : {{bdc.interventions.prix_ht}}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>

    </div>
  </div>
</div>
