<div class="card">
  <div class="card-status bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Articles commandés</h3>
  </div>
  <div class="progress progress-xs" *ngIf="bdc.commande != undefined">
    <div class="progress-bar bg-green" role="progressbar" [ngStyle]="{'width': bdc.commande.progression + '%'}"></div>
  </div>
  <div class="card-body" *ngIf="isBdc">
    <table class="table card-table">
      <thead>
        <tr>
          <th class=""></th>
          <th class="w-1">Code</th>
          <th>Dénomination</th>
          <th>Com.</th>
          <th>Real.</th>
          <th>Prix</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let article of bdc.commande.articles">
          <td class=""><span class="fa fa-check"
            *ngIf="article.realises >= article.nb"></span></td>
          <td class="text-muted">{{article.code_article}}</td>
          <td>{{article.nom}}</td>
          <td class="text-muted">{{article.nb}}</td>
          <td class="text-muted">{{article.realises}}</td>
          <td class="text-muted">{{article.prix_ht}}€</td>
        </tr>

        <tr>
          <td class="text-muted"></td>
          <td class="text-muted"></td>
          <td class="text-muted"></td>
          <td class="text-muted"></td>
          <td class="float-right">Total</td>
          <td><strong>{{bdc.commande.prix_ht.toFixed(2)}}€</strong></td>
        </tr>
      </tbody>
    </table>

    <p>{{bdc.objet_intervention}}</p>
  </div>
</div>
