import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../services/service-bdc.service';

import {AddModalComponent} from '../add-modal/add-modal.component';

@Component({
  selector: 'app-liste-commentaires',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss']
})
export class ListeComponent implements OnInit, OnChanges {
  @Input() idBdc;

  commentaires = []

  constructor(private BdcService: BdcService, private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if((this.idBdc !== undefined) && (this.idBdc > 0)) {
      this.BdcService.getCommentaires(this.idBdc).then((commentaires: any) => {
        this.commentaires = commentaires.map((item) => {
          item.edit = false;
          item.delete = false;
          return item;
        });
      });
    }
  }

  // Add modal
  addModal() {
    const modalRef = this.modalService.open(AddModalComponent);
    modalRef.componentInstance.idBdc = this.idBdc;
    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
    });
  }

  edit(com) {
    com.edit = true;
  }

  save_com(com) {
    this.BdcService.editCommentaire(com.id, com.commentaire).then((ret) => {
      this.init();
    });
  }

  delete(com) {
    com.delete = true;
  }

  delete_com(com) {
    this.BdcService.deleteCommentaire(com.id).then((ret) => {
      this.init();
    });
  }
}
