import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  BdcService
} from '../../../services/service-bdc.service';
import {AppelModalComponent} from './appel-modal/appel-modal.component';


@Component({
  selector: 'app-planification',
  templateUrl: './planification.component.html',
  styleUrls: ['./planification.component.scss']
})
export class PlanificationComponent implements OnInit {
  idBdc = this.route.snapshot.params['idBdc'];

  bdc: any = {
    adresse_locataire: '',
    articles: [{}],
    avis_passage: '',
    avis_passage_exists: false,
    appels: [],
    bailleur: '',
    client: '',
    code_postal_locataire: '',
    cpt_adresse_locataire: '',
    date: '',
    date_reception: '',
    date_limite: '',
    id: '',
    id_bailleur: '',
    id_charge_secteur: '',
    id_marche: '',
    lien_pdf: '',
    marche: '',
    moyen_demande: '',
    no_affaire: '',
    no_bdc: '',
    no_devis: '',
    no_quitus: '',
    nom_locataire: '',
    objet_intervention: '',
    prix: '',
    tel_fixe_locataire: '',
    tel_portable_locataire: '',
    urgence: 1,
    ville_locataire: ''
  };

  planification;


  constructor(private fb: FormBuilder, private router: Router, private modalService: NgbModal, private BdcService: BdcService, private route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.idBdc = params['idBdc'];
      this.getBdc();
    });
  }

  ngOnInit() {
  }

  getBdc() {
    this.BdcService.getBdc(this.idBdc).then((bdc) => {
      this.bdc = bdc;
    });
  }

  // Appels
  appelsModal() {
    const modalRef = this.modalService.open(AppelModalComponent);
    modalRef.componentInstance.idBdc = this.bdc.id;
    modalRef.result.then((result) => {
      this.getBdc();
    }, (reason) => {
    });
  }


  backState() {
    this.BdcService.bdcToValidationState(this.bdc.id).then((res) => {
      this.router.navigate(['bons-de-commande/validation/' + this.bdc.id]);
    });
  }

}
