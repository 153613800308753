import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { BdcService } from "../../services/service-bdc.service";

import { CustomArticleModalComponent } from "./custom-article-modal/custom-article-modal.component";

import { FilterPipe } from "../../pipe/filter.pipe";

declare var $: any;

@Component({
  selector: "app-add-article-modal",
  templateUrl: "./add-article-modal.component.html",
  styleUrls: ["./add-article-modal.component.scss"],
})
export class AddArticleModalComponent implements OnInit, OnChanges {
  @Input() idBdc;
  @Input() bailleur;
  @Input() marche_init;

  init = true;

  clients;

  listeArticles = [];
  listeBailleurs = [];
  marche;
  listeMarches = [];
  search = "";

  constructor(
    public activeModal: NgbActiveModal,
    private BdcService: BdcService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.initFct();

    $( "input[name='search-article']").focus();
  }

  ngOnChanges() {
    this.initFct();
  }

  initFct() {
    //console.log("ngoninit")
    //console.log("Bailleur : "+this.bailleur)
    console.log("Marché : " + this.marche_init)

    // Création de la liste des clients (bailleurs)
    this.BdcService.getListeClients().then((clients: any) => {
      this.clients = [];

      for (let i = 0; i < clients.length; i++) {
        if (clients[i].actif == 1) {
          this.clients.push(clients[i]);
        }
      }

      // this.bailleur = this.clients[0].id;

      // Une fois que les clients sont chargés
      this.clientsOk();
    });
  }

  clientsOk() {
    const that = this;

    ////console.log("client ok");

    (<any>$("#select-bailleur-articles")).selectize({}); // Création du menu
    this.BdcService.maj_select("#select-bailleur-articles", this.clients); // Insertion de la liste des clients dans le menu
    this.BdcService.set_selectize("#select-bailleur-articles", this.bailleur); // Sélection du bailleur dans le menu

    // Si on change le menu des bailleurs
    $("#select-bailleur-articles").change(function () {
      that.bailleur = parseInt($(this).val() as string); // Changement du bailleur actuellement sélectionné

      that.majListeMarches(); // MAJ de la liste des marchés
    });

    (<any>$("#select-marche-articles")).selectize({}); // création du menu marchés

    // Si on change le menu marchés
    $("#select-marche-articles").change(function () {
      that.marche = parseInt($(this).val() as string);

      if (that.marche === 0) {
        // Si c'est général
        ////console.log("DDD")
        that.getArticles("bailleur", that.bailleur);
      } else {
        ////console.log("EEE")

        that.BdcService.getListesArticlesMarche(that.marche).then(
          (listes: any) => {

            that.getArticles("liste", listes.marche.id_liste_articles_active);
          }
        );
      }
    });

    this.majListeMarches(); // MAJ de la liste des marchés
  }

  majListeMarches() {
    ////console.log(this.bailleur)
    if (this.bailleur === 0) {
      // Si le bailleur sélectionné est général
      // On change le contenu du menu marchés
      this.BdcService.maj_select("#select-marche-articles", [
        {
          id: 0,
          nom: "--",
        },
      ]);

      ////console.log("CCC")
      this.getArticles("general", 0); // On récupère les articles "général"
    } else {

      let listeMarches = this.getMarche(this.bailleur); // On récupère les marchés correspondant au bailleur
      this.BdcService.maj_select("#select-marche-articles", listeMarches); // On change le contenu du menu marchés
      this.marche = listeMarches[0].id;

      ////console.log("MAJ LISTE MARCHES")

      if (this.init) {
        // Si c'est la première exécution

        this.BdcService.set_selectize("#select-marche-articles", this.marche_init);
        this.init = false;

        ////console.log("AAA")
        this.BdcService.getListesArticlesMarche(this.marche_init).then(
          (listes: any) => {
            this.getArticles("liste", listes.marche.id_liste_articles_active);
          }
        );
      } else {
        ////console.log("BBB")
        this.getArticles("bailleur", this.bailleur);
      }

      //this.getArticles('bailleur', this.bailleur);
    }
  }

  getMarche = (idBailleur) => {
    for (const bailleur of this.clients) {
      if (parseInt(bailleur.id) === parseInt(idBailleur)) {
        let marches = [];

        for (let i = 0; i < bailleur.marches.length; i++) {
          if(bailleur.marches[i].actif == 1) {
            marches.push(bailleur.marches[i]);
          }
        }

        return marches;
      }
    }
    return [];
  };

  getArticles(type, idMarche) {
    //console.log(type)
    //console.log(idMarche)

    this.BdcService.getArticles(type, idMarche).then((articles) => {
      this.listeArticles = articles === 0 ? ([] as any) : (articles as any);
      //console.log(this.listeArticles)
    });
  }

  addArticle(article) {
    //console.log(article)

    this.activeModal.close({
      article: article,
    });
  }

  articleHorsBordereau() {
    // ouverture de la modal
    const modalRef = this.modalService.open(CustomArticleModalComponent);

    modalRef.result.then(
      (result) => {
        this.activeModal.close({
          article: result.article,
        });
      },
      (reason) => {
        //console.log(reason);
      }
    );
  }

  cancel() {
    this.activeModal.close();
  }
}
