<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire == ''">{{bdc.id}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire == ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire != ''">{{bdc.id}} - Affaire
            n°{{bdc.no_affaire}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire != ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}} -
            Affaire n°{{bdc.no_affaire}}</h2>
          <div class="ml-auto">
            <button type="button" class="btn btn-secondary float-right" (click)="supprimer()">Supprimer</button>
          </div>
        </div>
        <div class="card-body">

          <div class="row">

            <div class="col-12">
              <ul class="progress-tracker progress-tracker--text progress-tracker--center">

                <li class="progress-step" (click)="changeState(2)"
                  [ngClass]="{'is-complete ': state > 2, 'is-active ': state == 2, '': state < 2}">
                  <div class="progress-marker"></div>
                  <div class="progress-text">
                    <h4 class="progress-title">Client</h4>
                  </div>
                </li>

                <li class="progress-step" (click)="changeState(3)"
                  [ngClass]="{'is-complete ': state > 3, 'is-active ': state == 3, '': state < 3}">
                  <div class="progress-marker"></div>
                  <div class="progress-text">
                    <h4 class="progress-title">Identification</h4>
                  </div>
                </li>

                <li class="progress-step" (click)="changeState(4)"
                  [ngClass]="{'is-complete ': state > 4, 'is-active ': state == 4, '': state < 4}">
                  <div class="progress-marker"></div>
                  <div class="progress-text">
                    <h4 class="progress-title">Dates</h4>
                  </div>
                </li>

                <li class="progress-step" (click)="changeState(5)"
                  [ngClass]="{'is-complete ': state > 5, 'is-active ': state == 5, '': state < 5}">
                  <div class="progress-marker"></div>
                  <div class="progress-text">
                    <h4 class="progress-title">Location</h4>
                  </div>
                </li>

                <li class="progress-step" (click)="changeState(6)"
                  [ngClass]="{'is-complete ': state > 6, 'is-active ': state == 6, '': state < 6}">
                  <div class="progress-marker"></div>
                  <div class="progress-text">
                    <h4 class="progress-title">Commande</h4>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-6">
              <app-valider-piece-jointe [pjAValider]="bdc.pieces_jointes" (fichiersEvent)="majPiecesJointes($event)">
              </app-valider-piece-jointe>
            </div>


            <div class="col-6">

              <div class="card" [ngClass]="{'hidden ': state != 2, '': state == 2}">
                <div class="card-header">
                  <h3 class="card-title">Client</h3>
                </div>
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label">Bailleur</label>
                        <select name="bailleur" ng-model="bailleur" id="select-bailleur"
                          class="form-control custom-select selectized" tabindex="-1" style="display: none;">
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label">Marché</label>
                        <select name="marche" ng-model="marche" id="select-marche"
                          class="form-control custom-select selectized" tabindex="-1" style="display: none;">
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-6">

                      <div class="form-group">

                        <label class="form-label">Chargé de secteur</label>

                        <div class="input-group">

                          <select name="charge" ng-model="client" id="select-charge"
                            class="form-control custom-select selectized" tabindex="-1" style="display: none;">
                          </select>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="newCharge()"><span
                                class="fe fe-plus"></span></button>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label">Moyen de la demande</label>
                        <select name="moyen-demande" id="select-moyen-demande"
                          class="form-control custom-select selectized" tabindex="-1" style="display: none;">
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <button type="button" class="btn btn-primary btn-block" (click)="changeState(3)">Suivant</button>
                    </div>
                  </div>
                </div>
              </div>







              <div class="card" [ngClass]="{'hidden ': state != 3, '': state == 3}">
                <div class="card-header">
                  <h3 class="card-title">Identification</h3>
                </div>
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-6">
                      <input type="text" class="form-control" name="no-bdc" [(ngModel)]="bdc.no_bdc"
                        placeholder="N° bon de commande" (change)="noBdcChange()">
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" name="no-affaire" [(ngModel)]="bdc.no_affaire"
                        (change)="noAffaireChange()" placeholder="N° d'affaire">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-6">
                      <input type="text" class="form-control" name="no-assurance" [(ngModel)]="bdc.no_assurance"
                        placeholder="N° d'assurance">
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" name="no-devis" [(ngModel)]="bdc.no_devis"
                        placeholder="N° de devis">
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <button type="button" class="btn btn-outline-secondary btn-block"
                        (click)="changeState(2)">Précédent</button>
                    </div>
                    <div class="col-9">
                      <button type="button" class="btn btn-primary btn-block" (click)="changeState(4)">Suivant</button>
                    </div>
                  </div>
                </div>
              </div>






              <div class="card" [ngClass]="{'hidden ': state != 4, '': state == 4}">
                <div class="card-header">
                  <h3 class="card-title">Dates</h3>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label class="form-label">Date de réception</label>
                    <input class="form-control" type="date" [(ngModel)]="bdc.date_reception">
                  </div>

                  <div class="form-group">
                    <label class="form-label">Date d'échéance</label>
                    <input type="date" [(ngModel)]="bdc.date_limite" class="form-control">
                  </div>

                  <div class="form-group">
                    <label class="form-label">Urgence</label>
                    <select name="urgence" ng-model="urgence" id="select-urgence"
                      class="form-control custom-select selectized" tabindex="-1" style="display: none;">
                    </select>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <button type="button" class="btn btn-outline-secondary btn-block"
                        (click)="changeState(3)">Précédent</button>
                    </div>
                    <div class="col-9">
                      <button type="button" class="btn btn-primary btn-block" (click)="changeState(5)">Suivant</button>
                    </div>
                  </div>
                </div>
              </div>








              <div class="card" [ngClass]="{'hidden ': state != 5, '': state == 5}">
                <div class="card-header">
                  <h3 class="card-title">Location</h3>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label class="form-label">Nom du locataire</label>
                    <input type="text" class="form-control" name="nom-locataire" [(ngModel)]="bdc.nom_locataire"
                      placeholder="Nom">
                  </div>
                  <div class="form-group">
                    <label class="form-label">Adresse</label>
                    <div class="row mb-3">
                      <div class="col-12">
                        <input type="text" class="form-control" id="adresse-locataire" name="adresse-locataire"
                          [(ngModel)]="bdc.adresse_locataire" placeholder="Adresse" (change)="addressChange()">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-6">
                        <input type="text" class="form-control" id="code-postal-locataire" name="code-postal-locataire"
                          [(ngModel)]="bdc.code_postal_locataire" placeholder="Code postal"
                          (change)="codePostalChange()">
                      </div>
                      <div class="col-6">
                        <select name="ville" id="select-ville" class="form-control custom-select selectized"
                          tabindex="-1" style="display: none;" placeholder="Ville">
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <input type="text" class="form-control" name="complement-adresse-locataire"
                          [(ngModel)]="bdc.cpt_adresse_locataire" placeholder="Complément d'adresse">
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">

                        <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[10]"
                          [center]="positionLogement.coordinates">

                          <mgl-marker [lngLat]="positionLogement.coordinates">
                            <span class="fe fe-map-pin marker"></span>
                          </mgl-marker>

                        </mgl-map>

                      </div>
                      <div *ngIf="logementsSimilaires.length != 0" class="col-6">
                        <button type="button" class="btn btn-danger btn-block"
                          (click)="findLogementsSimilaires()">Logements similaires trouvés</button>

                      </div>

                    </div>

                    <div class="form-group">
                      <label class="form-label">Téléphone</label>
                      <div class="row mb-3">
                        <div class="col-6">
                          <input id="tel-fixe" type="text" class="form-control" name="tel-fixe-locataire"
                            autocomplete="no" [(ngModel)]="bdc.tel_fixe_locataire" placeholder="Téléphone fixe">
                        </div>
                        <div class="col-6">
                          <input id="tel-portable" type="text" class="form-control" name="tel-portable-locataire"
                            autocomplete="no" [(ngModel)]="bdc.tel_portable_locataire" placeholder="Téléphone portable">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <button type="button" class="btn btn-outline-secondary btn-block"
                        (click)="changeState(4)">Précédent</button>
                    </div>
                    <div class="col-9">
                      <button type="button" class="btn btn-primary btn-block" (click)="changeState(6)">Suivant</button>
                    </div>
                  </div>
                </div>
              </div>









              <div class="card" [ngClass]="{'hidden ': state != 6, '': state == 6}">
                <div class="card-header">
                  <h3 class="card-title">Articles commandés</h3>
                </div>
                <div class="card-body">

                  <button type="button" class="btn btn-secondary" (click)="articlesModal()">Ajouter
                    un
                    article</button>

                  <table class="table card-table">
                    <thead>
                      <tr>
                        <th class="w-1">Code</th>
                        <th>Dénomination</th>
                        <th>Qté</th>
                        <th>Prix HT</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody *ngIf="showArticles()">
                      <tr *ngFor="let article of bdc.commande.articles">
                        <td class="text-muted">{{article.code_article}}</td>
                        <td>{{article.nom}}</td>
                        <td><input type="number" [(ngModel)]="article.nb" style="width:40px" /></td>
                        <td class="text-muted">{{article.prix_ht}}€</td>
                        <td class="text-muted"><i class="fe fe-trash" (click)="supprimerArticle(article.id)"></i>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-muted"></td>
                        <td class="text-muted"></td>

                        <td class="float-right">Total</td>
                        <td><strong>{{totalArticles().ht.toFixed(2)}}€</strong></td>
                        <td class="text-muted"></td>
                      </tr>
                    </tbody>
                  </table>

                  <br>

                  <div class="form-group">
                    <label class="form-label">Description de l'intervention </label>
                    <textarea class="form-control" name="objet-intervention" [(ngModel)]="bdc.objet_intervention"
                      rows="4" placeholder="Intervention.."></textarea>
                  </div>

                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <button type="button" class="btn btn-outline-secondary btn-block"
                        (click)="changeState(5)">Précédent</button>
                    </div>
                    <div class="col-9">
                      <button type="button" class="btn btn-success btn-block" (click)="validerBdc()">Enregistrer le
                        bon</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>

      </div>

      <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>
    </div>
