<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <nav aria-label="breadcrumb" *ngIf="charge.bailleur != undefined">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/bailleurs">Bailleurs</a></li>
          <li class="breadcrumb-item"><a
              routerLink="/bailleurs/marches/{{charge.bailleur.id}}">{{charge.bailleur.nom}}</a></li>
          <li class="breadcrumb-item">{{charge.nom}}</li>
        </ol>
      </nav>

      <div class="page-header">

        <h1 class="page-title" *ngIf="!edit">
          {{charge.nom}} - {{charge.tel}}
        </h1>
        <button class="btn btn-sm btn-default" (click)="editNom()" style="margin-left: 10px;" *ngIf="!edit">
          <span class="fe fe-edit"></span>
        </button>

        <div class="input-group mb-3 col-6" *ngIf="edit">
          <input type="text" class="form-control" placeholder="{{charge.nom}}" [(ngModel)]="charge.nom"
            aria-describedby="button-addon2">
          <input type="text" class="form-control"  [(ngModel)]="charge.tel" placeholder="Téléphone"
            aria-describedby="button-addon2">
          <input type="email" class="form-control"  [(ngModel)]="charge.mail" placeholder="Email"
            aria-describedby="button-addon2">
          <div class="input-group-append">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="saveNom()">Enregistrer</button>
          </div>
        </div>

        <div class="card-options">
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
                [(ngModel)]="charge.actif" (change)="changeState()">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description" *ngIf="charge.actif">Actif</span>
              <span class="custom-switch-description" *ngIf="!charge.actif">Inactif</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-cards">
        <div class="col-4">

          <div class="card">
            <div class="card-body">
              <b>Bailleur : </b>{{charge.nom_bailleur}}
              <br>
              <b>N° Tel : </b>{{charge.tel}}
              <br>
              <b>Email : </b>{{charge.mail}}
            </div>

          </div>

        </div>

        <div class="col-8">

          <div class="card">
            <div class="card-status bg-blue"></div>

            <div class="card-header">
              <h3 class="card-title">Marchés</h3>
            </div>

            <div class="table-responsive">
              <table class="table card-table table-hover table-vcenter">
                <tbody>
                  <tr *ngFor="let marche of marches" (click)="goMarche(marche.id)">
                    <td>
                      <span>{{marche.nom}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
