<div class="card">
  <div class="card-status card-status-left bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Pièces jointes</h3>
    <div class="card-options">



      <div class="input-group">
        <button class="card-options-collapse btn btn-sm btn-default" data-toggle="collapse"
          data-target="#collapseListe"><i class="fe fe-eye-off"></i> Liste</button>
        <span class="input-group-btn ml-2">

          <label for="file" class="label-file"><span class="fe fe-plus"></span></label>
          <input id="file" class="input-file" type="file">

        </span>
      </div>
    </div>
  </div>


  <div class="table-responsive">
    <table class="table card-table table-hover table-vcenter">
      <tbody id="collapseListe" class="collapse show">
        <tr *ngFor="let file of tabFiles">
          <td (click)="clicItem(file.id)">{{file.nom}}</td>
          <td class="w-1" (click)="delete(file.id)"><a class="icon"><i class="fe fe-trash"></i></a></td>
        </tr>


        <tr>
          <td *ngIf="tabFiles.length == 0">Aucun fichier n'a été envoyé</td>
          <td *ngIf="!(tabFiles.length == 0)"></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>


  <div class="card-body" style="padding-top: 0;">

    <div class="form-group" *ngIf="displayedFile.show">
      <label class="form-label">Nom</label>
      <input class="form-control" [(ngModel)]="displayedFile.file.nom" (change)="change()">
    </div>

    <div class="pdf"></div>

    <div class="form-group" *ngIf="displayedFile.show">
      <label class="form-label">Description</label>
      <textarea class="form-control" rows="1" [(ngModel)]="displayedFile.file.description" (change)="change()"></textarea>
    </div>

  </div>
</div>