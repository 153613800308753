import {
  Component,
  OnInit,
  EventEmitter
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-impression-facture',
  templateUrl: './impression-facture.component.html',
  styleUrls: ['./impression-facture.component.scss']
})
export class ImpressionFactureComponent implements OnInit {
  bdc: any = {
    adresse_locataire: '',
    articles: [{}],
    avis_passage: '',
    avis_passage_exists: false,
    appels: [],
    bailleur: '',
    client: '',
    code_postal_locataire: '',
    cpt_adresse_locataire: '',
    date: '',
    date_reception: '',
    date_limite: '',
    id: '',
    id_bailleur: '',
    id_charge_secteur: '',
    id_marche: '',
    lien_pdf: '',
    marche: '',
    moyen_demande: '',
    no_affaire: '',
    no_bdc: '',
    no_devis: '',
    no_quitus: '',
    nom_locataire: '',
    objet_intervention: '',
    prix: '',
    tel_fixe_locataire: '',
    tel_portable_locataire: '',
    urgence: 1,
    ville_locataire: ''
  };
  clients;
  isArticles;
  pourcentage;
  planification;
  techniciens;
  photos = {
    avant: [],
    apres: []
  };

  form: FormGroup;

  dateProgressStyle = {
    width: '0%'
  };

  rapports;
  prixFactu = 0;
  prixTotal = 0;
  isRapports = false;

  articles = [];

  constructor(private fb: FormBuilder, private router: Router, private modalService: NgbModal, public BdcService: BdcService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getBdc();
    this.getInterventions();
  }

  getBdc() {
    this.BdcService.getBdc(this.route.snapshot.params.idBdc).then((bdc) => {
      this.bdc = bdc;
      this.bdc.appels = [];

      this.BdcService.getPlanifBdc(this.route.snapshot.params.idBdc).then((planif) => {

        const groupByTech = function (array) {
          let names_object = array.reduce((objectsByKeyValue, obj) => {
            obj.prenom_nom = obj.prenom + ' ' + obj.nom
            const value = obj['prenom_nom'];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});

          return Array.from(Object.keys(names_object), x => {
            return {
              nom: x,
              planif: names_object[x]
            };
          });
        };

        this.techniciens = groupByTech(planif);
      });
    });
  }

  getInterventions() {
    const that = this;

    this.BdcService.getRapportsOfBdc(this.route.snapshot.params.idBdc).then((rapports: any) => {
      console.log('RAPPORTS');
      console.log(rapports);

      for (const rapport of rapports) {
        console.log(rapport.articles);

        if (rapport.articles !== 0) {
          this.articles = this.articles.concat(rapport.articles);
        }
      }

      for (const article of this.articles) {
        this.prixFactu += article.prix_ht * article.nb;
      }

      console.log(this.articles);
    });
  }

  quitus() {
    window.print();
  }

  isArticlesFct() {
    if (this.bdc.appels.length === 0) {
      this.isArticles = false;
    } else {
      this.isArticles = true;
    }
  }

  // Articles
  showArticles() {
    return this.bdc.articles !== [];
  }

  totalArticles(rapport) {
    const prix = {
      ht: 0
    };

    if (rapport.articles !== undefined) {
      for (const article of rapport.articles) {
        prix.ht += parseFloat(article.prix_ht) * article.nb;
      }

      return prix;
    }
    else return { ht: 0 };
  }

  // Utilitaires
  get_moyen_demande(name) {
    const tabMoy = [{
      id: 1,
      nom: 'MAIL'
    }, {
      id: 2,
      nom: 'TEL'
    }, {
      id: 3,
      nom: 'COURRIER'
    }, {
      id: 4,
      nom: 'FAX'
    }];

    for (var i = 0; i < tabMoy.length; i++) {
      if (tabMoy[i].nom === name) {
        return tabMoy[i].id;
      }
    }
  }

  majListeMarches() {
    const listeMarches = this.getMarche(this.bdc.id_bailleur);
    this.BdcService.maj_select('#select-marche', listeMarches);
    this.BdcService.set_selectize('#select-marche', this.bdc.id_marche); // On sélectionne le marché courant
  }

  majListeChargesSecteur() {
    const listeCharges = this.getCharge(this.bdc.id_marche, this.getMarche(this.bdc.id_bailleur));
    this.BdcService.maj_select('#select-charge', listeCharges);
    this.BdcService.set_selectize('#select-charge', this.bdc.id_charge_secteur);
  }

  getMarche = (idBailleur) => {
    for (const bailleur of this.clients) {
      if (bailleur.id === idBailleur) {
        return bailleur.marches;
      }
    }
    return [];
  }

  getCharge = (idMarche, listeMarches) => {
    for (const marche of listeMarches) {
      if (marche.id === idMarche) {
        return marche.charges_secteur;
      }
    }
    return [];
  }

  isRapportsFct() {
    if (this.rapports.length as any === []) {
      this.isRapports = false;
    } else {
      this.isRapports = true;
    }
  }

}

