<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container-fluid">
      <div class="row row-cards row-deck">
        <div class="col-12">

          <app-week-calendar></app-week-calendar>

        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <app-tab-planifs></app-tab-planifs>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
