<div class="row">
  <div class="col-6">

    <b>Prix total &nbsp;&nbsp;</b> {{bdc.interventions.prix_ht}} €
    <br><br>

  </div>
</div>

<div class="row">
  <div class="col-4">
    <div class="form-group">
      <label for="no_facture">N° de facture</label>
      <input type="text" class="form-control" id="no_facture" [(ngModel)]="bdc.no_facture" placeholder="N° de facture">
    </div>
  </div>
  <div class="col-4">
    <div class="form-group">
      <label for="no_facture_fournisseur">N° de facture fournisseur</label>
      <input type="text" class="form-control" id="no_facture_fournisseur" aria-describedby="emailHelp"
        [(ngModel)]="bdc.no_facture_fournisseur" placeholder="N° de facture fournisseur">
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="form-group ml-auto">
      <label for="facturation-control-btns">&nbsp;</label>
      <div id="facturation-control-btns">
        <button type="button" class="btn btn-outline-secondary mr-3" (click)="saveFactu()">Enregistrer</button>
        <button type="button" class="btn btn-outline-primary" (click)="archiverFactu()">Enregistrer et archiver</button>
      </div>

    </div>

  </div>
</div>

