import { Component, OnInit, Input, OnChanges } from '@angular/core'; 
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mod-conf-planif-indiv',
  templateUrl: './mod-conf-planif-indiv.component.html',
  styleUrls: ['./mod-conf-planif-indiv.component.scss']
})
export class ModConfPlanifIndivComponent implements OnInit {

  @Input() events;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.events)
  }

  cancel() {
    this.activeModal.dismiss();
  }

  planifier() {
    this.activeModal.close();
  }

}
