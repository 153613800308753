<div class="modal-header">
  <h5 class="modal-title" id="addArticleModalLabel">Avis de passage</h5>
</div>
<div class="modal-body">

  <p>Avis de passage du {{date}}</p>

  <img src="{{photo}}" />

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Retour</button>
</div>
