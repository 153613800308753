import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { ModalNewClientComponent } from '../clients/modal-new-client/modal-new-client.component';

import {
  BdcService
} from '../services/service-bdc.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clients;
  clientsActifs = [];
  clientsInactifs = [];

  constructor(private BdcService: BdcService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.BdcService.getListeClients().then((clients: any) => {
      //console.log(clients);
      this.clients = clients;

      this.clientsActifs = [];
      this.clientsInactifs = [];

      for (let i = 0; i < clients.length; i++) {
        if(clients[i].actif == 1) {
          this.clientsActifs.push(clients[i]);
        }
        else {
          this.clientsInactifs.push(clients[i]);
        }
      }
    });
  }

  newClient() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ModalNewClientComponent);

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  goClient(id) {
    ////console.log(id);
    this.router.navigate(['bailleurs/marches/' + id]);
  }

}



