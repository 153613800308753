import { Injectable } from '@angular/core';

import {
  BdcService
} from './service-bdc.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  frequenceNotifs = 30000;

  tabNotifs = [];
  compteurs = {
    a_valider: 0,
    a_planifier: 0,
    a_executer: 0,
    a_facturer: 0,
    piece_a_commander: 0,
    devis: 0,
    anomalie: 0
  }

  constructor(private BdcService: BdcService) {
    this.initTabNotifs();
  }

  initTabNotifs() {
    this.tabNotifs = null;

    if (localStorage.length > 0) {
      this.tabNotifs = JSON.parse(localStorage.getItem('tabNotifs'));

      if (this.tabNotifs === null) {
        localStorage.setItem('tabNotifs', '[]');
        this.tabNotifs = [];
      }
    } else {
      localStorage.setItem('tabNotifs', '[]');
      this.tabNotifs = [];
    }
  }

  tickNotifs() {
    const that = this;

    setInterval(() => {
      that.pullNotifs();
    }, this.frequenceNotifs);
    that.pullNotifs();
  }

  pullNotifs() {
    const that = this;

    that.BdcService.getNotifNewBdc().then((notif: any) => {

      //console.log(notif)

      for (let i = 0; i < notif.length; i++) {
        that.tabNotifs.push(notif[i]);
        localStorage.setItem('tabNotifs', JSON.stringify(that.tabNotifs));
      }
    });

    that.BdcService.getNbBonsDansListes().then((compteurs: any) => {
      that.compteurs = compteurs;
    });
  }

  // Notifications
  freeNotif(id) {
    const notif = this.tabNotifs.find(elt => elt == id);
    const index = this.tabNotifs.indexOf(notif);

    if (index > -1) {
      this.tabNotifs.splice(index, 1);
    }

    localStorage.setItem('tabNotifs', JSON.stringify(this.tabNotifs));
  }

  clearNotifs() {
    localStorage.setItem('tabNotifs', '[]');
    this.tabNotifs = [];
  }

  getNotifs() {
    return this.tabNotifs;
  }

  // Compteurs
  getCompteurs() {
    return this.compteurs;
  }
}
