<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="row row-cards row-deck">
        <div class="col-12">

          <div class="card">

            <app-liste-bdc [listeBdc]="listeBdcAValider" [tableId]="'tableValider'" [datatables]="true" [filtres]="filter"
              [colonnes]="'is_identifiant id no_bdc client date urgence objet_intervention prix'"
              [select]="'corbeille'" (majRequest)="majListe()"></app-liste-bdc>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
