import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { ModalNewTechnicienComponent } from './/modal-new-technicien/modal-new-technicien.component';

import {
  BdcService
} from '../services/service-bdc.service';

@Component({
  selector: 'app-personnel',
  templateUrl: './personnel.component.html',
  styleUrls: ['./personnel.component.scss']
})
export class PersonnelComponent implements OnInit {

  techniciensActifs = [];
  techniciensInactifs = [];

  constructor(private BdcService: BdcService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.BdcService.getTechniciens().then((techniciens: any) => {
      this.techniciensActifs = [];
      this.techniciensInactifs = [];

      console.log(techniciens)

      for (let i = 0; i < techniciens.length; i++) {
        if(techniciens[i].actif == 1) {
          this.techniciensActifs.push(techniciens[i]);
        }
        else {
          this.techniciensInactifs.push(techniciens[i]);
        }
      }
    });
  }

  newTechnicien() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ModalNewTechnicienComponent);

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  goTechnicien(id) {
    ////console.log(id);
    this.router.navigate(['personnel/technicien/' + id]);
  }

}



