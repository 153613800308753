import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { BdcService } from "../../../services/service-bdc.service";
import { parse } from "querystring";

declare var $: any;

@Component({
  selector: "app-modal-new-liste",
  templateUrl: "./modal-new-liste.component.html",
  styleUrls: ["./modal-new-liste.component.scss"],
})
export class ModalNewListeComponent implements OnInit {
  @Input() idMarche;

  marche;

  articles = [];

  nom_liste = "";

  code_new_article = "";
  nom_new_article = "";
  prix_new_article = "";

  augmentation = "";
  reduction = "";

  table;

  filesCounter = 0;
  tabFiles = [];

  constructor(
    public activeModal: NgbActiveModal,
    private BdcService: BdcService,
    private router: Router
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    const that = this;

    $(document).ready(function () {
      let fileElem = document.getElementById("file");
      fileElem.addEventListener("change", handleFiles, false);

      function handleFiles() {
        if (!this.files.length) {
          //console.log("pas de fichier")
        } else {

          var reader = new FileReader();

          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e) {
              const data = $.csv.toObjects(e.target.result, {
                separator: ';'
              });

              that.articles = [];

              for (let i = 0; i < data.length; i++) {
                that.articles.push({
                  code_article: data[i].code,
                  nom: data[i].nom,
                  prix_ht: that.arrondi_prix(data[i].prix)   //$$$$$$$$$$$$$$$$$$$
                })
              }

              that.table.clear();
              that.table.rows.add(that.articles);
              that.table.draw();

            };
          })(this.files[0]);

          reader.readAsText(this.files[0], "Windows-1252");
        }
      }

      that.table = $("#table-new-liste").DataTable({
        data: that.articles,
        columns: [
          {
            data: "code_article",
            width: "15%",
          },
          {
            data: "nom",
            width: "60%",
          },
          {
            data: "prix_ht",
            width: "15%",
          },
          {
            data: "code_article",
            render: function (data, type, row) {
              return (
                '<i class="fe fe-trash trash-link" (click)="supprimerArticle(' +
                data +
                ')"></i>'
              );
            },
            width: "10%",
          },
        ],
        searching: false,
        info: false,
        //paging: false,
        language: {
          sProcessing: "Traitement en cours...",
          sSearch: "Rechercher&nbsp;:",
          sLengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          sInfo:
            "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          sInfoEmpty:
            "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
          sInfoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          sInfoPostFix: "",
          sLoadingRecords: "Chargement en cours...",
          sZeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          sEmptyTable: "Aucune donn&eacute;e disponible dans le tableau",
          oPaginate: {
            sFirst: "Premier",
            sPrevious: "Pr&eacute;c&eacute;dent",
            sNext: "Suivant",
            sLast: "Dernier",
          },
          oAria: {
            sSortAscending:
              ": activer pour trier la colonne par ordre croissant",
            sSortDescending:
              ": activer pour trier la colonne par ordre d&eacute;croissant",
          },
          select: {
            rows: {
              _: "%d lignes selectionnées",
              0: "Aucune ligne selectionnée",
              1: "1 ligne selectionnée",
            },
          },
        },
      });

      $("#table-new-liste tbody").on("click", ".fe-trash", function () {
        var data = that.table.row($(this).parents("tr")).data();
        that.supprimerArticle(data["code_article"]);
      });
    });

    this.BdcService.getListesArticlesMarche(this.idMarche).then(
      (listes: any) => {
        this.marche = listes;

        console.log(listes.marche.id_liste_articles_active);
        console.log(listes);

        this.nom_liste =
          listes.listes.find(
            (item) => item.id == that.marche.marche.id_liste_articles_active
          ).nom + " - v2";

        // Initialisation avec la liste active
        if (listes.marche.id_liste_articles_active != 0) {
          that.BdcService.getArticlesOfListe(
            listes.marche.id_liste_articles_active
          ).then((art: any) => {
            console.log(art);
            that.articles = art;

            that.table.clear();
            that.table.rows.add(that.articles);
            that.table.draw();
          });
        }

        console.log(this.marche);

        (<any>$("#select-liste-modal")).selectize({
          render: {
            option: function (item, escape) {
              console.log(item);

              let bell = "<span>";

              if (item.value == that.marche.marche.id_liste_articles_active) {
                bell =
                  '<span class="fa fa-check" style:"float: right; color: green"></span>';
              }

              return "<div>" + bell + " " + escape(item.text) + "</div>";
            },
          },
        });
        this.BdcService.maj_select("#select-liste-modal", listes.listes);
        this.BdcService.set_selectize(
          "#select-liste-modal",
          listes.marche.id_liste_articles_active
        );

        $("#select-liste-modal").change(function () {
          var id_liste = parseInt($(this).val() as string);
          console.log(id_liste);

          that.BdcService.getArticlesOfListe(id_liste).then((art: any) => {
            console.log(art);

            that.articles = art;

            that.table.clear();
            that.table.rows.add(that.articles);
            that.table.draw();
          });
        });
      }
    );
  }

  code_change() {
    if (
      this.articles.find(
        (item) => item.code_article == this.code_new_article
      ) == undefined
    ) {
      $("#code-new-article").removeClass("is-invalid");
    } else {
      $("#code-new-article").addClass("is-invalid");
    }
  }

  add_article(code, nom, prix) {
    // Vérification de l'unicité du code
    if (this.articles.find((item) => item.code_article == code) == undefined) {
      this.articles.unshift({
        code_article: code,
        nom: nom,
        prix_ht: this.arrondi_prix(prix)
      });

      this.table.clear();
      this.table.rows.add(this.articles);
      this.table.draw();

      this.code_new_article = "";
      this.nom_new_article = "";
      this.prix_new_article = "";
    }
  }

  supprimerArticle(code) {
    this.articles = this.articles.filter((item) => item.code_article != code);
    this.table.clear();
    this.table.rows.add(this.articles);
    this.table.draw();
  }

  change_prix(aug, red) {
    console.log(aug);
    console.log(red);

    aug = aug != null && aug != "" ? parseFloat(aug) : 0;
    red = red != null && red != "" ? parseFloat(red) : 0;

    console.log(red);

    let coef_aug = aug / 100;
    let coef_red = red / 100;

    let coef = 1 + coef_aug - coef_red;

    console.log(coef);



    for (let i = 0; i < this.articles.length; i++) {
      this.articles[i].prix_ht = this.arrondi_prix(parseFloat(this.articles[i].prix_ht) * coef); //!!!!!!!!!!!!!!!!!!!!!
    }

    this.table.clear();
    this.table.rows.add(this.articles);
    this.table.draw();
  }

  arrondi_prix(nb) {
    //console.log(nb)

    if(Math.trunc(nb * 100) != nb * 100) { // Si il y a + de 2 chiffres après la virgule
      //console.log('a traiter')

      let corr = Math.ceil(nb * 100) / 100;

      //console.log(corr)

      return corr;
    }
    else {
      //console.log('ok')

      let corr = Math.trunc(nb * 100) / 100;

      //console.log(corr)

      return corr;
    }
  }

  clear() {
    this.articles = [];
    this.table.clear();
    this.table.rows.add(this.articles);
    this.table.draw();
  }

  postList() {
    this.BdcService.newListeArticles(
      this.idMarche,
      this.nom_liste,
      this.articles
    ).then((resp) => {
      this.activeModal.close();
    });
  }

  cancel() {
    this.activeModal.close();
  }
}
