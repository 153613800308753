import {
  Component,
  OnInit,
  Input, ElementRef, ViewChild
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  BdcService
} from '../../../../../services/service-bdc.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-show-avis-passage',
  templateUrl: './show-avis-passage.component.html',
  styleUrls: ['./show-avis-passage.component.scss']
})
export class ShowAvisPassageComponent implements OnInit {
  @Input() idAvis;

  avis: any;
  photo: any;
  date = "";

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private BdcService: BdcService) {}

  ngOnInit() {
    this.BdcService.getAvisPassage(this.idAvis).then((avis: any) => {
      this.avis = avis;
      //this.photo = 'http://lab.etiennevilledieu.fr/quentin/avis/' + avis.photo; 
      this.photo = environment.avis_folder + avis.photo;
      //console.log(avis);
      this.date = this.BdcService.dateCompacte(avis.date);
    });
  }

  cancel() {
    this.activeModal.close();
  }

}
