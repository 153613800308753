import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';

import {BdcService} from '../../../../services/service-bdc.service';

@Component({
  selector: 'app-detail-rapport',
  templateUrl: './detail-rapport.component.html',
  styleUrls: ['./detail-rapport.component.scss']
})
export class DetailRapportComponent implements OnInit {

  @Input() rapport;

  env = environment;

  readonly = false;

  constructor(public activeModal: NgbActiveModal, public BdcService: BdcService) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  delete() {
    if(confirm("Voulez-vous vraiment supprimer ce rapport ?")) {
      this.BdcService.deleteRapport(this.rapport.id).then((res) => {
        this.activeModal.close("delete");
      });
    }
  }

  confirm_delete(rapport) {
    this.BdcService.deleteRapport(rapport.id).then((res) => {
      //this.init();
    });
  }

  totalArticles(rapport) {
    const prix = {
      ht: 0
    };

    //console.log(rapport)

    if (rapport.articles !== undefined) {
      for (const article of rapport.articles) {
        prix.ht += parseFloat(article.prix_ht) * article.nb;
      }

      return prix;
    }
    else return { ht: 0 };
  }

  photosModal(rapport) {
    /*console.log(rapport)

    const modalRef = this.modalService.open(ModalPhotosComponent, { size: 'lg' });
    modalRef.componentInstance.rapport = rapport;

    modalRef.result.then((result) => {
      //this.init()
    }, (reason) => {
      //console.log(reason);
    });*/
  }


}
