import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-recap-planifications',
  templateUrl: './recap-planifications.component.html',
  styleUrls: ['./recap-planifications.component.scss']
})
export class RecapPlanificationsComponent implements OnInit, OnChanges {
  @Input() bdc;
  @Input() controles;

  techniciens = [];

  constructor(private router: Router, public BdcService: BdcService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.BdcService.getPlanifBdc(this.bdc.id).then((planif) => {

      const groupByTech = function (array) {
        let names_object = array.reduce((objectsByKeyValue, obj) => {
          obj.prenom_nom = obj.prenom + ' ' + obj.nom
          const value = obj['prenom_nom'];
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
          return objectsByKeyValue;
        }, {});

        return Array.from(Object.keys(names_object), x => {
          return {
            nom: x,
            planif: names_object[x]
          };
        });
      };

      this.techniciens = groupByTech(planif);
    });

  }

  public isAm (date) {
    if(moment(date).isBetween(moment(date).format("YYYY-MM-DD") + " 00:00:00", moment(date).format("YYYY-MM-DD") + " 11:59:00")) {
      return true;
    }
    else return false;
  }


  replanif() {
    this.router.navigate(['bons-de-commande/planification/' + this.bdc.id]);
  }

  quitus() {
    let newRelativeUrl = this.router.createUrlTree(['quitus/' + this.bdc.id]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

}
