<div class="modal-header">
  <h3 class="modal-title">Nouvelle intervention</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">



  <div class="row">
    <div class="col-3">
      <button type="button" class="btn btn-primary" (click)="interventionConforme()">Intervention conforme</button>
    </div>
    <div class="col-5">
      <input id="input-tech" type="text" placeholder="Techniciens">
    </div>
    <div class="col-4">
      <input id="appel-time" type="datetime-local" class="form-control" name="appel-time" [(ngModel)]="date">
    </div>
  </div>

  <br>

  <h4>Commande</h4>

  <table class="table card-table table-commande">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>Qté</th>
        <th class="w-1">Code</th>
        <th>Dénomination</th>
        <th>Com.</th>
        <th>Real.</th>
        <th>Prix</th>
        <th></th>
      </tr>
    </thead>

    <tbody *ngIf="showArticles()">

      <tr *ngFor="let article of articlesCommande">
        <td>
          <div class="form-group">
            <div class="custom-controls-stacked">
              <label class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" name="example-checkbox1"
                  [(ngModel)]="article.selected" value="option1">
                <span class="custom-control-label"></span>
              </label>
            </div>
          </div>
        </td>
        <td class=""><span class="fa fa-check"
          *ngIf="article.realises >= article.nb"></span></td>
        <td><input type="number" [(ngModel)]="article.nb_inter" style="width:40px" /></td>
        <td class="text-muted">{{article.code_article}}</td>
        <td>{{article.nom}}</td>
        <td>{{article.nb}}</td>
        <td>{{article.realises}}</td>
        <td class="text-muted">{{article.prix_ht}}€</td>
        <td class="text-muted"></td>
      </tr>

    </tbody>
  </table>

  <br>

  <div class="row justify-content-between">
    <div class="col-3">
      <h4>Hors commande</h4>
    </div>
    <div class="col-6">
      <select class="form-control custom-select selectized" id="select-article" tabindex="-1"
      style="display: none;"></select>
    </div>
    <div class="col">
      <button type="button" class="btn btn-secondary float-right" (click)="articlesModal()">Autre article</button>
    </div>
  </div>

  <br>

  <table class="table card-table">
    <thead *ngIf="articles.length > 0">
      <tr>
        <th>Qté</th>
        <th class="w-1">Code</th>
        <th>Dénomination</th>
        <th>Prix</th>
        <th></th>
      </tr>
    </thead>

    <tbody *ngIf="articles.length > 0">

      <tr *ngFor="let article of articles">
        <td><input type="number" [(ngModel)]="article.nb" style="width:40px" /></td>
        <td class="text-muted">{{article.code_article}}</td>
        <td>{{article.nom}}</td>
        <td class="text-muted">{{article.prix_ht}}€</td>
        <td class="text-muted"><i class="fe fe-trash" (click)="supprimerArticle(article.id)"></i></td>
      </tr>

    </tbody>
  </table>
  <br>

  <h2 class="float-right">Total  {{totalArticles().ht.toFixed(2)}}€</h2>

  <br><br>

  <div class="form-group">
    <label class="form-label">Commentaire </label>
    <textarea class="form-control" name="objet-intervention" [(ngModel)]="objet_intervention" rows="4"
      placeholder="Intervention.."></textarea>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="save()">Sauvegarder</button>
</div>
