import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-modal-new-charge-incontext',
  templateUrl: './modal-new-charge-incontext.component.html',
  styleUrls: ['./modal-new-charge-incontext.component.scss']
})
export class ModalNewChargeIncontextComponent implements OnInit {
  @Input() idBailleur;
  @Input() idMarche;

  nom = '';
  tel = '';
  mail = '';

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit(): void {
    console.log(this.idBailleur)
    console.log(this.idMarche)
  }

  addCharge() {
    if (this.nom !== '') {
      this.BdcService.newChargeOfBailleur(this.idBailleur, this.nom, this.tel, this.mail).then((charge: any) => {

        if(charge.id !== undefined) {
          this.BdcService.newChargeOfMarche(this.idMarche, charge.id).then((charge: any) => {

            this.activeModal.close(charge.id);
          });
        }
      });
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

}


