<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire == ''">{{bdc.id}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire == ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire != ''">{{bdc.id}} - Affaire
            n°{{bdc.no_affaire}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire != ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}} -
            Affaire n°{{bdc.no_affaire}}</h2>
        </div>
        <div class="card-body">

          <app-infos-bon [bdc]="bdc" [readonly]="true"></app-infos-bon>

          <div class="row">
            <div class="col-9">
              <app-options-toggles [bdc]="bdc"></app-options-toggles>
            </div>
            <div class="col-3">
              <div class="row">
                <div class="col-12" style="padding-right: 12px;position: relative; top: -10px;">
                  <span class="float-right">
                    <button class="btn btn-primary" (click)="appelsModal()">Appels</button>&nbsp;&nbsp;
                    <button class="btn btn-secondary" (click)="avisPassageModal()">Avis de passage</button>
                  </span>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <app-intervention-card [bdc]="bdc"></app-intervention-card>
                </div>

                <div class="col-6">
                  <app-recap-planifications [bdc]="bdc" [controles]="false"></app-recap-planifications>
                </div>
              </div>

              <app-resume-interventions [idBdc]="bdc.id" (prixFactuEvent)="majPrixFactu($event)" [readonly]="true">
              </app-resume-interventions>

              <div class="card">
                <div class="card-status bg-blue"></div>
                <div class="card-header">
                  <h3 class="card-title">Facturation du bon</h3>
                </div>
                <div class="card-body">

                  <p>
                    <b>N° de facture : </b>{{bdc.no_facture}}<br>
                    <b>N° de facture fournisseur : </b>{{bdc.no_facture_fournisseur}}
                  </p>
                  <p>
                    <b>Prix : {{bdc.interventions.prix_ht}}</b>
                  </p>

                  <div class="row">
                    <div class="col-6">

                    </div>
                    <div class="col-6">
                      <button type="button" class="btn btn-outline-primary btn-block" (click)="printFactu()">Imprimer le bon</button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>

    </div>
  </div>
</div>
