<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouvelle planification
    <span *ngIf="matin">le matin </span>
    <span *ngIf="!matin">l'après-midi </span>

    du {{date}}
  </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label class="form-label">Choisir un technicien</label>
        <select class="form-control custom-select selectized" id="select-tech" tabindex="-1" style="display: none;">
        </select>
      </div>
    </div>

    <div class="col-2 offset-6" *ngIf="bdc !== undefined">
      <div class="form-group">
        <label class="form-label">&nbsp;</label>
        <button type="button" class="btn btn-primary" (click)="bdcSelected(bdc.id)">Enregistrer</button>
      </div>
    </div>
  </div>
</div>

<div class="modal-body" *ngIf="bdc === undefined">
  <div class="row">
    <div class="col-12">
      <div class="card">

        <app-liste-bdc [listeBdc]="listeBdcAPlanifier" [tableId]="'listeCalendarModalNew'" [datatables]="true" [action]="true" [minimal]="true"
          [colonnes]="'id no_bdc client date urgence objet_intervention prix'" (clicItem)="bdcSelected($event)">
        </app-liste-bdc>

      </div>

    </div>

  </div>
</div>
