<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Planification</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
  (click)="cancel()">
  </button>
</div>

<div class="modal-body">
  Planification de {{events.length}} interventions
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal"
  (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="planifier()">Planifier</button>
</div>
