import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { BdcService } from "../../../../services/service-bdc.service";

@Component({
  selector: 'app-page-bon-de-commande',
  templateUrl: './page-bon-de-commande.component.html',
  styleUrls: ['./page-bon-de-commande.component.scss']
})
export class PageBonDeCommandeComponent implements OnInit {

  bdc;

  constructor(private route: ActivatedRoute, private BdcService: BdcService) { }

  ngOnInit(): void {
    this.BdcService.getBdc(this.route.snapshot.params.idBdc).then((bdc) => {
      this.bdc = bdc;
    });
  }

}
