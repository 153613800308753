import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-week-calendar-event',
  templateUrl: './week-calendar-event.component.html',
  styleUrls: ['./week-calendar-event.component.scss']
})
export class WeekCalendarEventComponent implements OnInit {
  @Input() event;
  @Input() bdc;
  @Input() adresse;

  styleClass="";

  constructor() { }

  ngOnInit(): void {
    if(this.bdc != undefined) {
      if(this.bdc.id === this.event.id_bdc) {
        this.styleClass = "selfEvent";
      }
    }
  }

}
