import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-modal-new-charge-of-bailleur',
  templateUrl: './modal-new-charge-of-bailleur.component.html',
  styleUrls: ['./modal-new-charge-of-bailleur.component.scss']
})
export class ModalNewChargeOfBailleurComponent implements OnInit {
  @Input() idClient;

  nom = '';
  tel = '';
  mail = '';

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
  }

  addCharge() {
    if (this.nom !== '') {
      this.BdcService.newChargeOfBailleur(this.idClient, this.nom, this.tel, this.mail).then((charge: any) => {
        //console.log(charge)
        this.activeModal.close();
      });
    }
  }

  cancel() {
    this.activeModal.close();
  }

}
