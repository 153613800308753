<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <h3 class="page-title mb-5">Bons en attente</h3>
          <div>
            <div class="list-group list-group-transparent mb-0">

              <a routerLink="/liste-globale/bons-en-attente/piece-a-commander" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
                <span class="icon mr-3"><i class="fe fe-truck"></i></span>Pièce à commander <span class="ml-auto badge badge-primary">
                  {{NotificationsService.getCompteurs().piece_a_commander}}
                </span>
              </a>
              <a routerLink="/liste-globale/bons-en-attente/devis" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
                <span class="icon mr-3"><i class="fe fe-clipboard"></i></span>Devis à réaliser <span class="ml-auto badge badge-primary">
                  {{NotificationsService.getCompteurs().devis}}
                </span>
              </a>
              <a routerLink="/liste-globale/bons-en-attente/anomalie" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
                <span class="icon mr-3"><i class="fe fe-alert-triangle"></i></span>Anomalie <span class="ml-auto badge badge-primary">
                  {{NotificationsService.getCompteurs().anomalie}}
                </span>
              </a>
              <!--<a routerLink="/bons-de-commande/en-attente/autre" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
                <span class="icon mr-3"><i class="fe fe-layers"></i></span>Autre
              </a>-->

            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card">

            <router-outlet></router-outlet>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
