import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { BdcService } from '../../services/service-bdc.service';
import {
  NotificationsService
} from '../../services/notifications.service';

@Component({
  selector: 'app-liste-en-attente',
  templateUrl: './liste-en-attente.component.html',
  styleUrls: ['./liste-en-attente.component.scss']
})
export class ListeEnAttenteComponent implements OnInit {

  listeInterventions = [];

  constructor(private BdcService: BdcService, private router: Router, private httpClient: HttpClient, public NotificationsService: NotificationsService) { }

  ngOnInit(): void {

    this.BdcService.getListInterventions().then((listeInterventions: any) => {
      this.listeInterventions = listeInterventions;

      /*for (const item of listeInterventions) {
        const url = encodeURI(item.adresse_locataire + ' ' + item.code_postal_locataire);

        this.BdcService.geoCoding(url).then((resp: any) => {
          this.geojson.data.features.push(resp.features[0]);
        });
      }*/
    });

  }

}
