import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var PDFObject: any;

@Component({
  selector: 'app-piece-jointe-nouveau-bdc',
  templateUrl: './nouveau-bdc.component.html',
  styleUrls: ['./nouveau-bdc.component.scss']
})
export class PieceJointeNouveauBdcComponent implements OnInit {

  @Output() fichiersEvent = new EventEmitter<any[]>();

  tabFiles = [];
  filesCounter = 0;

  displayedFile = {
    show: false,
    file: null
  }

  constructor() { }

  ngOnInit() {
    const that = this;

    let fileElem = document.getElementById("file");
    fileElem.addEventListener("change", handleFiles, false);

    function handleFiles() {

      if (!this.files.length) {
        //console.log("pas de fichier")
      } else {
        //console.log("Fichiers : " + this.files.length)


        for (let i = 0; i < this.files.length; i++) {
          that.tabFiles.push({
            id: that.filesCounter,
            nom: this.files[i].name, 
            description: '',
            fichier: this.files[i]
          });
          
          that.clicItem(that.filesCounter)

          that.filesCounter++;

          that.sendFiles();
        }
      }
    }
  }

  clicItem(id) {
    const fichier = this.tabFiles.find(elt => elt.id == id);

    this.displayedFile.show = true;
    this.displayedFile.file = fichier

    PDFObject.embed(URL.createObjectURL(fichier.fichier), '.pdf', {
      height: '700px'
    });

    //console.log(fichier.fichier);
  }

  delete(id) {
    const fichier = this.tabFiles.find(elt => elt.id == id);

    const index = this.tabFiles.indexOf(fichier);
    if (index > -1) {
      this.tabFiles.splice(index, 1);
    }

    //console.log(this.tabFiles)

    this.sendFiles();
  }

  change($event) {
    this.sendFiles();
  }

  sendFiles() {
    this.fichiersEvent.emit(this.tabFiles);
  }

}
