<div class="wc-event-wrapper" [ngClass]="styleClass">
  <div class="wc-event-row">
    <div class="wc-event-tech">{{event.bdc.no_bdc}}</div>
    <div class="wc-event-ville">{{event.bdc.commande.prix_ht.toFixed(2)}} €</div>
  </div>
  <div class="wc-event-row-location" *ngIf="adresse">
    <div>{{event.bdc.nom_locataire}}</div>
    <div>{{event.bdc.adresse_locataire}}</div>
    <div>{{event.bdc.code_postal_locataire}} {{event.bdc.ville_locataire}}</div>
  </div>
</div>
