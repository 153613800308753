import { Component, OnInit, Input } from '@angular/core';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var $: any;
import 'bootstrap-notify';

@Component({
  selector: 'app-facturation-component',
  templateUrl: './facturation-component.component.html',
  styleUrls: ['./facturation-component.component.scss']
})
export class FacturationComponentComponent implements OnInit {
  @Input() bdc;

  constructor(public BdcService: BdcService) { }

  ngOnInit(): void {
  }

  archiverFactu() {
    this.BdcService.archiverFactu(
      this.bdc.id,
      this.bdc.no_facture,
      this.bdc.no_facture_fournisseur
    ).then((res) => {

        $.notify({
          message: 'Archivé'
        },{
          type: 'success',
          z_index: 2031,
        });

    });
  }

  saveFactu() {
    this.BdcService.saveFactu(
      this.bdc.id,
      this.bdc.no_facture,
      this.bdc.no_facture_fournisseur
    ).then((res) => {

        $.notify({
          message: 'Sauvegardé'
        },{
          type: 'success',
          z_index: 2031,
        });

    });
  }

}
