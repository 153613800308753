import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { BdcService } from '../../../../services/service-bdc.service';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-calendrier-modal-new-event',
  templateUrl: './calendrier-modal-new-event.component.html',
  styleUrls: ['./calendrier-modal-new-event.component.scss']
})
export class CalendrierModalNewEventComponent implements OnInit {
  @Input() dateClick;
  @Input() matin;
  @Input() bdc;

  date;

  listeBdcAPlanifier = [];

  tabTechs = [];
  selectedTech;

  constructor(
    public activeModal: NgbActiveModal,
    private BdcService: BdcService
    ) { }

  ngOnInit(): void {
    const that = this;

    console.log(this.dateClick)
    console.log(this.matin)

    this.date = moment(this.dateClick).format("DD/MM/YYYY");

    this.BdcService.getListBdcAPlanifier().then((listeBdcAPlanifier: any[]) => {
      this.listeBdcAPlanifier = listeBdcAPlanifier;
    });

    /*
    ** INIT TECH
    */

    // Récupération de tous les techniciens
    this.BdcService.getTechniciens().then((techs: any) => {
      console.log(techs)

      techs.map((item) => {
        item.nom = item.fullname;
      })

      for (let i = 0; i < techs.length; i++) {
        if (techs[i].actif == 1) {
          this.tabTechs.push(techs[i]);
        }
      }

      console.log(this.tabTechs)

      $('#select-tech').selectize({
        delimiter: ','
      });

      this.BdcService.maj_select("#select-tech", this.tabTechs);
      $('#select-tech')[0].selectize.setValue(this.selectedTech);

      $('#select-tech').change(function () {
        that.selectedTech = ($(this).val() as string).split(',')[0];
      });

    })
  }

  bdcSelected (id_bdc) {
    let start = this.dateClick;
    let end = this.dateClick;

    if(this.matin) {
      start += " 08:00:00"
      end += " 08:30:00"
    }
    else {
      start += " 13:00:00"
      end += " 13:30:00"
    }

    console.log(start)
    console.log(end)

    if(this.selectedTech !== undefined) { // Tech sélectionné --> On peut planifier
      console.log(this.selectedTech)

      this.BdcService.newPlanification (id_bdc, this.selectedTech, start, end).then((result) => {
        this.BdcService.flagBdcToIntervention(id_bdc).then((result) => {
          this.activeModal.close();
        });
      });
    }
    else alert("Aucun technicien n'a été sélectionné.")
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
