import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CalendrierModalEventComponent } from '../calendrier-modal-event/calendrier-modal-event.component';

@Component({
  selector: 'app-week-calendar-techgroup',
  templateUrl: './week-calendar-techgroup.component.html',
  styleUrls: ['./week-calendar-techgroup.component.scss']
})
export class WeekCalendarTechgroupComponent implements OnInit {
  @Input() day;
  @Input() bdc;
  @Input() adresse;

  @Output() maj = new EventEmitter();

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  clickEvent(event) {
    const modalRef = this.modalService.open(CalendrierModalEventComponent, { size: 'llg', backdrop: 'static' });
    modalRef.componentInstance.event = event;
    modalRef.componentInstance.selfBdc = this.bdc;
    modalRef.result.then((result) => {
        this.maj.emit(result);
    }, (reason) => {});
  }

}
