import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { ListeValidationComponent } from './bonsDeCommande/liste-validation/liste-validation.component';
import { NavbarBDCComponent } from './bonsDeCommande/navbar-bdc/navbar-bdc.component';
import { Routes, RouterModule, CanActivate, PreloadAllModules } from '@angular/router';


import { FilterPipe } from './pipe/filter.pipe';
import { UrgenceFilterPipe } from './pipe/urgenceFilter.pipe';

import { BdcService } from './services/service-bdc.service';
import { ValidationComponent } from './bonsDeCommande/liste-validation/validation/validation.component';
import { NouveauBdcComponent } from './bonsDeCommande/nouveau-bdc/nouveau-bdc.component';
import { AddArticleModalComponent } from './components/add-article-modal/add-article-modal.component';
import { ListePlanificationComponent } from './bonsDeCommande/liste-planification/liste-planification.component';
import { PlanificationComponent } from './bonsDeCommande/liste-planification/planification/planification.component';
import { ListeInterventionsComponent } from './bonsDeCommande/liste-interventions/liste-interventions.component';
import { AppelModalComponent } from './bonsDeCommande/liste-planification/planification/appel-modal/appel-modal.component';
import { InterventionComponent } from './bonsDeCommande/liste-interventions/intervention/intervention.component';
import { ListeAppelsModalComponent } from './bonsDeCommande/liste-interventions/intervention/liste-appels-modal/liste-appels-modal.component';
import { CorbeilleComponent } from './bonsDeCommande/corbeille/corbeille.component';
import { ListeFacturationComponent } from './bonsDeCommande/liste-facturation/liste-facturation.component';
import { AvisPassageComponent } from './bonsDeCommande/liste-interventions/intervention/avis-passage/avis-passage.component';
import { AddAvisPassageComponent } from './bonsDeCommande/liste-interventions/intervention/avis-passage/add-avis-passage/add-avis-passage.component';
import { ShowAvisPassageComponent } from './bonsDeCommande/liste-interventions/intervention/avis-passage/show-avis-passage/show-avis-passage.component';
import { AddPhotoComponent } from './bonsDeCommande/liste-interventions/intervention/add-photo/add-photo.component';
import { PhotoViewerComponent } from './components/photo-viewer/photo-viewer.component';
import { ListeHistoriqueComponent } from './bonsDeCommande/liste-historique/liste-historique.component';
import { DeleteEventModalComponent } from './bonsDeCommande/liste-planification/widget-planif/delete-event-modal/delete-event-modal.component';
import { ReplanifEventModalComponent } from './bonsDeCommande/liste-planification/widget-planif/replanif-event-modal/replanif-event-modal.component';
import { FacturationComponent } from './bonsDeCommande/liste-facturation/facturation/facturation.component';
import { ClientsComponent } from './clients/clients.component';
import { InterventionCardComponent } from './bonsDeCommande/components/intervention/intervention.component';
import { AppelsCardComponent } from './bonsDeCommande/components/appels-card/appels-card.component';
import { WidgetPlanifComponent } from './bonsDeCommande/liste-planification/widget-planif/widget-planif.component';
import { ListeComponent } from './bonsDeCommande/components/commentaires/liste/liste.component';
import { AddModalComponent } from './bonsDeCommande/components/commentaires/add-modal/add-modal.component';
import { AppelsCardModalComponent } from './bonsDeCommande/components/appels-card/appels-card-modal/appels-card-modal.component';
import { MarchesComponent } from './clients/marches/marches.component';
import { ResumeInterventionsComponent } from './bonsDeCommande/components/resume-interventions/resume-interventions.component';
import { ResumeAddInterventionModalComponent } from './bonsDeCommande/components/resume-interventions/resume-add-intervention-modal/resume-add-intervention-modal.component';
import { ResumeAddArticleModalComponent } from './bonsDeCommande/components/resume-interventions/resume-add-intervention-modal/resume-add-article-modal/resume-add-article-modal.component';
import { MarcheComponent } from './clients/marche/marche.component';
import { ChargeDeSecteurComponent } from './clients/charge-de-secteur/charge-de-secteur.component';
import { ModalNewClientComponent } from './clients/modal-new-client/modal-new-client.component';
import { ModalNewMarcheComponent } from './clients/marches/modal-new-marche/modal-new-marche.component';
import { ModalNewChargeOfBailleurComponent } from './clients/marches/modal-new-charge-of-bailleur/modal-new-charge-of-bailleur.component';
import { ModalNewChargeOfMarcheComponent } from './clients/marche/modal-new-charge-of-marche/modal-new-charge-of-marche.component';
import { ModalNewArticleComponent } from './clients/marche/modal-new-article/modal-new-article.component';
import { PostFacturationComponent } from './bonsDeCommande/liste-facturation/post-facturation/post-facturation.component';
import { PersonnelComponent } from './personnel/personnel.component';
import { ModalNewTechnicienComponent } from './personnel/modal-new-technicien/modal-new-technicien.component';
import { CustomArticleModalComponent } from './components/add-article-modal/custom-article-modal/custom-article-modal.component';
import { RapportsComponent } from './rapports/rapports.component';
import { ListeBdcComponent } from './components/liste-bdc/liste-bdc.component';
import { RechercheComponent } from './recherche/recherche.component';
import { HistoriqueComponent } from './bonsDeCommande/liste-historique/historique/historique.component';
import { ModalPhotosComponent } from './bonsDeCommande/components/resume-interventions/modal-photos/modal-photos.component';
import { ModalConfirmationPlanificationComponent } from './bonsDeCommande/liste-planification/widget-planif/modal-confirmation-planification/modal-confirmation-planification.component';
import { QuitusComponent } from './bonsDeCommande/liste-interventions/intervention/quitus/quitus.component';
import { ImpressionFactureComponent } from './bonsDeCommande/liste-facturation/impression-facture/impression-facture.component';
import { ModalMultipleNoBdcComponent } from './bonsDeCommande/components/modal-multiple-no-bdc/modal-multiple-no-bdc.component';
import { ModalMultipleNoAffaireComponent } from './bonsDeCommande/components/modal-multiple-no-affaire/modal-multiple-no-affaire.component';
import { SelectionDateModalComponent } from './bonsDeCommande/liste-planification/widget-planif/selection-date-modal/selection-date-modal.component';
import { SelectionTechModalComponent } from './bonsDeCommande/liste-planification/widget-planif/selection-tech-modal/selection-tech-modal.component';
import { ModalAppelsComponent } from './bonsDeCommande/components/modal-appels/modal-appels.component';
import { ModalPieceJointeComponent } from './bonsDeCommande/components/modal-piece-jointe/modal-piece-jointe.component';
import { InfosBonComponent } from './bonsDeCommande/components/infos-bon/infos-bon.component';
import { OptionsTogglesComponent } from './bonsDeCommande/components/options-toggles/options-toggles.component';
import { RecapPlanificationsComponent } from './bonsDeCommande/components/recap-planifications/recap-planifications.component';
import { WidgetPlanifIndivComponent } from './bonsDeCommande/components/widget-planif-indiv/widget-planif-indiv.component';
import { ModConfPlanifIndivComponent } from './bonsDeCommande/components/widget-planif-indiv/modals/mod-conf-planif-indiv/mod-conf-planif-indiv.component';
import { ModSelectDateComponent } from './bonsDeCommande/components/widget-planif-indiv/modals/mod-select-date/mod-select-date.component';
import { ModDeleteEventComponent } from './bonsDeCommande/components/widget-planif-indiv/modals/mod-delete-event/mod-delete-event.component';
import { ModReplanifEventComponent } from './bonsDeCommande/components/widget-planif-indiv/modals/mod-replanif-event/mod-replanif-event.component';
import { ModSelectTechComponent } from './bonsDeCommande/components/widget-planif-indiv/modals/mod-select-tech/mod-select-tech.component';
import { NavbarRapportsComponent } from './rapports/navbar-rapports/navbar-rapports.component';
import { ArticlesComponent } from './rapports/articles/articles.component';
import { RegulationComponent } from './rapports/regulation/regulation.component';
import { PieceJointeNouveauBdcComponent } from './bonsDeCommande/components/pieces-jointes/nouveau-bdc/nouveau-bdc.component';
import { ValiderPieceJointeComponent } from './bonsDeCommande/components/pieces-jointes/valider-piece-jointe/valider-piece-jointe.component';
import { ChiffreAffairesComponent } from './rapports/chiffre-affaires/chiffre-affaires.component';
import { LogementsSimilairesComponent } from './bonsDeCommande/components/logements-similaires/logements-similaires.component';
import { LoginGuard } from './guards/login.guard';
import { LoginComponent } from './authentification/login/login.component';
import { RedacRapportComponent } from './bonsDeCommande/components/redac-rapport/redac-rapport.component';
import { ModalUpdateBonComponent } from './bonsDeCommande/components/modal-update-bon/modal-update-bon.component';
import { ModalNewListeComponent } from './clients/marche/modal-new-liste/modal-new-liste.component';
import { ListeEnAttenteComponent } from './bonsDeCommande/liste-en-attente/liste-en-attente.component';
import { ListePieceACommanderComponent } from './bonsDeCommande/liste-en-attente/liste-piece-a-commander/liste-piece-a-commander.component';
import { ListeDevisARealiserComponent } from './bonsDeCommande/liste-en-attente/liste-devis-a-realiser/liste-devis-a-realiser.component';
import { ListeAnomaliesComponent } from './bonsDeCommande/liste-en-attente/liste-anomalies/liste-anomalies.component';
import { TechnicienComponent } from './personnel/technicien/technicien.component';
import { EspacePersoComponent } from './admin/user/espace-perso/espace-perso.component';
import { OptionsComponent } from './agence/options/options.component';
import { TableurBdcComponent } from './bonsDeCommande/tableur-bdc/tableur-bdc.component';
import { ModalBonInTableurComponent } from './bonsDeCommande/tableur-bdc/modal-bon-in-tableur/modal-bon-in-tableur.component';
import { BonDeCommandeComponent } from './bonsDeCommande/components/bon-de-commande/bon-de-commande.component';
import { NewCalendrierComponent } from './bonsDeCommande/new-calendrier/new-calendrier.component';
import { CalendrierModalEventComponent } from './bonsDeCommande/components/week-calendar/calendrier-modal-event/calendrier-modal-event.component';
import { CalendrierModalNewEventComponent } from './bonsDeCommande/components/week-calendar/calendrier-modal-new-event/calendrier-modal-new-event.component';
import { TabPlanifsComponent } from './bonsDeCommande/new-calendrier/tab-planifs/tab-planifs.component';
import { WeekCalendarComponent } from './bonsDeCommande/components/week-calendar/week-calendar.component';
import { WeekCalendarEventComponent } from './bonsDeCommande/components/week-calendar/week-calendar-event/week-calendar-event.component';
import { ListeRapportsOfBdcComponent } from './bonsDeCommande/components/liste-rapports-of-bdc/liste-rapports-of-bdc.component';
import { FacturationComponentComponent } from './bonsDeCommande/components/facturation-component/facturation-component.component';
import { DetailRapportComponent } from './bonsDeCommande/components/liste-rapports-of-bdc/detail-rapport/detail-rapport.component';
import { PageBonDeCommandeComponent } from './bonsDeCommande/components/bon-de-commande/page-bon-de-commande/page-bon-de-commande.component';
import { ModalNewBonComponent } from './bonsDeCommande/components/modal-new-bon/modal-new-bon.component';
import { WeekCalendarTechgroupComponent } from './bonsDeCommande/components/week-calendar/week-calendar-techgroup/week-calendar-techgroup.component';
import { ListeBdcModalBdcComponent } from './components/liste-bdc/liste-bdc-modal-bdc/liste-bdc-modal-bdc.component';
import { ModalNewChargeIncontextComponent } from './bonsDeCommande/components/modal-new-charge-incontext/modal-new-charge-incontext.component';
import { RapportListesBonsComponent } from './rapports/rapport-listes-bons/rapport-listes-bons.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'liste-globale', pathMatch: 'full' },
  { path: 'login', pathMatch: 'full', component: LoginComponent},
  { path: 'bons-de-commande', redirectTo: 'bons-de-commande/validation', pathMatch: 'full', canActivate:  [LoginGuard]},
  { path: 'boite-de-reception', pathMatch: 'full', canActivate:  [LoginGuard], component: ListeValidationComponent},
  { path: 'boite-de-reception/validation/:idBdc', pathMatch: 'full', canActivate:  [LoginGuard], component: ValidationComponent},
  { path: 'bons-en-attente',
    canActivateChild:  [LoginGuard],
    component: ListeEnAttenteComponent,
    children: [
      {
        path: '',
        component: ListePieceACommanderComponent
      },
      {
        path: 'piece-a-commander',
        component: ListePieceACommanderComponent
      },
      {
        path: 'devis',
        component: ListeDevisARealiserComponent
      },
      {
        path: 'anomalie',
        component: ListeAnomaliesComponent
      },
      {
        path: 'autre',
        component: ListePieceACommanderComponent
      }
    ]
  },
  { path: 'bon-de-commande/:idBdc', pathMatch: 'full', canActivate:  [LoginGuard], component: PageBonDeCommandeComponent},
  {
    path: 'bons-de-commande',
    component: NavbarBDCComponent,
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: ListeValidationComponent
      },
      {
        path: 'nouveau',
        component: NouveauBdcComponent
      },
      {
        path: 'validation',
        component: ListeValidationComponent
      },
      {
        path: 'validation/:idBdc',
        component: ValidationComponent
      },
      {
        path: 'planification',
        component: ListePlanificationComponent
      },
      {
        path: 'planification/:idBdc',
        component: PlanificationComponent,
        runGuardsAndResolvers: 'paramsChange'
      },
      {
        path: 'intervention',
        component: ListeInterventionsComponent
      },
      {
        path: 'intervention/:idBdc',
        component: InterventionComponent
      },
      {
        path: 'facturation',
        component: ListeFacturationComponent
      },
      {
        path: 'facturation/:idBdc',
        component: FacturationComponent
      },
      {
        path: 'historique',
        component: ListeHistoriqueComponent
      },
      {
        path: 'historique/:idBdc',
        component: HistoriqueComponent
      },
      {
        path: 'corbeille',
        component: CorbeilleComponent
      },
      {
        path: 'post-facturation/:idBdc',
        component: PostFacturationComponent
      },
      { path: 'en-attente', redirectTo: 'en-attente/piece-a-commander', pathMatch: 'full' },
      {
        path: 'en-attente',
        component: ListeEnAttenteComponent,
        children: [
          {
            path: '',
            component: ListePieceACommanderComponent
          },
          {
            path: 'piece-a-commander',
            component: ListePieceACommanderComponent
          },
          {
            path: 'devis',
            component: ListeDevisARealiserComponent
          },
          {
            path: 'anomalie',
            component: ListeAnomaliesComponent
          },
          {
            path: 'autre',
            component: ListePieceACommanderComponent
          }
        ]
      }
    ]
  },
  { path: 'rapports', redirectTo: 'rapports/general', pathMatch: 'full', canActivate:  [LoginGuard] },
  {
    path: 'rapports',
    canActivateChild: [LoginGuard],
    component: NavbarRapportsComponent,
    children: [
      {
        path: '',
        component: RapportsComponent
      },
      {
        path: 'general',
        component: RapportsComponent
      },
      {
        path: 'articles',
        component: ArticlesComponent
      },
      {
        path: 'regulation',
        component: RegulationComponent
      },
      {
        path: 'chiffre-affaires',
        component: ChiffreAffairesComponent
      },
      {
        path: 'listes-bons/:typeListe',
        component: RapportListesBonsComponent
      }
    ]
  },
  {
    path: 'liste-globale',
    component: TableurBdcComponent,
    canActivate:  [LoginGuard]
  },
  { path: 'liste-globale/bons-en-attente',
    canActivateChild:  [LoginGuard],
    component: ListeEnAttenteComponent,
    children: [
      {
        path: '',
        component: ListePieceACommanderComponent
      },
      {
        path: 'piece-a-commander',
        component: ListePieceACommanderComponent
      },
      {
        path: 'devis',
        component: ListeDevisARealiserComponent
      },
      {
        path: 'anomalie',
        component: ListeAnomaliesComponent
      },
      {
        path: 'autre',
        component: ListePieceACommanderComponent
      }
    ]
  },
  { path: 'liste-globale/historique',
    canActivateChild:  [LoginGuard],
    component: ListeHistoriqueComponent
  },
  { path: 'liste-globale/corbeille',
    canActivateChild:  [LoginGuard],
    component: CorbeilleComponent
  },
  {
    path: 'new-calendrier',
    component: NewCalendrierComponent,
    canActivate:  [LoginGuard]
  },
  {
    path: 'recherche/:recherche',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: RechercheComponent
      }
    ]
  },
  {
    path: 'bailleurs',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: ClientsComponent
      },
      {
        path: 'marches/:idClient',
        component: MarchesComponent
      },
      {
        path: 'marche/:idMarche',
        component: MarcheComponent
      },
      {
        path: 'charge-secteur/:idCharge',
        component: ChargeDeSecteurComponent
      }
    ]
  },
  {
    path: 'personnel',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: PersonnelComponent
      },
      {
        path: 'technicien/:idTechnicien',
        component: TechnicienComponent
      }
    ]
  },
  {
    path: 'options-agence',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: OptionsComponent
      }
    ]
  },
  {
    path: 'admin',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: EspacePersoComponent
      },
      {
        path: 'user',
        component: EspacePersoComponent
      }
    ]
  },
  {
    path: 'quitus/:idBdc',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: QuitusComponent
      }
    ]
  },
  {
    path: 'facture/:idBdc',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        component: ImpressionFactureComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ListeValidationComponent,
    NavbarBDCComponent,
    ValidationComponent,
    NouveauBdcComponent,
    AddArticleModalComponent,
    ListePlanificationComponent,
    PlanificationComponent,
    ListeInterventionsComponent,
    AppelModalComponent,
    InterventionComponent,
    ListeAppelsModalComponent,
    CorbeilleComponent,
    ListeFacturationComponent,
    AvisPassageComponent,
    AddAvisPassageComponent,
    ShowAvisPassageComponent,
    AddPhotoComponent,
    PhotoViewerComponent,
    ListeHistoriqueComponent,
    FilterPipe,
    UrgenceFilterPipe,
    DeleteEventModalComponent,
    ReplanifEventModalComponent,
    FacturationComponent,
    ClientsComponent,
    InterventionCardComponent,
    AppelsCardComponent,
    WidgetPlanifComponent,
    ListeComponent,
    AddModalComponent,
    AppelsCardModalComponent,
    MarchesComponent,
    ResumeInterventionsComponent,
    ResumeAddInterventionModalComponent,
    ResumeAddArticleModalComponent,
    MarcheComponent,
    ChargeDeSecteurComponent,
    ModalNewClientComponent,
    ModalNewMarcheComponent,
    ModalNewChargeOfBailleurComponent,
    ModalNewChargeOfMarcheComponent,
    ModalNewArticleComponent,
    PostFacturationComponent,
    PersonnelComponent,
    ModalNewTechnicienComponent,
    CustomArticleModalComponent,
    RapportsComponent,
    ListeBdcComponent,
    RechercheComponent,
    HistoriqueComponent,
    ModalPhotosComponent,
    ModalConfirmationPlanificationComponent,
    QuitusComponent,
    ImpressionFactureComponent,
    ModalMultipleNoBdcComponent,
    ModalMultipleNoAffaireComponent,
    SelectionDateModalComponent,
    SelectionTechModalComponent,
    ModalAppelsComponent,
    ModalPieceJointeComponent,
    InfosBonComponent,
    OptionsTogglesComponent,
    RecapPlanificationsComponent,
    WidgetPlanifIndivComponent,
    ModConfPlanifIndivComponent,
    ModSelectDateComponent,
    ModDeleteEventComponent,
    ModReplanifEventComponent,
    ModSelectTechComponent,
    NavbarRapportsComponent,
    ArticlesComponent,
    RegulationComponent,
    PieceJointeNouveauBdcComponent,
    ValiderPieceJointeComponent,
    ChiffreAffairesComponent,
    LogementsSimilairesComponent,
    LoginComponent,
    RedacRapportComponent,
    ModalUpdateBonComponent,
    ModalNewListeComponent,
    ListeEnAttenteComponent,
    ListePieceACommanderComponent,
    ListeDevisARealiserComponent,
    ListeAnomaliesComponent,
    TechnicienComponent,
    EspacePersoComponent,
    OptionsComponent,
    TableurBdcComponent,
    ModalBonInTableurComponent,
    BonDeCommandeComponent,
    NewCalendrierComponent,
    CalendrierModalEventComponent,
    CalendrierModalNewEventComponent,
    TabPlanifsComponent,
    WeekCalendarComponent,
    WeekCalendarEventComponent,
    ListeRapportsOfBdcComponent,
    FacturationComponentComponent,
    DetailRapportComponent,
    PageBonDeCommandeComponent,
    ModalNewBonComponent,
    WeekCalendarTechgroupComponent,
    ListeBdcModalBdcComponent,
    ModalNewChargeIncontextComponent,
    ModalNewChargeIncontextComponent,
    RapportListesBonsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      onSameUrlNavigation: 'reload'
    }),
    NgbModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiY2hpcHNvbmR1bGVlIiwiYSI6ImNreXJpMWRjajB1YjEydnA2cTNhbmVrdmgifQ.hDglZUkpYQOpcK6Qz5Omww', // Optionnal, can also be set per map (accessToken input of mgl-map)
      geocoderAccessToken: 'TOKEN' // Optionnal, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    })/*,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule // imports firebase/auth, only needed for auth features*/
  ],
  providers: [
    BdcService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
