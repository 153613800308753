<div class="card">
  <div class="card-status bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Planification</h3>
    <div class="ml-auto" *ngIf="controles">
      <!--<button type="button" class="btn btn-primary btn-sm"
        (click)="replanif()">Replanifier</button>&nbsp;&nbsp;-->
      <button type="button" class="btn btn-secondary btn-sm" (click)="quitus()">Impression d'un
        quitus</button>
    </div>
  </div>
  <div class="card-body">
    <div *ngFor="let tech of techniciens">
      <div class="row">
        <div class="col-6">
          <b>{{tech.nom}}</b>
          <br>
        </div>
        <div class="col-6">
          <div *ngFor="let event of tech.planif">

            Le <b>{{BdcService.dateCompacte(event.start)}} </b>

            <!--
              de <b>
              {{BdcService.heureCompacte(event.start)}}</b> à
              <b> {{BdcService.heureCompacte(event.end)}}</b>
            -->

            <span *ngIf="isAm(event.start)"> <b>matin</b></span>
            <span *ngIf="!isAm(event.start)"><b>après-midi</b></span>

          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
