<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="page-header">
        <h1 class="page-title">
          Bons de commande
        </h1>
      </div>
      <div class="row row-cards row-deck">

        <div class="col-2">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.a_valider}}</div>
              <div class="text-muted mb-4">A valider</div>
            </div>
          </div>
        </div>

        <div class="col-2">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.a_planifier}}</div>
              <div class="text-muted mb-4">A planifier</div>
            </div>
          </div>
        </div>

        <div class="col-2">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.attente_pieces}}</div>
              <div class="text-muted mb-4">En attente de pièces</div>
            </div>
          </div>
        </div>

        <div class="col-2">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.a_executer}}</div>
              <div class="text-muted mb-4">Planifiés</div>
            </div>
          </div>
        </div>

        <div class="col-2">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.a_facturer}}</div>
              <div class="text-muted mb-4">Attente de factu</div>
            </div>
          </div>
        </div>

        <div class="col-2">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.factures}}</div>
              <div class="text-muted mb-4">Facturés</div>
            </div>
          </div>
        </div>

      </div>

      <div class="row row-cards row-deck">

        <div class="col-4">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.prix_total_commandes}} €</div>
              <div class="text-muted mb-4">Commandé</div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.prix_total_a_facturer}} €</div>
              <div class="text-muted mb-4">A facturer</div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card">
            <div class="card-body p-3 text-center">
              <div class="h1 m-0">{{stats.prix_total_bdc_factures}} €</div>
              <div class="text-muted mb-4">Facturés</div>
            </div>
          </div>
        </div>

      </div>

      <div class="row row-cards row-deck">
        <div class="col-6">

          <div class="card table-height-limit">
            <div class="card-header">
              <h3 class="card-title"><b>{{listeBdcEnRetard.length}}</b> Retards</h3>
            </div>
            <app-liste-bdc class="table-height-limit" [tableId]="'datatable1'" [listeBdc]="listeBdcEnRetard"
              [datatables]="false" [colonnes]="'no_bdc client date status'" [minimal]="true"></app-liste-bdc>
          </div>



        </div>

        <div class="col-6">

          <div class="card table-height-limit">
            <div class="card-header">
              <h3 class="card-title"><b>{{listeBdcSansNo.length}}</b> Bons sans Numéro</h3>
            </div>
            <app-liste-bdc class="table-height-limit" [tableId]="'datatable2'" [listeBdc]="listeBdcSansNo"
              [datatables]="false" [colonnes]="'no_bdc client date status'" [minimal]="true"></app-liste-bdc>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
