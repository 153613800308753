import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { BdcService } from '../../services/service-bdc.service';
import { geoJson } from 'leaflet';

@Component({
  selector: 'app-liste-interventions',
  templateUrl: './liste-interventions.component.html',
  styleUrls: ['./liste-interventions.component.scss']
})
export class ListeInterventionsComponent implements OnInit {

  listeInterventions = [];

  geojson = {
    type: 'geojson',
    data: {
        'type': 'FeatureCollection',
        'features': []
    }
  };

  constructor(private BdcService: BdcService, private router: Router, private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.BdcService.getListInterventions().then((listeInterventions: any) => {
      this.listeInterventions = listeInterventions;

      /*for (const item of listeInterventions) {
        const url = encodeURI(item.adresse_locataire + ' ' + item.code_postal_locataire);

        this.BdcService.geoCoding(url).then((resp: any) => {
          this.geojson.data.features.push(resp.features[0]);
        });
      }*/
    });
  }

  majListe() {
    this.BdcService.getListInterventions().then((listeInterventions: any) => {
      this.listeInterventions = listeInterventions;
    });
  }

}
