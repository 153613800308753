import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

import { ModalPieceJointeComponent } from '../../components/modal-piece-jointe/modal-piece-jointe.component';
import { ModalUpdateBonComponent } from '../../components/modal-update-bon/modal-update-bon.component';


@Component({
  selector: 'app-infos-bon',
  templateUrl: './infos-bon.component.html',
  styleUrls: ['./infos-bon.component.scss']
})
export class InfosBonComponent implements OnInit, OnChanges {
  @Input() bdc;
  @Input() readonly;

  date = '';
  date_limite = '';
  date_reception = '';

  dateProgressStyle = {
    width: '0%'
  };

  positionLogement = {
    coordinates: [5.703130, 45.200103],
    type: "Point"
  };

  constructor(private BdcService: BdcService, private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
  }


  ngOnChanges() {
    this.init();
  }

  init() {
    if(this.bdc.id !== '')
    {
      this.datePourcentage();
      this.date = this.BdcService.dateCompacte(this.bdc.date);
      this.date_reception = this.BdcService.dateCompacte(this.bdc.date_reception);
      this.date_limite = this.BdcService.dateCompacte(this.bdc.date_limite);

      const url = encodeURI(this.bdc.adresse_locataire + ' ' + this.bdc.code_postal_locataire + ' ' + this.bdc.ville_locataire);

      this.BdcService.geoCoding(url).then((resp: any) => {

        if(resp.features.length > 0) {
          resp.features[0].properties['marker-symbol'] = 'monument';

          this.positionLogement = resp.features[0].geometry;
        }

      });
    }
  }

  // Piece jointe
  attachementModal() {
    const that = this;

    const modalRef = this.modalService.open(ModalPieceJointeComponent, { size: 'lg' });
    modalRef.componentInstance.bdc = this.bdc;
    modalRef.result.then((result) => {
      setTimeout(() => {
        that.BdcService.getBdc(that.bdc.id).then((bdc) => {
          that.bdc = bdc;
        });
      }, 500);
    }, (reason) => {

    });
  }

  get_moyen_demande() {
    const tabMoy = ['Mail', 'Téléphone', 'Courrier', 'Fax'];

    return tabMoy[this.bdc.moyen_demande - 1];
  }

  getMapsLink() {
    const adresse = this.bdc.adresse_locataire + ' ' + this.bdc.code_postal_locataire + ' ' + this.bdc.ville_locataire;
    return encodeURI("https://www.google.fr/maps/place/" + adresse.replace(' ', '+'));
  }

  datePourcentage() {
    let dateNow = new Date();
    let dateCreation = new Date(this.bdc.date_reception);
    let dateFin = new Date(this.bdc.date_limite);


    if (dateNow.getTime() > dateFin.getTime()) {
      this.dateProgressStyle.width = '100%';
    } else {
      let duree = dateFin.getTime() - dateCreation.getTime();
      let ecoule = dateNow.getTime() - dateCreation.getTime();

      let percent = (ecoule * 100) / duree;
      percent = percent <= 100 ? percent : 100;
      percent = Math.floor(percent);

      this.dateProgressStyle.width = percent + '%';
    }
  }

  iconStatus() {
    if (this.bdc.status === "" || this.bdc.status === "A_VALIDER") {
      return '<i class="text-muted fe fe-check-square"></i>';
    } else if (this.bdc.status === "A_PLANIFIER") {
      return '<i class="text-muted fe fe-calendar"></i>';
    } else if (this.bdc.status === "A_EXECUTER") {
      return '<i class="text-muted fe fe-truck"></i>';
    } else if (this.bdc.status === "A_FACTURER" || this.bdc.status === "FACTURE") {
      return '<i class="text-muted fe fe-dollar-sign"></i>';
    } else if (this.bdc.status === "ARCHIVE") {
      return '<i class="text-muted fe fe-book"></i>';
    } else {
      return '<i class="text-muted fe fe-trash"></i>';
    }
  }

  modifBon() {
    const modalRef = this.modalService.open(ModalUpdateBonComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.bdc = this.bdc;
    modalRef.result.then((result) => {
      this.BdcService.getBdc(this.bdc.id).then((bdc) => {
        this.bdc = bdc;
      });

    }, (reason) => {});
  }

}
