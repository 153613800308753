import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  BdcService
} from '../../services/service-bdc.service';

@Component({
  selector: 'app-charge-de-secteur',
  templateUrl: './charge-de-secteur.component.html',
  styleUrls: ['./charge-de-secteur.component.scss']
})
export class ChargeDeSecteurComponent implements OnInit {

  marches = [];
  client = {};
  charge = {} as any;

  edit = false;

  idCharge = this.route.snapshot.params['idCharge'];

  constructor(private BdcService: BdcService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.init();
  }

  init() {

    this.BdcService.getCharge(this.idCharge).then((charge: any) => {
      charge.actif = charge.actif == 1 ? true : false;

      console.log(charge)

      this.marches = charge.marches;
      this.charge = charge;
    });
  }

  goMarche(id) {
    this.router.navigate(['bailleurs/marche/' + id]);
  }

  changeState() {
    this.BdcService.changeChargeSecteurState(this.charge.id, this.charge.actif);
  }

  editNom() {
    this.edit = true;
  }
  saveNom() {
    this.BdcService.updateChargeSecteur(this.charge.id, this.charge.nom, this.charge.tel, this.charge.mail);

    this.edit = false;
  }
}
