import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-intervention-card',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.scss']
})
export class InterventionCardComponent implements OnInit, OnChanges {
  @Input() bdc;

  isBdc = false;

  constructor() { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    // Si on a bien un Bdc en paramètre
    if (typeof this.bdc !== 'undefined') {
      if (typeof this.bdc.commande !== 'undefined') {
        this.isBdc = true;
      }
    }
  }

}
