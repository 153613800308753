<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <nav aria-label="breadcrumb" *ngIf="technicien != undefined">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/personnel">Techniciens</a></li>
          <li class="breadcrumb-item">{{technicien.fullname}}</li>
        </ol>
      </nav>

      <div class="page-header">

        <h1 class="page-title" *ngIf="!edit">
          {{technicien.fullname}} - {{technicien.tel}}
        </h1>
        <button class="btn btn-sm btn-default" (click)="editNom()" style="margin-left: 10px;" *ngIf="!edit">
          <span class="fe fe-edit"></span>
        </button>

        <div class="input-group mb-3 col-6" *ngIf="edit">
          <input type="text" class="form-control" placeholder="{{technicien.prenom}}" [(ngModel)]="technicien.prenom"
            aria-describedby="button-addon2">
          <input type="text" class="form-control" placeholder="{{technicien.nom}}" [(ngModel)]="technicien.nom"
            aria-describedby="button-addon2">
          <input type="text" class="form-control" placeholder="{{technicien.tel}}" [(ngModel)]="technicien.tel"
            aria-describedby="button-addon2">
          <div class="input-group-append">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="saveNom()">Enregistrer</button>
          </div>
        </div>

        <div class="card-options">
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
                [(ngModel)]="technicien.actif" (change)="changeState()">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description" *ngIf="technicien.actif">Actif</span>
              <span class="custom-switch-description" *ngIf="!technicien.actif">Inactif</span>
            </label>
          </div>
        </div>
      </div>


      <div class="row row-cards">
        <div class="col-12">

          <div class="card">
            <div class="card-status bg-blue"></div>
            <div class="card-header">
              <h3 class="card-title">Planification</h3>
            </div>
            <div class="card-body">
              <table class="table card-table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th>N° bon</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>Lieu</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let planif of planification">
                    <td>
                      <span>{{planif.no_bdc}}</span>
                    </td>
                    <td>
                      <span>{{planif.start}}</span>
                    </td>
                    <td>
                      <span>{{planif.end}}</span>
                    </td>
                    <td>
                      <span>{{planif.ville_locataire}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

      </div>


    </div>
  </div>
</div>
