import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Subject
} from 'rxjs';
import { environment } from '../../environments/environment';

import * as moment from 'moment';
import { resolve } from 'dns';

// declare var moment: any;
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class BdcService {

  constructor(private httpClient: HttpClient) { }

  /*
  ** ENDPOINTS
  */

  API_OLD_ENDPOINT = environment.api_old_endpoint;
  API_ENDPOINT = environment.api_endpoint;


  /*
  ** FONCTIONS HTTP GENERIQUES
  */

  post(target, params) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };

      this.httpClient
        .post(target, $.param(params), httpOptions)
        .subscribe(ret => resolve(ret), error => reject(error));
    });
  }

  postFormData(target, params) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(target, params)
        .subscribe(ret => resolve(ret), error => reject(error));
    });
  }

  get(target) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any[]>(target)
        .subscribe(ret => resolve(ret), error => reject(error));
    });
  }








  /* ////////////////////////////
  ** AGENCE
  */ ////////////////////////////

  id_agence = null;

  /*
  ** LOCAL STORAGE
  */

  getAgenceId() {
    this.id_agence = null;

    if (localStorage.length > 0) {
      this.id_agence = localStorage.getItem('id_agence');

      if (this.id_agence === null) {
        localStorage.setItem('id_agence', '3');
        this.id_agence = '3';
      }
    } else {
      localStorage.setItem('id_agence', '3');
      this.id_agence = '3';
    }

    return this.id_agence;
  }

  switchAgence(id_agence) {
    localStorage.setItem('id_agence', id_agence);
    window.location.reload();
  }

  /*
  ** API
  */

  getAgence(id) {
    return this.get(this.API_ENDPOINT + 'agences/get_agence.php?id_agence=' + id);
  }

  updateAgence(inputmail_mailbox, inputmail_username, inputmail_password, latitude, longitude) {
    return this.post(this.API_ENDPOINT + 'agences/update_agence.php', {
      'id_agence': this.getAgenceId(),
      'inputmail_mailbox': inputmail_mailbox,
      'inputmail_username': inputmail_username,
      'inputmail_password': inputmail_password,
      'latitude': latitude,
      'longitude': longitude
    });
  }

  /* ////////////////////////////
  ** NOTIFICATIONS
  */ ////////////////////////////

  getNotifNewBdc() {
    return this.get(this.API_ENDPOINT + 'agences/import_bdc_from_mail.php?id_agence=' + this.getAgenceId());
  }

  ////////////////////////////////////////
  // AUTHENTIFICATION
  ////////////////////////////////////////

  login(email, mdp) {
    return this.post(this.API_ENDPOINT + 'authentification/login.php', {
      email: email,
      password: mdp
    });
  }

  change_pw(old_pw, new_pw, email) {
    return this.post(this.API_ENDPOINT + 'authentification/change_pw.php', {
      password: old_pw,
      new_pw: new_pw,
      email: email
    });
  }

  forgot_pw(email) {
    return this.post(this.API_ENDPOINT + 'authentification/forgot_pw.php', {
      email: email
    });
  }

  updateMailReports(id_user, mail_reports) {
    return this.post(this.API_ENDPOINT + 'authentification/update_mail_reports.php', {
      id_user: id_user,
      mail_reports: mail_reports
    });
  }


  /* //////////////////////////////////
  ** BONS DE COMMANDE
  */ //////////////////////////////////

  getBdc(idBdc) {
    return this.get(this.API_ENDPOINT + 'bdc/get.php?id=' + idBdc + '&agence=' + this.getAgenceId());
  }

  postBdc(bdc) {
    return this.postFormData(this.API_OLD_ENDPOINT + 'bdc/add_bdc.php', bdc);
  }


  // Etapes BDC

  validerBdc(bdc) {
    return this.postFormData(this.API_OLD_ENDPOINT + 'bdc/valider_bdc.php', bdc);
  }

  updateBdc(bdc) {
    return this.postFormData(this.API_OLD_ENDPOINT + 'bdc/update_bdc.php', bdc);
  }


  // Planification

  ////////////////// A REFAIRE !!!!!!!!!!!!!!!!!!!!!!!!!!!
  planifierBdc(idBdc, events) {

    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };

      // Suppression des planifications du BDC avant de les re-remplir
      // A revoir !!
      this.httpClient
        .post(this.API_OLD_ENDPOINT + 'bdc/reset_planif.php', $.param({
          id_bdc: idBdc
        }), httpOptions)
        .subscribe(
          (ret) => {
            // Si on a des événements à planifier
            if (events.length !== 0) {


              this.httpClient
                .post(this.API_OLD_ENDPOINT + 'bdc/planifier_bdc.php', $.param({
                  id_bdc: idBdc
                }), httpOptions)
                .subscribe(
                  (ret) => {

                    for (const event of events) {
                      this.httpClient
                        .post(this.API_OLD_ENDPOINT + 'techniciens/new_planification.php', $.param({
                          id_bdc: idBdc,
                          id_technicien: event.resourceId,
                          start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
                          end: moment(event.end).format('YYYY-MM-DD HH:mm:ss')
                        }), httpOptions)
                        .subscribe(
                          (ret) => {

                            resolve(ret);
                          },
                          (error) => {
                            reject(error);
                          }
                        );
                    }
                  },
                  (error) => {
                    reject(error);
                  }
                );
            } else {
              reject("no_event")
            }
          },
          (error) => {
            //console.log(error)
            reject(error);
          }
        );
    });
  }

  flagBdcToIntervention(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/planifier_bdc.php', {
      id_bdc: idBdc
    });
  }

  newPlanification(id_bdc, id_technicien, start, end) {
    return this.post(this.API_ENDPOINT + 'planification/new.php', {
      'id_technicien': id_technicien,
      'id_bdc': id_bdc,
      'start': start,
      'end': end
    });
  }

  updatePlanification(idPlanif, start, end, idTechnicien) {
    return this.post(this.API_ENDPOINT + 'planification/update.php', {
      'id_planif': idPlanif,
      'start': start,
      'end': end,
      'id_technicien': idTechnicien
    });
  }

  deletePlanification(idPlanif) {
    return this.post(this.API_ENDPOINT + 'planification/delete.php', {
      'id_planif': idPlanif
    });
  }

  majOptionsBdc(optionsBdc) {
    return this.postFormData(this.API_ENDPOINT + 'bdc/anomalies/update.php', optionsBdc);
  }

  finIntervention(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/fin_intervention_bdc.php', {
      id_bdc: idBdc
    });
  }

  supprimerBdc(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/supprimer_bdc.php', {
      id_bdc: idBdc
    });
  }

  bdcToValidationState(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/bdc_to_validation.php', {
      id_bdc: idBdc
    });
  }

  bdcToPlanificationState(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/bdc_to_planification.php', {
      id_bdc: idBdc
    });
  }

  bdcToInterventionState(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/bdc_to_intervention.php', {
      id_bdc: idBdc
    });
  }

  bdcToFacturationState(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/bdc_to_facturation.php', {
      id_bdc: idBdc
    });
  }

  bdcToFactureState(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/bdc_to_facture.php', {
      id_bdc: idBdc
    });
  }

  archiverFactu(idBdc, no_facture, no_facture_fournisseur) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/bdc_to_archive.php', {
      id_bdc: idBdc,
      no_facture: no_facture,
      no_facture_fournisseur: no_facture_fournisseur
    });
  }

  saveFactu(idBdc, no_facture, no_facture_fournisseur) {
    return this.post(this.API_OLD_ENDPOINT + 'bdc/save_factu.php', {
      id_bdc: idBdc,
      no_facture: no_facture,
      no_facture_fournisseur: no_facture_fournisseur
    });
  }

  updateRapportOfBdc(id_bdc, rapport) {
    return this.post(this.API_ENDPOINT + 'bdc/rapport/update.php', {
      id_bdc: id_bdc,
      rapport: rapport
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // PIECES JOINTES
  //////////////////////////////////////////////////////////////////////////////////////////////////


  postPJ(bdc) {
    return this.postFormData(this.API_ENDPOINT + 'pieces_jointes/new.php', bdc);
  }

  updatePJ(id, nom, description) {
    return this.get(this.API_ENDPOINT + 'pieces_jointes/update.php?id_piece_jointe=' + id + '&nom=' + nom + '&description=' + description);
  }

  deletePJ(id) {
    return this.get(this.API_ENDPOINT + 'pieces_jointes/delete.php?id_piece_jointe=' + id);
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  // LISTES
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getNbBonsDansListes() {
    return this.get(this.API_ENDPOINT + 'agences/get_nb_listes.php?id_agence=' + this.getAgenceId());
  }

  getAllBdcActifs() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=ALL');
  }

  getListBdcAValider() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=A_VALIDER' + '&noanomalie=true');
  }

  getListBdcAPlanifier() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=A_PLANIFIER' + '&noanomalie=true');
  }

  getListBdcAPlanifierPieceACommander() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=A_PLANIFIER_PIECE_A_COMMANDER');
  }

  getListInterventions() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=A_EXECUTER' + '&noanomalie=true');
  }

  getListFacturation() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=A_FACTURER' + '&noanomalie=true');
  }

  getListFacturationFaite() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=FACTURE');
  }

  getListDifficultePlanif() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=DIFFICULTE_PLANIF');
  }

  getListRapportsRetard() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=RAPPORTS_NON_SAISIS');
  }


  getListBdcCorbeille() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=CORBEILLE');
  }

  getListBdcArchives() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=ARCHIVE');
  }

  // ANOMALIES /////////////////////////////////////////////////////////////////////////////

  getListBdcPieceACommander() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=PIECE_A_COMMANDER');
  }

  getListBdcDevisARealiser() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=DEVIS_A_REALISER');
  }

  getListBdcAnomalie() {
    return this.get(this.API_ENDPOINT + 'bdc/list.php?agence=' + this.getAgenceId() + '&type=ANOMALIE');
  }

  // RECHERCHES DE BDC ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getListBdcEnRetard() {
    return this.get(this.API_ENDPOINT + 'bdc/search/retard.php?agence=' + this.getAgenceId());
  }

  getListBdcSansNoBdc() {
    return new Promise((resolve, reject) => {
      this.get(this.API_ENDPOINT + 'bdc/search/no_ref.php?agence=' + this.getAgenceId())
        .then(ret => {
          const listeBdc = this.sortByQuitus(ret);
          resolve(listeBdc)
        }, error => reject(error));
    });
  }

  getListBdcRecherches(recherche) {
    return new Promise((resolve, reject) => {
      this.get(this.API_ENDPOINT + 'bdc/search/recherche.php?agence=' + this.getAgenceId() + '&recherche=' + recherche)
        .then(ret => {
          const listeBdc = this.sortByQuitus(ret);
          resolve(listeBdc)
        }, error => reject(error));
    });
  }

  getListBdcRecherchesAvecTel(recherche, telephone) {
    return new Promise((resolve, reject) => {
      this.get(this.API_ENDPOINT + 'bdc/search/recherche.php?agence=' + this.getAgenceId() + '&recherche=' + recherche + '&phone=' + telephone)
        .then(ret => {
          const listeBdc = this.sortByQuitus(ret);
          resolve(listeBdc)
        }, error => reject(error));
    });
  }

  getRegul() {
    return this.get(this.API_ENDPOINT + 'bailleurs/regulation.php?id_agence=' + this.getAgenceId());
  }

  getListesArticles() {
    return this.get(this.API_ENDPOINT + 'bailleurs/get_listes_articles.php?id_agence=' + this.getAgenceId());
  }

  getArticlesFromList(idListe) {
    return this.get(this.API_ENDPOINT + 'articles/get_list.php?id_liste=' + idListe);
  }

  getListeArticlesWithStat(idListe, date_debut, date_fin) {
    return this.get(this.API_ENDPOINT + 'articles/get_stats_of_list.php?id_liste=' + idListe + '&date_debut=' + date_debut + '&date_fin=' + date_fin);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////

  searchNoBdc(no_bdc, id_bailleur) {
    return new Promise((resolve, reject) => {
      this.get(this.API_OLD_ENDPOINT + 'recherche/search_no_bdc.php?id_agence=' + this.getAgenceId() + '&no_bdc=' + no_bdc + '&id_bailleur=' + id_bailleur)
        .then(ret => {
          const listeBdc = this.sortByQuitus(ret);
          resolve(listeBdc)
        }, error => reject(error));
    });
  }

  searchNoAffaire(no_affaire) {
    return new Promise((resolve, reject) => {
      this.get(this.API_OLD_ENDPOINT + 'recherche/search_no_affaire.php?id_agence=' + this.getAgenceId() + '&no_affaire=' + no_affaire)
        .then(ret => {
          const listeBdc = this.sortByQuitus(ret);
          resolve(listeBdc)
        }, error => reject(error));
    });
  }




  /* ////////////////////////////////////////////
  ** STATISTIQUES
  */ ////////////////////////////////////////////

  getChiffreAffaires(date_min, date_max, granularite, id_bailleur, id_marche, id_technicien) {
    return this.get(this.API_ENDPOINT + 'agences/get_chiffre_affaires.php?id_agence=' + this.getAgenceId() + '&date_min=' + date_min + '&date_max=' + date_max
          + '&granularite=' + granularite
          + '&id_bailleur=' + id_bailleur
          + '&id_marche=' + id_marche
          + "&id_technicien=" + id_technicien);
  }

  getStatsBdc() {
    return this.get(this.API_OLD_ENDPOINT + 'bdc/stats.php?id_agence=' + this.getAgenceId());
  }


  getLogementsAdresse(adresse) {
    return this.get(this.API_ENDPOINT + 'bdc/search/adresse.php?agence=' + this.getAgenceId() + '&adresse=' + encodeURI(adresse));
  }



  /* /////////////////////
  ** ARTICLES
  */ /////////////////////

  getListesArticlesMarche(idMarche) {
    return this.get(this.API_OLD_ENDPOINT + 'articles/get_liste_articles_marche.php?' +
          '&id_marche=' + parseInt(idMarche));
  }

  getArticlesOfListe(idListe) {
    return this.get(this.API_OLD_ENDPOINT + 'articles/get_liste_articles.php?' +
          '&id_liste_articles=' + parseInt(idListe));
  }

  getArticles(typeAttribution, idAttribution) {
    return this.get(this.API_OLD_ENDPOINT + 'articles/get_articles.php?' +
          'type_attribution=' + typeAttribution + '&id_attribution=' + parseInt(idAttribution));
  }

  newListeArticles(idMarche, nom, articles) {
    return this.post(this.API_ENDPOINT + 'articles/new_list.php', {
      'id_marche': idMarche,
      'nom': nom,
      'articles': JSON.stringify(articles)
    });
  }

  newArticle(idMarche, code, nom, prixHt) {
    return this.post(this.API_OLD_ENDPOINT + 'articles/new_article.php', {
      'id_marche': idMarche,
      'nom': nom,
      'code': code,
      'prix_ht': prixHt
    });
  }

  newArticleCustom(code, nom, prixHt) {
    return this.post(this.API_OLD_ENDPOINT + 'articles/new_article_custom.php', {
      'nom': nom,
      'code': code,
      'prix_ht': prixHt
    });
  }

  /* /////////////////////
  ** APPELS
  */ /////////////////////

  getAppels(idBdc) {
    return this.get(this.API_OLD_ENDPOINT + 'get_appels_bdc.php?id_bdc=' + idBdc);
  }

  newAppel(idBdc, commentaire, date) {
    return this.post(this.API_OLD_ENDPOINT + 'appels/new_appel.php', {
      "id_bdc": idBdc,
      "commentaire": commentaire,
      "date": date
    });
  }

  editAppel(id, commentaire) {
    return this.post(this.API_OLD_ENDPOINT + 'appels/edit_appel.php', {
      "id_appel": id,
      "commentaire": commentaire
    });
  }

  deleteAppel(id) {
    return this.post(this.API_OLD_ENDPOINT + 'appels/delete_appel.php', {
      "id_appel": id
    });
  }

  /* /////////////////////
  ** COMMENTAIRES
  */ /////////////////////

  getCommentaires(idBdc) {
    return this.get(this.API_OLD_ENDPOINT + 'commentaires/get_com_bdc.php?id_bdc=' + idBdc);
  }

  newCommentaire(idBdc, commentaire) {
    return this.post(this.API_OLD_ENDPOINT + 'commentaires/post_com_bdc.php', {
      "id_bdc": idBdc,
      "commentaire": commentaire
    });
  }

  editCommentaire(id, commentaire) {
    return this.post(this.API_OLD_ENDPOINT + 'commentaires/edit_com_bdc.php', {
      "id": id,
      "commentaire": commentaire
    });
  }

  deleteCommentaire(id) {
    return this.post(this.API_OLD_ENDPOINT + 'commentaires/delete_com_bdc.php', {
      "id": id
    });
  }

  /* /////////////////////
  ** AVIS DE PASSAGE
  */ /////////////////////

  newAvisPassage(avis) {
    return this.postFormData(this.API_OLD_ENDPOINT + 'avis_passage/new_avis.php', avis);
  }

  getListeAvisPassage(idBdc) {
    return this.get(this.API_OLD_ENDPOINT + 'avis_passage/get_liste_avis.php?id_bdc=' + idBdc);
  }

  getAvisPassage(idAvis) {
    return new Promise((resolve, reject) => {
      this.get(this.API_OLD_ENDPOINT + 'avis_passage/get_avis.php?id_avis=' + idAvis)
        .then(ret => {
          resolve(ret[0])
        }, error => reject(error));
    });
  }

  /* /////////////////////
  ** PHOTOS // Inutilisés ?
  */ /////////////////////

  newPhoto(photo) {
    return this.postFormData(this.API_OLD_ENDPOINT + 'photos/new_photo.php', photo);
  }

  getPhotos(idBdc, type) {
    return this.get(this.API_OLD_ENDPOINT + 'photos/get_photos.php?id_bdc=' + idBdc + '&type=' + type);
  }

  deletePhoto(id) {
    return this.post(this.API_OLD_ENDPOINT + 'photos/delete_photo.php', {
      "id": id
    });
  }

  /* /////////////////////
  ** CLIENTS
  */ /////////////////////

  getListeClients() {
    return this.get(this.API_ENDPOINT + 'agences/get_bailleurs.php?id_agence=' + this.getAgenceId());
  }

  getClient(idClient) {
    return this.get(this.API_ENDPOINT + 'bailleurs/get.php?id=' + idClient);
  }

  newClient(nom) {
    return this.post(this.API_ENDPOINT + 'bailleurs/new.php', {
      'id_agence': this.getAgenceId(),
      'nom': nom
    });
  }

  changeBailleurState(idBailleur, newState) {
    return this.post(this.API_ENDPOINT + 'bailleurs/change_state.php', {
      'id_bailleur': idBailleur,
      'new_state': newState
    });
  }

  changeNomBailleur(idBailleur, nom) {
    return this.post(this.API_ENDPOINT + 'bailleurs/update.php', {
      'id_bailleur': idBailleur,
      'nom': nom
    });
  }

  // Marches

  getMarche(idMarche) {
    return this.get(this.API_ENDPOINT + 'bailleurs/marches/get.php?id_marche=' + idMarche);
  }

  newMarche(idBailleur, nom) {
    return this.post(this.API_ENDPOINT + 'bailleurs/marches/new.php', {
      'id_bailleur': idBailleur,
      'nom': nom
    });
  }

  changeMarcheState(idMarche, newState) {
    return this.post(this.API_ENDPOINT + 'bailleurs/marches/change_state.php', {
      'id_marche': idMarche,
      'new_state': newState
    });
  }

  changeNomMarche(idMarche, nom) {
    return this.post(this.API_ENDPOINT + 'bailleurs/marches/update.php', {
      'id_marche': idMarche,
      'nom': nom
    });
  }

  deleteChargeSecteurOfMarche(idMarche, idChargeSecteur) {
    return this.post(this.API_ENDPOINT + 'bailleurs/marches/charges_secteur/delete.php', {
      'id_charge_secteur': idChargeSecteur,
      'id_marche': idMarche
    });
  }

  // Charges de secteur

  getCharge(idCharge) {
    return this.get(this.API_ENDPOINT + 'bailleurs/charges_secteur/get.php?id_charge=' + idCharge);
  }

  newChargeOfBailleur(idBailleur, nom, tel, mail) {
    return this.post(this.API_ENDPOINT + 'bailleurs/charges_secteur/new.php', {
      'id_bailleur': idBailleur,
      'nom': nom,
      'tel': tel,
      'mail': mail
    });
  }

  changeChargeSecteurState(idChargeSecteur, newState) {
    return this.post(this.API_ENDPOINT + 'bailleurs/charges_secteur/change_state.php', {
      'id_charge_secteur': idChargeSecteur,
      'new_state': newState
    });
  }

  updateChargeSecteur(idChargeSecteur, nom, tel, mail) {
    return this.post(this.API_ENDPOINT + 'bailleurs/charges_secteur/update.php', {
      'id_charge_secteur': idChargeSecteur,
      'nom': nom,
      'tel': tel,
      'mail': mail
    });
  }

  newChargeOfMarche(idMarche, idCharge) {
    return this.post(this.API_ENDPOINT + 'bailleurs/marches/charges_secteur/new.php', {
      'id_marche': idMarche,
      'id_charge_secteur': idCharge
    });
  }

  getChargesOfMarche(idMarche) {
    return this.get(this.API_ENDPOINT + 'bailleurs/marches/charges_secteur/get.php?id_marche=' + idMarche);
  }

  getChargesOfBailleur(idBailleur) {
    return this.get(this.API_ENDPOINT + 'bailleurs/charges_secteur/get_list.php?id_bailleur=' + idBailleur);
  }

  /* /////////////////////
  ** TECHNICIENS
  */ /////////////////////

  getTechniciens() {
    return this.get(this.API_ENDPOINT + 'agences/get_techniciens.php?id_agence=' + this.getAgenceId());
  }

  getTechnicien(idTechnicien) {
    return this.get(this.API_ENDPOINT + 'techniciens/get.php?id_technicien=' + idTechnicien);
  }

  newTechnicien(nom, prenom, tel) {
    return this.post(this.API_ENDPOINT + 'techniciens/new.php', {
      'id_agence': this.getAgenceId(),
      'nom': nom,
      'prenom': prenom,
      'tel': tel
    });
  }

  changeTechnicienState(idTechnicien, newState) {
    return this.post(this.API_ENDPOINT + 'techniciens/change_state.php', {
      'id_technicien': idTechnicien,
      'new_state': newState
    });
  }

  updateTechnicien(idTechnicien, nom, prenom, tel) {
    return this.post(this.API_ENDPOINT + 'techniciens/update.php', {
      'id_technicien': idTechnicien,
      'nom': nom,
      'prenom': prenom,
      'tel': tel
    });
  }

  getPlanifTechnicien(id_technicien) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(this.API_ENDPOINT + 'techniciens/get_planification.php?id_technicien=' + id_technicien)
        .subscribe(
          (response) => {

            response = response === 0 ? [] : response;

            resolve(response);
          },
          (error) => {
            //console.log('Erreur ! : ' + error);
            reject(error);
          }
        );
    });
  }

  getPlanifJour(start, end) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(this.API_ENDPOINT + 'planification/get.php?start=' + start + '&end=' + end + '&agence=' + this.getAgenceId())
        .subscribe(
          (response) => {

            response = response === 0 ? [] : response;

            resolve(response);
          },
          (error) => {
            //console.log('Erreur ! : ' + error);
            reject(error);
          }
        );
    });
  }

  getPlanifBdc(idBdc) {
    return this.post(this.API_OLD_ENDPOINT + 'techniciens/get_planif_bdc.php', {
      "id_bdc": idBdc
    });
  }

  /* /////////////////////
  ** RAPPORTS
  */ /////////////////////

  /*
  *************************
  ** A REVOIR !!!
  ***************************
  */


  getRapportsOfBdc(idBdc) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(this.API_OLD_ENDPOINT + 'rapports/get_rapports_of_bdc.php?id=' + idBdc)
        .subscribe(
          (rapports) => {

            if (rapports == 0) {
              resolve([]);
              rapports = [];
            }

            for (let rapport of rapports) {

              this.httpClient
                .get<any>(this.API_OLD_ENDPOINT + 'rapports/get_articles_of_rapport.php?id=' + rapport.id)
                .subscribe(
                  (articles) => {

                    if (articles == 0) {
                      articles = [];
                    }

                    rapport.articles = articles;

                    this.httpClient
                      .get<any>(this.API_OLD_ENDPOINT + 'rapports/get_techniciens_of_rapport.php?id=' + rapport.id)
                      .subscribe(
                        (techniciens) => {

                          rapport.techniciens = techniciens;

                          this.httpClient
                            .get<any>(this.API_OLD_ENDPOINT + 'rapports/get_photos_of_rapport.php?id_rapport=' + rapport.id)
                            .subscribe(
                              (photos) => {
                                rapport.photos = photos;
                                resolve(rapports);
                              },
                              (error) => {
                                reject(error);
                              }
                            );
                        },
                        (error) => {
                          reject(error);
                        }
                      );
                  },
                  (error) => {
                    reject(error);
                  }
                );
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addRapport(bdc) {
    return this.postFormData(this.API_OLD_ENDPOINT + 'rapports/add_rapport.php', bdc)
  }

  deleteRapport(id) {
    return this.post(this.API_OLD_ENDPOINT + 'rapports/delete_rapport.php', {
      "id_rapport": id
    });
  }

  /*
   ** FONCTIONS DIVERSES
   */

  // Fonctions de manipulation des tableaux de bdc
  sortByQuitus(tab) {

    tab.sort((a, b) => {
      if (parseInt(a.id) > parseInt(b.id)) {
        return -1;
      }
      if (parseInt(a.id) < parseInt(b.id)) {
        return 1;
      }
      return 0;
    });

    return tab;
  }

  // Fonctions utilitaires
  maj_select(select, jsondata) {
    //console.log(jsondata)

    var htmldata = '';
    var new_value_options: any;
    new_value_options = '[';
    for (let key in jsondata) {
      htmldata += '<option value="' + jsondata[key].id + '">' + jsondata[key].nom + '</option>';

      var keyPlus = parseInt(key) + 1;
      if (keyPlus == jsondata.length) {
        new_value_options += '{"text": "' + jsondata[key].nom + '", "value": ' + jsondata[key].id + '}';
      } else {
        new_value_options += '{"text": "' + jsondata[key].nom + '", "value": ' + jsondata[key].id + '},';
      }
    }
    new_value_options += ']';

    new_value_options = new_value_options.replace(/(\r\n|\n|\r)/gm, " ");

    //console.log(new_value_options)


    // convert to json object
    new_value_options = eval('(' + new_value_options + ')');
    if (new_value_options[0] !== undefined) {
      // re-fill html select option field
      $(select).html(htmldata);
      // re-fill/set the selectize values
      const selectize = $(select)[0].selectize;

      selectize.clear();
      selectize.clearOptions();
      selectize.renderCache['option'] = {};
      selectize.renderCache['item'] = {};

      selectize.addOption(new_value_options);

      selectize.setValue(new_value_options[0].value);
    }
    else if (new_value_options.length === 0) {
      // re-fill html select option field
      $(select).html(htmldata);
      // re-fill/set the selectize values
      const selectize = $(select)[0].selectize;

      selectize.clear();
      selectize.clearOptions();
      selectize.renderCache['option'] = {};
      selectize.renderCache['item'] = {};

      selectize.addOption(new_value_options);
    }
  }

  set_selectize(select, id) {
    //console.log(id)
    let selectize = $(select)[0].selectize;
    selectize.setValue(id)
  }

  formatDate(dateString) {
    ////console.log(moment(dateString, "DD-MM-YYYY").format('YYYY-MM-DD [23:00:00]'))
    //console.log(dateString)
    return moment(dateString, "YYYY-MM-DD").format('YYYY-MM-DD [23:00:00]')
  }

  dateHeure(date) {
    if (date === '0000-00-00 00:00:00') {
      return '--';
    } else {
      return moment(date).format('DD/MM/YYYY [à] HH[h]mm');
    }
  }

  dateCompacte(date) {
    if (date === '0000-00-00 00:00:00') {
      return '--';
    } else {
      return moment(date).format('DD/MM/YYYY');
    }
  }

  heureCompacte(date) {
    if (date === '0000-00-00 00:00:00') {
      return '--';
    } else {
      return moment(date).format('HH[h]mm');
    }
  }

  geoCoding(requete) {
    return this.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + requete + '.json?access_token=pk.eyJ1IjoiY2hpcHNvbmR1bGVlIiwiYSI6ImNreXJpMWRjajB1YjEydnA2cTNhbmVrdmgifQ.hDglZUkpYQOpcK6Qz5Omww&cachebuster=1551446394528&autocomplete=false&country=fr');
  }
}
