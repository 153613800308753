<div class="row">
  <div class="col-12 d-flex">
    <button type="button" class="btn btn-sm btn-primary mb-1 mr-1" (click)="newRapport()">Nouveau rapport</button>
    <button type="button" class="btn btn-sm btn-secondary mb-1" (click)="toPlanif()">Retour en planification</button>

    <div class="ml-auto">

      <button type="button" class="btn btn-sm btn-secondary mb-1 " (click)="toFactu()">Passer en
        facturation</button>
    </div>



  </div>
</div>

<table id="table_id" class="table table-hover table-outline table-vcenter text-nowrap card-table">
  <thead>
    <tr>
      <th>Date</th>
      <th>Technicien</th>
      <th>Prix</th>
      <th>Commentaire</th>
      <th>Photos</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let rapport of rapports" (click)="viewRapport(rapport)">
      <td>{{BdcService.dateHeure(rapport.date)}}</td>

      <td *ngIf="rapport.techniciens.length > 0">{{rapport.techniciens[0].prenom}} {{rapport.techniciens[0].nom}}</td>
      <td *ngIf="rapport.techniciens.length <= 0"></td>

      <td>{{totalArticles(rapport).ht.toFixed(2)}}€</td>
      <td>{{rapport.commentaire}}</td>
      <td>{{rapport.photos.length}}</td>
      <td></td>
    </tr>
  </tbody>
</table>

<div class="row">
  <div class="col-12 d-flex">
    <span class="ml-auto"><b>Prix total &nbsp;&nbsp;</b> {{bdc.interventions.prix_ht}} €</span>
  </div>
</div>
