<div id="liste-globale" class="home">
    <div class="my-3 my-md-5">
      <div class="container-fluid">
  
        <div class="card p-2">
          <div class="row">
            <div class="col-12 d-flex">
  
  
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-secondary" id="label-status-all">
                  <input type="radio" name="status-all" id="status-all" (click)="switchStatusFilter('ALL')">
                  <i class="fe fe-check-square"></i> Tous les bons
                </label>
                <label class="btn btn-secondary" id="label-status-a-planifier">
                  <input type="radio" name="status-a-planifier" id="status-a-planifier" (click)="switchStatusFilter('DIFFICULTE_PLANIF')">
                  <i class="fe fe-calendar"></i> Difficultés de planification 
                </label>
                <label class="btn btn-secondary" id="label-rapport-retard">
                    <input type="radio" name="rapport-retard" id="rapport-retard" (click)="switchStatusFilter('RAPPORT_RETARD')">
                    <i class="fe fe-calendar"></i> Rapports d'intervention en retard 
                  </label>
                
              </div>
  
              <!--<button class="btn btn-secondary ml-5" (click)="exportFactu()">
                 Exporter
              </button>-->
  
  
            </div>
          </div>
  
        </div>
  
        <div class="row row-cards row-deck">
          <div class="col-12">
  
            <div class="card p-3">
  
              <div class="row">
                <div class="col-8">
                  <div class="form-group">
                    <small class="form-text text-muted">Colonnes</small>
                    <input id="selected-columns" type="text" placeholder="Colonnes">
                  </div>
                </div>
  
                <div class="col-3">
                  <div class="form-group">
                    <small class="form-text text-muted">Recherche</small>
                    <input id="recherche-tab" type="text" class="form-control" placeholder="Recherche">
                  </div>
                </div>
  
                <div class="col-1">
                  <div class="form-group">
                    <small class="form-text text-muted">Taille</small>
  
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-secondary" (click)="fontSizeUp()"><i
                          class="fe fe-chevron-up"></i></button>
                      <button type="button" class="btn btn-secondary" (click)="fontSizeDown()"><i
                          class="fe fe-chevron-down"></i></button>
                    </div>
                  </div>
                </div>
  
              </div>
  
  
  
  
              <div id="{{tableId}}-wrapper" class="table-responsive">
                <table id="{{tableId}}" class="table table-hover table-vcenter text-nowrap card-table">
  
  
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  