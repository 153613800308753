<div class="modal-header">
    <h5 class="modal-title" id="addArticleModalLabel">Ajouter un article</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
    </button>
  </div>
  <div class="modal-body">
  
    <div class="row mb-3">
      <div class="col-3">
        <div class="form-group">
          <label class="form-label">Bailleur</label>
          <select name="cat-article" [(ngModel)]="bailleur" id="select-bailleur-articles" class="form-control custom-select selectized"
            tabindex="-1" style="display: none;">
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="form-label">Marché</label>
          <select name="marche-article" [(ngModel)]="marche" id="select-marche-articles" class="form-control custom-select selectized"
            tabindex="-1" style="display: none;">
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="form-label">Recherche</label>
          <input type="text" class="form-control" name="search-article" [(ngModel)]="search">
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="form-label">&nbsp;</label>
          <button type="button" class="btn btn-secondary" 
      (click)="articleHorsBordereau()">Article hors bordereau</button>
        </div>
      </div>
    </div>
  
    <div class="row mb-3" style="padding: 1.5rem 1.5rem;">
      <table class="table card-table table-hover">
        <thead>
          <tr>
            <th class="w-1">Code</th>
            <th>Dénomination</th>
            <th>Prix HT</th>
          </tr>
        </thead>
  
        <tbody *ngIf="listeArticles.length !== 0">
          <tr *ngFor="let article of listeArticles | filter : search"
            (click)="addArticle(article)">
            <td class="text-muted">{{article.code_article}}</td>
            <td>{{article.nom}}</td>
            <td class="text-muted">{{article.prix_ht}}€</td>
          </tr>
        </tbody>
      </table>
    </div>
  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Annuler</button>
  </div>
  