import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { BdcService } from '../../../services/service-bdc.service';

@Component({
  selector: 'app-liste-anomalies',
  templateUrl: './liste-anomalies.component.html',
  styleUrls: ['./liste-anomalies.component.scss']
})
export class ListeAnomaliesComponent implements OnInit {
  listeBdc = [];

  constructor(private BdcService: BdcService, private router: Router, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.BdcService.getListBdcAnomalie().then((listeACommander: any) => {
      this.listeBdc = listeACommander;
    });


  }

}
