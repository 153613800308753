import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { BdcService } from '../../services/service-bdc.service';

@Component({
  selector: 'app-liste-planification',
  templateUrl: './liste-planification.component.html',
  styleUrls: ['./liste-planification.component.scss']
})
export class ListePlanificationComponent implements OnInit {

  listeBdcAPlanifier = [];
  listeBdcPieceACommander = [];

  constructor(private BdcService: BdcService, private router: Router, private route: ActivatedRoute) {
    route.params.subscribe(val => {
      this.BdcService.getListBdcAPlanifier().then((listeBdcAPlanifier: any[]) => {
        this.listeBdcAPlanifier = listeBdcAPlanifier;
      });

      this.BdcService.getListBdcAPlanifierPieceACommander().then((listeACommander: any) => {
        this.listeBdcPieceACommander = listeACommander;
      });
    });
  }

  ngOnInit() {
    /* Voir dans :
          route.params.subscribe(val => {
            this.getBdc();
          });
        */
  }

  majListe() {
    this.BdcService.getListBdcAPlanifier().then((listeBdcAPlanifier: any[]) => {
      this.listeBdcAPlanifier = listeBdcAPlanifier;
    });
  }

}
