<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Choisir un technicien</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
  (click)="cancel()">
  </button>
</div>

<div class="modal-body">

  <div class="form-group">
    <label class="form-label">Technicien</label>
    <select name="tech" [(ngModel)]="selectedTech" id="select-tech" class="form-control custom-select selectized"
      tabindex="-1" style="display: none;">
    </select>
  </div>

  <button type="button" class="btn btn-primary" (click)="ok()">Valider</button> 
  
</div>

