import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../services/service-bdc.service';
import { supported } from 'mapbox-gl';

@Component({
  selector: 'app-delete-event-modal',
  templateUrl: './delete-event-modal.component.html',
  styleUrls: ['./delete-event-modal.component.scss']
})
export class DeleteEventModalComponent implements OnInit {
  @Input() idBdc;
  @Input() event;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
    //console.log(this.idBdc)
    //console.log(this.event)
  }

  suppr() {
    this.activeModal.close({
      action: 'suppr',
      event: this.event
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
