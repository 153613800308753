<div class="alert alert-warning" role="alert" *ngIf="bdc.urgence == 2">
  Urgence de niveau 2 - A traiter dans la semaine
</div>
<div class="alert alert-danger" role="alert" *ngIf="bdc.urgence == 3">
  Urgence de niveau 3 - A traiter immédiatement
</div>

<div class="row">
  <div class="col-12">
    <div class="clearfix">
      <div class="float-left">
        <small class="text-muted">{{date_reception}}</small>
      </div>
      <div class="float-right">
        <small class="text-muted">{{date_limite}}</small>
      </div>
    </div>
    <div class="progress progress-xs">
      <div class="progress-bar bg-green" role="progressbar" [ngStyle]="dateProgressStyle"></div>
    </div>
  </div>
</div>

<br>


<div class="row">
  <div class="col-12">

    <div class="card">
      <div class="card-header">
        <!--{{iconStatus()}}-->


        <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire == ''">Bon n°{{bdc.no_bdc}}</h2>
        <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire != ''">{{bdc.id}} - Affaire
          n°{{bdc.no_affaire}}</h2>
        <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire != ''">Bon n°{{bdc.no_bdc}} - Affaire
          n°{{bdc.no_affaire}}</h2>
        <div class="ml-auto">
          <span class="float-right">
            <button type="button" class="btn btn-secondary" (click)="attachementModal()">Pièces jointes</button>
            <span *ngIf="!readonly">&nbsp;&nbsp;
              <button type="button" class="btn btn-secondary" (click)="modifBon()">Modifier</button>
            </span>
          </span>
        </div>
      </div>

      <div class="card-body pb-1">

        <div class="row">
          <div class="col-6">

            <span class="title">{{bdc.bailleur}}</span> <b>{{bdc.marche}}</b> <br><br>
            <b>{{bdc.client}}</b> &nbsp;&nbsp; <a href="tel:{{bdc.tel_charge}}">{{bdc.tel_charge}}</a> &nbsp;&nbsp; <a href="mailto:{{bdc.mail_charge}}" target="_blank">{{bdc.mail_charge}}</a>
            <!--Demande par {{get_moyen_demande()}}-->

            <hr class="mb-5">

            <div class="row">
              <div class="col-6">
                <p>
                  <b>Quitus &nbsp;&nbsp;</b>{{bdc.id}}<br>
                  <b>N° BDC &nbsp;&nbsp;</b>{{bdc.no_bdc}}<br>
                  <b>N° Affaire &nbsp;&nbsp;</b>{{bdc.no_affaire}}
                </p>
              </div>
              <div class="col-6">
                <span *ngIf="this.bdc.status === '' || this.bdc.status === 'A_VALIDER'"><span
                    class="badge badge-secondary"><i class="fe fe-check-square"></i></span>&nbsp;&nbsp; A valider</span>
                <span *ngIf="this.bdc.status === 'A_PLANIFIER'"><span class="badge badge-secondary"><i
                      class="fe fe-calendar"></i></span>&nbsp;&nbsp; A planifier</span>
                <span *ngIf="this.bdc.status === 'A_EXECUTER'"><span class="badge badge-secondary"><i
                      class="fe fe-truck"></i></span>&nbsp;&nbsp; En intervention</span>
                <span *ngIf="this.bdc.status === 'A_FACTURER' || this.bdc.status === 'FACTURE'"><span
                    class="badge badge-secondary"><i class="fe fe-dollar-sign"></i></span>&nbsp;&nbsp; En facturation</span>
                <span *ngIf="this.bdc.status === 'ARCHIVE'"><span class="badge badge-secondary"><i
                      class="fe fe-book"></i></span>&nbsp;&nbsp; Archivé</span>

                      <br>

                <b>Devis &nbsp;&nbsp;</b>{{bdc.no_devis}}<br>
                <b>Assurance &nbsp;&nbsp;</b>{{bdc.no_assurance}}

              </div>
            </div>



          </div>

          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <span class="title">Chez {{bdc.nom_locataire}}</span> <br><br>


                <a href="{{getMapsLink()}}"
                  onclick="window.open(this.href); return false;">{{bdc.adresse_locataire}}<br>
                  {{bdc.code_postal_locataire}} {{bdc.ville_locataire}} <br>
                  {{bdc.cpt_adresse_locataire}}</a><br><br>



                <!--https://www.google.fr/maps/place/4+Rue+Petit+David,+69002+Lyon-->

                <p>
                  <b>Tel fixe </b><a href="tel:{{bdc.tel_fixe_locataire}}">{{bdc.tel_fixe_locataire}}</a><br>
                  <b>Tel portable </b><a href="tel:{{bdc.tel_portable_locataire}}">{{bdc.tel_portable_locataire}}</a>
                </p>
              </div>
              <div class="col-6">
                <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[10]"
                  [center]="positionLogement.coordinates">

                  <mgl-marker [lngLat]="positionLogement.coordinates">
                    <span class="fe fe-map-pin marker"></span>
                  </mgl-marker>

                </mgl-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
