import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../services/service-bdc.service';
import {AddAvisPassageComponent} from './add-avis-passage/add-avis-passage.component';
import { ShowAvisPassageComponent } from './show-avis-passage/show-avis-passage.component';


@Component({
  selector: 'app-avis-passage',
  templateUrl: './avis-passage.component.html',
  styleUrls: ['./avis-passage.component.scss']
})
export class AvisPassageComponent implements OnInit {
  @Input() idBdc;

  avisPassage = [];

  constructor(public activeModal: NgbActiveModal, public BdcService: BdcService, private modalService: NgbModal) { }

  ngOnInit() {
    this.getAvisPassage();
  }

  getAvisPassage() {
    this.BdcService.getListeAvisPassage(this.idBdc).then((liste) => {
      this.avisPassage = liste as [];
      //console.log(this.avisPassage)
    });
  }

  add() {
    const modalRef = this.modalService.open(AddAvisPassageComponent);
    modalRef.componentInstance.idBdc = this.idBdc;
    modalRef.result.then((result) => {
      this.getAvisPassage();
    }, (reason) => {});
  }

  show(idAvis) {
    const modalRef = this.modalService.open(ShowAvisPassageComponent);
    modalRef.componentInstance.idAvis = idAvis;
    modalRef.result.then((result) => {
      this.getAvisPassage();
    }, (reason) => {});
  }

  cancel() {
    this.activeModal.close();
  }

}
