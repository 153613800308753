<div class="card-header">
  <h3 class="card-title">Appels</h3>
  <div class="card-options">
    <button class="btn btn-sm btn-default" (click)="appelsModal()">
      <span class="fe fe-plus"></span>
    </button>
  </div>
</div>

{{appels}}
<div *ngIf="isArticles">
  <div class="card-body" *ngFor="let appel of bdc.appels">
    <div class="clearfix">
      <div class="float-left">
        <p class="text-muted">Appel du {{appel.date}}</p>
      </div>
      <div class="float-right">
        <p class="text-muted dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false"></p>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <button class="dropdown-item btn-primary" type="button" ng-click="edit_appel_modal(appel)"><i class="fe fe-edit"></i>
            Modifier</button>
          <button class="dropdown-item btn-danger" type="button" ng-click="delete_appel_modal(appel)"><i class="fe fe-trash-2"></i>
            Supprimer</button>
        </div>
      </div>
    </div>
    {{appel.commentaire}}
  </div>
</div>

<div class="card-body" *ngIf="!isArticles">
  <div class="clearfix">

    <div class="float-left">
      <p class="text-muted">Aucun appel</p>
    </div>
  </div>
