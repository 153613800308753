<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <div class="page-header">

        <h1 class="page-title">
          Agence de {{agence.nom}}
        </h1>

      </div>


      <div class="row row-cards">
        <div class="col-12">

          <form class="card">
            <div class="card-body">
              <h3 class="card-title">Boîte mail d'import</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Serveur</label>
                    <input type="text" autocomplete="off" class="form-control" [(ngModel)]="agence.inputmail_mailbox" name="inputmail_mailbox">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Adresse email</label>
                    <input type="email" autocomplete="false" class="form-control" [(ngModel)]="agence.inputmail_username" name="inputmail_username">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Mot de passe</label>
                    <input type="password" autocomplete="false" class="form-control" [(ngModel)]="agence.inputmail_password" name="inputmail_password">
                  </div>
                </div>

              </div>
            </div>

            <div class="card-body">
              <h3 class="card-title">Localisation de l'agence</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Latitude</label>
                    <input type="text" autocomplete="no" class="form-control" [(ngModel)]="agence.latitude" name="latitude">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Longitude</label>
                    <input type="text" autocomplete="no" class="form-control" [(ngModel)]="agence.longitude" name="longitude">
                  </div>
                </div>

              </div>
            </div>

            <div class="card-body">
              <h3 class="card-title">Application Android</h3>
              <div class="row">
                <div class="col-md-4">
                  <a class="btn btn-primary" [href]="appUrl()" role="button">Télécharger l'application</a>
                </div>
                <div class="col-md-4">
                </div>

              </div>
            </div>

            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary" (click)="update_import_settings()">Enregistrer</button>
            </div>
          </form>

        </div>

      </div>


    </div>
  </div>
</div>
