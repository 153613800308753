import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-modal-new-marche',
  templateUrl: './modal-new-marche.component.html',
  styleUrls: ['./modal-new-marche.component.scss']
})
export class ModalNewMarcheComponent implements OnInit {
  @Input() idClient;

  nom = '';

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
  }

  addMarche() {
    if (this.nom !== '') {
      this.BdcService.newMarche(this.idClient, this.nom).then((marche: any) => {
        console.log(marche)
        this.activeModal.close();
        this.router.navigate(['bailleurs/marche/' + marche.id]);
      });
    }
  }

  cancel() {
    this.activeModal.close();
  }

}

