<div class="modal-header">
  <h5 class="modal-title" id="addArticleModalLabel">Avis de passage</h5>
</div>
<div class="modal-body">

  <div class="form-group">
    <div class="form-label">Photo</div>
    <div class="custom-file">
      <input type="hidden" name="MAX_FILE_SIZE" value="30000" />
      <input type="file" class="custom-file-input" name="fichier" file-model="myFile" id="fichier" (change)="onFileChange($event)"
        #fileInput>
      <label class="custom-file-label">Choisir une photo</label>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="save()">Enregistrer</button>
</div>
