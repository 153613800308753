import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../../services/service-bdc.service';

@Component({
  selector: 'app-mod-replanif-event',
  templateUrl: './mod-replanif-event.component.html',
  styleUrls: ['./mod-replanif-event.component.scss']
})
export class ModReplanifEventComponent implements OnInit {
  @Input() idBdc;
  @Input() event;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
    //console.log(this.idBdc)
    //console.log(this.event)
  }

  replanifier() {
    this.activeModal.close({
      action: 'replanifier',
      event: this.event
    });
  }

  consulter() {
    this.activeModal.close({
      action: 'consulter',
      event: this.event
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
