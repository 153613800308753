import {
  Component,
  OnInit,
  Input, ElementRef, ViewChild
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  BdcService
} from '../../../../services/service-bdc.service';

@Component({
  selector: 'app-add-photo',
  templateUrl: './add-photo.component.html',
  styleUrls: ['./add-photo.component.scss']
})
export class AddPhotoComponent implements OnInit {
  @Input() idBdc;
  @Input() type;

  form: FormGroup;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private BdcService: BdcService) {}

  ngOnInit() {
    this.createForm();
  }

  save() {
    const formModel = this.prepareSave();

    this.BdcService.newPhoto(formModel).then(() => {
      this.activeModal.close();
    });
  }

  // Formulaire
  createForm() {
    this.form = this.fb.group({
      fichier: null
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get('fichier').setValue(file);
    }
  }

  private prepareSave(): any {
    const input = new FormData();

    input.append('id_bdc', this.idBdc);
    input.append('type', this.type);
    input.append('fichier', this.form.get('fichier').value);

    return input;
  }
}

