import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import {
  BdcService
} from '../../services/service-bdc.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  idAgence;
  agence = {
    nom: '',
    inputmail_mailbox: '',
    inputmail_username: '',
    inputmail_password: '',
    latitude: '',
    longitude: ''
  };

  constructor(private BdcService: BdcService) { }

  ngOnInit(): void {
    this.idAgence = this.BdcService.getAgenceId();

    console.log(this.idAgence)

    this.BdcService.getAgence(this.idAgence).then((agence: any) => {
      console.log(agence)

      this.agence = agence;
    })
  }

  update_import_settings() {
    this.BdcService.updateAgence(
      this.agence.inputmail_mailbox,
      this.agence.inputmail_username,
      this.agence.inputmail_password,
      this.agence.latitude,
      this.agence.longitude
      );
  }

  appUrl() {
    return environment.app_mobile_folder + "ems-mobile.apk";
  }

}
