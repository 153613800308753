<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">{{bdc.id}}</h2>

          <div class="ml-auto">
            <button type="button" class="btn btn-secondary float-right" (click)="backState()">Retour à la
              planification</button>
          </div>
        </div>
        <div class="card-body">

          <app-infos-bon [bdc]="bdc" [readonly]="false"></app-infos-bon>

          <div class="row">
            <div class="col-9">
              <app-options-toggles [bdc]="bdc"></app-options-toggles>
            </div>
            <div class="col-3">
              <div class="row">
                <div class="col-12" style="padding-right: 12px;position: relative; top: -10px;">
                  <span class="float-right">
                    <button class="btn btn-secondary" (click)="appelsModal()">Appels</button>&nbsp;&nbsp;
                    <button class="btn btn-secondary" (click)="avisPassageModal()">Avis de passage</button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <app-intervention-card [bdc]="bdc"></app-intervention-card>
            </div>

            <div class="col-6">
              <app-recap-planifications [bdc]="bdc" [controles]="true"></app-recap-planifications>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-resume-interventions [idBdc]="bdc.id" [readonly]="false" (bdcEvent)="eventFromInterventions($event)"></app-resume-interventions>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-redac-rapport [idBdc]="bdc.id" [readonly]="false" [(rapport)]="bdc.texte_rapport"></app-redac-rapport>
            </div>
          </div>



          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary ml-auto float-right" (click)="finIntervention()">Passer en facturation</button>
            </div>
          </div>
        </div>
      </div>

      <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>


    </div>
  </div>
</div>
