import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { ModalNewMarcheComponent } from '../../clients/marches/modal-new-marche/modal-new-marche.component';
import { ModalNewChargeOfBailleurComponent } from '../../clients/marches/modal-new-charge-of-bailleur/modal-new-charge-of-bailleur.component';

import {
  BdcService
} from '../../services/service-bdc.service';

@Component({
  selector: 'app-marches',
  templateUrl: './marches.component.html',
  styleUrls: ['./marches.component.scss']
})
export class MarchesComponent implements OnInit {

  marches = [];
  client = {} as any;

  marchesActifs = [];
  marchesInactifs = [];

  chargesActifs = [];
  chargesInactifs = [];

  idClient = this.route.snapshot.params['idClient'];

  edit = false;

  constructor(private BdcService: BdcService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
  }

  init() {

    this.BdcService.getClient(this.idClient).then((client: any) => {
      client.actif = client.actif == 1 ? true : false;

      console.log(client)

      this.client = client;
      this.marches = client.marches;

      for (let i = 0; i < client.marches.length; i++) {
        if(client.marches[i].actif == 1) {
          this.marchesActifs.push(client.marches[i]);
        }
        else {
          this.marchesInactifs.push(client.marches[i]);
        }
      }
    });

    this.BdcService.getChargesOfBailleur(this.idClient).then((charges: any) => {
      this.chargesActifs = [];
      this.chargesInactifs = [];

      for (let i = 0; i < charges.length; i++) {
        if(charges[i].actif == 1) {
          this.chargesActifs.push(charges[i]);
        }
        else {
          this.chargesInactifs.push(charges[i]);
        }
      }

    });
  }

  goMarche(id) {
    this.router.navigate(['bailleurs/marche/' + id]);
  }

  goCharge(id) {
    this.router.navigate(['bailleurs/charge-secteur/' + id]);
  }

  newMarche() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ModalNewMarcheComponent);
    modalRef.componentInstance.idClient = this.idClient;

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  newCharge() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ModalNewChargeOfBailleurComponent);
    modalRef.componentInstance.idClient = this.idClient;

    modalRef.result.then((result) => {
      this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  changeState() {
    this.BdcService.changeBailleurState(this.client.id, this.client.actif);
  }

  editNom() {
    this.edit = true;
  }
  saveNom() {
    this.BdcService.changeNomBailleur(this.client.id, this.client.nom);

    this.edit = false;
  }

}
