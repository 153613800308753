import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../services/service-bdc.service';

import * as moment from 'moment';

@Component({
  selector: 'app-appel-modal',
  templateUrl: './appel-modal.component.html',
  styleUrls: ['./appel-modal.component.scss']
})
export class AppelModalComponent implements OnInit {
  @Input() idBdc;

  commentaire;
  date;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
    this.date = moment().format("YYYY-MM-DD[T]HH:mm");
  }

  addAppel() {
    this.BdcService.newAppel(this.idBdc, this.commentaire, this.date).then((resp) => {
      this.activeModal.close();
    });
  }

  cancel() {
    this.activeModal.close();
  }
}
