<div class="card">

  <div class="card-body">
    <div class="row">

      <div class="col-6">

        <div id='map'></div>

      </div>

      <div class="col-6">
        <app-liste-bdc [listeBdc]="listeBdcAPlanifier" [tableId]="'tablePlanif1'" [datatables]="true"
          [colonnes]="'no_bdc date tel urgence objet_intervention'" [minimal]="true" [action]="true"
          (clicItem)="bdcSelected($event, undefined)">
        </app-liste-bdc>


      </div>

    </div>
  </div>

</div>




<div class="card">

  <div class="card-header">
    <h3 class="card-title" *ngIf="isSelectedBdc"><b>Bon n°{{selectedBdc.no_bdc}}</b> | {{selectedBdc.adresse_locataire}}
      {{selectedBdc.code_postal_locataire}} {{selectedBdc.ville_locataire}}</h3>

    <p class="card-title" *ngIf="!isSelectedBdc">Sélectionner un bon de commande dans la liste ci-dessus</p>

    <div class="card-options">

      <div id="draggableEvent">
        <div class="draggableEvent" *ngIf="draggableEvent.title != ''">{{draggableEvent.title}}</div>
      </div>

      <button class="btn btn-primary btn-sm" *ngIf="isSelectedBdc" (click)="goToBdc(selectedBdc.id)">Consulter</button>

      <a (click)="cardIsCollapsed = !cardIsCollapsed"
      [attr.aria-expanded]="!cardIsCollapsed" aria-controls="collapsableCard"><i class="fe fe-chevron-up"></i></a>

    </div>
  </div>

  <div class="card-body" id="collapsableCard" [ngbCollapse]="cardIsCollapsed">
    <p *ngIf="isSelectedBdc">
      <b>{{selectedBdc.bailleur}} - {{selectedBdc.marche}}</b> {{selectedBdc.client}}
    </p>

    <app-intervention-card *ngIf="isSelectedBdc" [bdc]="selectedBdc"></app-intervention-card>
  </div>

</div>





<div class="card">

  <div class="card-body">

    <div id="calendar"></div>

  </div>
</div>



<div class="card">

  <div class="card-body">

    <button class="btn btn-primary ml-auto float-right" (click)="savePlanif()">Sauver la planification</button>

  </div>
</div>















<!--

<div class="row">

  <div class="col-6">

    <div class="row">
      <div class="col-12">
        <div id='map'></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card" [hidden]="!isSelectedBdc">

          <div class="card-body">
            <p *ngIf="isSelectedBdc">
              <b>{{selectedBdc.adresse_locataire}} {{selectedBdc.code_postal_locataire}}
                {{selectedBdc.ville_locataire}}</b>
              <br>
              <b>{{selectedBdc.bailleur}} - {{selectedBdc.marche}}</b> {{selectedBdc.client}}

            </p>
            <div id="draggableEvent">
              <div class="draggableEvent" *ngIf="draggableEvent.title != ''">{{draggableEvent.title}}</div>
            </div>

            <app-intervention-card [bdc]="selectedBdc"></app-intervention-card>

            <button class="btn btn-primary ml-auto float-right" (click)="goToBdc(selectedBdc.id)">Consulter</button>


          </div>
        </div>

      </div>
    </div>

  </div>


  <div class="col-6">
    <div class="row">
      <div class="col-12">

        <div id="calendar"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary ml-auto float-right" (click)="savePlanif()">Sauver planification</button>
      </div>
    </div>

  </div>

</div>

-->
