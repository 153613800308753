import { Component, OnInit, Input, OnChanges } from '@angular/core'; 
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-appels',
  templateUrl: './modal-appels.component.html',
  styleUrls: ['./modal-appels.component.scss']
})
export class ModalAppelsComponent implements OnInit {
  @Input() idBdc;

  constructor ( public activeModal: NgbActiveModal ) {}

  ngOnInit() {
  }

}
