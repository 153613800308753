<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <div class="page-header">

        <h1 class="page-title" *ngIf="!edit">
          {{user.prenom}} {{user.nom}}
        </h1>

      </div>


      <div class="row row-cards">
        <div class="col-12">

          <form class="card">
            <div class="card-body">
              <h3 class="card-title">Changer le mot de passe</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Ancien mot de passe</label>
                    <input type="password" class="form-control" [(ngModel)]="old_pw" name="old_pw">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Nouveau mot de passe</label>
                    <input type="password" class="form-control" [(ngModel)]="new_pw" name="new_pw">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label">Nouveau mot de passe</label>
                    <input type="password" class="form-control" [(ngModel)]="new_pw_2" name="new_pw_2">
                  </div>
                </div>

              </div>

              <div class="text-right">
                <button type="submit" class="btn btn-primary" (click)="change_pw()">Changer le mot de passe</button>
              </div>
            </div>

          </form>


          <form class="card">
            <div class="card-body">
              <h3 class="card-title">Rapports par mail : agence de {{agence.nom}} 
                <span class="fe fe-help-circle" data-toggle="tooltip" data-placement="right" title="Il n'est possible d'avoir les rapports que d'une seule agence à la fois"></span>
              </h3>
              <div class="row">
               
                <div class="col-md-3">
                  <b>Planification difficile</b>
                </div>

                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="planifdiff-daily"
                    name="planifdiff-daily" [(ngModel)]="notifs_displayed.planification_difficile.daily">
                    <label class="custom-control-label" for="planifdiff-daily">Quotidien</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="planifdiff-weekly" 
                    name="planifdiff-weekly" [(ngModel)]="notifs_displayed.planification_difficile.weekly">
                    <label class="custom-control-label" for="planifdiff-weekly">Hebdomadaire</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="planifdiff-monthly"
                    name="planifdiff-monthly" [(ngModel)]="notifs_displayed.planification_difficile.monthly">
                    <label class="custom-control-label" for="planifdiff-monthly">Mensuel</label>
                  </div>
                </div>

              </div>

              <div class="row">
               
                <div class="col-md-3">
                  <b>Rapports en retard</b>
                </div>

                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="latereport-daily" 
                    name="latereport-daily" [(ngModel)]="notifs_displayed.rapports_retard.daily">
                    <label class="custom-control-label" for="latereport-daily">Quotidien</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="latereport-weekly" 
                    name="latereport-weekly" [(ngModel)]="notifs_displayed.rapports_retard.weekly">
                    <label class="custom-control-label" for="latereport-weekly">Hebdomadaire</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="latereport-monthly" 
                    name="latereport-monthly" [(ngModel)]="notifs_displayed.rapports_retard.monthly">
                    <label class="custom-control-label" for="latereport-monthly">Mensuel</label>
                  </div>
                </div>

              </div>

              <br>

              <div class="text-right">
                <button type="submit" class="btn btn-primary" (click)="update_reports()">Enregistrer</button>
              </div>
            </div>

          </form>

        </div>

      </div>


    </div>
  </div>
</div>
