import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { BdcService } from "../../services/service-bdc.service";

@Component({
  selector: "app-regulation",
  templateUrl: "./regulation.component.html",
  styleUrls: ["./regulation.component.scss"],
})
export class RegulationComponent implements OnInit {
  regul = [];
  chargesSecteur = [];
  bons = [];

  constructor(private BdcService: BdcService, private router: Router) {}

  ngOnInit() {
    this.BdcService.getRegul().then((regul: any) => {
      this.regul = [];

      for (let i = 0; i < regul.length; i++) {
        if (regul[i].actif == 1) {
          this.regul.push(regul[i]);
        }
      }

      console.log(this.regul);

      for (let i = 0; i < this.regul.length; i++) {
        if (i === 0) {
          this.regul[i].selected = true;
        } else this.regul[i].selected = false;
      }

      this.getChargesSecteur();

      console.log(this.regul);
    });
  }

  selectBailleur(id) {
    this.regul.find((elt) => elt.selected).selected = false;
    this.regul.find((elt) => elt.id === id).selected = true;

    this.getChargesSecteur();
  }

  getChargesSecteur() {
    let totalChargesSecteur = this.regul.find((elt) => elt.selected)
      .charges_secteur;

    this.chargesSecteur = [];

    for (let i = 0; i < totalChargesSecteur.length; i++) {
      if (totalChargesSecteur[i].actif == 1) {
        this.chargesSecteur.push(totalChargesSecteur[i]);
      }
    }

    if (this.chargesSecteur.length > 0) {
      for (let i = 0; i < this.chargesSecteur.length; i++) {
        if (i === 0) {
          this.chargesSecteur[i].selected = true;
        } else this.chargesSecteur[i].selected = false;
      }

      this.getBons();
    } else {
      this.bons = [];
    }
  }

  selectChargeSecteur(id) {
    this.chargesSecteur.find((elt) => elt.selected).selected = false;
    this.chargesSecteur.find((elt) => elt.id === id).selected = true;

    this.getBons();
  }

  getBons() {
    this.bons = this.chargesSecteur.find((elt) => elt.selected).regulation.bons;
  }

  clicBdc(bdc) {
    //console.log(bdc.status);

    switch (bdc.status) {
      case "": {
        this.router.navigate(["bons-de-commande/validation/" + bdc.id]);
        break;
      }
      case "A_VALIDER": {
        this.router.navigate(["bons-de-commande/validation/" + bdc.id]);
        break;
      }
      case "A_PLANIFIER": {
        this.router.navigate(["bons-de-commande/planification/" + bdc.id]);
        break;
      }
      case "A_EXECUTER": {
        this.router.navigate(["bons-de-commande/intervention/" + bdc.id]);
        break;
      }
      case "A_FACTURER": {
        this.router.navigate(["bons-de-commande/facturation/" + bdc.id]);
        break;
      }
      case "FACTURE": {
        this.router.navigate(["bons-de-commande/post-facturation/" + bdc.id]);
        break;
      }
      case "ARCHIVE": {
        this.router.navigate(["bons-de-commande/historique/" + bdc.id]);
        break;
      }
      case "CORBEILLE": {
        //statements;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }
}
