import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare var PDFObject: any;

@Component({
  selector: 'app-valider-piece-jointe',
  templateUrl: './valider-piece-jointe.component.html',
  styleUrls: ['./valider-piece-jointe.component.scss']
})
export class ValiderPieceJointeComponent implements OnInit, OnChanges {

  @Input() pjAValider = [];
  tabPjAValider = []
  savedFilesCounter = 0;

  @Output() fichiersEvent = new EventEmitter<any[]>();

  tabFiles = [];
  filesCounter = 0;

  displayedFile = {
    show: false,
    file: null
  }

  constructor() { }

  ngOnInit() {
    const that = this;

    let fileElem = document.getElementById("file");
    fileElem.addEventListener("change", handleFiles, false);

    function handleFiles() {

      if (!this.files.length) {
      } else {

        for (let i = 0; i < this.files.length; i++) {
          that.tabFiles.push({
            id: that.filesCounter,
            nom: this.files[i].name,
            description: '',
            fichier: this.files[i]
          });

          that.clicItem(that.filesCounter)

          that.filesCounter++;

          that.sendFiles();
        }
      }
    }

    this.sendFiles();
  }

  ngOnChanges() {
    console.log(this.pjAValider)

    this.sendFiles();

    if(this.pjAValider != undefined) {
      this.tabPjAValider = this.pjAValider.slice();

      if(this.tabPjAValider.length != 0) {
        this.clicItemSaved(this.tabPjAValider[0].id);
      }

      this.fichiersEvent.emit({
        new: this.tabFiles,
        old: this.tabPjAValider
      } as any);
    }
  }

  /*
  ** ANCIENS
  */

 clicItemSaved(id) {
  const fichier = this.tabPjAValider.find(elt => elt.id == id);

  this.displayedFile.show = true;
  this.displayedFile.file = fichier

  PDFObject.embed(environment.pdf_folder + fichier.url, '.pdf', {
    height: '700px'
  });

  //console.log(fichier.fichier);
}

deleteSaved(id) {
  const fichier = this.tabPjAValider.find(elt => elt.id == id);

  const index = this.tabPjAValider.indexOf(fichier);
  if (index > -1) {
    this.tabPjAValider.splice(index, 1);
  }

  //console.log(this.tabPjAValider)

  this.sendFiles();
}



  /*
  ** NOUVEAUX
  */

  clicItem(id) {
    const fichier = this.tabFiles.find(elt => elt.id == id);

    this.displayedFile.show = true;
    this.displayedFile.file = fichier

    PDFObject.embed(URL.createObjectURL(fichier.fichier), '.pdf', {
      height: '700px'
    });

    //console.log(fichier.fichier);
  }

  delete(id) {
    const fichier = this.tabFiles.find(elt => elt.id == id);

    const index = this.tabFiles.indexOf(fichier);
    if (index > -1) {
      this.tabFiles.splice(index, 1);
    }

    //console.log(this.tabFiles)

    this.sendFiles();
  }

  change($event) {
    this.sendFiles();
  }

  sendFiles() {
    this.fichiersEvent.emit({
      new: this.tabFiles,
      old: this.tabPjAValider
    } as any);
  }

}
