import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';

import * as codesPostaux from "codes-postaux";

import {
  BdcService
} from '../../../services/service-bdc.service';
import { AddArticleModalComponent } from '../../../components/add-article-modal/add-article-modal.component';
import { ModalMultipleNoBdcComponent } from '../../components/modal-multiple-no-bdc/modal-multiple-no-bdc.component';
import { ModalMultipleNoAffaireComponent } from '../../components/modal-multiple-no-affaire/modal-multiple-no-affaire.component';
import { LogementsSimilairesComponent } from '../../components/logements-similaires/logements-similaires.component';

import { ModalBonInTableurComponent } from '../../tableur-bdc/modal-bon-in-tableur/modal-bon-in-tableur.component';
import { ModalNewChargeIncontextComponent } from '../../components/modal-new-charge-incontext/modal-new-charge-incontext.component';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {

  bdc: any = {
    adresse_locataire: '',
    articles: [{}],
    avis_passage: '',
    avis_passage_exists: false,
    bailleur: '',
    client: '',
    code_postal_locataire: '',
    cpt_adresse_locataire: '',
    date: '',
    date_reception: '',
    date_limite: '',
    id: '',
    id_bailleur: '',
    id_charge_secteur: '',
    id_marche: '',
    lien_pdf: '',
    marche: '',
    moyen_demande: '',
    no_affaire: '',
    no_bdc: '',
    no_devis: '',
    no_quitus: '',
    no_assurance: '',
    nom_locataire: '',
    objet_intervention: '',
    prix: '',
    tel_fixe_locataire: '',
    tel_portable_locataire: '',
    urgence: 1,
    ville_locataire: ''
  };
  clients;
  form: FormGroup;

  positionLogement = {
    coordinates: [5.703130, 45.200103],
    type: "Point"
  };
  geojson = {
    type: 'geojson',
    data: {
      'type': 'FeatureCollection',
      'features': []
    }
  };
  geojsonS = '';
  adresse_formatee = '';
  infosCodePostal = [];

  isDoc = false;

  logementsSimilaires = [];

  piecesJointes = {
    old: [],
    new: []
  }

  state = 2;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private BdcService: BdcService, private route: ActivatedRoute, private router: Router) {
    route.params.subscribe(params => {
      this.init ();
    });
  }

  ngOnInit() {
    //this.init ();
  }

  init () {
    this.BdcService.getBdc(this.route.snapshot.params['idBdc']).then((bdc) => {

      this.bdc = bdc;

      this.bdc.date_reception = moment(this.bdc.date_reception, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
      this.bdc.date_limite = moment(this.bdc.date_limite, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');

      //console.log(bdc)

      this.BdcService.getListeClients().then((clients: any) => {

        this.clients = [];

        for (let i = 0; i < clients.length; i++) {
          if(clients[i].actif == 1) {
            this.clients.push(clients[i]);
          }
        }

        this.clientsOk();
      });

      this.bdcOk();
    });
  }

  bdcOk() {
    let that = this;

    (<any>$('#tel-fixe')).mask('99-99-99-99-99', {
      placeholder: 'Téléphone fixe'
    });
    (<any>$('#tel-portable')).mask('99-99-99-99-99', {
      placeholder: 'Téléphone portable'
    });

    const selectize_moyen_demande = (<any>$('#select-moyen-demande')).selectize({})[0].selectize;

    const tabMoyensDemande = [{
      id: 1,
      nom: 'Mail',
      code: 'MAIL'
    }, {
      id: 2,
      nom: 'Téléphone',
      code: 'TEL'
    }, {
      id: 3,
      nom: 'Courrier',
      code: 'COURRIER'
    }, {
      id: 4,
      nom: 'Fax',
      code: 'FAX'
    }];

    this.BdcService.maj_select('#select-moyen-demande', tabMoyensDemande);

    $('#select-moyen-demande').change(function () {
      var result = ""

      if($(this).val() != "")
      {

        if (($(this).val() == "MAIL") || ($(this).val() == "TEL") || ($(this).val() == "COURRIER") || ($(this).val() == "FAX")) {
          result = $(this).val();
        }
        else if ($(this).val() == 1) result = "MAIL"
        else if ($(this).val() == 2) result = "TEL"
        else if ($(this).val() == 3) result = "COURRIER"
        else if ($(this).val() == 4) result = "FAX"

        that.bdc.moyen_demande = result;
      }
    });

    selectize_moyen_demande.setValue(tabMoyensDemande.find(elt => elt.code == that.bdc.moyen_demande).id, false);

    const selectize_urgence = (<any>$('#select-urgence')).selectize({
      render: {
        option: function (item, escape) {

          let bell = "<span>";

          if (item.value === 1) {
            bell = '<span class="fa fa-bell" style:"float: right"></span>'
          }
          else if (item.value === 2) {
            bell = '<span class="fa fa-bell evwarning" style:"float: right"></span>'
          }
          else if (item.value === 3) {
            bell = '<span class="fa fa-bell evimportant" style:"float: right"></span>'
          }

          return "<div>" + bell + ' ' + escape(item.text) + "</div>";
        }
      }
    })[0].selectize;

    this.BdcService.maj_select('#select-urgence', [{
      id: 1,
      nom: "Pas d'urgence"
    }, {
      id: 2,
      nom: 'A traiter dans la semaine'
    }, {
      id: 3,
      nom: 'A traiter immédiatement'
    }]);

    $('#select-urgence').change(function () {
      that.bdc.urgence = parseInt($(this).val() as string);
    });

    selectize_urgence.setValue(this.bdc.urgence, false);


    (<any>$('#date')).mask('99/99/9999', {
      placeholder: 'jj/mm/aaaa'
    });

    $('#date').change(function () {
      that.BdcService.formatDate($(this).val())
    });

    this.addressChange();
  }

  clientsOk() {

    (<any>$('#select-bailleur')).selectize({});
    (<any>$('#select-marche')).selectize({});
    (<any>$('#select-charge')).selectize({});

    this.BdcService.maj_select("#select-bailleur", this.clients);
    this.BdcService.set_selectize('#select-bailleur', this.bdc.id_bailleur);

    this.majListeMarches();
    this.majListeChargesSecteur();

    let that = this;

    $('#select-bailleur').change(function () {
      ////console.log($(this).val())

      that.bdc.id_bailleur = $(this).val();

      that.majListeMarches();
    });

    $('#select-marche').change(function () {

      that.bdc.id_marche = $(this).val();
      that.majListeChargesSecteur();
    });

    $('#select-charge').change(function () {
      that.bdc.id_charge_secteur = $(this).val();
    });

    // Sélection de la ville
    (<any>$("#select-ville")).selectize({});
    this.BdcService.maj_select("#select-ville", []);
    this.BdcService.set_selectize("#select-ville", 0);
    $("#select-ville").change(function () {
      let ville = that.infosCodePostal.find((item) => {
        return item.id == $(this).val();
      });

      that.bdc.ville_locataire = ville.nomCommune;

      that.addressChange();
    });
  }

  // Articles
  showArticles() {

    if(this.bdc.commande === undefined) {
      return false;
    }
    else return this.bdc.commande.articles !== [];
  }

  totalArticles() {
    const prix = {
      ht: 0
    };

    for (const article of this.bdc.commande.articles) {
      prix.ht += parseFloat(article.prix_ht) * article.nb;
    }

    return prix;
  }

  articlesModal() {
    const modalRef = this.modalService.open(AddArticleModalComponent, { size: 'lg' })
    modalRef.componentInstance.bailleur = this.bdc.id_bailleur;
    modalRef.componentInstance.marche_init = this.bdc.id_marche;

    modalRef.result.then((result) => {
      result.article.nb = 1;
      this.bdc.commande.articles.push(result.article);
    }, (reason) => {
    });

  }

  supprimerArticle(id) {
    for (let i = 0; i < this.bdc.commande.articles.length; i++) {

      if (this.bdc.commande.articles[i].id === id) {
        this.bdc.commande.articles.splice(i, 1);
      }
    }
  }

  noBdcChange() {

    if (this.bdc.no_bdc !== '') {
      this.BdcService.searchNoBdc(this.bdc.no_bdc, this.bdc.id_bailleur).then((resp: any) => {
        if (resp.length != 0 && resp[0].id != this.bdc.id) {
          const modalRef = this.modalService.open(ModalMultipleNoBdcComponent);
          modalRef.componentInstance.idBdc = resp[0].id;
          modalRef.componentInstance.status = resp[0].status;

          modalRef.result.then((result) => {
          }, (reason) => {
          });
        }
      });
    }
  }

  noAffaireChange() {

    if (this.bdc.no_affaire !== '') {
      this.BdcService.searchNoAffaire(this.bdc.no_affaire).then((resp: any) => {
        if (resp.length != 0 && resp[0].id != this.bdc.id) {
          const modalRef = this.modalService.open(ModalMultipleNoAffaireComponent);
          modalRef.componentInstance.idBdc = resp[0].id;
          modalRef.componentInstance.status = resp[0].status;

          modalRef.result.then((result) => {
          }, (reason) => {
          });
        }
      });
    }
  }

  // Adresse
  codePostalChange() {
    if (this.bdc.code_postal_locataire !== "") {
      let infosCodePostal = codesPostaux.find(this.bdc.code_postal_locataire);

      this.infosCodePostal = infosCodePostal.map((item, index) => {
        item.id = index;
        item.nom = item.nomCommune;
        return item;
      });

      if (this.infosCodePostal.length != 0) {
        this.BdcService.maj_select("#select-ville", this.infosCodePostal);
        this.BdcService.set_selectize("#select-ville", 0);
      }
    }

    this.addressChange();
  }

  addressChange() {
    const url = encodeURI(this.bdc.adresse_locataire + ' ' + this.bdc.code_postal_locataire + ' ' + this.bdc.ville_locataire);

    this.BdcService.geoCoding(url).then((resp: any) => {
      if(resp.features.length != 0) {
        this.geojson.data.features.push(resp.features[0]);

        resp.features[0].properties['marker-symbol'] = 'monument';

        this.geojsonS = resp.features[0];

        this.positionLogement = resp.features[0].geometry;
        //console.log(this.positionLogement)

        this.adresse_formatee = resp.features[0].place_name;

        console.log(this.adresse_formatee)

        //console.log(resp.features[0].place_name)
        this.BdcService.getLogementsAdresse(resp.features[0].place_name).then((resp) => {
          //console.log(resp)
          this.logementsSimilaires = resp as any;
        })
      }
    });
  }

  findLogementsSimilaires() {
    if(this.logementsSimilaires.length != 0) {
      const modalRef = this.modalService.open(LogementsSimilairesComponent, { size: 'lg' });
      modalRef.componentInstance.adressesSimilaires = this.logementsSimilaires;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    }
  }

  clicBdc(idBdc) {
    //console.log("clic "+idBdc)
    this.router.navigate(['bons-de-commande/validation/' + idBdc]);
  }

  // Formulaire
  createForm() {
    this.form = this.fb.group({
      fichier: null
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get('fichier').setValue(file);
    }
  }

  private prepareSave(): any {
    let post_articles: any = [];
    for (var i = 0; i < this.bdc.commande.articles.length; i++) {
      post_articles.push({

        id: this.bdc.commande.articles[i].id,

        nb: this.bdc.commande.articles[i].nb
      });
    }

    post_articles = JSON.stringify(post_articles);

    let input = new FormData();

    input.append('id', this.bdc.id);
    input.append('bailleur', this.bdc.id_bailleur);
    input.append('marche', this.bdc.id_marche);
    input.append('charge', this.bdc.id_charge_secteur);
    input.append('moyen-demande', this.bdc.moyen_demande);
    input.append('no-quitus', this.bdc.no_quitus);
    input.append('no-affaire', this.bdc.no_affaire);
    input.append('no-bdc', this.bdc.no_bdc);
    input.append('no-devis', this.bdc.no_devis);
    input.append('no-assurance', this.bdc.no_assurance);
    input.append('date-reception', this.BdcService.formatDate(this.bdc.date_reception));
    input.append('date-echeance', this.BdcService.formatDate(this.bdc.date_limite));
    input.append('urgence', this.bdc.urgence);
    input.append('nom-locataire', this.bdc.nom_locataire);
    input.append('adresse-locataire', this.bdc.adresse_locataire);
    input.append('code-postal-locataire', this.bdc.code_postal_locataire);
    input.append('ville-locataire', this.bdc.ville_locataire);
    input.append('complement-adresse-locataire', this.bdc.cpt_adresse_locataire);
    input.append('tel-fixe-locataire', this.bdc.tel_fixe_locataire);
    input.append('tel-portable-locataire', this.bdc.tel_portable_locataire);
    input.append('objet-intervention', this.bdc.objet_intervention);
    input.append('prix', this.bdc.prix);

    input.append('liste_articles', post_articles);
    //input.append('fichier', this.form.get('fichier').value);

    input.append('adresse-formatee', this.adresse_formatee);

    return input;
  }







  majPiecesJointes(pj) {
    this.piecesJointes = pj;

    console.log(this.piecesJointes);
  }









  private prepareSavePJ(idBdc, nom, description, fichier): any {
    let input = new FormData();

    input.append('id_bdc', idBdc);
    input.append('nom', nom);
    input.append('description', description);

    console.log(fichier)

    input.append('fichier', fichier);

    return input;
  }

  validerBdc() {
    const formModel = this.prepareSave();

    // Update old
    for (let i = 0; i < this.piecesJointes.old.length; i++) {
      console.log(this.piecesJointes.old[i])
      this.BdcService.updatePJ(this.piecesJointes.old[i].id, this.piecesJointes.old[i].nom, this.piecesJointes.old[i].description);
    }

    console.log(this.bdc.pieces_jointes)

    // Delete
    // Pour chaque pj récupérée dans get_bdc (toutes celles en bdd)
    for (let i = 0; i < this.bdc.pieces_jointes.length; i++) {
      console.log(this.bdc.pieces_jointes[i].id) // Id de la pièce jointe déjà enregistrée

      // Recherche de la pièce jointe dans le tableau actuel de pièces jointes
      console.log(this.piecesJointes.old.find(element => element.id == this.bdc.pieces_jointes[i].id))

      // Si la pj n'est pas trouvée, c'est qu'elle est à supprimer
      if (this.piecesJointes.old.find(element => element.id == this.bdc.pieces_jointes[i].id) == undefined) {
        console.log(this.bdc.pieces_jointes[i].id)
        this.BdcService.deletePJ(this.bdc.pieces_jointes[i].id);
      }
    }

    // New PJ
    for (let i = 0; i < this.piecesJointes.new.length; i++) {
      const formModelPJ = this.prepareSavePJ(this.bdc.id, this.piecesJointes.new[i].nom, this.piecesJointes.new[i].description, this.piecesJointes.new[i].fichier);
      this.BdcService.postPJ(formModelPJ).then((resp) => {
        console.log(resp);
      });
    }


    this.BdcService.validerBdc(formModel).then(() => {
      //this.router.navigate(['bons-de-commande/planification/' + this.bdc.id]);

      this.BdcService.getBdc(this.bdc.id).then((bdc) => {

        //this.router.navigate(["bons-de-commande/planification/" + idNewBdc]);
        this.router.navigate(["liste-globale"]);
        const modalRef = this.modalService.open(ModalBonInTableurComponent, { size: 'llg' });
        modalRef.componentInstance.bdc = bdc;
        modalRef.result.then((result) => {
            //this.init()
        }, (reason) => {
            //console.log(reason);
        });


      });
    });
  }










  newCharge() {
    const that = this;

    console.log(this.bdc.id_bailleur)
    console.log(this.bdc.id_marche)

    if (this.bdc.id_bailleur !== "" && this.bdc.id_marche !== "" && this.bdc.id_bailleur !== undefined && this.bdc.id_marche !== undefined && !isNaN(this.bdc.id_bailleur) && !isNaN(this.bdc.id_marche)) {

      const modalRef = this.modalService.open(ModalNewChargeIncontextComponent, { size: 'lg' });
      modalRef.componentInstance.idBailleur = this.bdc.id_bailleur;
      modalRef.componentInstance.idMarche = this.bdc.id_marche;
      modalRef.result.then((idChargeSecteur) => {
        console.log(idChargeSecteur);

        this.BdcService.getListeClients().then((clients: any) => {
          this.clients = [];

          for (let i = 0; i < clients.length; i++) {
            if (clients[i].actif == 1) {
              this.clients.push(clients[i]);
            }
          }

          this.bdc.id_charge_secteur = idChargeSecteur;

          that.majListeChargesSecteur();
        });

        //this.init()
      }, (reason) => {
        //console.log(reason);
      });

    }
    else {
      alert("Sélectionner un bailleur et un marché pour créer un nouveau chargé de secteur");
    }
  }











  supprimer() {
    this.BdcService.supprimerBdc(this.bdc.id).then(() => {
      this.router.navigate(['bons-de-commande/']);
    });
  }

  // Utilitaires
  get_moyen_demande(name) {
    const tabMoy = [{
      id: 1,
      nom: 'MAIL'
    }, {
      id: 2,
      nom: 'TEL'
    }, {
      id: 3,
      nom: 'COURRIER'
    }, {
      id: 4,
      nom: 'FAX'
    }];

    for (var i = 0; i < tabMoy.length; i++) {
      if (tabMoy[i].nom === name) {
        return tabMoy[i].id;
      }
    }
  }

  majListeMarches() {
    const listeMarches = this.getMarche(this.bdc.id_bailleur);
    this.BdcService.maj_select('#select-marche', listeMarches);
    this.BdcService.set_selectize('#select-marche', this.bdc.id_marche); // On sélectionne le marché courant
  }

  majListeChargesSecteur() {
    const listeCharges = this.getCharge(this.bdc.id_marche, this.getMarche(this.bdc.id_bailleur)).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });;
    this.BdcService.maj_select("#select-charge", listeCharges);
    this.BdcService.set_selectize('#select-charge', this.bdc.id_charge_secteur);
  }

  getMarche = (idBailleur) => {
    for (const bailleur of this.clients) {
      if (bailleur.id === idBailleur) {
        let marches = [];

        for (let i = 0; i < bailleur.marches.length; i++) {
          if(bailleur.marches[i].actif == 1) {
            marches.push(bailleur.marches[i]);
          }
        }

        return marches;
      }
    }
    return [];
  }

  getCharge = (idMarche, listeMarches) => {
    for (const marche of listeMarches) {
      if (marche.id === idMarche) {
        return marche.charges_secteur;
      }
    }
    return [];
  }

  changeState(state) {
    this.state = state;
  }
}


