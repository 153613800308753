import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import {
  BdcService
} from '../../../../services/service-bdc.service';
import {
  ListeAppelsModalComponent
} from '../liste-appels-modal/liste-appels-modal.component';
import { AvisPassageComponent } from '../avis-passage/avis-passage.component';
import { AddPhotoComponent } from '../add-photo/add-photo.component';
import { PhotoViewerComponent } from '../../../../components/photo-viewer/photo-viewer.component';
import { WindowScrollController } from '@fullcalendar/core';


declare var $: any;


@Component({
  selector: 'app-quitus',
  templateUrl: './quitus.component.html',
  styleUrls: ['./quitus.component.scss']
})
export class QuitusComponent implements OnInit {
  bdc: any = {
    adresse_locataire: '',
    articles: [{}],
    avis_passage: '',
    avis_passage_exists: false,
    appels: [],
    bailleur: '',
    client: '',
    code_postal_locataire: '',
    cpt_adresse_locataire: '',
    date: '',
    date_reception: "",
    date_limite: '',
    id: '',
    id_bailleur: '',
    id_charge_secteur: '',
    id_marche: '',
    lien_pdf: '',
    marche: '',
    moyen_demande: '',
    no_affaire: '',
    no_bdc: '',
    no_devis: '',
    no_quitus: '',
    nom_locataire: '',
    objet_intervention: '',
    prix: '',
    tel_fixe_locataire: '',
    tel_portable_locataire: '',
    urgence: 1,
    ville_locataire: ''
  };
  clients;
  isArticles;
  pourcentage;
  planification;
  techniciens;
  photos = {
    avant: [],
    apres: []
  };

  form: FormGroup;

  dateProgressStyle = {
    width: '0%'
  };

  date = '';
  date_reception = '';
  date_limite = '';

  constructor(private fb: FormBuilder, private router: Router, private modalService: NgbModal, public BdcService: BdcService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getBdc();
  }

  getBdc() {
    this.BdcService.getBdc(this.route.snapshot.params.idBdc).then((bdc) => {
      this.bdc = bdc;
      this.bdc.appels = [];
      this.datePourcentage();

      this.date = this.BdcService.dateCompacte(this.bdc.date);
      this.date_limite = this.BdcService.dateCompacte(this.bdc.date_limite);

      this.BdcService.getPlanifBdc(this.route.snapshot.params.idBdc).then((planif) => {

        const groupByTech = function (array) {
          let names_object = array.reduce((objectsByKeyValue, obj) => {
            obj.prenom_nom = obj.prenom + ' ' + obj.nom
            const value = obj['prenom_nom'];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});

          return Array.from(Object.keys(names_object), x => {
            return {
              nom: x,
              planif: names_object[x]
            };
          });
        };

        this.techniciens = groupByTech(planif);
      });
    });
  }

  quitus() {
    window.print();
  }

  isArticlesFct() {
    if (this.bdc.appels.length === 0) {
      this.isArticles = false;
    } else {
      this.isArticles = true;
    }
  }

  // Articles
  showArticles() {
    return this.bdc.articles !== [];
  }

  totalArticles() {
    const prix = {
      ht: 0
    };

    for (const article of this.bdc.articles) {
      prix.ht += parseFloat(article.prix_ht) * article.nb;
    }

    return prix;
  }

  // Utilitaires
  get_moyen_demande(name) {
    const tabMoy = [{
      id: 1,
      nom: 'MAIL'
    }, {
      id: 2,
      nom: 'TEL'
    }, {
      id: 3,
      nom: 'COURRIER'
    }, {
      id: 4,
      nom: 'FAX'
    }];

    for (var i = 0; i < tabMoy.length; i++) {
      if (tabMoy[i].nom === name) {
        return tabMoy[i].id;
      }
    }
  }

  majListeMarches() {
    const listeMarches = this.getMarche(this.bdc.id_bailleur);
    this.BdcService.maj_select('#select-marche', listeMarches);
    this.BdcService.set_selectize('#select-marche', this.bdc.id_marche); // On sélectionne le marché courant
  }

  majListeChargesSecteur() {
    const listeCharges = this.getCharge(this.bdc.id_marche, this.getMarche(this.bdc.id_bailleur));
    this.BdcService.maj_select('#select-charge', listeCharges);
    this.BdcService.set_selectize('#select-charge', this.bdc.id_charge_secteur);
  }

  getMarche = (idBailleur) => {
    for (const bailleur of this.clients) {
      if (bailleur.id === idBailleur) {
        return bailleur.marches;
      }
    }
    return [];
  }

  getCharge = (idMarche, listeMarches) => {
    for (const marche of listeMarches) {
      if (marche.id === idMarche) {
        return marche.charges_secteur;
      }
    }
    return [];
  }

  datePourcentage() {
    let dateNow = new Date();
    let dateCreation = new Date(this.bdc.date);
    let dateFin = new Date(this.bdc.date_limite);


    if (dateNow.getTime() > dateFin.getTime()) {
      this.dateProgressStyle.width = '100%';
    } else {
      let duree = dateFin.getTime() - dateCreation.getTime();
      let ecoule = dateNow.getTime() - dateCreation.getTime();

      let percent = (ecoule * 100) / duree;
      percent = percent <= 100 ? percent : 100;
      percent = Math.floor(percent);

      this.dateProgressStyle.width = percent + '%';
    }
  }



}
