<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouvelle liste d'articles</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-2 col-form-label">
      Se baser sur
    </div>
    <div class="col-4">

      <div class="form-group">
        <select name="liste_articles" id="select-liste-modal" class="form-control custom-select selectized"
          tabindex="-1" style="display: none;">
        </select>
      </div>

    </div>

    <div class="col-1"></div>

    <div class="col-2">
      <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal" (click)="clear()">Réinitialiser</button>
    </div>

    <div class="col-3">
      <div class="input-group">
        <span class="input-group">

          <label for="file" class="btn btn-secondary btn-block"><span class="fe fe-file"></span> Importer CSV</label>
          <input id="file" class="input-file" type="file">

        </span>
      </div>
    </div>
  </div>



  <div class="row">
    <div class="col-2 col-form-label">
      Nom de la liste
    </div>
    <div class="col-4">

      <div class="form-group">
        <input type="text" class="form-control" name="nom-liste" [(ngModel)]="nom_liste" placeholder="Nom">
      </div>

    </div>
  </div>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-2 col-form-label">
      Ajouter un article
    </div>
    <div class="col-2">
      <div class="form-group">
        <input type="text" class="form-control" id="code-new-article" name="code-article" [(ngModel)]="code_new_article" placeholder="Code" (change)="code_change()" autocomplete="no">
        <div class="invalid-feedback">
          Ce code existe déjà
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <input type="text" class="form-control" name="nom-article" [(ngModel)]="nom_new_article" placeholder="Nom" autocomplete="no">
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <input type="number" class="form-control" name="prix-article" [(ngModel)]="prix_new_article" placeholder="Prix HT" autocomplete="no">
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <button type="button" class="btn btn-primary btn-block" data-dismiss="modal" (click)="add_article(code_new_article, nom_new_article, prix_new_article)">Ajouter</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-2 col-form-label">
      Modifier les prix
    </div>

    <div class="col-4">
      <div class="form-group">
        <input type="number" class="form-control" name="aug-pc" [(ngModel)]="augmentation" placeholder="Augmentation (%)" autocomplete="no">
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <input type="number" class="form-control" name="red-pc" [(ngModel)]="reduction" placeholder="Réduction (%)" autocomplete="no">
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <button type="button" class="btn btn-primary btn-block" data-dismiss="modal" (click)="change_prix(augmentation, reduction)">Modifier</button>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table id="table-new-liste" class="table card-table table-hover table-vcenter">
      <thead>
        <tr>
          <th>Code</th>
          <th>Nom</th>
          <th>Prix HT</th>
          <th></th>
        </tr>
      </thead>
      <!--<tbody>
        <tr *ngFor="let article of articles">
          <td>
            <span>{{article.code_article}}</span>
          </td>

          <td>
            <span>{{article.nom}}</span>
          </td>

          <td>
            <span>{{article.prix_ht}}€</span>
          </td>

          <td class="text-muted trash-link">
            <i class="fe fe-trash" (click)="supprimerArticle(article.code_article)"></i>
          </td>
        </tr>
      </tbody>-->
    </table>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="postList()">Enregistrer</button>
</div>
