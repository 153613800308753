<div class="modal-header">
  <h5 class="modal-title" id="addArticleModalLabel">Photo</h5>
</div>
<div class="modal-body">

    <div class="clearfix">
        <div class="float-left">
            <div *ngIf="showDelete">
                <button type="button" class="btn btn-danger" (click)="delete_photo()">Supprimer</button>
            </div>
        </div>
        <div class="float-right">
            <p class="text-muted dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false"></p>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <button class="dropdown-item btn-danger" type="button" (click)="delete()"><i
                  class="fe fe-trash-2"></i> Supprimer</button>
            </div>
          </div>
        
        
      </div>

      <img src="{{url}}" />


  
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Retour</button>
</div>
