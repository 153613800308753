<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="row row-cards row-deck">
        <div class="col-12">

          <!--<div class="d-flex" style="margin-bottom:12px">

            <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[9]" [center]="[5.703130, 45.200103]">
              <mgl-image id="cat"
                url="https://cdn0.iconfinder.com/data/icons/flat-color-1/100/sqi2016-flat-go-30-512.png"
                (loaded)="imageLoaded = true">
              </mgl-image>
              <mgl-layer *ngIf="imageLoaded" id="points" type="symbol" [source]="geojson"
                [layout]="{'icon-image': 'cat', 'icon-size': 0.05}">
              </mgl-layer>
            </mgl-map>

          </div>-->

          <div class="card">

            <app-liste-bdc [listeBdc]="listeInterventions" [tableId]="'listeInterventions'" [datatables]="true"
              [colonnes]="'id no_bdc client objet_intervention prix prix_real progression'"
              [select]="'planification'" (majRequest)="majListe()"></app-liste-bdc>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
