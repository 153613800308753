<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Associer un chargé de secteur</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="form-label">Chargé de secteur</label>
    <select name="charge" [(ngModel)]="charge" id="select-charge"
      class="form-control custom-select selectized" tabindex="-1" style="display: none;">
    </select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="addCharge()">Sauvegarder</button>
</div>
