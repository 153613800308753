<div class="modal-header">
  <h5 class="modal-title" id="addArticleModalLabel">Avis de passage</h5>
</div>
<div class="modal-body">
  <button type="button" class="btn btn-primary" (click)="add()">Ajouter un avis de passage</button>

  <table class="table card-table">
    <thead>
      <tr>
        <th class="w-1">Date</th>
        
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let avis of avisPassage" (click)="show(avis.id)">
        <td class="text-muted">Avis de passage du {{BdcService.dateCompacte(avis.date)}}</td>
      </tr>

    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Annuler</button>
</div>
