<div class="page-single">



  <div class="container">


    <div class="row">
      <div class="col col-login mx-auto">
        <div class="text-center">
          <img src="assets/images/logo_ems_grand.png" class="" alt="" style="height: 200px; margin:50px;">
        </div>
        <form class="card">
          <div class="card-body p-6">
            <div class="card-title">Connexion</div>
            <div class="form-group">
              <label class="form-label">Adresse email</label>
              <input type="email" class="form-control" id="email-login" name="email" aria-describedby="emailHelp" placeholder="Adresse email" autocomplete="off" [(ngModel)]="email" required>
              <div class="invalid-feedback">
                Renseignez une adresse email.
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">
                Mot de passe
                <a href="#" class="float-right small" (click)="forgotPwd()">Mot de passe oublié</a>
              </label>
              <input type="password" class="form-control" id="pwd-login" name="password" placeholder="Password" autocomplete="off" [(ngModel)]="mdp">
              <div class="invalid-feedback">
                Entrez votre mot de passe.
              </div>
            </div>
            <!--<div class="form-group">
              <label class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input">
                <span class="custom-control-label">Remember me</span>
              </label>
            </div>-->
            <div class="form-footer">
              <button type="submit" class="btn btn-primary btn-block" (click)="login()">Connexion</button>
            </div>
          </div>
        </form>
        <!--<div class="text-center text-muted">
          Don't have account yet? <a href="./register.html">Sign up</a>
        </div>-->

        <div class="alert alert-primary show" role="alert" *ngIf="showAlertForgotPw">
          Un email vous a été envoyé avec le nouveau mot de passe.
        </div>
      </div>
    </div>



  </div>
</div>


