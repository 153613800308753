import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';


import {
  BdcService
} from '../../../services/service-bdc.service';
import { AvisPassageComponent } from './avis-passage/avis-passage.component';
import { ModalAppelsComponent } from '../../components/modal-appels/modal-appels.component';

declare var $: any;

@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.scss']
})
export class InterventionComponent implements OnInit {
  bdc: any = {
    adresse_locataire: '',
    articles: [{}],
    avis_passage: '',
    avis_passage_exists: false,
    appels: [],
    bailleur: '',
    client: '',
    code_postal_locataire: '',
    cpt_adresse_locataire: '',
    date: '',
    date_reception: "",
    date_limite: '',
    id: '',
    id_bailleur: '',
    id_charge_secteur: '',
    id_marche: '',
    lien_pdf: '',
    marche: '',
    moyen_demande: '',
    no_affaire: '',
    no_bdc: '',
    no_devis: '',
    no_quitus: '',
    nom_locataire: '',
    objet_intervention: '',
    prix: '',
    tel_fixe_locataire: '',
    tel_portable_locataire: '',
    urgence: 1,
    ville_locataire: '',
    piece_a_commander_commentaire: ''
  };

  constructor(private router: Router, private modalService: NgbModal, private BdcService: BdcService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getBdc();
  }

  getBdc() {
    this.BdcService.getBdc(this.route.snapshot.params.idBdc).then((bdc) => {
      this.bdc = bdc;
    });
  }


  // Appels
  appelsModal() {
    const modalRef = this.modalService.open(ModalAppelsComponent, { size: 'lg' });
      modalRef.componentInstance.idBdc = this.bdc.id;
      modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  // Avis de passage
  avisPassageModal() {
    const modalRef = this.modalService.open(AvisPassageComponent);
    modalRef.componentInstance.idBdc = this.bdc.id;
    modalRef.result.then((result) => {
      this.getBdc();
    }, (reason) => { });
  }

  eventFromInterventions(event) {
    this.bdc = event;

    console.log(event)
  }


  finIntervention() {
    if(this.bdc.interventions.liste.length > 0)
    {
      this.BdcService.bdcToFacturationState(this.bdc.id).then((res) => {
        this.router.navigate(['bons-de-commande/facturation/' + this.bdc.id]);
      });
    }
    else {
      alert("Aucune intervention n'a été réalisée !");
    }

  }

  backState() {
    this.BdcService.bdcToPlanificationState(this.bdc.id).then((res) => {
      this.router.navigate(['bons-de-commande/planification/' + this.bdc.id]);
    });
  }

}
