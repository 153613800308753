import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

import { AppelsCardModalComponent } from './appels-card-modal/appels-card-modal.component';

import * as moment from 'moment';

@Component({
  selector: 'app-appels-card',
  templateUrl: './appels-card.component.html',
  styleUrls: ['./appels-card.component.scss']
})
export class AppelsCardComponent implements OnInit, OnChanges {

  @Input() idBdc;

  appels: any;
  isAppels;

  constructor(private modalService: NgbModal, public BdcService: BdcService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {

    if((this.idBdc !== undefined) && (this.idBdc > 0)) {
      this.BdcService.getAppels(this.idBdc).then((appels: any) => {
        this.appels = appels.sort((a,b) => moment(a.date).diff(b.date));
        this.isAppelsFct();
      });
    }
  }

  // Affichage ou non des appels
  isAppelsFct() {
    if (this.appels.length === []) {
      this.isAppels = false;
    } else {
      this.isAppels = true;
    }
  }

  appelsModal() {
    // ouverture de la modal
    const modalRef = this.modalService.open(AppelsCardModalComponent);
    modalRef.componentInstance.idBdc = this.idBdc;

    modalRef.result.then((result) => {
      this.init()
    }, (reason) => {
      //console.log(reason);
    });
  }

  edit(com) {
    com.edit = true;
  }

  save_com(com) {
    this.BdcService.editAppel(com.id, com.commentaire).then((ret) => {
      this.init();
    });
  }

  delete(com) {
    com.delete = true;
  }

  delete_com(com) {
    this.BdcService.deleteAppel(com.id).then((ret) => {
      this.init();
    });
  }

  formatDate(date) {
    if (date === '0000-00-00 00:00:00') {
      return '--';
    } else {
      return moment(date).format('DD/MM/YYYY');
    }
  }

}
