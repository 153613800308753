import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { BdcService } from '../../services/service-bdc.service';

@Component({
  selector: 'app-liste-facturation',
  templateUrl: './liste-facturation.component.html',
  styleUrls: ['./liste-facturation.component.scss']
})
export class ListeFacturationComponent implements OnInit {

  listeFacturation = [];
  listeFacturationFaite = [];

  constructor(private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
    this.BdcService.getListFacturation().then((listeFacturation: any[]) => {
      this.listeFacturation = listeFacturation;
    });

    this.BdcService.getListFacturationFaite().then((facturationFaite: any) => {
      this.listeFacturationFaite = facturationFaite;
    });
  }

  export(list) {
    const rows = [
      ["Id quitus", "No de commande", "Bailleur", "Marche", "Charge de secteur", "Date de creation", "Objet d'intervention", "Prix HT"]
    ];

    for (let i = 0; i < list.length; i++) {
      const bon = list[i];

      rows.push([
        bon.id,
        bon.no_bdc,
        bon.bailleur,
        bon.marche,
        bon.client,
        bon.date,
        bon.objet_intervention,
        bon.interventions.prix_ht.toFixed(2)
      ])
    }

    this.exportToCsv("export_facturation.csv", rows);
  }

  exportAll() {
    this.export(this.listeFacturation);
  }

  exportSelected(list) {
    this.export(list);
  }

  exportToCsv(filename, rows) {
      var processRow = function (row) {
          var finalVal = '';
          for (var j = 0; j < row.length; j++) {
              var innerValue = row[j] === null ? '' : row[j].toString();
              if (row[j] instanceof Date) {
                  innerValue = row[j].toLocaleString();
              };
              var result = innerValue.replace(/"/g, '""');
              if (result.search(/("|,|\n)/g) >= 0)
                  result = '"' + result + '"';
              if (j > 0)
                  finalVal += ';';
              finalVal += result;
          }
          return finalVal + '\n';
      };

      var csvFile = '';
      for (var i = 0; i < rows.length; i++) {
          csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
  }

}
