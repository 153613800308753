import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from './services/service-bdc.service';
import {
  NotificationsService
} from './services/notifications.service';
import { AuthentificationService } from './services/authentification.service';

import { ModalNewBonComponent } from './bonsDeCommande/components/modal-new-bon/modal-new-bon.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges {
  title = 'emsSuite';

  nomAgence = '';

  searchText = '';

  showNavbar = true;

  user = {} as any;

  notif = [];

  constructor(private BdcService: BdcService, private router: Router, public NotificationsService: NotificationsService, public authService: AuthentificationService, private modalService: NgbModal) {
    this.router.events.subscribe(event => {

      if(this.authService.isLogged()){

        if (event instanceof NavigationStart) {

          if ((<any>event).url.split("/")[1] === 'quitus') {
            this.showNavbar = false;
          }
          else if ((<any>event).url.split("/")[1] === 'facture') {
            this.showNavbar = false;
          }
          else {
            console.log("Show true")
            this.showNavbar = true;
          }
        }

      }
      else  { // Si pas de login
        this.showNavbar = false;
      }
    })
  }

  ngOnInit() {
    const that = this;

    this.user = JSON.parse(this.authService.getUser());

    this.BdcService.getAgence(this.BdcService.getAgenceId()).then((agence: any) => {
      this.nomAgence = agence.nom;
    });

    this.NotificationsService.tickNotifs();

  }

  ngOnChanges () {

  }

  clicNotif(id) {
    console.log(id);

    this.NotificationsService.freeNotif(id);
  }

  changeAgence(idAgence) {
    this.BdcService.switchAgence(idAgence);
  }

  keyDownFunction(event) {

    if (event.keyCode === 13) {
      //console.log(event.target.value)
      //alert(event.target.value);

      this.router.navigate(['recherche/' + encodeURI(event.target.value)]);
    }
  }

  deconnexion() {
    this.authService.deconnexion();
  }

  newBon() {
    const modalRef = this.modalService.open(ModalNewBonComponent, { size: 'llg' });
    modalRef.result.then((result) => {
        //this.init()
    }, (reason) => {
        //console.log(reason);
    });
  }
}
