// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  api_endpoint: "https://lab.etiennevilledieu.fr/quentin/backend/api/",
  api_old_endpoint: 'https://lab.etiennevilledieu.fr/quentin/backend/',
  pdf_folder: 'https://lab.etiennevilledieu.fr/quentin/pdf/',
  signatures_folder: 'https://lab.etiennevilledieu.fr/quentin/signatures/',
  rapports_folder: 'https://lab.etiennevilledieu.fr/quentin/photos/rapports/',
  avis_folder: 'https://lab.etiennevilledieu.fr/quentin/avis/',
  app_mobile_folder: 'https://lab.etiennevilledieu.fr/quentin/mobile/'
};


/*
export const environment = {
  production: true,
  api_endpoint: "https://api.ems-suite.fr/api/",
  api_old_endpoint: "https://api.ems-suite.fr/",
  pdf_folder: 'https://files.ems-suite.fr/pdf/',
  signatures_folder: 'https://files.ems-suite.fr/signatures/',
  rapports_folder: 'https://files.ems-suite.fr/photos/rapports/',
  avis_folder: 'https://files.ems-suite.fr/photos/avis/'
};
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
