import {
  Component,
  OnInit,
  Input, ElementRef, ViewChild
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  BdcService
} from '../../services/service-bdc.service';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss']
})
export class PhotoViewerComponent implements OnInit {
  @Input() id;
  @Input() url;

  showDelete = false;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private BdcService: BdcService) {}

  ngOnInit() {
  }

  cancel() {
    this.activeModal.close();
  }

  delete() {
    this.showDelete = true;
  }

  delete_photo() {
    this.BdcService.deletePhoto(this.id).then((resp) => {
      this.activeModal.close();
    })
  }

}

