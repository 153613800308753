<div class="header d-lg-flex p-0" id="headerMenuCollapse" ng-show="onglets.bdc.active">
  <div class="container">
    <div class="row align-items-center d-flex">
      <div class="col-12 mx-auto order-lg-first">
        <ul class="nav nav-tabs border-0 flex-column flex-lg-row">


          <!--<li class="nav-item">
            <button data-toggle="dropdown" class="btn btn-sm btn-outline-primary my-2 my-sm-0"><i
                class="fe fe-plus"></i>
              Nouveau</button>

            <div class="dropdown-menu dropdown-menu-arrow">
              <a routerLink="/bons-de-commande/nouveau" class="dropdown-item ">
                <i class="fe fe-clipboard"></i> Bon de commande
              </a>
            </div>
          </li>-->

          <li class="nav-item">
            <button routerLink="/bons-de-commande/nouveau" class="btn btn-sm btn-outline-primary my-2 my-sm-0"><i
                class="fe fe-plus"></i>
              Nouveau</button>
          </li>

          <li class="nav-item">
            <a routerLink="/bons-de-commande/validation" routerLinkActive="active" class="nav-link">
              <i class="fe fe-check-square"></i>
              Validation <span class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_valider}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/bons-de-commande/planification" routerLinkActive="active" class="nav-link"><i
                class="fe fe-calendar"></i>
              Planification <span class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_planifier}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/bons-de-commande/intervention" routerLinkActive="active" class="nav-link">
              <i class="fe fe-truck"></i>
              Intervention <span class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_executer}}</span></a>
          </li>
          <li class="nav-item">
            <a routerLink="/bons-de-commande/facturation" routerLinkActive="active" class="nav-link">
              <i class="fe fe-dollar-sign"></i>
              Facturation <span class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_facturer}}</span></a>
          </li>

          <div class="d-flex order-lg-2 ml-auto">

            <li class="nav-item">
              <a routerLink="/bons-de-commande/en-attente" routerLinkActive="active" class="nav-link"><i
                  class="fe fe-alert-octagon"></i> En attente <span class="badge badge-pill badge-danger">
                    {{NotificationsService.getCompteurs().piece_a_commander + NotificationsService.getCompteurs().devis + NotificationsService.getCompteurs().anomalie}}
                  </span></a>
            </li>






            <li ngbDropdown class="nav-item">
              <a ngbDropdownToggle id="dropdownMoreBdc" href="#" class="nav-link"><i class="fe fe-more-vertical"></i></a>

              <div ngbDropdownMenu aria-labelledby="dropdownMoreBdc" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">

                <a ngbDropdownItem routerLink="/bons-de-commande/historique">
                  <i class="fe fe-book"></i> Historique
                </a>

                <a ngbDropdownItem routerLink="/bons-de-commande/corbeille">
                  <i class="fe fe-trash"></i> Corbeille
                </a>

              </div>
            </li>

          </div>



        </ul>
      </div>

    </div>
  </div>
</div>


<router-outlet></router-outlet>
