import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import * as codesPostaux from "codes-postaux";

import { BdcService } from "../../services/service-bdc.service";
import { AddArticleModalComponent } from "../../components/add-article-modal/add-article-modal.component";
import { ModalMultipleNoBdcComponent } from "../components/modal-multiple-no-bdc/modal-multiple-no-bdc.component";
import { ModalMultipleNoAffaireComponent } from "../components/modal-multiple-no-affaire/modal-multiple-no-affaire.component";
import { LogementsSimilairesComponent } from "../components/logements-similaires/logements-similaires.component";

import { geoJson } from "leaflet";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-nouveau-bdc",
  templateUrl: "./nouveau-bdc.component.html",
  styleUrls: ["./nouveau-bdc.component.scss"],
})
export class NouveauBdcComponent implements OnInit {
  bdc: any = {
    adresse_locataire: "",
    articles: [],
    avis_passage: "",
    avis_passage_exists: false,
    bailleur: "",
    client: "",
    code_postal_locataire: "",
    cpt_adresse_locataire: "",
    date: "",
    date_reception: "",
    date_limite: "",
    id: "",
    id_bailleur: "",
    id_charge_secteur: "",
    id_marche: "",
    lien_pdf: "",
    marche: "",
    moyen_demande: "MAIL",
    no_affaire: "",
    no_bdc: "",
    no_devis: "",
    no_quitus: "",
    no_assurance: "",
    nom_locataire: "",
    objet_intervention: "",
    prix: "",
    tel_fixe_locataire: "",
    tel_portable_locataire: "",
    urgence: 1,
    ville_locataire: "",
  };
  clients = [];
  form: FormGroup;

  positionLogement = {
    coordinates: [5.70313, 45.200103],
    type: "Point",
  };
  geojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  };
  geojsonS = "";
  adresse_formatee = "";
  infosCodePostal = [];

  logementsSimilaires = [];

  tabPiecesJointes = [];

  state = 2;

  @ViewChild("fileInput") fileInput: ElementRef;

  constructor(
    private modalService: NgbModal,
    private BdcService: BdcService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.bdc.date_reception = moment().format("YYYY-MM-DD");

    this.BdcService.getListeClients().then((clients: any) => {
      //console.log(clients)

      this.clients = [];

      for (let i = 0; i < clients.length; i++) {
        if (clients[i].actif == 1) {
          this.clients.push(clients[i]);
        }
      }

      this.bdc.id_bailleur = this.clients[0].id;
      this.clientsOk();
    });

    this.bdcOk();
  }

  bdcOk() {
    let that = this;

    (<any>$("#tel_fixe")).mask("99-99-99-99-99", {
      placeholder: "Téléphone fixe",
    });
    (<any>$("#tel_portable")).mask("99-99-99-99-99", {
      placeholder: "Téléphone portable",
    });

    // moyen de la demande
    (<any>$("#select-moyen-demande")).selectize({});
    this.BdcService.maj_select("#select-moyen-demande", [
      {
        id: 1,
        nom: "Mail",
      },
      {
        id: 2,
        nom: "Téléphone",
      },
      {
        id: 3,
        nom: "Courrier",
      },
      {
        id: 4,
        nom: "Fax",
      },
    ]);
    $("#select-moyen-demande").change(function () {
      var result = "";

      if ($(this).val() == 1) result = "MAIL";
      else if ($(this).val() == 2) result = "TEL";
      else if ($(this).val() == 3) result = "COURRIER";
      else if ($(this).val() == 4) result = "FAX";

      that.bdc.moyen_demande = result;
    });

    // Urgence
    (<any>$("#select-urgence")).selectize({
      render: {
        option: function (item, escape) {
          console.log(item);

          let bell = "<span>";

          if (item.value === 1) {
            bell = '<span class="fa fa-bell" style:"float: right"></span>';
          } else if (item.value === 2) {
            bell =
              '<span class="fa fa-bell evwarning" style:"float: right"></span>';
          } else if (item.value === 3) {
            bell =
              '<span class="fa fa-bell evimportant" style:"float: right"></span>';
          }

          return "<div>" + bell + " " + escape(item.text) + "</div>";
        },
      },
    });
    this.BdcService.maj_select("#select-urgence", [
      {
        id: 1,
        nom: "Pas d'urgence",
      },
      {
        id: 2,
        nom: "A traiter dans la semaine",
      },
      {
        id: 3,
        nom: "A traiter immédiatement",
      },
    ]);
    $("#select-urgence").change(function () {
      that.bdc.urgence = parseInt($(this).val() as string);
    });

    /*
    // Date limite
    (<any>$('#date')).mask('99/99/9999', {
      placeholder: 'jj/mm/aaaa'
    });
    $('#date').change(function () {
      //console.log($(this).val())
      that.bdc.date_limite = that.BdcService.formatDate($(this).val());
      //console.log(that.bdc.date_limite)
    });
    */
  }

  clientsOk() {
    let that = this;

    (<any>$("#select-bailleur")).selectize({});
    console.log(this.clients);
    this.BdcService.maj_select("#select-bailleur", this.clients);
    this.BdcService.set_selectize("#select-bailleur", this.bdc.id_bailleur);
    $("#select-bailleur").change(function () {
      that.bdc.id_bailleur = parseInt($(this).val() as string);
      //console.log(that.bdc.id_bailleur)
      that.majListeMarches();
    });

    (<any>$("#select-marche")).selectize({});
    $("#select-marche").change(function () {
      that.bdc.id_marche = parseInt($(this).val() as string);
      that.majListeChargesSecteur();
    });

    (<any>$("#select-charge")).selectize({});
    $("#select-charge").change(function () {
      that.bdc.id_charge_secteur = parseInt($(this).val() as string);
    });

    this.majListeMarches();
    this.majListeChargesSecteur();

    // Sélection de la ville
    (<any>$("#select-ville")).selectize({});
    this.BdcService.maj_select("#select-ville", []);
    this.BdcService.set_selectize("#select-ville", 0);
    $("#select-ville").change(function () {
      let ville = that.infosCodePostal.find((item) => {
        return item.id == $(this).val();
      });

      that.bdc.ville_locataire = ville.nomCommune;

      that.addressChange();
    });
  }

  // Articles
  showArticles() {
    return this.bdc.articles !== [];
  }

  totalArticles() {
    const prix = {
      ht: 0,
    };

    for (const article of this.bdc.articles) {


      prix.ht += parseFloat(article.prix_ht) * article.nb;

      //console.log(prix.ht)
    }

    //console.log("total")
    //console.log(prix)

    return prix;
  }

  articlesModal() {
    const modalRef = this.modalService.open(AddArticleModalComponent, {
      size: "lg",
    });
    ////console.log(this.bdc.id_bailleur);
    modalRef.componentInstance.bailleur = this.bdc.id_bailleur;
    modalRef.componentInstance.marche_init = this.bdc.id_marche;

    modalRef.result.then(
      (result) => {
        ////console.log(result);
        result.article.nb = 1;
        this.bdc.articles.push(result.article);
        ////console.log(this.bdc.articles);
      },
      (reason) => {
        ////console.log(reason);
      }
    );
  }

  supprimerArticle(id) {
    for (let i = 0; i < this.bdc.articles.length; i++) {
      if (this.bdc.articles[i].id === id) {
        this.bdc.articles.splice(i, 1);
      }
    }
  }

  noBdcChange() {
    if (this.bdc.no_bdc !== "") {
      this.BdcService.searchNoBdc(this.bdc.no_bdc, this.bdc.id_bailleur).then(
        (resp: any) => {
          console.log(resp);

          if (resp.length != 0) {
            const modalRef = this.modalService.open(
              ModalMultipleNoBdcComponent
            );
            modalRef.componentInstance.idBdc = resp[0].id;
            modalRef.componentInstance.status = resp[0].status;

            modalRef.result.then(
              (result) => {},
              (reason) => {}
            );
          }
        }
      );
    }
  }

  noAffaireChange() {
    if (this.bdc.no_affaire !== "") {
      this.BdcService.searchNoAffaire(this.bdc.no_affaire).then((resp: any) => {
        console.log(resp);

        if (resp.length != 0) {
          const modalRef = this.modalService.open(
            ModalMultipleNoAffaireComponent
          );
          modalRef.componentInstance.idBdc = resp[0].id;
          modalRef.componentInstance.status = resp[0].status;

          modalRef.result.then(
            (result) => {},
            (reason) => {}
          );
        }
      });
    }
  }

  // Adresse
  codePostalChange() {
    if (this.bdc.code_postal_locataire !== "") {
      let infosCodePostal = codesPostaux.find(this.bdc.code_postal_locataire);

      this.infosCodePostal = infosCodePostal.map((item, index) => {
        item.id = index;
        item.nom = item.nomCommune;
        return item;
      });

      if (this.infosCodePostal.length != 0) {
        this.BdcService.maj_select("#select-ville", this.infosCodePostal);
        this.BdcService.set_selectize("#select-ville", 0);
      }
    }

    this.addressChange();
  }

  addressChange() {
    const url = encodeURI(
      this.bdc.adresse_locataire +
        " " +
        this.bdc.code_postal_locataire +
        " " +
        this.bdc.ville_locataire
    );

    this.BdcService.geoCoding(url).then((resp: any) => {

      this.geojson.data.features.push(resp.features[0]);

      resp.features[0].properties["marker-symbol"] = "monument";

      this.geojsonS = resp.features[0];

      this.positionLogement = resp.features[0].geometry;
      //console.log(this.positionLogement)

      this.adresse_formatee = resp.features[0].place_name;

      console.log(this.adresse_formatee);

      this.BdcService.getLogementsAdresse(this.adresse_formatee).then(
        (resp: any) => {
          this.logementsSimilaires = resp as any;

          //this.findLogementsSimilaires();
        }
      );
    });
  }

  findLogementsSimilaires() {
    if (this.logementsSimilaires.length != 0) {
      const modalRef = this.modalService.open(LogementsSimilairesComponent, {
        size: "lg",
      });
      modalRef.componentInstance.adressesSimilaires = this.logementsSimilaires;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    }
  }

  clicBdc(idBdc) {
    this.router.navigate(["bons-de-commande/validation/" + idBdc]);
  }

  // Formulaire
  createForm() {
    this.form = this.fb.group({
      fichier: null,
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get("fichier").setValue(file);
    }
  }

  private prepareSave(): any {
    let post_articles = [];
    for (var i = 0; i < this.bdc.articles.length; i++) {
      post_articles.push({
        id: this.bdc.articles[i].id,
        nb: this.bdc.articles[i].nb,
      });
    }

    //console.log(post_articles);

    var jsonArticles = JSON.stringify(post_articles);

    //console.log(post_articles)

    let input = new FormData();

    //console.log(this.bdc.date_limite)
    //console.log(this.BdcService.formatDate(this.bdc.date_limite))

    input.append("id_agence", this.BdcService.getAgenceId().toString());
    input.append("bailleur", this.bdc.id_bailleur);
    input.append("marche", this.bdc.id_marche);
    input.append("charge", this.bdc.id_charge_secteur);
    input.append("moyen-demande", this.bdc.moyen_demande);
    input.append("no-quitus", this.bdc.no_quitus);
    input.append("no-affaire", this.bdc.no_affaire);
    input.append("no-bdc", this.bdc.no_bdc);
    input.append("no-devis", this.bdc.no_devis);
    input.append("no-assurance", this.bdc.no_assurance);
    input.append(
      "date-reception",
      this.BdcService.formatDate(this.bdc.date_reception)
    );
    input.append(
      "date-echeance",
      this.BdcService.formatDate(this.bdc.date_limite)
    );
    input.append("urgence", this.bdc.urgence);
    input.append("nom-locataire", this.bdc.nom_locataire);
    input.append("adresse-locataire", this.bdc.adresse_locataire);
    input.append("code-postal-locataire", this.bdc.code_postal_locataire);
    input.append("ville-locataire", this.bdc.ville_locataire);
    input.append(
      "complement-adresse-locataire",
      this.bdc.cpt_adresse_locataire
    );
    input.append("tel-fixe-locataire", this.bdc.tel_fixe_locataire);
    input.append("tel-portable-locataire", this.bdc.tel_portable_locataire);
    input.append("objet-intervention", this.bdc.objet_intervention);
    input.append("prix", this.bdc.prix);
    input.append("liste_articles", jsonArticles);
    input.append("fichier", this.form.get("fichier").value);

    console.log(this.form.get("fichier").value);

    input.append("adresse-formatee", this.adresse_formatee);

    return input;
  }

  private prepareSavePJ(idBdc, nom, description, fichier): any {
    let input = new FormData();

    input.append("id_bdc", idBdc);
    input.append("nom", nom);
    input.append("description", description);

    console.log(fichier);

    input.append("fichier", fichier);

    return input;
  }

  sendForm() {
    const formModel = this.prepareSave();

    const url = encodeURI(
      this.bdc.adresse_locataire + " " + this.bdc.code_postal_locataire
    );

    /*
        this.BdcService.geoCoding(url).then((resp: any) => {
          //console.log(resp);
        });*/

    this.BdcService.postBdc(formModel).then((idNewBdc) => {
      for (let i = 0; i < this.tabPiecesJointes.length; i++) {
        const formModelPJ = this.prepareSavePJ(
          idNewBdc,
          this.tabPiecesJointes[i].nom,
          this.tabPiecesJointes[i].description,
          this.tabPiecesJointes[i].fichier
        );
        this.BdcService.postPJ(formModelPJ).then((resp) => {
          console.log(resp);
        });
      }

      this.router.navigate(["bons-de-commande/planification/" + idNewBdc]);
      //this.router.navigate(["bon-de-commande/" + idNewBdc]);
    });
  }

  // Utilitaires
  get_moyen_demande(name) {
    const tabMoy = [
      {
        id: 1,
        nom: "MAIL",
      },
      {
        id: 2,
        nom: "TEL",
      },
      {
        id: 3,
        nom: "COURRIER",
      },
      {
        id: 4,
        nom: "FAX",
      },
    ];

    for (var i = 0; i < tabMoy.length; i++) {
      if (tabMoy[i].nom === name) {
        return tabMoy[i].id;
      }
    }
  }

  majListeMarches() {
    ////console.log(this.bdc.id_bailleur)
    const listeMarches = this.getMarche(this.bdc.id_bailleur);
    ////console.log(listeMarches)
    this.BdcService.maj_select("#select-marche", listeMarches);
    //this.BdcService.set_selectize('#select-marche', this.bdc.id_marche); // On sélectionne le marché courant
  }

  majListeChargesSecteur() {
    const listeCharges = this.getCharge(
      this.bdc.id_marche,
      this.getMarche(this.bdc.id_bailleur)
    ).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });
    this.BdcService.maj_select("#select-charge", listeCharges);
    this.BdcService.set_selectize("#select-charge", this.bdc.id_charge_secteur);
  }

  getMarche = (idBailleur) => {
    ////console.log(idBailleur)

    for (const bailleur of this.clients) {
      ////console.log(bailleur.id)
      if (parseInt(bailleur.id) === parseInt(idBailleur)) {
        let marches = [];

        for (let i = 0; i < bailleur.marches.length; i++) {
          if (bailleur.marches[i].actif == 1) {
            marches.push(bailleur.marches[i]);
          }
        }

        return marches;
      }
    }
    return [];
  };

  getCharge = (idMarche, listeMarches) => {
    for (const marche of listeMarches) {
      if (parseInt(marche.id) === parseInt(idMarche)) {
        return marche.charges_secteur;
      }
    }
    return [];
  };

  changeState(state) {
    this.state = state;
  }

  getFichiers(event) {
    this.tabPiecesJointes = event;
  }
}
