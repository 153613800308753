<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouvel appel</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="row">
      <div class="col-2">
        <label for="appel-time" class="col-form-label">Date</label>
      </div>
      <div class="col-10">
        <input id="appel-time" type="datetime-local" class="form-control" name="appel-time" [(ngModel)]="date">
      </div>
    </div>
  </div>
  <div class="form-group">
    <textarea class="form-control" name="commentaire_add_appel_modal" [(ngModel)]="commentaire" rows="6"
      placeholder="Commentaire"></textarea>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="addAppel()">Enregistrer l'appel</button>
</div>
