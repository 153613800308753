<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouveau bailleur</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
    <input type="text" class="form-control" name="nom-client" [(ngModel)]="nom" placeholder="Nom">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="addBailleur()">Sauvegarder</button>
</div>
