<div class="row row-cards row-deck">
  <div class="col-12">

    <div class="card p-3">

      <div class="row">
        <div class="col-7">

          <div class="form-group">
            <small class="form-text text-muted">Colonnes</small>
            <input id="selected-columns" type="text" placeholder="Colonnes">

          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <small class="form-text text-muted">Recherche</small>
            <input id="recherche-tab" type="text" class="form-control" placeholder="Recherche">
          </div>
        </div>
        <div class="col-2">

          <div class="form-group">
            <small class="form-text text-muted">Total </small>
            <div *ngIf="prixTotalFiltres !== undefined">{{prixTotalFiltres.toFixed(2)}} €</div>
          </div>
        </div>
      </div>




        <div id="tab-planifs-wrapper" class="table-responsive">
          <table id="{{tableId}}" class="table table-hover table-vcenter text-nowrap card-table">


          </table>
        </div>

    </div>
  </div>
</div>
