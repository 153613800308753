<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire == ''">{{bdc.id}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire == ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire != ''">{{bdc.id}} - Affaire
            n°{{bdc.no_affaire}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire != ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}} -
            Affaire n°{{bdc.no_affaire}}</h2>
          <div class="ml-auto">
            <button type="button" class="btn btn-secondary float-right" (click)="backState()">Retour à la
              validation</button>
          </div>
        </div>
        <div class="card-body">

          <app-infos-bon [bdc]="bdc"></app-infos-bon>

          <app-options-toggles [bdc]="bdc"></app-options-toggles>

          <div class="row">
            <div class="col-6">
              <div *ngIf="bdc">
                <app-intervention-card [bdc]="bdc"></app-intervention-card>
              </div>
            </div>

            <div class="col-6">
              <app-appels-card [idBdc]="bdc.id"></app-appels-card>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-widget-planif-indiv [bdc]="bdc"></app-widget-planif-indiv>
            </div>
          </div>

        </div>
      </div>

      <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>
    </div>
  </div>
</div>