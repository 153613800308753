<div class="page">
  <div class="page-main">
    <!--<div class="sticky-top">-->
    <div class="header py-4" *ngIf="showNavbar">
      <div class="container">
        <div class="d-flex">
          <a class="header-brand" href="/">
            <img src="assets/images/logo_ems.png" class="header-brand-img" alt="EMS">
          </a>

          <li class="nav-item">
            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" (click)="newBon()">
              <i class="fe fe-plus"></i> Nouveau bon de commande
            </button>
          </li>


          <div class="d-flex order-lg-2 ml-auto">


            <form class="input-icon" (keydown)="keyDownFunction($event)">
              <input type="search" class="form-control header-search" placeholder="Rechercher ..." tabindex="1">
              <div class="input-icon-addon">
                <i class="fe fe-search"></i>
              </div>
            </form>


            <div ngbDropdown class="d-none d-md-flex">
              <a ngbDropdownToggle id="dropdownNotifs" class="nav-link icon">
                <i class="fe fe-bell"></i>
                <span class="nav-unread" *ngIf="NotificationsService.getNotifs().length > 0"></span>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownNotifs" class="dropdown-menu-right dropdown-menu-arrow" x-placement="bottom-end"
                style="position: absolute; transform: translate3d(39px, 32px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a ngbDropdownItem href="#" routerLink="/boite-de-reception/validation/{{n}}" class="dropdown-item d-flex"
                  *ngFor="let n of NotificationsService.getNotifs()" (click)="clicNotif(n)">
                  <span class="avatar mr-3 align-self-center"><i class="fe fe-clipboard"></i></span>
                  <div>
                    <strong>Nouveau bon de commande</strong>
                    <div class="small text-muted">{{n}}</div>
                  </div>
                </a>
                <div class="dropdown-divider" *ngIf="NotificationsService.getNotifs().length > 0"></div>
                <a ngbDropdownItem href="#" class="dropdown-item text-center" (click)="NotificationsService.clearNotifs()"
                  *ngIf="NotificationsService.getNotifs().length > 0">Tout marquer comme lu</a>
                <a ngbDropdownItem href="#" class="dropdown-item text-center"
                  *ngIf="NotificationsService.getNotifs().length == 0">Aucune notification</a>
              </div>
            </div>




            <div ngbDropdown>
              <a ngbDropdownToggle class="nav-link pr-0 leading-none" id="dropdownProfile">
                <span class="avatar" style="background-image: url(assets/images/avatar.png)"></span>
                <span class="ml-2 d-none d-lg-block">
                  <span class="text-default">{{user.prenom + " " + user.nom}}</span>
                </span>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownProfile" class="dropdown-menu-right dropdown-menu-arrow" x-placement="bottom-end"
                style="position: absolute; transform: translate3d(-56px, 32px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a ngbDropdownItem routerLink="/admin/user">
                  <i class="dropdown-icon fe fe-user"></i> Profil
                </a>

                <div class="dropdown-divider"></div>

                <a ngbDropdownItem (click)="deconnexion()" routerLink="">
                  <i class="dropdown-icon fe fe-log-out"></i> Déconnexion
                </a>
              </div>
            </div>

          </div>
        </div>

        <a href="#" class="header-toggler d-lg-none ml-3 ml-lg-0" data-toggle="collapse"
          data-target="#headerMenuCollapse">
          <span class="header-toggler-icon"></span>
        </a>
      </div>

    </div>

    <div class="header d-lg-flex p-0" id="headerMenuCollapse" *ngIf="showNavbar">
      <div class="container">
        <div class="row align-items-center">

          <div class="col-12 order-lg-first mx-auto">
            <ul class="nav nav-tabs border-0 flex-column flex-lg-row">

              <li class="nav-item">
                <a routerLink="/boite-de-reception" routerLinkActive="active" class="nav-link">
                  <i class="fe fe-mail"></i>
                  Boîte de réception <span class="badge badge-pill badge-secondary">{{NotificationsService.getCompteurs().a_valider}}</span>
                </a>
              </li>

              <li class="nav-item">
                <a routerLink="/liste-globale" routerLinkActive="active" class="nav-link"><i class="fe fe-clipboard"></i>
                  Bons de commande </a>
              </li>

              <li class="nav-item">
                <a routerLink="/new-calendrier" routerLinkActive="active" class="nav-link"><i class="fe fe-calendar"></i>
                  Calendrier</a>
              </li>

              <li class="nav-item">
                <a routerLink="/rapports" routerLinkActive="active" class="nav-link"><i class="fe fe-layout"></i>
                  Rapports</a>
              </li>

              <li class="nav-item">
                <a routerLink="/bons-de-commande" routerLinkActive="active" class="nav-link"><i
                    class="fe fe-clipboard"></i> Bons de commande (ancien)</a>
              </li>

              <div class="d-flex order-lg-3 ml-auto">

                <li class="nav-item">




                  <div class="form-group" style="margin-bottom: 0;">
                    <div class="input-group">
                      <div ngbDropdown class="input-group-prepend">

                        <button ngbDropdownToggle type="button" class="btn btn-outline-secondary" id="dropdownSelectAgence">
                          Agence de {{nomAgence}}
                        </button>

                        <div ngbDropdownMenu aria-labelledby="dropdownSelectAgence" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                          <a ngbDropdownItem (click)="changeAgence(4)">Lyon</a>
                          <a ngbDropdownItem (click)="changeAgence(3)">Tullins</a>
                          <a ngbDropdownItem (click)="changeAgence(8)">Bourgoin-Jallieu</a>
                        </div>

                      </div>
                      <div ngbDropdown class="input-group-append">
                        <button ngbDropdownToggle type="button" class="btn btn-outline-secondary" id="dropdownOptionsAgence">
                          <i class="fe fe-settings"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownOptionsAgence" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                          <a ngbDropdownItem class="dropdown-item" href="#" routerLink="/personnel"><i class="dropdown-icon fe fe-users"></i> Personnel</a>
                          <a ngbDropdownItem class="dropdown-item" href="#" routerLink="/bailleurs"><i class="dropdown-icon fe fe-home"></i> Bailleurs</a>
                          <a ngbDropdownItem class="dropdown-item" href="#" routerLink="/options-agence"><i class="dropdown-icon fe fe-settings"></i> Options</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

              </div>



            </ul>




          </div>



        </div>

        <div class="d-flex col-lg-3 ml-auto">

        </div>
      </div>
    </div>
    <!--</div>-->

    <router-outlet></router-outlet>

  </div>
</div>
