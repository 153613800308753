import { Component, OnInit } from '@angular/core';

import { BdcService } from '../services/service-bdc.service';

@Component({
  selector: 'app-rapports',
  templateUrl: './rapports.component.html',
  styleUrls: ['./rapports.component.scss']
})
export class RapportsComponent implements OnInit {
  stats = {} as any;
  listeBdcEnRetard = [];
  listeBdcSansNo = [];

  constructor(private BdcService: BdcService) { }

  ngOnInit() {
    this.BdcService.getStatsBdc().then((stats: any) => {
      //console.log(stats);

      this.stats = stats;
    });

    this.BdcService.getListBdcEnRetard().then((retard: any) => {
      //console.log(retard);
      this.listeBdcEnRetard = retard;
    });

    this.BdcService.getListBdcSansNoBdc().then((no_n_bdc: any) => {
      //console.log(no_n_bdc);
      this.listeBdcSansNo = no_n_bdc;
    });
  }

}
