import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

declare var $: any;
import 'bootstrap-notify';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-redac-rapport',
  templateUrl: './redac-rapport.component.html',
  styleUrls: ['./redac-rapport.component.scss']
})
export class RedacRapportComponent implements OnInit {
  @Input() idBdc;
  @Input() rapport;
  @Input() readonly;

  constructor(private BdcService: BdcService) { }

  ngOnInit() {
  }

  save() {
    this.BdcService.updateRapportOfBdc(this.idBdc, this.rapport).then(resp => {
      $(document).ready(function(){
        $.notify({
          message: 'Enregistré'
        },{
          type: 'success'
        });
      });
    });
  }

}
