import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../../services/service-bdc.service';
import { supported } from 'mapbox-gl';
@Component({
  selector: 'app-mod-delete-event',
  templateUrl: './mod-delete-event.component.html',
  styleUrls: ['./mod-delete-event.component.scss']
})
export class ModDeleteEventComponent implements OnInit {
  @Input() idBdc;
  @Input() event;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
    //console.log(this.idBdc)
    //console.log(this.event)
  }

  suppr() {
    this.activeModal.close({
      action: 'suppr',
      event: this.event
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
