import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { DetailRapportComponent } from '../liste-rapports-of-bdc/detail-rapport/detail-rapport.component';
import { ResumeAddInterventionModalComponent } from '../resume-interventions/resume-add-intervention-modal/resume-add-intervention-modal.component';

import {
  BdcService
} from '../../../services/service-bdc.service';

import * as moment from 'moment';

@Component({
  selector: 'app-liste-rapports-of-bdc',
  templateUrl: './liste-rapports-of-bdc.component.html',
  styleUrls: ['./liste-rapports-of-bdc.component.scss']
})
export class ListeRapportsOfBdcComponent implements OnInit {
  @Input() bdc;

  @Output() update = new EventEmitter();

  rapports = [];
  prixFactu = 0;
  isRapports = false;

  constructor(public BdcService: BdcService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    const that = this;

    that.BdcService.getRapportsOfBdc(this.bdc.id).then((rapports: any) => {
      this.rapports = rapports.sort((a,b) => moment(a.date).diff(b.date));

      console.log(this.rapports)

      this.isRapportsFct();

        that.prixFactu = 0;

        for (let rapport of rapports) {
          that.prixFactu = that.prixFactu + that.totalArticles(rapport).ht;

          rapport.deleteRequested = false;

          ////console.log(rapport.techniciens)

          if(rapport.photos == 0) rapport.photos = [];
          if(rapport.commentaire ===undefined) rapport.commentaire = '';

          if (rapport.techniciens.length !== 0) {
            rapport.techniciens_mef = "";

            ////console.log(rapport.techniciens)

            let rapp = rapport;

            for (let i = 0; i < rapport.techniciens.length; i++) {
              const tech = rapport.techniciens[i];

              rapp.techniciens_mef += tech.prenom + ' ' + tech.nom

              if(i < (rapport.techniciens.length - 1)) {
                rapp.techniciens_mef += ', ';
              }
            }
          }
        }

    }, (err) => {
      console.log(err)
    });
  }



  totalArticles(rapport) {
    const prix = {
      ht: 0
    };

    //console.log(rapport)

    if (rapport.articles !== undefined) {
      for (const article of rapport.articles) {
        prix.ht += parseFloat(article.prix_ht) * article.nb;
      }

      return prix;
    }
    else return { ht: 0 };
  }

  isRapportsFct() {
    if (this.rapports.length == 0) {
      this.isRapports = false;
    } else {
      this.isRapports = true;
    }
  }



  viewRapport(rapport) {
    const modalRef = this.modalService.open(DetailRapportComponent, { size: 'lg' });
    modalRef.componentInstance.rapport = rapport;
    modalRef.result.then((result) => {
    //this.init()
    }, (reason) => {
        //console.log(reason);
    });
  }


  newRapport() {
    // ouverture de la modal
    const modalRef = this.modalService.open(ResumeAddInterventionModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idBdc = this.bdc.id;

    modalRef.result.then((result) => {
      this.init();
      this.update.emit();
      //this.init();
    }, (reason) => {
      //console.log(reason);
    });
  }

  toFactu() {
    this.BdcService.bdcToFacturationState(this.bdc.id).then((res) => {
      this.update.emit();
    });
  }

  toPlanif() {
    this.BdcService.bdcToPlanificationState(this.bdc.id).then((res) => {
      this.update.emit();
    });
  }



}
