<div class="modal-header">
  <h3 class="modal-title" id="addArticleModalLabel">Logements similaires</h3>
</div>
<div class="modal-body">

  <h4>Adresse similaire</h4>

  <app-liste-bdc class="table-height-limit" [tableId]="'datatable1'" [listeBdc]="adressesSimilaires"
    [datatables]="false" [colonnes]="'no_bdc objet_intervention date status'" (clicItem)="cancel()"></app-liste-bdc>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Retour</button>
</div>
