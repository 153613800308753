import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { BdcService } from "../../services/service-bdc.service";

import { CalendrierModalEventComponent } from '../components/week-calendar/calendrier-modal-event/calendrier-modal-event.component';
import { CalendrierModalNewEventComponent } from '../components/week-calendar/calendrier-modal-new-event/calendrier-modal-new-event.component';
import { element } from 'protractor';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-new-calendrier',
  templateUrl: './new-calendrier.component.html',
  styleUrls: ['./new-calendrier.component.scss']
})
export class NewCalendrierComponent implements OnInit {


  constructor(
    private BdcService: BdcService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

  }



}

