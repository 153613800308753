import { Component, OnInit } from '@angular/core';

import { BdcService } from '../../services/service-bdc.service';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  listesArticles = [];
  bailleurSelected;
  marcheSelected;
  ListeSelected;

  date_debut;
  date_fin;

  articles = [];

  datatableOptions = {
    /*data: this.articles,
    columns: [
      {data: "code_article"},
      {data: "nom"},
      {data: "prix_ht"},
      {data: "total_commandes"}
    ],*/
    order: [[3, "desc"]],
    language: {
      "sProcessing": "Traitement en cours...",
      "sSearch": "Rechercher&nbsp;:",
      "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
      "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
      "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
      "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
      "sInfoPostFix": "",
      "sLoadingRecords": "Chargement en cours...",
      "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
      "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
      "oPaginate": {
        "sFirst": "Premier",
        "sPrevious": "Pr&eacute;c&eacute;dent",
        "sNext": "Suivant",
        "sLast": "Dernier"
      },
      "oAria": {
        "sSortAscending": ": activer pour trier la colonne par ordre croissant",
        "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
      },
      "select": {
        "rows": {
          _: "%d lignes selectionnées",
          0: "Aucune ligne selectionnée",
          1: "1 ligne selectionnée"
        }
      }
    }
  } as any;

  constructor(private BdcService: BdcService) { }

  table;

  ngOnInit() {
    const that = this;

    /*
    ** INIT DATES
    */

   this.date_fin = moment().format("YYYY-MM-DD");
   this.date_debut = moment().subtract(1, 'months').format("YYYY-MM-DD");

   /*
   ** INIT TABLE
   */

    $(document).ready(function () {

      that.table = $('#table-articles').DataTable({
        data: that.articles,
        columns: [
          {
            data: 'code_article',
            width: '15%'
          },
          {
            data: 'nom',
            width: '35%'
          },
          {
            data: 'prix_ht',
            width: '10%'
          },
          {
            data: 'total_commandes',
            width: '20%'
          },
          {
            data: 'total_interventions',
            width: '20%'
          }
        ],
        order: [[3, "desc"]],
        language: {
          "sProcessing": "Traitement en cours...",
          "sSearch": "Rechercher&nbsp;:",
          "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
          "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
          "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          "sInfoPostFix": "",
          "sLoadingRecords": "Chargement en cours...",
          "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
          "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
          "oPaginate": {
            "sFirst": "Premier",
            "sPrevious": "Pr&eacute;c&eacute;dent",
            "sNext": "Suivant",
            "sLast": "Dernier"
          },
          "oAria": {
            "sSortAscending": ": activer pour trier la colonne par ordre croissant",
            "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
          },
          "select": {
            "rows": {
              _: "%d lignes selectionnées",
              0: "Aucune ligne selectionnée",
              1: "1 ligne selectionnée"
            }
          }
        }
      });
    });

    this.BdcService.getListesArticles().then((listesArticles: any) => {
      this.listesArticles = listesArticles;

      this.listesArticles = [];

      for (let i = 0; i < listesArticles.length; i++) {
        if(listesArticles[i].actif == 1) {
          this.listesArticles.push(listesArticles[i]);
        }
      }


      console.log(this.listesArticles);

      // Selection du bailleur
      (<any>$('#select-bailleur')).selectize({});
      this.BdcService.maj_select("#select-bailleur", this.listesArticles);
      this.bailleurSelected = this.listesArticles[0].id;

      $('#select-bailleur').change(function () {
        that.bailleurSelected = parseInt($(this).val() as string);

        let totalMarches = that.listesArticles.find(elt =>
          elt.id == that.bailleurSelected
        ).marches;

        let marches = [];

        for (let i = 0; i < totalMarches.length; i++) {
          if(totalMarches[i].actif == 1) {
            marches.push(totalMarches[i]);
          }
        }

        that.BdcService.maj_select("#select-marche", marches);
      });

      // Selection du marche
      (<any>$('#select-marche')).selectize({});
      this.BdcService.maj_select("#select-marche", this.listesArticles[0].marches);
      this.marcheSelected = this.listesArticles[0].marches[0].id;

      $('#select-marche').change(function () {
        if (parseInt($(this).val() as string) > 0) {
          that.marcheSelected = parseInt($(this).val() as string);

          const marchesBailleur = that.listesArticles.find(elt => elt.id == that.bailleurSelected).marches;
          const marche = marchesBailleur.find(elt => elt.id == that.marcheSelected);

          that.BdcService.maj_select("#select-liste", marche.listes);
          selectizeListeObj.setValue(marche.id_liste_articles_active, false);
        }
      });

      // Selection de la liste
      let selectizeListeObj = (<any>$('#select-liste')).selectize({})[0].selectize;
      this.BdcService.maj_select("#select-liste", this.listesArticles[0].marches[0].listes);
      this.ListeSelected = this.listesArticles[0].marches[0].id_liste_articles_active;
      selectizeListeObj.setValue(this.ListeSelected, false);
      that.getArticles();

      $('#select-liste').change(function () {
        if (parseInt($(this).val() as string) > 0) {
          //console.log(parseInt($(this).val() as string))

          that.ListeSelected = parseInt($(this).val() as string);

          that.getArticles();
        }
      });
    })

  }

  getArticles() {
    console.log(this.date_debut)
    console.log(this.date_fin)

    this.BdcService.getListeArticlesWithStat(this.ListeSelected, this.date_debut + ' 00:00:00', this.date_fin + ' 23:59:59').then((articles: any) => {

      this.articles = articles.articles;

      this.table.clear();
      this.table.rows.add(this.articles);
      this.table.draw();

      /*this.table.rows()
      .invalidate("dom")
      .draw();*/

      console.log(this.articles);
    })
  }

}
