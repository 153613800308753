import { Component, OnInit, Input, OnChanges } from '@angular/core'; 
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-selection-tech-modal',
  templateUrl: './selection-tech-modal.component.html',
  styleUrls: ['./selection-tech-modal.component.scss']
})
export class SelectionTechModalComponent implements OnInit {
  @Input() techs;

  listTechs = [];
  selectedTech;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
    const that = this;

    for(const i in this.techs) {
      this.techs[i].nom = this.techs[i].title;
    }

    //console.log(this.techs);
    that.selectedTech = this.techs[0].id;

    (<any>$('#select-tech')).selectize({});
    this.BdcService.maj_select("#select-tech", this.techs);
    
    $('#select-tech').change(function () {
      that.selectedTech = parseInt($(this).val() as string);
    });
  }

  ok() {
    this.activeModal.close(this.selectedTech);
  }

  cancel() {
    this.activeModal.dismiss();
  }


}