<div class="wc-tech-group" *ngFor="let tech of day">

  <div class="wc-tech-wrapper">
    <b>{{tech.nom_tech}}</b>
  </div>

  <div class="wc-tech-events-wrapper" *ngFor="let event of tech.planifs" (click)="clickEvent(event)">
    <app-week-calendar-event [adresse]="adresse" [event]="event" [bdc]="bdc"></app-week-calendar-event>
  </div>
</div>
