<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="row row-cards row-deck">
        <div class="col-12">
          <div class="card">

            <app-liste-bdc [listeBdc]="listeBdc" [tableId]="'listeCorbeille'" [datatables]="true"
              [colonnes]="'is_identifiant id no_bdc client date urgence objet_intervention prix'">
            </app-liste-bdc>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
