<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">Nouveau technicien</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
    <input type="text" id="prenom-client" class="form-control" name="prenom-client" [(ngModel)]="prenom" placeholder="Prénom" required>
    <div class="invalid-feedback">
      Renseignez un prénom.
    </div>
    <br>
    <input type="text" id="nom-client" class="form-control" name="nom-client" [(ngModel)]="nom" placeholder="Nom" required>
    <div class="invalid-feedback">
      Renseignez un nom.
    </div>
    <br>
    <input type="text" class="form-control" name="tel-client" [(ngModel)]="tel" placeholder="N° téléphone">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="addTechnicien()">Sauvegarder</button>
</div>
