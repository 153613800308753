import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var $: any;
import 'bootstrap-notify';

@Component({
  selector: 'app-modal-bon-in-tableur',
  templateUrl: './modal-bon-in-tableur.component.html',
  styleUrls: ['./modal-bon-in-tableur.component.scss']
})
export class ModalBonInTableurComponent implements OnInit {

  @Input() bdc;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private BdcService: BdcService) { }

  ngOnInit(): void {
    console.log(this.bdc)
  }

  cancel() {
    this.activeModal.close();
  }

  delete() {
    console.log("Delete " + this.bdc.id)

    if(confirm("Voulez-vous vraiment supprimer ce bon de commande ?")) {

      this.BdcService.supprimerBdc(this.bdc.id).then(() => {
        this.activeModal.close();

        $.notify({
          message: 'Bon de commande supprimé.'
        },{
          type: 'danger',
          z_index: 2031,
        });
      });


    }
  }

}
