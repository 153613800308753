import { Component, OnInit, NgZone  } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import {
  BdcService
} from '../services/service-bdc.service';

import { ModalBonInTableurComponent } from "../bonsDeCommande/tableur-bdc/modal-bon-in-tableur/modal-bon-in-tableur.component";

import {
  Router,
  ActivatedRoute
} from '@angular/router';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent implements OnInit {
  recherche = "";

  listeRecherche = [];

  constructor(private BdcService: BdcService, private route: ActivatedRoute, private modalService: NgbModal, private ngZone: NgZone) { }

  ngOnInit() {

    this.route.params.subscribe(params => {

      this.recherche = params.recherche;

      this.recherche = decodeURI(this.recherche);
      let phoneNumber = this.formatPhoneNumber(this.recherche);

      if (phoneNumber !== null) {
        this.BdcService.getListBdcRecherchesAvecTel(this.recherche, phoneNumber).then((listeBdc: any) => {
          this.listeRecherche = listeBdc;
        });
      }
      else {
        this.BdcService.getListBdcRecherches(this.recherche).then((listeBdc: any) => {
          this.listeRecherche = listeBdc;
        });
      }
    });
  }

  formatPhoneNumber(str) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);

    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4] + '-' + match[5]
    };

    return null
  };

  click(idBdc) {
    const that = this;

    console.log(idBdc)

    this.ngZone.run(() => {

      this.BdcService.getBdc(idBdc).then(bdc => {
        const modalRef = that.modalService.open(ModalBonInTableurComponent, {
          size: "llg",
        });

        modalRef.componentInstance.bdc = bdc;

        modalRef.result.then(
          (result) => {
          },
          (reason) => {
          }
        );
      })

    });





  }

}
