import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



import {
  BdcService
} from '../../../../services/service-bdc.service';

@Component({
  selector: 'app-add-modal',
  templateUrl: './add-modal.component.html',
  styleUrls: ['./add-modal.component.scss']
})
export class AddModalComponent implements OnInit {
  @Input() idBdc;

  commentaire;

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService) { }

  ngOnInit() {
  }

  addCom() {
    this.BdcService.newCommentaire(this.idBdc, this.commentaire).then((resp) => {
      this.activeModal.close();
    });
  }

  cancel() {
    this.activeModal.close();
  }

}
