import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  BdcService
} from '../../services/service-bdc.service';

declare var moment: any;

@Component({
  selector: 'app-technicien',
  templateUrl: './technicien.component.html',
  styleUrls: ['./technicien.component.scss']
})
export class TechnicienComponent implements OnInit {

  idTechnicien = this.route.snapshot.params['idTechnicien'];

  technicien = {} as any;
  edit = false;

  planification = [];

  constructor(private BdcService: BdcService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.init();
  }

  init() {

    this.BdcService.getTechnicien(this.idTechnicien).then((technicien: any) => {
      technicien.actif = technicien.actif == 1 ? true : false;

      console.log(technicien)

      this.technicien = technicien;
    });

    this.BdcService.getPlanifTechnicien(this.idTechnicien).then((planif: any) => {

      for (let i = 0; i < planif.length; i++) {
        planif[i].start = moment(planif[i].start).format('DD/MM/YYYY HH[h]mm');
        planif[i].end = moment(planif[i].end).format('DD/MM/YYYY HH[h]mm');
      }

      this.planification = planif;
    })
  }

  goMarche(id) {
    this.router.navigate(['bailleurs/marche/' + id]);
  }

  changeState() {
    this.BdcService.changeTechnicienState(this.technicien.id, this.technicien.actif);
  }

  editNom() {
    this.edit = true;
  }
  saveNom() {
    this.BdcService.updateTechnicien(this.technicien.id, this.technicien.nom, this.technicien.prenom, this.technicien.tel);

    this.edit = false;
  }

}
