<div class="header d-lg-flex p-0" id="headerMenuCollapse" ng-show="onglets.bdc.active">
  <div class="container">
    <div class="row align-items-center d-flex">
      <div class="col-12 mx-auto order-lg-first">
        <ul class="nav nav-tabs border-0 flex-column flex-lg-row">
          <li class="nav-item">
            <a routerLink="/rapports/general" routerLinkActive="active" class="nav-link">
              <i class="fe fe-bar-chart"></i>
              General
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/rapports/listes-bons/all" routerLinkActive="active" class="nav-link">
              <i class="fe fe-clipboard"></i>
              Listes de bons
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/rapports/chiffre-affaires" routerLinkActive="active" class="nav-link"><i
                class="fe fe-dollar-sign"></i>
              Chiffre d'affaires 
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/rapports/regulation" routerLinkActive="active" class="nav-link">
              <i class="fe fe-check-circle"></i>
              Régulation
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/rapports/articles" routerLinkActive="active" class="nav-link"><i
                class="fe fe-award"></i>
              Articles 
            </a>
          </li>
          
         
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>