<div class="card">
  <div class="card-status bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Interventions</h3>
    <div class="card-options" *ngIf="!readonly">
      <button class="btn btn-sm btn-default" (click)="newInterventionModal()">
        <span class="fe fe-plus"></span>
      </button>
    </div>
  </div>


  <div *ngIf="isRapports">

    <div class="card-body" *ngFor="let rapport of rapports">
      <div class="clearfix">
        <div class="float-left">
          <p class="text-muted">Rapport du {{BdcService.dateHeure(rapport.date)}} {{rapport.techniciens_mef}}</p>
        </div>
        <div class="float-right" *ngIf="!readonly">

          <p class="text-muted dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"></p>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <!--<button class="dropdown-item btn-primary" type="button" (click)="edit(rapport)"><i
                class="fe fe-edit"></i> Modifier</button>-->
            <button class="dropdown-item btn-danger" type="button" (click)="delete(rapport)"><i
                class="fe fe-trash-2"></i> Supprimer</button>
          </div>
          <button *ngIf="rapport.deleteRequested" class="btn btn-danger" (click)="confirm_delete(rapport)">Confirmer la suppression</button>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <table class="table card-table">
            <thead>
              <tr>
                <th class="w-1">Code</th>
                <th>Dénomination</th>
                <th>Qté</th>
                <th>Prix HT</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let article of rapport.articles">
                <td class="text-muted">{{article.code_article}}</td>
                <td>{{article.nom}}</td>
                <td class="text-muted">{{article.nb}}</td>
                <td class="text-muted">{{article.prix_ht}}€</td>
              </tr>

              <tr>
                <td class="text-muted"></td>
                <td class="text-muted"></td>
                <td class="float-right">Total</td>
                <td><strong>{{totalArticles(rapport).ht.toFixed(2)}}€</strong></td>
              </tr>
            </tbody>

          </table>
        </div>
        <div class="col-4">
          <p *ngIf="rapport.commentaire !== 'undefined'">{{rapport.commentaire}}</p>
          <button class="btn btn-primary ml-auto float-right" (click)="photosModal(rapport)"
           *ngIf="photos">Photos</button>

           <img *ngIf="rapport.signature != ''" [src]="env.signatures_folder + rapport.signature">
        </div>
      </div>
    </div>



    <div class="card-body" *ngIf="!readonly">
      <div class="clearfix">
        <div class="float-left">

        </div>
        <div class="float-right">
          <p>Total <strong>{{prixFactu.toFixed(2)}}€</strong></p>
        </div>
      </div>
    </div>


  </div>

  <div class="card-body" *ngIf="!isRapports">
    <div class="clearfix">

      <div class="float-left">
        <p class="text-muted">Aucune intervention</p>
      </div>
    </div>
  </div>
</div>
