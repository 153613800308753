

<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="page-header">
        <h1 class="page-title">
          Recherche
        </h1>
      </div>


      <div class="row row-cards row-deck">
        <div class="col-12">

          <div class="card table-height-limit">
            <div class="card-header">
              <h3 class="card-title">{{recherche}}</h3>
            </div>
            <app-liste-bdc [listeBdc]="listeRecherche" [tableId]="'datatable1'" [datatables]="true" [colonnes]="'no_bdc client date urgence objet_intervention status prix prix_real'"
              [action]="true" (clicItem)="click($event)"></app-liste-bdc>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
