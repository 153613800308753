<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire == ''">{{bdc.id}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire == ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc == '' && bdc.no_affaire != ''">{{bdc.id}} - Affaire
            n°{{bdc.no_affaire}}</h2>
          <h2 class="card-title" *ngIf="bdc.no_bdc != '' && bdc.no_affaire != ''">{{bdc.id}} - Bon n°{{bdc.no_bdc}} -
            Affaire n°{{bdc.no_affaire}}</h2>
          <div class="ml-auto">
          </div>
        </div>
        <div class="card-body">
          <app-infos-bon [bdc]="bdc" [readonly]="true"></app-infos-bon>

          <div class="row">
            <div class="col-9">
              <app-options-toggles [bdc]="bdc"></app-options-toggles>
            </div>
            <div class="col-3">
              <div class="row">
                <div class="col-12" style="padding-right: 12px;position: relative; top: -10px;">
                  <span class="float-right">
                    <button class="btn btn-primary" (click)="appelsModal()">Appels</button>&nbsp;&nbsp;
                    <button class="btn btn-secondary" (click)="avisPassageModal()">Avis de passage</button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <app-intervention-card [bdc]="bdc"></app-intervention-card>
            </div>

            <div class="col-6">
              <app-recap-planifications [bdc]="bdc" [controles]="false"></app-recap-planifications>
            </div>
          </div>

          <app-resume-interventions [idBdc]="bdc.id" (prixFactuEvent)="majPrixFactu($event)" [readonly]="true">
          </app-resume-interventions>

          <div class="row">
            <div class="col-12">
              <app-redac-rapport [idBdc]="bdc.id" [readonly]="true" [(rapport)]="bdc.texte_rapport"></app-redac-rapport>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-status bg-blue"></div>
                <div class="card-header">
                  <h3 class="card-title">Facturation</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="no_facture">N° de facture</label>
                        <input type="text" class="form-control" id="no_facture" [(ngModel)]="bdc.no_facture"
                          placeholder="N° de facture">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="no_facture_fournisseur">N° de facture fournisseur</label>
                        <input type="text" class="form-control" id="no_facture_fournisseur" aria-describedby="emailHelp"
                          [(ngModel)]="bdc.no_facture_fournisseur" placeholder="N° de facture fournisseur">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="prix">Prix</label>
                        <input type="text" readonly class="form-control form-control-plaintext" id="prix"
                          [(ngModel)]="bdc.prix" placeholder="{{prixFactu}}">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                    </div>
                    <div class="col-6">
                      <button type="button" class="btn btn-outline-primary btn-block"
                        (click)="archiverFactu()">Archiver</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>

    </div>
  </div>
</div>
