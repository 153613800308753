<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="page-header">
        <h1 class="page-title">
          Régulation
        </h1>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Clients</h4>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-6" style="padding:0px;">

                    <table class="table table-hover card-table">
                      <thead>
                        <tr>
                          <th>Bailleurs</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of regul" 
                        (click)="selectBailleur(item.id)"
                        [ngClass]="{'selected ': item.selected == true, '': item.selected == false}">
                          <td>{{item.nom}}</td>
                          <td class="text-right">
                            <span class="badge badge-success" *ngIf="item.nb_regul == 0">Ok</span>
                            <span class="badge badge-danger" *ngIf="item.nb_regul > 0">{{item.nb_regul}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <div class="col-6" style="padding:0px">

                    <table class="table table-hover card-table">
                      <thead>
                        <tr>
                          <th>Chargés de secteur</th>
                          <th></th>
                        </tr>
                        
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of chargesSecteur" 
                        (click)="selectChargeSecteur(item.id)"
                        [ngClass]="{'selected ': item.selected == true, '': item.selected == false}">
                          <td>
                            {{item.nom}} <span class="small text-muted">{{item.tel}}</span>
                          </td>
                          <td class="text-right">
                            <span class="badge badge-success" *ngIf="item.regulation.nb == 0">Ok</span>
                            <span class="badge badge-danger" *ngIf="item.regulation.nb > 0">{{item.regulation.nb}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Bons à régulariser</h4>
            </div>

            <div class="card-body">

              <app-liste-bdc class="table-height-limit" [tableId]="'datatable1'" [listeBdc]="bons"
              [datatables]="false" [colonnes]="'client date status'"></app-liste-bdc>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>