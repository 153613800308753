import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { AvisPassageComponent } from '../../liste-interventions/intervention/avis-passage/avis-passage.component';
import { ModalAppelsComponent } from '../../components/modal-appels/modal-appels.component';

import {
  BdcService
} from '../../../services/service-bdc.service';

@Component({
  selector: 'app-bon-de-commande',
  templateUrl: './bon-de-commande.component.html',
  styleUrls: ['./bon-de-commande.component.scss']
})
export class BonDeCommandeComponent implements OnInit {

  @Input() bdc;

  tabState = 1;

  constructor(
    private modalService: NgbModal,
    private BdcService: BdcService) { }

  ngOnInit(): void {

    console.log(this.bdc)

    this.checkState();

  }

  update() {
    console.log("update")
    this.BdcService.getBdc(this.bdc.id).then(bdc => {
      this.bdc = bdc;
      this.checkState();
    })
  }

  checkState() {
    switch (this.bdc.status) {
      case 'A_VALIDER':
        this.tabState = 1;
        break;
      case 'A_PLANIFIER':
        this.tabState = 1;
        break;
      case 'A_EXECUTER':
        this.tabState = 2;
        break;

      default:
        this.tabState = 3;
        break;
    }
  }


  // Appels
  appelsModal() {
    const modalRef = this.modalService.open(ModalAppelsComponent, { size: 'lg' });
      modalRef.componentInstance.idBdc = this.bdc.id;
  }

  // Avis de passage
  avisPassageModal() {
    const modalRef = this.modalService.open(AvisPassageComponent);
    modalRef.componentInstance.idBdc = this.bdc.id;
  }

  eventFromInterventions(event) {
    this.bdc = event;
  }


  finIntervention() {
    if(this.bdc.interventions.liste.length > 0)
    {
     /* this.BdcService.bdcToFacturationState(this.bdc.id).then((res) => {
        this.router.navigate(['bons-de-commande/facturation/' + this.bdc.id]);
      });*/
    }
    else {
      alert("Aucune intervention n'a été réalisée !");
    }

  }

}
