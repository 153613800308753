<div class="card p-3">

  <div class="row mb-4">
    <div class="col-2">
      <div class="form-group ml-auto">
        <label class="custom-switch">
          <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" [(ngModel)]="showAdresse" (change)="changeShowAdresse()">
          <span class="custom-switch-indicator"></span>
          <span class="custom-switch-description">Affichage adresse</span>
        </label>
      </div>
    </div>

    <div class="col-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" (click)="previous()"><i
              class="fe fe-arrow-left"></i></button>
        </div>
        <input type="week" class="form-control" placeholder="" aria-label="" [(ngModel)]="selectedWeek"
          (change)="changeWeek()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="next()"><i
              class="fe fe-arrow-right"></i></button>
        </div>
      </div>

    </div>
    <div class="col-2 d-flex">
      <div class="form-group ml-auto">
        <label class="custom-switch">
          <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" [(ngModel)]="showWeekEnd"
            (change)="weekEnd()">
          <span class="custom-switch-indicator"></span>
          <span class="custom-switch-description">Week-End</span>
        </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!-- Calendrier -->

      <div class="wc-wrapper">
        <!-- Container global -->
        <div class="wc-days-row wc-row">
          <!-- Ligne des jours -->
          <div class="wc-days-corner wc-first-col">
            <!-- Coin -->
            &nbsp;
          </div>
          <div class="wc-lundi wc-col">
            <!-- Lundi -->
            Lundi {{displayDay(this.eventsWeek.lundi.moment)}}
          </div>
          <div class="wc-mardi wc-col">
            <!-- Mardi -->
            Mardi {{displayDay(this.eventsWeek.mardi.moment)}}
          </div>
          <div class="wc-mercredi wc-col">
            <!-- Mercredi -->
            Mercredi {{displayDay(this.eventsWeek.mercredi.moment)}}
          </div>
          <div class="wc-jeudi wc-col">
            <!-- Jeudi -->
            Jeudi {{displayDay(this.eventsWeek.jeudi.moment)}}
          </div>
          <div class="wc-vendredi wc-col">
            <!-- Vendredi -->
            Vendredi {{displayDay(this.eventsWeek.vendredi.moment)}}
          </div>
          <div class="wc-jeudi wc-col" *ngIf="showWeekEnd">
            <!-- Samedi -->
            Samedi {{displayDay(this.eventsWeek.samedi.moment)}}
          </div>
          <div class="wc-vendredi wc-col" *ngIf="showWeekEnd">
            <!-- Dimanche -->
            Dimanche {{displayDay(this.eventsWeek.dimanche.moment)}}

          </div>

        </div>

        <div class="wc-am-row wc-row">
          <!-- Ligne des matins -->
          <div class="wc-first-col" style="background-color: #f5f5f5">
            Matin
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.lundi.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.lundi.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.mardi.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.mardi.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.mercredi.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.mercredi.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.jeudi.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.jeudi.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.vendredi.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.vendredi.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col" *ngIf="showWeekEnd">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.samedi.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.samedi.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col" *ngIf="showWeekEnd">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.dimanche.tri_tech.am" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.dimanche.moment, true)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>

          </div>
        </div>

        <div class="wc-pm-row wc-row">
          <!-- Ligne des aprem -->
          <div class="wc-first-col" style="background-color: #f5f5f5">
            Après-midi
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.lundi.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.lundi.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.mardi.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.mardi.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.mercredi.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.mercredi.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.jeudi.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.jeudi.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.vendredi.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.vendredi.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col" *ngIf="showWeekEnd">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.samedi.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.samedi.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
          <div class="wc-col" *ngIf="showWeekEnd">

            <app-week-calendar-techgroup [adresse]="showAdresse" [day]="eventsWeek.dimanche.tri_tech.pm" [bdc]="bdc" (maj)="maj($event)"></app-week-calendar-techgroup>

            <div class="wc-event-wrapper bg-grey" (click)="clickNew(eventsWeek.dimanche.moment, false)">
              <div class="wc-event-row">
                <i class="fe fe-plus-square wc-add-event"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="wc-tech-row wc-row">
          <!-- Ligne de la compta -->
          <div class="wc-first-col">
            &nbsp;
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.lundi.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.lundi.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.mardi.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.mardi.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.mercredi.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.mercredi.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.jeudi.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.jeudi.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.vendredi.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.vendredi.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column" *ngIf="showWeekEnd">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.samedi.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.samedi.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
          <div class="wc-col wc-ca-tech-day d-flex flex-column" *ngIf="showWeekEnd">
            <div class="wc-ca-tech" *ngFor="let tech of eventsWeek.dimanche.ca_tech">
              <div class="wc-ca-nom-tech">{{tech.nom_tech}}</div>
              <div class="wc-ca-ca">{{tech.ca.toFixed(2)}} €</div>
            </div>
            <div class="wc-ca-tech font-weight-bold mt-auto">
              <div class="wc-ca-nom-tech">Total</div>
              <div class="wc-ca-ca">{{eventsWeek.dimanche.ca_day.toFixed(2)}} €</div>
            </div>
          </div>
        </div>

      </div>


    </div>

  </div>


</div>
