<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">

      <div class="card">
        <div class="card-status bg-blue"></div>
        <div class="card-header">
          <h3 class="card-title">Bailleurs</h3>
          <div class="card-options">
            <button class="btn btn-sm btn-default" (click)="newClient()">
              <span class="fe fe-plus"></span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <table class="table card-table table-hover table-vcenter">
            <thead>
              <tr>
                <th>Actifs</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of clientsActifs" (click)="goClient(client.id)">
                <td>
                  <span>{{client.nom}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body">
          <table class="table card-table table-hover table-vcenter">
            <thead>
              <tr>
                <th>Inactifs</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of clientsInactifs" (click)="goClient(client.id)">
                <td>
                  <span>{{client.nom}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>
