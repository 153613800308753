<app-infos-bon [bdc]="bdc" [readonly]="false"></app-infos-bon>

<div class="row">
  <div class="col-9">
    <app-options-toggles [bdc]="bdc"></app-options-toggles>
  </div>
  <div class="col-3">
    <div class="row">
      <div class="col-12" style="padding-right: 12px;position: relative; top: -10px;">
        <span class="float-right">
          <button class="btn btn-secondary" (click)="appelsModal()"
            *ngIf="bdc.status != 'A_VALIDER' && bdc.status != 'A_PLANIFIER'">Appels</button>&nbsp;&nbsp;
          <button class="btn btn-secondary" (click)="avisPassageModal()">Avis de passage</button>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-6">
    <app-intervention-card [bdc]="bdc"></app-intervention-card>
  </div>

  <div class="col-6">
    <app-recap-planifications [bdc]="bdc" [controles]="true" *ngIf="bdc.status != 'A_VALIDER' && bdc.status != 'A_PLANIFIER'">
    </app-recap-planifications>
    <app-appels-card [idBdc]="bdc.id" *ngIf="bdc.status == 'A_VALIDER' || bdc.status == 'A_PLANIFIER'"></app-appels-card>
  </div>
</div>

<div class="row">

  <div class="col-12">




    <div class="card">
      <div class="card-header">
        <ul ngbNav #nav="ngbNav" [(activeId)]="tabState" class="nav nav-tabs">
          <li [ngbNavItem]="1" class="nav-item">
            <a ngbNavLink class="nav-link">Planification</a>
            <ng-template ngbNavContent>
              <app-week-calendar [bdc]="bdc"></app-week-calendar>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" class="nav-item">
            <a ngbNavLink class="nav-link">Intervention</a>
            <ng-template ngbNavContent>

              <!--<app-resume-interventions [idBdc]="bdc.id" [readonly]="false" (bdcEvent)="eventFromInterventions($event)">
              </app-resume-interventions>-->

              <app-liste-rapports-of-bdc [bdc]="bdc" (update)="update()"></app-liste-rapports-of-bdc>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" class="nav-item">
            <a ngbNavLink class="nav-link">Facturation</a>
            <ng-template ngbNavContent>
              <app-facturation-component [bdc]="bdc"></app-facturation-component>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="card-body" style="padding-top: 1rem;">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>


  </div>

</div>

<div class="row">
  <div class="col-12">
    <app-liste-commentaires [idBdc]="bdc.id"></app-liste-commentaires>
  </div>
</div>
