import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { BdcService } from '../../services/service-bdc.service';

@Component({
  selector: 'app-corbeille',
  templateUrl: './corbeille.component.html',
  styleUrls: ['./corbeille.component.scss']
})
export class CorbeilleComponent implements OnInit {

  listeBdc = [];

  constructor(private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
    this.BdcService.getListBdcCorbeille().then((listeBdc: any[]) => {
        this.listeBdc = listeBdc;
      }
    );
  }

}
