<div class="card">
  <div class="card-body">

    <div class="row">
      <div class="col-6">
        <div id='map'></div>
      </div>

      <div class="col-6">

        <h3>Interventions</h3>

        <div class="table-responsive">
          <table id="tabPlanifs" class="table table-hover table-outline table-vcenter text-nowrap card-table">
            <thead>
              <tr>
                <th>Début</th>
                <th>Fin</th>
                <th>Technicien</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let planif of getPlanifsOfBdc()" (click)="clicOnEvent(planif.start)">

                <td class="text-center">
                  {{planif.start_hr}}
                </td>
                <td class="text-center">
                  {{planif.end_hr}}
                </td>
                <td class="text-center">
                  {{planif.nom}}
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-12">
        <div id="calendar_indiv"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary ml-auto float-right" (click)="savePlanif()">Sauver la planification</button>
      </div>
    </div>
  </div>
</div>
