import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logements-similaires',
  templateUrl: './logements-similaires.component.html',
  styleUrls: ['./logements-similaires.component.scss']
})
export class LogementsSimilairesComponent implements OnInit, OnChanges {
  @Input() adressesSimilaires;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.adressesSimilaires);
  }

  ngOnChanges() {
    console.log(this.adressesSimilaires);
  }

  cancel() {
    this.activeModal.close();
  }

}
