import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BdcService
} from '../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-modal-new-technicien',
  templateUrl: './modal-new-technicien.component.html',
  styleUrls: ['./modal-new-technicien.component.scss']
})
export class ModalNewTechnicienComponent implements OnInit {
  nom = '';
  prenom = '';
  tel = '';

  constructor(public activeModal: NgbActiveModal, private BdcService: BdcService, private router: Router) { }

  ngOnInit() {
  }

  addTechnicien() {
    if (this.nom !== '' && this.prenom !== '') {
      this.BdcService.newTechnicien(this.nom, this.prenom, this.tel).then((id_technicien: any) => {
        //console.log(id_technicien)
        this.activeModal.close();
        this.router.navigate(['personnel/technicien/' + id_technicien.id]);
      });
    }
    else {
      if (this.prenom == '') {
        $('#prenom-client').addClass('is-invalid');
      }
      if (this.nom == '') {
        $('#nom-client').addClass('is-invalid');
      }
    }
  }

  cancel() {
    this.activeModal.close();
  }

}

