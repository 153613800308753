import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  BdcService
} from '../../../services/service-bdc.service';

import { AddArticleModalComponent } from '../../../components/add-article-modal/add-article-modal.component';
import { ModalMultipleNoBdcComponent } from '../../components/modal-multiple-no-bdc/modal-multiple-no-bdc.component';
import { ModalMultipleNoAffaireComponent } from '../../components/modal-multiple-no-affaire/modal-multiple-no-affaire.component';
import { LogementsSimilairesComponent } from '../../components/logements-similaires/logements-similaires.component';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-modal-update-bon',
  templateUrl: './modal-update-bon.component.html',
  styleUrls: ['./modal-update-bon.component.scss']
})
export class ModalUpdateBonComponent implements OnInit {
  @Input() bdc;

  state = 2;

  clients;

  positionLogement = {
    coordinates: [5.703130, 45.200103],
    type: "Point"
  };
  logementsSimilaires = [];
  geojson = {
    type: 'geojson',
    data: {
      'type': 'FeatureCollection',
      'features': []
    }
  };
  geojsonS = '';
  adresse_formatee = '';

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private modalService: NgbModal, private BdcService: BdcService) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.bdc = Object.assign({}, this.bdc);

    this.bdc.date_reception = moment(this.bdc.date_reception, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
    this.bdc.date_limite = moment(this.bdc.date_limite, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');

      //console.log(bdc)

      this.BdcService.getListeClients().then((clients: any) => {
        this.clients = [];

        for (let i = 0; i < clients.length; i++) {
          if(clients[i].actif == 1) {
            this.clients.push(clients[i]);
          }
        }

        this.clientsOk();
      });

      this.bdcOk();
  }

  bdcOk() {
    let that = this;

    (<any>$('#tel-fixe')).mask('99-99-99-99-99', {
      placeholder: 'Téléphone fixe'
    });
    (<any>$('#tel-portable')).mask('99-99-99-99-99', {
      placeholder: 'Téléphone portable'
    });

    const selectize_moyen_demande = (<any>$('#select-moyen-demande')).selectize({})[0].selectize;

    const tabMoyensDemande = [{
      id: 1,
      nom: 'Mail',
      code: 'MAIL'
    }, {
      id: 2,
      nom: 'Téléphone',
      code: 'TEL'
    }, {
      id: 3,
      nom: 'Courrier',
      code: 'COURRIER'
    }, {
      id: 4,
      nom: 'Fax',
      code: 'FAX'
    }];

    this.BdcService.maj_select('#select-moyen-demande', tabMoyensDemande);

    $('#select-moyen-demande').change(function () {
      var result = ""

      if($(this).val() != "")
      {

        if (($(this).val() == "MAIL") || ($(this).val() == "TEL") || ($(this).val() == "COURRIER") || ($(this).val() == "FAX")) {
          result = $(this).val();
        }
        else if ($(this).val() == 1) result = "MAIL"
        else if ($(this).val() == 2) result = "TEL"
        else if ($(this).val() == 3) result = "COURRIER"
        else if ($(this).val() == 4) result = "FAX"

        that.bdc.moyen_demande = result;
      }
    });

    selectize_moyen_demande.setValue(tabMoyensDemande.find(elt => elt.code == that.bdc.moyen_demande).id, false);

    const selectize_urgence = (<any>$('#select-urgence')).selectize({
      render: {
        option: function (item, escape) {

          let bell = "<span>";

          if (item.value === 1) {
            bell = '<span class="fa fa-bell" style:"float: right"></span>'
          }
          else if (item.value === 2) {
            bell = '<span class="fa fa-bell evwarning" style:"float: right"></span>'
          }
          else if (item.value === 3) {
            bell = '<span class="fa fa-bell evimportant" style:"float: right"></span>'
          }

          return "<div>" + bell + ' ' + escape(item.text) + "</div>";
        }
      }
    })[0].selectize;

    this.BdcService.maj_select('#select-urgence', [{
      id: 1,
      nom: "Pas d'urgence"
    }, {
      id: 2,
      nom: 'A traiter dans la semaine'
    }, {
      id: 3,
      nom: 'A traiter immédiatement'
    }]);

    $('#select-urgence').change(function () {
      that.bdc.urgence = parseInt($(this).val() as string);
    });

    selectize_urgence.setValue(this.bdc.urgence, false);


    (<any>$('#date')).mask('99/99/9999', {
      placeholder: 'jj/mm/aaaa'
    });

    $('#date').change(function () {
      that.BdcService.formatDate($(this).val())
    });

    this.addressChange();
  }

  clientsOk() {

    (<any>$('#select-bailleur')).selectize({});
    (<any>$('#select-marche')).selectize({});
    (<any>$('#select-charge')).selectize({});

    this.BdcService.maj_select("#select-bailleur", this.clients);
    this.BdcService.set_selectize('#select-bailleur', this.bdc.id_bailleur);

    this.majListeMarches();
    this.majListeChargesSecteur();

    let that = this;

    $('#select-bailleur').change(function () {
      ////console.log($(this).val())

      that.bdc.id_bailleur = $(this).val();

      that.majListeMarches();
    });

    $('#select-marche').change(function () {

      that.bdc.id_marche = $(this).val();
      that.majListeChargesSecteur();
    });

    $('#select-charge').change(function () {
      that.bdc.id_charge_secteur = $(this).val();
    });
  }

  majListeMarches() {
    const listeMarches = this.getMarche(this.bdc.id_bailleur);
    this.BdcService.maj_select('#select-marche', listeMarches);
    this.BdcService.set_selectize('#select-marche', this.bdc.id_marche); // On sélectionne le marché courant
  }

  majListeChargesSecteur() {
    const listeCharges = this.getCharge(this.bdc.id_marche, this.getMarche(this.bdc.id_bailleur)).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });;
    this.BdcService.maj_select("#select-charge", listeCharges);
    this.BdcService.set_selectize('#select-charge', this.bdc.id_charge_secteur);
  }

  getMarche = (idBailleur) => {
    for (const bailleur of this.clients) {
      if (bailleur.id === idBailleur) {
        let marches = [];

        for (let i = 0; i < bailleur.marches.length; i++) {
          if(bailleur.marches[i].actif == 1) {
            marches.push(bailleur.marches[i]);
          }
        }

        return marches;
      }
    }
    return [];
  }

  getCharge = (idMarche, listeMarches) => {
    for (const marche of listeMarches) {
      if (marche.id === idMarche) {
        return marche.charges_secteur;
      }
    }
    return [];
  }




  changeState(state) {
    this.state = state;
  }

  noBdcChange() {

    if (this.bdc.no_bdc !== '') {
      this.BdcService.searchNoBdc(this.bdc.no_bdc, this.bdc.id_bailleur).then((resp: any) => {
        if (resp.length != 0 && resp[0].id != this.bdc.id) {
          const modalRef = this.modalService.open(ModalMultipleNoBdcComponent);
          modalRef.componentInstance.idBdc = resp[0].id;
          modalRef.componentInstance.status = resp[0].status;

          modalRef.result.then((result) => {
          }, (reason) => {
          });
        }
      });
    }
  }

  noAffaireChange() {

    if (this.bdc.no_affaire !== '') {
      this.BdcService.searchNoAffaire(this.bdc.no_affaire).then((resp: any) => {
        if (resp.length != 0 && resp[0].id != this.bdc.id) {
          const modalRef = this.modalService.open(ModalMultipleNoAffaireComponent);
          modalRef.componentInstance.idBdc = resp[0].id;
          modalRef.componentInstance.status = resp[0].status;

          modalRef.result.then((result) => {
          }, (reason) => {
          });
        }
      });
    }
  }

  addressChange() {
    const url = encodeURI(this.bdc.adresse_locataire + ' ' + this.bdc.code_postal_locataire + ' ' + this.bdc.ville_locataire);

    this.BdcService.geoCoding(url).then((resp: any) => {
      this.geojson.data.features.push(resp.features[0]);

      resp.features[0].properties['marker-symbol'] = 'monument';

      this.geojsonS = resp.features[0];

      this.positionLogement = resp.features[0].geometry;
      //console.log(this.positionLogement)

      this.adresse_formatee = resp.features[0].place_name;

      console.log(this.adresse_formatee)

      //console.log(resp.features[0].place_name)
      this.BdcService.getLogementsAdresse(resp.features[0].place_name).then((resp) => {
        //console.log(resp)
        this.logementsSimilaires = resp as any;
      })
    });
  }

  findLogementsSimilaires() {
    if(this.logementsSimilaires.length != 0) {
      const modalRef = this.modalService.open(LogementsSimilairesComponent, { size: 'lg' });
      modalRef.componentInstance.adressesSimilaires = this.logementsSimilaires;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    }
  }

  // Articles
  showArticles() {
    return this.bdc.commande.articles !== [];
  }

  totalArticles() {
    const prix = {
      ht: 0
    };

    for (const article of this.bdc.commande.articles) {
      prix.ht += parseFloat(article.prix_ht) * article.nb;
    }

    return prix;
  }

  articlesModal() {
    const modalRef = this.modalService.open(AddArticleModalComponent, { size: 'lg' })
    modalRef.componentInstance.bailleur = this.bdc.id_bailleur;
    modalRef.componentInstance.marche_init = this.bdc.id_marche;

    modalRef.result.then((result) => {
      result.article.nb = 1;
      this.bdc.commande.articles.push(result.article);
    }, (reason) => {
    });

  }

  supprimerArticle(id) {
    for (let i = 0; i < this.bdc.commande.articles.length; i++) {

      if (this.bdc.commande.articles[i].id === id) {
        this.bdc.commande.articles.splice(i, 1);
      }
    }
  }



  private prepareSave(): any {
    let post_articles: any = [];
    for (var i = 0; i < this.bdc.commande.articles.length; i++) {
      post_articles.push({

        id: this.bdc.commande.articles[i].id,

        nb: this.bdc.commande.articles[i].nb
      });
    }

    post_articles = JSON.stringify(post_articles);

    let input = new FormData();

    input.append('id', this.bdc.id);
    input.append('bailleur', this.bdc.id_bailleur);
    input.append('marche', this.bdc.id_marche);
    input.append('charge', this.bdc.id_charge_secteur);
    input.append('moyen-demande', this.bdc.moyen_demande);
    input.append('no-quitus', this.bdc.no_quitus);
    input.append('no-affaire', this.bdc.no_affaire);
    input.append('no-bdc', this.bdc.no_bdc);
    input.append('no-devis', this.bdc.no_devis);
    input.append('no-assurance', this.bdc.no_assurance);
    input.append('date-reception', this.BdcService.formatDate(this.bdc.date_reception));
    input.append('date-echeance', this.BdcService.formatDate(this.bdc.date_limite));
    input.append('urgence', this.bdc.urgence);
    input.append('nom-locataire', this.bdc.nom_locataire);
    input.append('adresse-locataire', this.bdc.adresse_locataire);
    input.append('code-postal-locataire', this.bdc.code_postal_locataire);
    input.append('ville-locataire', this.bdc.ville_locataire);
    input.append('complement-adresse-locataire', this.bdc.cpt_adresse_locataire);
    input.append('tel-fixe-locataire', this.bdc.tel_fixe_locataire);
    input.append('tel-portable-locataire', this.bdc.tel_portable_locataire);
    input.append('objet-intervention', this.bdc.objet_intervention);
    input.append('prix', this.bdc.prix);

    input.append('liste_articles', post_articles);
    //input.append('fichier', this.form.get('fichier').value);

    input.append('adresse-formatee', this.adresse_formatee);

    return input;
  }


  validerBdc() {
    const formModel = this.prepareSave();

    this.BdcService.updateBdc(formModel).then(() => {
      this.activeModal.close();
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
