import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { BdcService } from "../../../services/service-bdc.service";

import { CalendrierModalEventComponent } from '../../components/week-calendar/calendrier-modal-event/calendrier-modal-event.component';
import { CalendrierModalNewEventComponent } from '../../components/week-calendar/calendrier-modal-new-event/calendrier-modal-new-event.component';
import { element } from 'protractor';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-tab-planifs',
  templateUrl: './tab-planifs.component.html',
  styleUrls: ['./tab-planifs.component.scss']
})
export class TabPlanifsComponent implements OnInit {

  // Tableau
  isDataLoaded = false; // Flag servant à la gestion de l'enregistrement de la pagination du tableau
  columnTab;
  defaultVisibleColumns = [0, 2, 1, 3, 4];
  tableId = "tab-planifs"; // Id du tableur datatables dans le DOM
  table; // L'objet datatables
  datatableOptions; // Options de construction du datatables
  tabItems = []; // Liste des bons injectés dans le tableur

  planifsToCome = [];

  prixTotalFiltres;

  constructor(
    private BdcService: BdcService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    moment.locale('fr')

    this.initTable();
  }

  refreshList() {
    // Planifs entre aujourd'hui et l'an 2100
    this.BdcService.getPlanifJour(moment().format("YYYY-MM-DD") + ' 00:00:00', '2100-01-01 23:59:59').then((planifs: any) => {
      this.tabItems = planifs;

      this.newData();
    });
  }








// Mise à jour des données à intégrer au tableau
newData() {
  const that = this;

  if (this.table !== undefined) { // Si le tableau est initialisé

    this.table.clear(); // Suppression de toutes les données du tableau
    this.table.rows.add(this.tabItems); // Chargement de la liste des bons

    this.table.draw(false); // Rendu des lignes

    // Affichage de la page enregistrée uniquement si celle-ci existe toujours
    if (parseInt(localStorage.getItem("page_" + this.tableId)) < this.table.page.info().pages) {
      this.table.page(parseInt(localStorage.getItem("page_" + this.tableId))).draw(false);
    } else {
      this.table.page(0).draw(false);
    }

    /*
     ** INITIALISATION DES FILTRES DES COLONNES VISIBLES
     */
    $.fn.dataTable.ext.search = [];

    for (let i = 0; i < that.defaultVisibleColumns.length; i++) {
      const element = that.defaultVisibleColumns[i];

      // Config du filtre de la colonne
      that.configFiltreColonne(element);
    }

    that.table.draw();
  }
}



  initTable() {
    const that = this;

    this.isDataLoaded = false;

    /*
     ** CONFIGURATION DES COLONNES
     */
    let orderColumn = 0; // Id de la colonne à trier à l'initialisation

    that.columnTab = [
      { // Date
        title: "Date",
        name: "date",
        data: "start",
        render: function (data, type, row, meta) {
          if(data !== undefined) {

            let demiJournee = that.isAm(data) ? 'Matin' : 'Après-midi';

            return that.formatDate(data) + '<div class="small text-muted">' + demiJournee + "</div>";
          }
          else return '';
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterDate(idColonne, dataIndex, 'start');
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownDate (idColonne);
        }
      },
      { // N° BDC
        title: "N° BDC",
        name: "no_bdc",
        data: "bdc.no_bdc",
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'bdc.no_bdc');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "bdc.no_bdc", 'N° Bon de commande');
        }
      },
      { // Technicien intervention 17
        title: "Technicien",
        name: "tech_inter",
        data: "technicien.fullname",
        render: function (data, type, row, meta) {
          if(data !== undefined) {
            return data;
          }
          else return '';
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterSelectize(idColonne, dataIndex, 'technicien.fullname');
        },
        dropdownBuilder: function (idColonne) {
          that.pre_dropdownSelectize (idColonne, "technicien.fullname", 'Technicien');
        }
      },
      { // Location
        title: "Location",
        name: "location",
        data: "bdc.code_postal_locataire",
        render: function (data, type, row, meta) {
          let content = row.bdc.nom_locataire;

          if (row.bdc.tel_fixe_locataire != '' || row.bdc.tel_portable_locataire != '') {
            content += '<div class="small text-muted">';

            if (row.bdc.tel_fixe_locataire != '') {
              content += row.bdc.tel_fixe_locataire;
            }

            if (row.bdc.tel_fixe_locataire != '' && row.bdc.tel_portable_locataire != '') {
              content += " / ";
            }

            if (row.bdc.tel_portable_locataire != '') {
              content += row.bdc.tel_portable_locataire;
            }

            content += "</div>";
          }


          content += '<div class="small text-muted">' +
            row.bdc.adresse_locataire + ' ' +
            "</div>";
          content += '<div class="small text-muted">' +
            row.bdc.code_postal_locataire + ' ' + row.bdc.ville_locataire +
            "</div>";

          return content;
        }
      },
      { // Prix commande 8
        title: "Prix com.",
        name: "prix_commande",
        data: "bdc.commande.prix_ht",
        render: function (data, type, row, meta) {
          return data.toFixed(2);
        },
        columnFilter: function (data, idColonne, dataIndex) {
          return that.filterRange(idColonne, dataIndex, "bdc.commande.prix_ht");
        },
        dropdownBuilder: function (idColonne) {
          that.dropdownRange(idColonne);
        }
      },
    ];

    // Ajout des champs indispensables à chaque colonne
    that.columnTab = that.columnTab.map((item, index) => {
      item.id = index;
      item.datafilter = [];

      //Colonnes à masquer à l'initialisation
      if (!that.defaultVisibleColumns.includes(index)) {
        item.visible = false;
      }

      return item;
    });

    /*
     ** SELECTIZE DES COLONNES A AFFICHER
     */
    let listSelectizeColumns = that.columnTab.map((item, index) => {
      return {
        id: item.id,
        column_name: item.name,
        nom: item.title,
      };
    });

    $("#selected-columns").selectize({ delimiter: ",", plugins: ["remove_button"] });
    this.BdcService.maj_select("#selected-columns", listSelectizeColumns); // Insertion de la liste des colonnes dans le menu
    $("#selected-columns")[0].selectize.setValue(that.defaultVisibleColumns);

    $("#selected-columns").change(function () {
      let tListCol = listSelectizeColumns.slice();
      let selection = $(this).val().split(",").map(item => parseInt(item));

      let columnsToCreate = $(selection).not(that.defaultVisibleColumns).get();
      let columnsToDelete = $(that.defaultVisibleColumns).not(selection).get();
      that.defaultVisibleColumns = selection;

      // Création des colonnes
      for (let i = 0; i < columnsToCreate.length; i++) {
        const element = columnsToCreate[i];

        // Recherche de la colonne qui porte cet identifiant
        let col = tListCol.find(item => item.id == element).column_name; // Récupération de son nom datatable

        var column = that.table.column(col + ":name");

        column.visible(true);

        that.configFiltreColonne(element); // Création du menu et du filtre de la colonne
      }

      // Suppression des colonnes
      for (let i = 0; i < columnsToDelete.length; i++) {
        let col = tListCol.find(item => item.id == columnsToDelete[i]);

        $('#notif-filter-' + col.id).addClass('col-no-filter'); // Masquage du marqueur de filtre si il est présent

        var column = that.table.column(col.column_name + ":name");
        column.visible(false); // Masquage de la colonne

        that.columnTab.find(item => item.id == col.id).datafilter = []; // Réinitialisation des données du filtre
      }

      // Réinitialisation des filtres uniquement sur les colonnes affichées
      $.fn.dataTable.ext.search = [];
      for (let i = 0; i < selection.length; i++) {
        // Recherche de la colonne qui porte cet identifiant
        let colonne = that.columnTab.find(item => item.id == selection[i]);

        // Création du filtre (si il y a une fonction particuliere)
        if ((colonne.columnFilter != true) && (colonne.columnFilter != undefined)) {

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            // Don't filter on anything other than "myTable"
            if (settings.nTable.id !== that.tableId) {
              return true;
            }

            return colonne.columnFilter(data, colonne.id, dataIndex);
          });
        }
      }

      that.table.draw();
    });

    /*
     ** CHAMP DE RECHERCHE GLOBAL
     */
    $("#recherche-tab").on("keyup", function () {
      that.table.search(this.value).draw();
    });

    /*
     ** CONFIGURATION DE LA DATATABLE
     */
    this.datatableOptions = {
      data: that.tabItems,
      columns: that.columnTab,
      colReorder: true,
      order: [[orderColumn, "desc"]], // Tri du tableau via une colonne
      stateSave: false,
      "pageLength": 50,
      initComplete: function (settings, json) {
      },
      drawCallback: function (settings) { // Callback : A la fin d'un rendu
        var api = this.api();

        // Si on est sur la page dont le no est enregistré, on peut commencer à autoriser des modifs de no de page futures
        if (api.page.info().page == parseInt(localStorage.getItem("page_" + that.tableId))) {
          that.isDataLoaded = true;
        }

        if (that.isDataLoaded) {
          localStorage.setItem("page_" + that.tableId, api.page.info().page);


        }



      },
      language: {
        sProcessing: "Traitement en cours...",
        sSearch: "Rechercher&nbsp;:",
        sLengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        sInfo:
          "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        sInfoEmpty:
          "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
        sInfoFiltered:
          "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        sInfoPostFix: "",
        sLoadingRecords: "Chargement en cours...",
        sZeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        sEmptyTable: "Aucune donn&eacute;e disponible dans le tableau",
        oPaginate: {
          sFirst: "Premier",
          sPrevious: "Pr&eacute;c&eacute;dent",
          sNext: "Suivant",
          sLast: "Dernier",
        },
        oAria: {
          sSortAscending: ": activer pour trier la colonne par ordre croissant",
          sSortDescending:
            ": activer pour trier la colonne par ordre d&eacute;croissant",
        },
        select: {
          rows: {
            _: "%d lignes selectionnées",
            0: "Aucune ligne selectionnée",
            1: "1 ligne selectionnée",
          },
        },
      },
    };

    /*
     ** INITIALISATION DU TABLEAU
     ** Lorsque le DOM est prêt
     */
    $(document).ready(function () {
      // Initialisation de la datatable
      that.table = $("#" + that.tableId).DataTable(that.datatableOptions);

      that.refreshList(); // On va chercher les données une fois que le tableau est initialisé

      // Lors du clic sur le bouton d'action des lignes sélectionnées
      $("#" + that.tableId).on("click", "tbody tr td", function () {
        const lineClicked = that.table.row($(this).parent()).data();

        if ($($(this).children()[0]).is(".tab-select")) {
          // Si le clic est sur la checkbox
          /*
          if ($($(this).children()[0]).is(":checked")) {
            that.selectRow(that.table.row($(this).parent()).data().id);
          } else {
            that.deselectRow(that.table.row($(this).parent()).data().id);
          }*/
        } else {
          that.clickEvent(lineClicked);
        }
      });

      that.table.on( 'search.dt', function () {
        $('#filterInfo').html( 'Currently applied global search: '+that.table.search() );

        let visibleRows = that.table.rows({ order: 'index', search: 'applied' })

        that.prixTotalFiltres = 0;

          let dataCol = visibleRows.data().map((item) => {
            that.prixTotalFiltres += item.bdc.commande.prix_ht;
            return item;
          });
    } );
    });
  }



/*
   ** DROPDOWN FILTRE
   */
  configFiltreColonne(id) {
    const that = this;

    let colonne = that.columnTab.find(item => item.id == id);
    if (colonne.columnFilter != undefined) {

      let visibleRows = that.table.rows({ order: 'index', search: 'applied' })

      let dataCol = visibleRows.data().map((item) => {
        return item[colonne.data];
      });

      // Création du filtre (si il y a une fonction particuliere)
      if (colonne.columnFilter != true) {

        colonne.dropdownBuilder(colonne.id, dataCol);

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          // Don't filter on anything other than "myTable"
          if (settings.nTable.id !== that.tableId) {
            return true;
          }

          return colonne.columnFilter(data, colonne.id, dataIndex);
        });
      }

      // Blocage de la propagation du clic vers les parents
      $(".filter-box").click(function (event) {
        event.stopPropagation();
      });

      // Récupération du header de la colonne
      let header_col = that.table.column(colonne.id).header();
      let dropdownId = "tableur-col-" + colonne.id;

      // Au survol du header
      $(header_col).hover(
        function () {
          $("#" + dropdownId).css("display", "block"); // Affichage de la dropdown
        },
        function () {
          // Si on sort, masquage du dropdown lors d'un clic à l'extérieur
          $("#" + dropdownId).css("display", "none");
        }
      );
    }
  }

  /*
  ** TYPES DE DROPDOWNS FILTRES
  */

  dropdownSelectize(listeDeChoix, idColonne, label) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let idSelectize = 'filter-dropdown-' + idColonne;

    let dropdownHtml = '<div class="form-group"><small class="form-text text-muted">' + label + '</small>' +
      '<input id="' + idSelectize + '" type="text" class="form-control" placeholder="Recherche"></div>';

    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();

    let dropdownId = "tableur-col-" + idColonne;

    //$("#" + dropdownId).remove();

    // Création du dropdown
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" +
      dropdownId +
      "' class='filter-box'>" +
      dropdownHtml +
      "</div>"
    );

    let listSelectize = listeDeChoix.map((item, index) => {
      return {
        id: index,
        nom: item
      };
    });


    $('#' + idSelectize).selectize({
      delimiter: ",",
      plugins: ["remove_button"],
    });

    that.BdcService.maj_select('#' + idSelectize, listSelectize);


    $('#' + idSelectize)[0].selectize.setValue();

    $('#' + idSelectize).change(function () {
      let tList = listSelectize.slice();

      datafilter = [];

      if ($(this).val() != '') {
        // Pour chaque élément sélectionné
        let selection = $(this).val().split(",");

        for (let i = 0; i < selection.length; i++) {
          let itemSelection = listSelectize.find(item => item.id == selection[i])

          datafilter.push(itemSelection.nom);
        }

      }

      if (datafilter.length == 0) {
        $('#notif-filter-' + idColonne).addClass('col-no-filter')
      }
      else {
        $('#notif-filter-' + idColonne).removeClass('col-no-filter')
      }

      that.columnTab[idColonne].datafilter = datafilter;

      that.table.draw();
    });

  }

  dropdownCheckboxes(listeDeChoix, idColonne) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let checkboxesHtml = "";

    // Pour chaque item, création d'une checkbox
    for (let i = 0; i < listeDeChoix.length; i++) {
      checkboxesHtml +=
        '<label for="' +
        idColonne +
        "-" +
        i +
        '" ' +
        'style="display: block"' +
        '><input type="checkbox" id="' +
        idColonne +
        "-" +
        i +
        '"/>' +
        listeDeChoix[i] +
        "</label>";
    }

    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();

    let dropdownId = "tableur-col-" + idColonne;

    $("#" + dropdownId).remove();

    // Création du dropdown
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" +
      dropdownId +
      "' class='filter-box'>" +
      checkboxesHtml +
      "</div>"
    );

    // Lors d'une modif sur une checkbox
    $("#" + dropdownId + " input[type='checkbox']").on("change", (e) => {
      //let tabFilter = [];

      datafilter = [];

      // On regarde l'état de chaque checkbox
      $("#tableur-col-" + idColonne + " input[type='checkbox']").each(
        (index, checkB) => {
          if ($(checkB).is(":checked")) {
            // Si la checkbox est validée

            let item = $(
              "label[for='" + $(checkB).attr("id") + "']"
            ).text();

            datafilter.push(item);
          }
        }
      );

      if (datafilter.length == 0) {
        $('#notif-filter-' + idColonne).addClass('col-no-filter')
      }
      else {
        $('#notif-filter-' + idColonne).removeClass('col-no-filter')
      }

      that.columnTab[idColonne].datafilter = datafilter;

      that.table.draw();

    });

  }

  dropdownRange(idColonne) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let Html = '<div class="row">';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Minimum</small>' +
      '<input id="' + idColonne + '-min" type="number" class="form-control" placeholder="Min."></div></div>';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Maximum</small>' +
      '<input id="' + idColonne + '-max" type="number" class="form-control" placeholder="Max."></div></div>';

    Html += '</div>';


    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();
    let dropdownId = "tableur-col-" + idColonne;

    // Création du dropdown
    $("#" + dropdownId).remove();
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" + dropdownId + "' class='filter-box'>" +
      Html +
      "</div>"
    );

    $('#' + idColonne + '-min, #' + idColonne + '-max').keyup(function () {
      that.table.draw();
    });


    /*
    // Lors d'une modif sur une checkbox
    $("#" + dropdownId + " input[type='checkbox']").on("change", (e) => {
      //let tabFilter = [];

      datafilter = [];



      that.columnTab[idColonne].datafilter = datafilter;



      that.table.draw();
    });


*/




  }

  dropdownDate(idColonne) {
    const that = this;

    let datafilter = that.columnTab[idColonne].datafilter;

    let Html = '<div class="row">';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Minimum</small>' +
      '<input id="' + idColonne + '-min" type="date" class="form-control" placeholder="Min."></div></div>';

    Html += '<div class="col-6"><div class="form-group"><small class="form-text text-muted">Maximum</small>' +
      '<input id="' + idColonne + '-max" type="date" class="form-control" placeholder="Max."></div></div>';

    Html += '</div>';
    Html += '<div class="row"><div class="col-12"><button type="button" class="btn btn-secondary btn-sm" id="' + idColonne + '-reinit">Réinitialiser</button></div></div>'


    // Récupération du header de la colonne
    let header_col = that.table.column(idColonne).header();
    let dropdownId = "tableur-col-" + idColonne;

    // Création du dropdown
    $("#" + dropdownId).remove();
    $(header_col).prepend(
      "<span id='notif-filter-" + idColonne + "' class='nav-unread col-notif-filter col-no-filter'></span><div id='" + dropdownId + "' class='filter-box'>" +
      Html +
      "</div>"
    );

    $('#' + idColonne + '-min, #' + idColonne + '-max').change(function () {
      that.table.draw();
    });

    $('#' + idColonne + '-reinit').click(function () {
      $('#' + idColonne + '-min').val('');
      $('#' + idColonne + '-max').val('');
      that.table.draw();
    });

  }



  // Filtres

  filterSelectize(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    let datafilter = that.columnTab[idColonne].datafilter;

    if (datafilter.length > 0) {
      if (datafilter.includes(that.GetPropertyValue(row, dataTarget))) {
        return true;
      } else return false;
    } else return true;
  }

  filterRange(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    if ($('#' + idColonne + '-min').val() == '' && $('#' + idColonne + '-max').val() == '') {
      $('#notif-filter-' + idColonne).addClass('col-no-filter')
    }
    else {
      $('#notif-filter-' + idColonne).removeClass('col-no-filter')
    }

    var min = parseInt($('#' + idColonne + '-min').val(), 10);
    var max = parseInt($('#' + idColonne + '-max').val(), 10);
    var prix = parseFloat(that.GetPropertyValue(row, dataTarget)) || 0; // use data for the age column

    if ((isNaN(min) && isNaN(max)) ||
      (isNaN(min) && prix <= max) ||
      (min <= prix && isNaN(max)) ||
      (min <= prix && prix <= max)) {
      return true;
    }
    return false;
  }

  filterDate(idColonne, dataIndex, dataTarget) {
    const that = this;

    let row = that.table.row(dataIndex).data();

    if ($('#' + idColonne + '-min').val() == '' && $('#' + idColonne + '-max').val() == '') {
      $('#notif-filter-' + idColonne).addClass('col-no-filter')
    }
    else {
      $('#notif-filter-' + idColonne).removeClass('col-no-filter')
    }

    var min = $('#' + idColonne + '-min').val();
    var max = $('#' + idColonne + '-max').val();
    var date = moment(that.GetPropertyValue(row, dataTarget)).format("YYYY-MM-DD")

    // Si un des deux paramètres n'est pas complet, on désactive le filtre
    if((moment(min).format("DD/MM/YYYY") == "Invalid date") ||
    (moment(max).format("DD/MM/YYYY") == "Invalid date")) {
      return true;
    }
    // Si la date est invalide, on n'affiche pas
    else if((moment(date).format("DD/MM/YYYY") == "Invalid date") || (date === undefined)) {
      return false;
    }
    else if (moment(date).isBetween(min, max, undefined, '[]')) {
      return true;
    }
    return false;
  }

// Pre-builders

pre_dropdownSelectize (idColonne, dataTarget, nomChamp) {
  const that = this;

  let visibleRows = that.table.rows({ order: 'index', search: 'applied' })

  let dataCol = [];

  for (let i = 0; i < visibleRows.data().length; i++) {
    let item = visibleRows.data()[i];

    let data = that.GetPropertyValue(item, dataTarget)

    if (data == null) dataCol.push('');
    else dataCol.push(data);
  }

  // Recherche des occurrences uniques
  let listeDeChoix = dataCol.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  listeDeChoix.sort((a, b) => {
    if (a === '') return 1;
    else if (b === '') return -1;
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  that.dropdownSelectize(listeDeChoix, idColonne, nomChamp);
}




GetPropertyValue(obj1, dataToRetrieve) {
  return dataToRetrieve
    .split('.') // split string based on `.`
    .reduce(function(o, k) {
      return o && o[k]; // get inner property if `o` is defined else get `o` and return
    }, obj1) // set initial value as object
}














  public isAm (date) {
    if(moment(date).isBetween(moment(date).format("YYYY-MM-DD") + " 00:00:00", moment(date).format("YYYY-MM-DD") + " 11:59:00")) {
      return true;
    }
    else return false;
  }

  public formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }


  clickEvent(event) {

    const modalRef = this.modalService.open(CalendrierModalEventComponent, { size: 'llg' });
    modalRef.componentInstance.event = event;
    modalRef.result.then((result) => {
        this.refreshList();

        if(result == "update") {
          $(document).ready(function(){
            $.notify({
              message: 'Evenement mis à jour.'
            },{
              type: 'success'
            });
          });
        }
        else if(result == "delete") {
          $(document).ready(function(){
            $.notify({
              message: 'Evenement supprimé.'
            },{
              type: 'success'
            });
          });
        }

    }, (reason) => {});
  }

  displayDay(date) {
    return moment(date).format("D MMMM");
  }

}

