<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">
      <div class="page-header">
        <h1 class="page-title">
          Chiffre d'affaires
        </h1>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="card">

              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">Techniciens</label>
                          
                          <input type="text" class="form-control selectized" id="select-tech" value="" tabindex="-1" style="display: none;">
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-4">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">Bailleur</label>
                          <select class="form-control custom-select selectized" id="select-bailleur" tabindex="-1"
                            style="display: none;">
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">Marché</label>
                          <select class="form-control custom-select selectized" id="select-marche" tabindex="-1"
                            style="display: none;">
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Début</label>
                          <input class="form-control" type="date" [(ngModel)]="date_debut" (change)="formChange()">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Fin</label>
                          <input class="form-control" type="date" [(ngModel)]="date_fin" (change)="formChange()">
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">Granularité</label>
                          <select class="form-control custom-select selectized" id="select-granularite" tabindex="-1"
                            style="display: none;">
                          </select>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

              <div class="card-body">
                <canvas id="myChart" width="400" height="200"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>