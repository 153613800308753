<div class="modal-header">
    <h5 class="modal-title" id="newAppelModalLabel">Nouveau commentaire</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
    </button>
  </div>
  <div class="modal-body">
    <textarea class="form-control" name="commentaire_add_appel_modal" [(ngModel)]="commentaire" rows="6"
      placeholder="Commentaire"></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">Annuler</button>
    <button type="button" class="btn btn-primary" (click)="addCom()">Enregistrer le commentaire</button>
  </div>
  