import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import {
  BdcService
} from '../../../services/service-bdc.service';
import { AuthentificationService } from '../../../services/authentification.service';

declare var $: any;

@Component({
  selector: 'app-espace-perso',
  templateUrl: './espace-perso.component.html',
  styleUrls: ['./espace-perso.component.scss']
})
export class EspacePersoComponent implements OnInit {

  agence = {} as any;
  nomAgence = "";

  user = {} as any;
  notifs_user = {} as any;

  notifs_displayed = {
    planification_difficile: {
      daily: false,
      weekly: false,
      monthly: false
    },
    rapports_retard: {
      daily: false,
      weekly: false,
      monthly: false
    }
  }

  new_pw = '';
  new_pw_2 = '';
  old_pw = '';

  edit = false;

  constructor(private BdcService: BdcService, private router: Router, public authService: AuthentificationService) { }

  ngOnInit(): void {
    this.user = JSON.parse(this.authService.getUser());
    console.log(this.user)
    this.notifs_user = JSON.parse(this.user.mail_notifs)
    console.log(this.notifs_user)



    this.BdcService.getAgence(this.BdcService.getAgenceId()).then((agence: any) => {
      this.agence = agence;

      for (let subscription of this.notifs_user) {
        if(subscription.id_agence == this.agence.id) {
          console.log(subscription)

          switch (subscription.name) {
            case "planification_difficile":
              if(subscription.frequence == 'daily') this.notifs_displayed.planification_difficile.daily = true;
              if(subscription.frequence == 'weekly') this.notifs_displayed.planification_difficile.weekly = true;
              if(subscription.frequence == 'monthly') this.notifs_displayed.planification_difficile.monthly = true;
              break;

            case "rapports_retard":
              if(subscription.frequence == 'daily') this.notifs_displayed.rapports_retard.daily = true;
              if(subscription.frequence == 'weekly') this.notifs_displayed.rapports_retard.weekly = true;
              if(subscription.frequence == 'monthly') this.notifs_displayed.rapports_retard.monthly = true;
              break;
          
            default:
              break;
          }
        }
      }

      console.log(this.notifs_displayed)
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  editNom() {
    this.edit = true;
  }
  saveNom() {
    this.BdcService.updateTechnicien(this.user.id, this.user.nom, this.user.prenom, this.user.tel);

    this.edit = false;
  }

  change_pw() {
    this.authService.change_password(this.old_pw, this.new_pw, this.new_pw_2, this.user.mail);
  }



  update_reports() {
    console.log(this.notifs_displayed)

    var subscriptions = [];



    if(this.notifs_displayed.planification_difficile.daily) {
      subscriptions.push({
        id_agence: this.agence.id,
        name: "planification_difficile",
        frequence: "daily"
      })
    }
    if(this.notifs_displayed.planification_difficile.weekly) {
      subscriptions.push({
        id_agence: this.agence.id,
        name: "planification_difficile",
        frequence: "weekly"
      })
    }
    if(this.notifs_displayed.planification_difficile.monthly) {
      subscriptions.push({
        id_agence: this.agence.id,
        name: "planification_difficile",
        frequence: "monthly"
      })
    }
    if(this.notifs_displayed.rapports_retard.daily) {
      subscriptions.push({
        id_agence: this.agence.id,
        name: "rapports_retard",
        frequence: "daily"
      })
    }
    if(this.notifs_displayed.rapports_retard.weekly) {
      subscriptions.push({
        id_agence: this.agence.id,
        name: "rapports_retard",
        frequence: "weekly"
      })
    }
    if(this.notifs_displayed.rapports_retard.monthly) {
      subscriptions.push({
        id_agence: this.agence.id,
        name: "rapports_retard",
        frequence: "monthly"
      })
    }

    console.log(subscriptions)

    this.BdcService.updateMailReports(this.user.id, JSON.stringify(subscriptions));
    this.user.mail_notifs = JSON.stringify(subscriptions);
  }


}
