import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { AvisPassageComponent } from '../../liste-interventions/intervention/avis-passage/avis-passage.component';
import { ModalAppelsComponent } from '../../components/modal-appels/modal-appels.component';

import {
  BdcService
} from '../../../services/service-bdc.service';

declare var $: any;

@Component({
  selector: 'app-facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.scss']
})
export class FacturationComponent implements OnInit {
  idBdc = this.route.snapshot.params.idBdc

  bdc: any = {
    adresse_locataire: '',
    articles: [{}],
    avis_passage: '',
    avis_passage_exists: false,
    appels: [],
    bailleur: '',
    client: '',
    code_postal_locataire: '',
    cpt_adresse_locataire: '',
    date: '',
    date_reception: '',
    date_limite: '',
    id: '',
    id_bailleur: '',
    id_charge_secteur: '',
    id_marche: '',
    lien_pdf: '',
    marche: '',
    moyen_demande: '',
    no_affaire: '',
    no_bdc: '',
    no_devis: '',
    no_quitus: '',
    nom_locataire: '',
    objet_intervention: '',
    prix: '',
    tel_fixe_locataire: '',
    tel_portable_locataire: '',
    urgence: 1,
    ville_locataire: ''
  };

  prixFactu = 0;

  constructor(private router: Router, private fb: FormBuilder, private modalService: NgbModal, private BdcService: BdcService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.getBdc();
  }

  getBdc() {
    this.BdcService.getBdc(this.route.snapshot.params.idBdc).then((bdc) => {
      this.bdc = bdc;

      if (parseInt(this.bdc.prix) === 0) {
        this.bdc.prix = '';
      }
    });
  }

  majPrixFactu(prixFactu) {
    this.prixFactu = prixFactu;
  }


  // Appels
  appelsModal() {
    const modalRef = this.modalService.open(ModalAppelsComponent, { size: 'lg' });
      modalRef.componentInstance.idBdc = this.bdc.id;
      modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  // Avis de passage
  avisPassageModal() {
    const modalRef = this.modalService.open(AvisPassageComponent);
    modalRef.componentInstance.idBdc = this.bdc.id;
    modalRef.result.then((result) => {
      this.getBdc();
    }, (reason) => { });
  }

  saveFactu() {
    this.BdcService.bdcToFactureState(this.bdc.id).then((res) => {
      this.router.navigate(['bons-de-commande/post-facturation/' + this.bdc.id]);
    });
  }

  printFactu() {
    this.router.navigate(['facture/' + this.bdc.id]);
  }


  backState() {
    this.BdcService.bdcToInterventionState(this.bdc.id).then((res) => {
      this.router.navigate(['bons-de-commande/intervention/' + this.bdc.id]);
    });
  }

}







