<div class="modal-header">
  <h5 class="modal-title" id="newAppelModalLabel">{{bdc.id}}</h5>

  <div class="card-options mr-0">
    <button class="btn btn-sm btn-default" (click)="delete()"><i class="fe fe-trash"></i> Supprimer</button>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
  </div>

</div>

<div class="modal-body">

  <app-bon-de-commande [bdc]="bdc"></app-bon-de-commande>

</div>
