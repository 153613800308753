<div class="card">
  <div class="card-status bg-blue"></div>
  <div class="card-header">
    <h3 class="card-title">Appels</h3>
    <div class="card-options">
      <button class="btn btn-sm btn-default" (click)="appelsModal()">
        <span class="fe fe-plus"></span>
      </button>
    </div>
  </div>


  <div *ngIf="isAppels">
    <div class="card-body" *ngFor="let appel of appels">
      <div class="clearfix">
        <div class="float-left">
          <p class="text-muted">{{formatDate(appel.date)}}</p>
        </div>
        <div class="float-right">
          <p class="text-muted dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"></p>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <button class="dropdown-item btn-primary" type="button" (click)="edit(appel)"><i
                class="fe fe-edit"></i> Modifier</button>
            <button class="dropdown-item btn-danger" type="button" (click)="delete(appel)"><i
                class="fe fe-trash-2"></i> Supprimer</button>
          </div>
        </div>
      </div>
      <div *ngIf="!appel.edit">
          {{appel.commentaire}}
        </div>
        <div class="row justify-content-between" *ngIf="appel.edit">
          <div class="col-9">
            <textarea class="form-control" name="edit_commentaire" [(ngModel)]="appel.commentaire" rows="3"
              placeholder="appel.commentaire"></textarea>
          </div>
          <div class="col align-self-end">
            <button type="button" class="btn btn-primary" (click)="save_com(appel)">Enregistrer</button>
          </div>
        </div>
        <div *ngIf="appel.delete">
          <button type="button" class="btn btn-danger" (click)="delete_com(appel)">Supprimer</button>
        </div>
    </div>
  </div>

  <div class="card-body" *ngIf="!isAppels">
    <div class="clearfix">

      <div class="float-left">
        <p class="text-muted">Aucun appel</p>
      </div>
    </div>
  </div>
</div>
