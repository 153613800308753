import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { BdcService } from "../../services/service-bdc.service";

import { ListeBdcModalBdcComponent } from '../../components/liste-bdc/liste-bdc-modal-bdc/liste-bdc-modal-bdc.component';

declare var $: any;

@Component({
  selector: "app-liste-bdc",
  templateUrl: "./liste-bdc.component.html",
  styleUrls: ["./liste-bdc.component.scss"],
})
export class ListeBdcComponent implements OnInit, OnChanges {
  @Input() listeBdc;
  @Input() colonnes;
  @Input() datatables;
  @Input() tableId;
  @Input() filtres;
  @Input() minimal;
  @Input() action;
  @Input() select;

  @Output() clicItem = new EventEmitter<any[]>();
  @Output() majRequest = new EventEmitter<any[]>();
  @Output() selection = new EventEmitter<any[]>();

  datatableOptions;

  table;

  selectedRows = [];
  delete_selection_listener;

  isDataLoaded = false;

  constructor(public BdcService: BdcService, private router: Router, private modalService: NgbModal) {}

  ngOnInit() {
    this.initTable();
  }

  ngOnChanges() {
    this.newData();
  }

  ngOnDestroy() {
    $(document).off('click');
  }

  initTable() {
    const that = this;

    let columns = [] as any;

    let orderColumn = 0;

    this.isDataLoaded = false;

    if(localStorage.getItem('page_' + this.tableId) === null) {
      localStorage.setItem('page_' + that.tableId, '0');
    }

    if (typeof this.select !== "undefined") {
      orderColumn = 1;

      columns.push({
        render: function (data, type, row, meta) {
          return (
            '<input type="checkbox" id="select-' +
            row.id +
            '" class="editor-active tab-select">'
          );
        },
      });
    }
    if (this.colonnes.search("id") !== -1) {
      columns.push({
        title: "N° Quitus",
        name: "no_quitus",
        data: "id",
        render: function (data, type, row, meta) {
          return '<span class="text-muted">' + data + "</span>";
        },
      });
    }
    if (this.colonnes.search("no_bdc") !== -1) {
      columns.push({
        title: "N° BDC",
        name: "no_bdc",
        data: "no_bdc",
      });
    }
    if (this.colonnes.search("client") !== -1) {
      columns.push({
        title: "Client",
        name: "client",
        data: "client",
        render: function (data, type, row, meta) {
          return (
            row.bailleur +
            '<div class="small text-muted">' +
            row.marche +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("tel") !== -1) {
      columns.push({
        title: "Téléphone",
        name: "telephone",
        data: "tel_fixe_locataire",
        render: function (data, type, row, meta) {
          return (
            '<div class="small text-muted">' +
            row.tel_fixe_locataire +
            "</div>" +
            '<div class="small text-muted">' +
            row.tel_portable_locataire +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("date") !== -1) {
      columns.push({
        title: "Date",
        name: "date",
        data: "date_limite",
        render: function (data, type, row, meta) {
          return (
            "<div>" +
            that.BdcService.dateCompacte(row.date_limite) +
            "</div>" +
            '<div class="small text-muted">Date de création : ' +
            that.BdcService.dateCompacte(row.date_reception) +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("progression") !== -1) {
      columns.push({
        title: "Progression",
        name: "progression",
        data: "commande.progression",
        render: function (data, type, row, meta) {
          return (
            '<div class="clearfix">' +
            '<div class="float-left">' +
            '<small class="text-muted">' +
            that.BdcService.dateCompacte(row.date_reception) +
            "</small>" +
            "</div>" +
            '<div class="float-right">' +
            '<small class="text-muted">' +
            that.BdcService.dateCompacte(row.date_limite) +
            "</small>" +
            "</div>" +
            "</div>" +
            '<div class="progress progress-xs">' +
            '<div class="progress-bar bg-green" role="progressbar" ' +
            'style="width: ' +
            row.commande.progression +
            '%"></div>' +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("urgence") !== -1) {
      columns.push({
        title: "Urgence",
        name: "urgence",
        data: "urgence",
        render: function (data, type, row, meta) {
          if (data == 3) {
            return '<span class="icon"><span class="fa fa-bell evimportant"></span></span><span class="icon"><span class="fa fa-bell evimportant"></span></span><span class="icon"><span class="fa fa-bell evimportant"></span></span>';
          } else if (data == 2) {
            return '<span class="icon"><span class="fa fa-bell evwarning"></span></span><span class="icon"><span class="fa fa-bell evwarning"></span></span>';
          } else {
            return '<span class="icon"><span class="fa fa-bell"></span></span>';
          }
        },
      });
    }
    if (this.colonnes.search("objet_intervention") !== -1) {
      columns.push({
        title: "Objet de l'intervention",
        name: "objet_intervention",
        data: "objet_intervention_1",
        render: function (data, type, row, meta) {
          return (
            '<div class="small text-muted">' +
            row.objet_intervention_1 +
            "</div>" +
            '<div class="small text-muted">' +
            row.objet_intervention_2 +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("piece_a_commander_commentaire") !== -1) {
      columns.push({
        title: "Pièce à commander",
        name: "piece_a_commander_commentaire",
        data: "piece_a_commander_commentaire",
        render: function (data, type, row, meta) {
          return (
            '<div class="small text-muted">' +
            row.piece_a_commander_commentaire +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("devis_commentaire") !== -1) {
      columns.push({
        title: "Devis à réaliser",
        name: "devis_commentaire",
        data: "devis_commentaire",
        render: function (data, type, row, meta) {
          return (
            '<div class="small text-muted">' + row.devis_commentaire + "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("anomalie_commentaire") !== -1) {
      columns.push({
        title: "Anomalie",
        name: "anomalie_commentaire",
        data: "anomalie_commentaire",
        render: function (data, type, row, meta) {
          return (
            '<div class="small text-muted">' +
            row.anomalie_commentaire +
            "</div>"
          );
        },
      });
    }
    if (this.colonnes.search("status") !== -1) {
      columns.push({
        title: "Status",
        name: "status",
        data: "status",
        render: function (data, type, row, meta) {
          if (data === "" || data === "A_VALIDER") {
            return '<i class="text-muted fe fe-check-square"></i>';
          } else if (data === "A_PLANIFIER") {
            return '<i class="text-muted fe fe-calendar"></i>';
          } else if (data === "A_EXECUTER") {
            return '<i class="text-muted fe fe-truck"></i>';
          } else if (data === "A_FACTURER" || data === "FACTURE") {
            return '<i class="text-muted fe fe-dollar-sign"></i>';
          } else if (data === "ARCHIVE") {
            return '<i class="text-muted fe fe-book"></i>';
          } else {
            return '<i class="text-muted fe fe-trash"></i>';
          }
        },
      });
    }
    if (this.colonnes.search("prix") !== -1) {
      columns.push({
        title: "Prix com.",
        name: "prix_commande",
        data: "commande.prix_ht",
        render: function (data, type, row, meta) {
          return '<span class="text-muted">' + data.toFixed(2) + "</span>";
        },
      });
    }
    if (this.colonnes.search("prix_real") !== -1) {
      columns.push({
        title: "Prix réal.",
        name: "prix_intervention",
        data: "interventions.prix_ht",
        render: function (data, type, row, meta) {
          return '<span class="text-muted">' + data.toFixed(2) + "</span>";
        },
      });
    }

    this.datatableOptions = {
      data: that.listeBdc,
      columns: columns,
      order: [[orderColumn, "desc"]],
      stateSave: true,
      "drawCallback": function( settings ) {
        var api = this.api();

        // Si on est sur la page dont le no est enregistré, on peut commencer à autoriser des modifs de no de page futures
        if(api.page.info().page == parseInt(localStorage.getItem('page_' + that.tableId))) {
          that.isDataLoaded = true;
        }

        if(that.isDataLoaded) {
          localStorage.setItem('page_' + that.tableId, api.page.info().page);
        }

      },
      language: {
        sProcessing: "Traitement en cours...",
        sSearch: "Rechercher&nbsp;:",
        sLengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        sInfo:
          "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        sInfoEmpty:
          "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
        sInfoFiltered:
          "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        sInfoPostFix: "",
        sLoadingRecords: "Chargement en cours...",
        sZeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        sEmptyTable: "Aucune donn&eacute;e disponible dans le tableau",
        oPaginate: {
          sFirst: "Premier",
          sPrevious: "Pr&eacute;c&eacute;dent",
          sNext: "Suivant",
          sLast: "Dernier",
        },
        oAria: {
          sSortAscending: ": activer pour trier la colonne par ordre croissant",
          sSortDescending:
            ": activer pour trier la colonne par ordre d&eacute;croissant",
        },
        select: {
          rows: {
            _: "%d lignes selectionnées",
            0: "Aucune ligne selectionnée",
            1: "1 ligne selectionnée",
          },
        },
      },
    };

    if (typeof this.minimal !== "undefined") {
      this.datatableOptions.pageLength = 5;
      this.datatableOptions.searching = false;
      this.datatableOptions.lengthChange = false;
      this.datatableOptions.info = false;
    }

    $(document).ready(function () {
      that.table = $("#" + that.tableId).DataTable(that.datatableOptions);

      $("#" + that.tableId).on("click", "tbody tr td", function () {
        if ($($(this).children()[0]).is(".tab-select")) {
          // Si le clic est sur la checkbox

          if ($($(this).children()[0]).is(":checked")) {
            that.selectRow(that.table.row($(this).parent()).data().id);
          } else {
            that.deselectRow(that.table.row($(this).parent()).data().id);
          }
        } else {
          that.clicBdc(that.table.row($(this).parent()).data());
        }
      });

      that.newData();
    });
  }

  selectRow(id) {
    const that = this;

    if (this.selectedRows.indexOf(id) === -1) {
      this.selectedRows.push(id);

      if (this.selectedRows.length === 1) {
        that.actionSelection();
      }
    }

    console.log(this.selectedRows);
  }

  deselectRow(id) {
    if (this.selectedRows.indexOf(id) !== -1) {
      this.selectedRows.splice(this.selectedRows.indexOf(id), 1);

      if (this.selectedRows.length === 0) {
        $("#action-selection").remove();
        this.delete_selection_listener.off();
      }
    }

  }

  // Sélection de lignes via la checkbox
  actionSelection() {
    const that = this;

    let textButton = '';
    let actionFct = function(id) {
    };

    // Création du boutton
    let createButton = function() {
      $(".dataTables_length").after(
        '<label><button id="action-selection" class="btn btn-primary btn-sm" style="margin: 1rem 1rem">' + textButton + '</button></label>'
      );

      // Action lors du clic sur le bouton
      that.delete_selection_listener = $("#action-selection").on(
        "click",
        function () {

          // On effectue l'action sur chacun des items sélectionnés
          for (let i = 0; i < that.selectedRows.length; i++) {
            actionFct(that.selectedRows[i]);
          }

          that.selectedRows = [];
          $("#action-selection").remove();
          that.delete_selection_listener.off();
          that.majRequest.emit();
        }
      );
    }


    if (that.select === "corbeille") {
      textButton = 'Supprimer';

      actionFct = function(id_bdc) {
        that.BdcService.supprimerBdc(id_bdc).then(() => {});
      };

      createButton();
    }
    else if (that.select === "validation") {
      textButton = 'Passer en validation';

      actionFct = function(id_bdc) {
        that.BdcService.bdcToValidationState(id_bdc).then(() => {});
      };

      createButton();
    }
    else if (that.select === "planification") {
      textButton = 'Passer en planification';

      actionFct = function(id_bdc) {
        that.BdcService.bdcToPlanificationState(id_bdc).then(() => {});
      };

      createButton();
    }
    else if (that.select === "export_factu") {

      $(".dataTables_length").after(
        '<label><button id="action-selection" class="btn btn-primary btn-sm" style="margin: 1rem 1rem">Exporter la sélection</button></label>'
      );

      that.delete_selection_listener = $("#action-selection").on(
        "click",
        function () {

          let tabSelectedBdc = [];

          for (let i = 0; i < that.selectedRows.length; i++) {

            tabSelectedBdc.push(
              that.listeBdc.find(element => {
                return element.id === that.selectedRows[i];
              })
            );

          }

          that.selection.emit(tabSelectedBdc);

          /*
          that.selectedRows = [];
          $("#action-selection").remove();
          that.delete_selection_listener.off();
          that.majRequest.emit();
          */
        }
      );
    }

    this.newData();

  }

  newData() {
    const that = this;

    if (this.table !== undefined) {

      this.table.clear();
      this.table.rows.add(this.listeBdc);

      this.table.draw(false);

      // Mise à jour de la page uniquement si celel-ci existe toujours
      if(parseInt(localStorage.getItem('page_' + this.tableId)) < this.table.page.info().pages) {
        this.table.page(parseInt(localStorage.getItem('page_' + this.tableId))).draw(false);
      }
      else {
        this.table.page(0).draw(false);
      }

    }
  }

  clicBdc(bdc) {
    //console.log(bdc.status);

    if (typeof this.action == "undefined") {
      this.clicItem.emit(bdc.id);



      switch (bdc.status) {
        case "": {
          this.router.navigate(["bons-de-commande/validation/" + bdc.id]);
          break;
        }
        case "A_VALIDER": {
          this.router.navigate(["boite-de-reception/validation/" + bdc.id]);
          break;
        }
        /*case "A_PLANIFIER": {
          this.router.navigate(["bons-de-commande/planification/" + bdc.id]);
          break;
        }
        case "A_EXECUTER": {
          this.router.navigate(["bons-de-commande/intervention/" + bdc.id]);
          break;
        }
        case "A_FACTURER": {
          this.router.navigate(["bons-de-commande/facturation/" + bdc.id]);
          break;
        }
        case "FACTURE": {
          this.router.navigate(["bons-de-commande/post-facturation/" + bdc.id]);
          break;
        }
        case "ARCHIVE": {
          this.router.navigate(["bons-de-commande/historique/" + bdc.id]);
          break;
        }
        case "CORBEILLE": {
          //statements;
          break;
        }*/
        default: {
          const modalRef = this.modalService.open(ListeBdcModalBdcComponent, {
            size: "llg",
          });
          modalRef.componentInstance.bdc = bdc;
          modalRef.result.then(
            (result) => {
              console.log("result")
              //this.majListe();
            },
            (reason) => {
              console.log("reason")
              //this.majListe();
            }
          );
          break;
        }
      }
    } else {
      this.clicItem.emit(bdc.id);
    }
  }
}
