<div id="home" class="home">
  <div class="my-3 my-md-5">
    <div class="container">




      <div class="row row-cards row-deck">
        <div class="col-12">

          <div class="row">
            <div class="col-12">
              <div>
                <div class="row">
                  <div class="col-10">

                    <ul ngbNav #navFactu="ngbNav" class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="margin-top: 16px;">

                      <li ngbNavItem class="nav-item">
                        <a ngbNavLink id="pills-home-tab">Bons à facturer</a>
                        <ng-template ngbNavContent>
                          <div class="card">

                            <app-liste-bdc [listeBdc]="listeFacturation" [tableId]="'listeFacturation'" [datatables]="true"
                              [colonnes]="'is_identifiant id no_bdc client date urgence objet_intervention prix_real'"
                              [select]="'export_factu'" (selection)="exportSelected($event)">

                            </app-liste-bdc>

                          </div>
                        </ng-template>
                      </li>

                      <li ngbNavItem class="nav-item">
                        <a ngbNavLink id="pills-profile-tab">Bons facturés</a>

                        <ng-template ngbNavContent>
                          <div class="card">

                            <app-liste-bdc [listeBdc]="listeFacturationFaite" [tableId]="'listeFacturationFaite'"
                              [datatables]="true"
                              [colonnes]="'is_identifiant id no_bdc client date urgence objet_intervention prix_real'">
                            </app-liste-bdc>


                          </div>
                        </ng-template>
                      </li>

                    </ul>

                  </div>
                  <div class="col-2">
                    <button type="button" class="btn btn-primary float-right" (click)="exportAll()"><span class="fe fe-file"></span> Tout exporter</button>
                  </div>
                </div>


                <div [ngbNavOutlet]="navFactu"></div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
